export default (
  state = {
    siteList: [],
    isFetching: false,
    error: null,
    shouldRefresh: false,
    siteOpen: null
    // siteDisplay: ""
  },
  action
) => {
  switch (action.type) {
    case "SITE_LIST_REQUESTED":
    case "SITE_ADD_REQUESTED":
    case "SITE_UPDATE_REQUESTED":
    case "SITE_DELETE_REQUESTED":
    case "SITE_UPGRADE_REQUESTED":
      return {
        ...state,
        isFetching: true,
        shouldRefresh: false
      }
    case "SITE_MODIFY_RECEIVED": {
      let { siteList } = state
      const newList = siteList.map(site => {
        if (site._id === action.site._id) {
          return action.site
        } else {
          return site
        }
      })

      return {
        ...state,
        isFetching: false,
        site: action.site,
        siteList: newList,
        error: null,
        lastUpdated: Date.now()
      }
    }
    case "SITE_SELECTED": {
      return { ...state, siteOpen: action.siteOpen }
    }
    case "SITE_ADD_RECEIVED": {
      const { siteList } = state
      const newList = [...siteList, action.site]
      return {
        ...state,
        isFetching: false,
        site: action.site,
        siteList: newList,
        error: null,
        lastUpdated: Date.now()
      }
    }
    case "SITE_LIST_RECEIVED":
      return {
        ...state,
        isFetching: false,
        shouldRefresh: false,
        siteList: action.siteList,
        lastUpdated: Date.now()
      }
    case "SITE_DELETE_RECEIVED": {
      let { siteList } = state

      let newList = siteList.filter(function(item) {
        if (item.siteId == action.siteId) {
          return false
        } else {
          return true
        }
      })
      return {
        ...state,
        isFetching: false,
        site: action.site,
        siteList: newList,
        error: null,
        lastUpdated: Date.now()
      }
    }
    case "SITE_AJAX_SUCCESS":
      return { ...state, isFetching: false }

    case "SITE_AJAX_ERROR":
      return {
        ...state,
        isFetching: false,
        shouldRefresh: true,
        error: action.error
      }

    case "SITE_RESET_ERROR":
      return { ...state, error: null }

    default:
      return state
  }
}
