import React, { useState } from "react"
import { useSelector } from "react-redux"

import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Dialog from "@material-ui/core/Dialog"
import Fade from "@material-ui/core/Fade"
import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"
import useTheme from "@material-ui/core/styles/useTheme"

import DeleteIcon from "@material-ui/icons/Delete"
import { tr } from "translations"

const LoadModal = props => {
  const {
    classes,
    isOpen,
    closeMenu,
    onSave,
    target,
    favourites,
    widgetTypes,
    deleteFavourite
  } = props

  const [selection, setSelection] = useState("")
  const [types, setTypes] = useState(widgetTypes)
  const [targetSlot] = useState(target)
  const [selectedWidget, setSelectedWidget] = useState(null)

  const isDark = useSelector(state => state.ui.currentTheme === "dark")

  const theme = useTheme()

  const checkIfSelected = (obj, de) => {
    // onko annettu nimi sama kuin selectec object

    if (obj === selection) {
      return de ? "#a44" : "green"
    } else {
      return null
    }
  }

  const handleSave = () => {
    onSave(selectedWidget, targetSlot)
    closeMenu()
  }

  const handleType = value => () => {
    let temp = types.slice()
    if (temp.includes(value)) {
      temp.splice(temp.indexOf(value), 1)
      if (selectedWidget && selectedWidget.type === value) {
        setSelectedWidget(null)
        setSelection("")
      }
    } else {
      temp.push(value)
    }

    setTypes(temp)
  }

  const handleSelect = value => () => {
    setSelection(value)
    let fav = favourites.find(elem => elem.name === value)
    setSelectedWidget(fav)
  }

  const deleteSelect = value => () => {
    if (selectedWidget && selectedWidget.name === value.name) {
      setSelectedWidget(null)
      setSelection("")
    }

    deleteFavourite(value)
  }

  return (
    <Dialog
      open={isOpen}
      onClose={closeMenu}
      aria-labelledby="simple-dialog-title"
    >
      <Fade in={isOpen} timeout={700}>
        <Paper className={classes.menuPaper}>
          <Grid container>
            <Grid
              item
              xs={12}
              container
              alignItems="center"
              className={classes.title}
            >
              <Typography
                variant="h6"
                color={isDark ? "primary" : "textPrimary"}
              >
                {tr("_open_widget")}
              </Typography>
            </Grid>

            <Grid item xs={12} container justify="flex-start">
              {widgetTypes.map((type, index) => {
                if (type != "DefaultWidget")
                  return (
                    <Button
                      variant="outlined"
                      className={classes.button}
                      key={index}
                      id={type}
                      onClick={handleType(type)}
                      style={{
                        backgroundColor: types.includes(type)
                          ? theme.palette.action.main
                          : "gray",
                        fontSize: "10px"
                      }}
                    >
                      {type}
                    </Button>
                  )
              })}
            </Grid>

            <Paper className={classes.selectPaper}>
              {true && (
                <Accordion square expanded={true}>
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Typography variant="body1">
                      {tr("_saved_widgets")}
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails className={classes.topSummary}>
                    <div style={{ width: "100%" }}>
                      {favourites.map((fav, index) => {
                        return (
                          types.includes(fav.type) && (
                            <Grid key={index} item xs={12}>
                              <Button
                                variant="outlined"
                                className={classes.smallbutton}
                                onClick={handleSelect(fav.name)}
                                style={{
                                  backgroundColor: checkIfSelected(fav.name)
                                }}
                              >
                                <Grid container justify="flex-start">
                                  {fav.name.length > 50
                                    ? fav.name.slice(0, 45) + "..."
                                    : fav.name}
                                </Grid>
                              </Button>

                              <Button
                                variant="outlined"
                                className={classes.smallbutton_del}
                                onClick={deleteSelect(fav)}
                                style={{
                                  backgroundColor: checkIfSelected(
                                    fav.name,
                                    true
                                  )
                                }}
                              >
                                <Grid container justify="center">                                
                                  <DeleteIcon
                                    className={classes.listIconLight /*isDark ? classes.listIconLight : classes.listIcon */}
                                  />                                                                 
                                </Grid>
                              </Button>
                            </Grid>
                          )
                        )
                      })}
                    </div>
                  </AccordionDetails>
                </Accordion>
              )}
            </Paper>

            <Grid
              item
              xs={12}
              container
              className={classes.buttons}
              justify="flex-end"
            >
              <Button
                variant="outlined"
                className={classes.big_button}
                onClick={closeMenu}
                style={{
                  backgroundColor: "gray"
                }}
              >
                {tr("_cancel")}
              </Button>
              <Button
                variant="contained"
                className={classes.big_button}
                style={{
                  backgroundColor: theme.palette.action.main
                }}
                onClick={handleSave}
              >
                {tr("_open")}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Fade>
    </Dialog>
  )
}


const styles = theme => ({
  big_button: {
    margin: theme.spacing(1),
    width: 125,
    height: 40
  },
  button: {
    margin: theme.spacing(1),
    marginTop: 0,
    width: 125,
    height: 30
  },
  smallbutton: {
    margin: 0,
    width: "86%",
    height: 30
  },
  smallbutton_del: {
    margin: 0,
    marginLeft: 4,
    width: "2%",
    height: 30
  },
  buttons: {
    marginTop: 25
  },
  menuPaper: {
    backgroundColor: theme.palette.popup.background,
    width: 600,
    minHeight: 400,
    padding: theme.spacing(4)
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  topSummary: {
    padding: 0,
    paddingLeft: 10,
    paddingRight: 10,
    marginBottom: 10
  },
  selectPaper: {
    backgroundColor: theme.palette.popup.background,
    overflow: "scroll",
    width: "100%",
    maxHeight: 600
  },
  listIcon: {
    color: theme.palette.primary.dark
  },
  listIconLight: {
    color: theme.palette.text.primary
  }
})

export default withStyles(styles)(LoadModal)
