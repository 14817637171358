import React from "react"

import ButtonBase from "@material-ui/core/ButtonBase"

import Grid from "@material-ui/core/Grid"
import withStyles from "@material-ui/core/styles/withStyles"
import MoreHorizIcon from "@material-ui/icons/MoreHoriz"

const DashboardMenu = props => {
  const { classes, openMenu } = props

  return (
    <Grid container item xs={12} justify="flex-end">
      <ButtonBase onClick={openMenu} className={classes.button}>
        <MoreHorizIcon fontSize="large" className={classes.icon} />
      </ButtonBase>
    </Grid>
  )
}

const styles = theme => ({
  icon: {
    color: theme.palette.text.primary
  },
  button: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0)
    }
  }
})

export default withStyles(styles)(DashboardMenu)
