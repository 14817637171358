import React, { useEffect, useState } from "react"

import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import ButtonBase from "@material-ui/core/ButtonBase"
import Popover from "@material-ui/core/Popover"
import Zoom from "@material-ui/core/Zoom"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"

import useTheme from "@material-ui/core/styles/useTheme"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { useDispatch, useSelector } from "react-redux"
import { tr } from "translations"
import AddFile from "./parts/AddFile"
import {
  fetchAreas,
  DeleteArea,
  ModifyArea,
  deleteImage
} from "../../actions/areas"
import CloseIcon from "@material-ui/icons/Close"
import AddBoxIcon from "@material-ui/icons/AddBox"
import SettingsButtons from "../Dashboard/DashboardWidgets/settingModals/parts/settingsButtons"

export default function EditArea(props) {
  const { onClose, isOpen, handleAddArea, handleAddDevice } = props

  const [floor, setFloor] = useState(null)
  const [info, setInfo] = useState(null)
  const [name, setName] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [isSaving, setIsSaving] = useState(false)

  const classes = useStyles()
  const dispatch = useDispatch()
  const theme = useTheme()

  const areaOpen = useSelector(state => state.areas.areaOpen)

  useEffect(() => {
    if (areaOpen) {
      setName(areaOpen.name)
    }
  }, [areaOpen])
  /** Delete area and it's images in Blob storage */
  const handleDeleteArea = () => {
    dispatch(DeleteArea(areaOpen._id)).then(() => onClose())
  }

  /**
   * Remove uploaded new image from state. Existing image needs
   * to be removed from Blob storage. Also image reference is
   * removed from area
   */
  const handleDeleteFile = () => {
    const siteObject = areaOpen
    const imageFromProps = areaOpen
      ? areaOpen.image.replace(/^.*[\\/]/, "")
      : null

    dispatch(deleteImage(imageFromProps, siteObject, "selectedFile"))
      .then(() => {
        setSelectedFile(null)
      })
      .catch(err => console.log(err))
  }

  /** Save uploaded file to state */
  const handleselectedFile = event => {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0])
    }
  }

  /**
   * Save or clear form button handlers
   */
  const handleSave = () => {
    const siteObject = areaOpen
    const updatedArea = { _id: siteObject._id }
    updatedArea.floor = floor !== null ? floor : areaOpen.floor
    updatedArea.info = info !== null ? info : areaOpen.info
    updatedArea.name = name !== null ? name : areaOpen.name

    /** If image has been changed, new image is in state */
    const file1 = selectedFile !== null ? selectedFile : null

    handleSaveStatus()
    dispatch(ModifyArea(updatedArea, file1, null))
      .then(() => {
        dispatch(fetchAreas())
        handleSaveStatus()
        onClose()
      })
      .catch(err => {
        console.log("Editing area failed", err)
        handleSaveStatus()
      })
  }

  const handleCancel = () => {
    setFloor(null)
    setInfo(null)
    setName(null)
    setSelectedFile(null)
    onClose()
  }

  const handleSaveStatus = () => {
    setIsSaving(!isSaving)
  }

  return (
    <Popover
      id={isOpen ? "another-popover" : undefined}
      open={isOpen}
      anchorReference="anchorPosition"
      anchorPosition={{ top: 300, left: window.innerWidth / 2 }}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      TransitionComponent={Zoom}
    >
      <Paper className={classes.paper}>
        <Grid
          item
          xs={12}
          container
          justify="space-between"
          alignItems="center"
          className={classes.textField}
        >
          <Typography variant="h6">{tr("Edit_area")}</Typography>
          <ButtonBase size="large" onClick={onClose}>
            <CloseIcon fontSize="large" className={classes.icon} />
          </ButtonBase>
        </Grid>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <TextField
              label={tr("Floor_name")}
              name="name"
              className={classes.textField}
              value={name !== null ? name : areaOpen ? areaOpen.name : ""}
              onChange={event => setName(event.target.value)}
              margin="normal"
              InputLabelProps={{
                style: { color: theme.palette.text.fixed, fontWeight: 500 }
              }}
              inputProps={{
                style: { color: theme.palette.text.fixed }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={tr("Floor_number")}
              name="floor"
              type="number"
              className={classes.textField}
              value={floor !== null ? floor : areaOpen ? areaOpen.floor : ""}
              onChange={event => setFloor(event.target.value)}
              margin="normal"
              InputLabelProps={{
                style: { color: theme.palette.text.fixed, fontWeight: 500 }
              }}
              inputProps={{
                style: { color: theme.palette.text.fixed }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={tr("Info")}
              name="info"
              value={info !== null ? info : areaOpen ? areaOpen.info : ""}
              onChange={event => setInfo(event.target.value)}
              margin="normal"
              InputLabelProps={{
                style: { color: theme.palette.text.fixed, fontWeight: 500 }
              }}
              inputProps={{
                style: { color: theme.palette.text.fixed }
              }}
            />
          </Grid>
          <AddFile
            title={tr("Upload_ground_plan")}
            id="firstId"
            selectedFile={
              selectedFile !== null
                ? selectedFile.name
                : areaOpen
                  ? areaOpen.image
                      ? areaOpen.image.replace(/^.*[\\/]/, "")
                      : null
                  : null
            }
            handleDeleteFile={handleDeleteFile}
            handleselectedFile={handleselectedFile}
          />
          <Grid item xs={12}>
            <Typography className={classes.topicField} variant="subtitle2">
              {tr("_addNew")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            container
            className={classes.addButtonField}
            onClick={handleAddArea}
          >
            <AddBoxIcon />
            <Typography variant="subtitle1">
              <b>{tr("Add_layer_to_site")}</b>
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            container
            className={classes.addButtonField}
            onClick={handleAddDevice}
          >
            <AddBoxIcon />
            <Typography variant="subtitle1">
              <b>{tr("Add_devices")}</b>
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={4}>
              <Button
                variant="outlined"
                className={classes.deleteButtonField}
                onClick={handleDeleteArea}
              >
                {tr("_delete_floor")}
              </Button>
            </Grid>
            <Grid item xs={8}>
              <SettingsButtons
                handleSave={handleSave}
                closeMenu={handleCancel}
                disabled={!name}
                isSaving={isSaving}
                groundPlan={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Popover>
  )
}

const useStyles = makeStyles(theme => ({
  paper: {
    width: 500,
    minHeight: 400,
    padding: 15,
    backgroundColor: theme.palette.popup.background
  },
  addButtonField: {
    color: theme.palette.button.fixedAction,
    cursor: "pointer",
    marginTop: theme.spacing(2),
    textDecoration: "underline"
  },
  deleteButtonField: {
    height: 40,
    marginTop: theme.spacing(2),
    borderColor: theme.palette.error.fixed,
    color: theme.palette.error.fixed
  },
  dialogRoot: {
    minWidth: 500,
    maxWidth: 600,
    margin: "auto"
  },
  textField: { color: theme.palette.text.fixed },
  topicField: {
    color: theme.palette.text.fixed,
    marginTop: theme.spacing(2),
    fontWeight: "bold"
  }
}))
