import React from "react"

import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"
// import IconStar from "@material-ui/icons/StarBorder"
import logoImage from "./../../images/ajrsolutions_logo.jpg"

function SiteTile(props) {
  const { onClick, siteId, line1, line2, line3, line4, classes, logo } = props
  const handleClick = () => {
    if (onClick) {
      onClick(siteId)
    }
  }
  return (
    <Paper
      onClick={handleClick}
      className={classes.paper}
      variant="elevation"
      style={{ display: "flex", flexDirection: "row", elevation: 2 }}
    >
      {/* Company logo */}
      <Grid
        container
        direction="column"
        justify="center"
        style={{ width: "auto" }}
      >
        <Grid item>
          <div style={{
            width: 50,
            height: 50,
            marginLeft: "10px",
            marginRight: "10px",
            backgroundImage: "url(" + logo || logoImage + ")",
            backgroundSize: "cover",
            backgroundPosition: "center"
          }}
          >
          </div>

        </Grid>
      </Grid>

      {/* Name, number of devices, address, country */}
      <Grid container direction="column" justify="center" style={{}}>
        <Grid item>
          <Typography className={classes.header}>{line1}</Typography>
        </Grid>
        <Grid item>
          <Typography style={{ fontSize: 12, flex: 1 }}>{line2}</Typography>
        </Grid>
        <Grid item>
          <Typography style={{ fontSize: 16, flex: 1 }}>{line3}</Typography>
        </Grid>
        <Grid item>
          <Typography style={{ fontSize: 12, fontStyle: "italic" }}>
            {line4}
          </Typography>
        </Grid>
      </Grid>

      {/* Top right corner star */}
      {/* <IconStar style={{ marginRight: "5px", marginTop: "5px" }}></IconStar> */}
    </Paper>
  )
}
const styles = theme => ({
  paper: {
    minWidth: 300,
    minHeight: 115,
    padding: theme.spacing(1),
    background: theme.palette.primary.main
  },
  header: {
    fontSize: 20,
    flex: 1,
    color: theme.palette.action.main
  }
})

export default withStyles(styles, { withTheme: true })(SiteTile)
