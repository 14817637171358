import { stateType } from "../constants/actions"
import { parseErrorData } from "../services/errors"
import { setLanguage } from "translations"

const resetErr = () => ({ type: "RESET_ERROR" })
const resetInfo = () => ({ type: "RESET_INFO" })
const setNewLanguage = language => ({
  type: stateType.CHANGE_LANGUAGE,
  language
})
const requestFailed = error => ({
  type: stateType.SET_ERROR,
  error
})

const resetViews = () => ({ type: "UI_RESET_VIEWS" })

export const clearError = () => async dispatch => {
  try {
    return Promise.resolve(dispatch(resetErr()))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(requestFailed(errorReply)))
  }
}
export const clearInfo = () => async dispatch => {
  try {
    return Promise.resolve(dispatch(resetInfo()))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(requestFailed(errorReply)))
  }
}

export const changeLanguage = language => async dispatch => {
  try {
    // const state = getState()
    // let newLanguage = "FI"
    // if (state.ui.language === "FI") {
    //   newLanguage = "EN"
    // }
    setLanguage(language)

    return Promise.resolve(dispatch(setNewLanguage(language)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(requestFailed(errorReply)))
  }
}

export const changeTheme = theme => dispatch => {
  dispatch({ type: stateType.CHANGE_THEME, theme })
}
export const changeAlertHistoryVisibility = showAlertHistory => dispatch => {
  dispatch({ type: stateType.CHANGE_ALERTHISTORY_VISIBILITY, showAlertHistory })
}

export const startFetching = target => ({ type: "UI_START_FETCHING", target })
export const dataReceived = (target, data) => ({
  type: "UI_DATA_RECEIVED",
  target,
  data
})
export const errorEvent = (target, error, description) => ({
  type: "UI_ERROR",
  target,
  description,
  error
})

export const resetError = () => async dispatch => {
  setTimeout(() => {
    dispatch(resetErr())
  }, 3000)
}

export const resetviews = () => async dispatch => {
  dispatch(resetViews())
}

const updateTargetState = (target, newValue) => ({
  type: "UI_UPDATE_STATE",
  target,
  newValue
})

export const updateUIState = (target, newValue) => async dispatch => {
  dispatch(updateTargetState(target, newValue))
}

export const updateNavigation = (target, newValue) => async dispatch => {
  dispatch(updateTargetState("UI_NAVIGATION", newValue))
  dispatch({ type: "ADD_CATEGORIES", target: "ORDERHISTORY", newValue })
}

export const changePageAndDialogState = (pageState, dialogState, data) =>
  updateUIState("UI_NAVIGATION", { pageState, dialogState, ...data })
export const changePageState = (state, id) =>
  updateUIState("UI_NAVIGATION", { pageState: state, id })

export const changeDialogState = dialogState =>
  updateUIState("UI_NAVIGATION", { dialogState })

export const changeDialogStateAndDisable = (
  dialogState,
  delay
) => async dispatch => {
  dispatch(
    updateUIState("UI_NAVIGATION", { dialogState, dialogDisabled: true })
  )
  setTimeout(
    () =>
      dispatch(
        updateUIState("UI_NAVIGATION", { dialogState, dialogDisabled: false })
      ),
    delay
  )
}

export const closeDialog = () =>
  updateUIState("UI_NAVIGATION", { pageState: "LIST" })
export const editDialog = () => changeDialogState("EDIT")
export const displayDialog = () => changeDialogState("DISPLAY")
