import React from "react"

import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"
import image from "../../images/ajrsolutions_logo.jpg"
import { tr } from "translations"

const SiteSummary = props => {
  const { classes, site, deviceCount, siteColor, customer } = props

  return (
    <Card className={classes.card}>
      <CardMedia
        component="img"
        className={classes.image}
        image={site.image || (customer ? customer.logo : undefined) || image}
      />
      <CardContent className={classes.cardContent}>
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="stretch"
          wrap="nowrap"
        >
          <Grid item zeroMinWidth>
            <Typography variant="h4" noWrap style={{ color: siteColor }}>
              {site.name}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2">
              {deviceCount === 1
                ? `${deviceCount} ${tr("_one_device")}`
                : `${deviceCount} ${tr("_n_devices")}`}
            </Typography>
          </Grid>
          <Grid item zeroMinWidth>
            <Typography variant="subtitle1" noWrap>
              {site.address}, {site.city}{" "}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2">{site.country}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

const styles = theme => ({
  card: {
    backgroundcolor: theme.palette.secondary.themed,
    display: "flex",
    width: 400,
    borderWidth: 1,
    borderColor: theme.palette.primary.light,
    borderStyle: "solid"
  },
  image: {
    width: 150,
    margin: "10px"
  }
})

export default withStyles(styles, { withTheme: true })(SiteSummary)
