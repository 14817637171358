import React, { useEffect, useState } from "react"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormLabel from "@material-ui/core/FormLabel"
import RadioGroup from "@material-ui/core/RadioGroup"
import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import createStyles from "@material-ui/core/styles/createStyles"
import withStyles from "@material-ui/core/styles/withStyles"

import CustomRadio from "../elements/CustomRadio"
import { tr } from "translations"

const LimitData = props => {
  const { classes, minValue, maxValue, handleChange, rowid, limitType } = props

  const [error, setError] = useState(false)

  useEffect(() => {
    setError(parseInt(maxValue, 10) <= parseInt(minValue, 10))
  }, [minValue, maxValue])

  const changeValue = event => {
    handleChange(event.target.name, rowid, event.target.value)
  }
  return (
    <Grid container direction="row" className={classes.grid} spacing={1}>
      <Grid container item xs={5} alignItems="center">
        <FormControl component="fieldset">
          <FormLabel color="secondary">
            <Typography className={classes.colorSecondary}>
              {tr("_limitType")}
            </Typography>
          </FormLabel>
          <RadioGroup
            value={limitType}
            name={"limitType"}
            onChange={changeValue}
          >
            <FormControlLabel
              classes={{
                labelPlacementStart: classes.labelPlacementStart
              }}
              labelPlacement="start"
              value="average"
              control={
                <CustomRadio
                  outer={classes.customRadio}
                  inner={classes.customRadioInner}
                />
              }
              label={tr("_average")}
            />
            <FormControlLabel
              classes={{
                labelPlacementStart: classes.labelPlacementStart
              }}
              labelPlacement="start"
              value="current"
              control={
                <CustomRadio
                  outer={classes.customRadio}
                  inner={classes.customRadioInner}
                />
              }
              label={tr("_value")}
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid container item xs={7}>
        <Grid container item justify="flex-start" xs={12}>
          <Typography>{tr("_alert_if")} </Typography>
        </Grid>
        <Grid container item xs={12} alignItems="center">
          <Grid item xs={6}>
            <Typography>{tr("_value_below")}</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              error={error}
              name={"minValue"}
              type="number"
              value={minValue}
              onChange={changeValue}
              margin="normal"
              helperText={error ? tr("_limitInputError") : null}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} alignItems="center">
          <Grid item xs={6}>
            <Typography>{tr("_value_above")}</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              error={error}
              name={"maxValue"}
              type="number"
              value={maxValue}
              onChange={changeValue}
              margin="normal"
            />
          </Grid>
        </Grid>
      </Grid>
  
    </Grid>
  )
}

const styles = (theme) => 
  createStyles({ grid: {
    minWidth: "150px",
    background: "transparent",
    margin: "auto",
    height: "100%",
    flexGrow: 1,
    overflowX: "auto",
    maxWidth: "96%"
  },
  labelPlacementStart: {
    justifyContent: "space-between",
    marginLeft: "0px"
  },
  customRadio: {
    borderRadius: "50%",
    width: 20,
    height: 20,
    border: "1px solid " + theme.palette.text.primary
  },
  customRadioInner: {
    position: "absolute",
    borderRadius: "50%",
    height: "10px",
    width: "10px",
    top: "50%",
    left: "50%",
    margin: "-5px 0px 0px -5px",
    backgroundColor: theme.palette.action.main + " !important"
  },
  colorSecondary: {
    color: theme.palette.action.main
  }
  })


export default withStyles(styles, { withTheme: true })(LimitData)
