import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"

import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import * as jwt from "jsonwebtoken"
import * as React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { tr } from "translations"
import { logOut } from "../actions/auth"
import { validate } from "../actions/registration"
import { resetForgottenPassword } from "../actions/users"
import TextLine from "../components/TextLine"
import { checkPassword } from "../services/utils"

class RegisterForgot extends React.Component {
  constructor(props) {
    super(props)
    let customer = ""
    let email = ""
    let role = "user"
    if (props.token) {
      const token = jwt.decode(props.token)
      email = token.email
      role = token.role
      customer = token.customerId
    }
    this.state = {
      username: "",
      password: "",
      email: email,
      phoneNumber: "",
      role: role,
      customer: customer,
      error: null,
      isSending: false,
      submitSuccessful: false,
      info: false,
      infoText: ""
    }
    this.resetError = this.resetError.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.onInfoTextLinePress = this.onInfoTextLinePress.bind(this)
  }

  componentDidMount() {
    this.setState({
      info: false,
      infoText: "",
      submitSuccessful: false
    })
    if (this.props.stateToken) {
      this.props.logOut()
    }
  }

  onInfoTextLinePress() {
    this.setState({
      info: false,
      infoText: ""
    })
  }

  handleChange({ target }) {
    this.setState({ [target.name]: target.value, formUpdated: true })
  }

  resetError() {
    this.setState({ error: null })
  }

  async handleSubmit(e) {
    e.preventDefault()
    const { password } = this.state

    const passwordChecked = checkPassword(password)
    if (this.props.token && passwordChecked === "ok") {
      this.props
        .resetForgottenPassword({ token: this.props.token, password })
        .then(() => {
          this.setState({
            info: false,
            infoText: "",
            submitSuccessful: true
          })
          setTimeout(() => {
            this.props.history.push("/login")
          }, 5000)
        })
        .catch(err => console.log("Error", err))
    } else {
      this.setState({
        info: true,
        infoText: passwordChecked,
        submitSuccessful: false
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.stateToken &&
      prevProps.stateToken !== this.props.stateToken
    ) {
      this.props.logOut()
    }
  }

  render() {
    const { classes } = this.props
    const { info, infoText, password, submitSuccessful } = this.state

    if (this.props.stateToken) {
      return <div>Waiting to log out...</div>
    }

    return (
      <Grid
        container
        justify={"flex-end"}
        alignItems="center"
        className={classes.root}
      >
        <Grid item xs={12} md={6}>
          <Grid container justify={"center"} alignItems="center">
            <Grid item xs={11} sm={10} className={classes.padding}>
              <Typography
                color="primary"
                variant={"h5"}
                className={classes.labelText}
              >
                {tr("_create_new_password")}
              </Typography>
            </Grid>

            <Grid item xs={11} sm={10} className={classes.padding}>
              <TextField
                id="password"
                label={tr("_password")}
                className={classes.textInput}
                value={password}
                type="password"
                name="password"
                onChange={this.handleChange}
                margin="normal"
                onKeyUp={this.onHandleReturn}
                InputProps={{
                  classes: {
                    input: classes.inputColor,
                    underline: classes.underline
                  }
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.labelColor,
                    focused: classes.labelColor,
                    error: classes.labelColor
                  }
                }}
              />
            </Grid>

            <Grid item xs={11} sm={10} className={classes.padding}>
              <Button
                disabled={!submitSuccessful ? false : true}
                variant="contained"
                color="primary"
                className={classes.buttonPadding}
                onClick={this.handleSubmit}
              >
                {tr("_continue")}
              </Button>
            </Grid>
            <Grid item xs={11} sm={10} className={classes.padding}>
              {submitSuccessful && (
                <Typography color="primary" variant="h6">
                  {tr("_password_change_success")}
                </Typography>
              )}
            </Grid>
            <Grid item xs={11} sm={10} className={classes.padding}>
              {info && (
                <TextLine
                  mode={0}
                  text={infoText}
                  onClick={this.onInfoTextLinePress}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    height: "100vh",
    backgroundImage: theme.loginBackground,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed"
  },
  labelText: {
    fontWeight: "bold",
    fontStyle: "italic",
    textDecoration: "underline",
    letterSpacing: "3px",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  labelTextSecondary: {
    fontWeight: "bold",
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  textInput: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400
  },
  padding: {
    paddingTop: 10,
    paddingBottom: 10
  },
  buttonPadding: {
    paddingTop: 20,
    paddingBottom: 10,
    alignItems: "center",
    paddingLeft: "10%",
    paddingRight: "10%",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
    textTransform: "capitalize",
    color: theme.palette.getContrastText(theme.palette.primary.dark),
    // background: "linear-gradient(to right, #46c77f, #18ba60)",
    backgroundColor: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: theme.palette.primary.main
    }
  },
  inputColor: {
    color: "black"
  },
  labelColor: {
    color: "green"
  },
  underline: {
    borderBottom: "1px solid black"
  }
})

const mapDispatchToProps = dispatch => ({
  validate: token => dispatch(validate(token)),
  resetForgottenPassword: (token, password) =>
    dispatch(resetForgottenPassword(token, password)),
  logOut: () => dispatch(logOut())
})

const mapStateToProps = (state, ownProps) => {
  return {
    token: ownProps.match.params.token,
    stateToken: state.auth.token // Used to determine if we need to log out
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles, { withTheme: true })(RegisterForgot))
)
