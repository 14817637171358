export default (
  state = {
    deviceFilter: [],
    faultSites: {},
    error: null,
    iotDeviceList: [],
    iotDeviceFiltered: [],
    iotDeviceData: [],
    isFetching: false,
    shouldRefresh: false,
    lastSite: null, // Here we save the latest site id which was used in device creation.
    pageSize: 50
  },
  action
) => {
  switch (action.type) {
    case "IOTDEVICE_LIST_REQUESTED":
    case "IOTDEVICE_ADD_REQUESTED":
    case "IOTDEVICE_UPDATE_REQUESTED":
    case "IOTDEVICE_DELETE_REQUESTED":
    case "IOTDEVICE_UPGRADE_REQUESTED":
    case "IOTDEVICE_DATA_REQUESTED":
      return {
        ...state,
        isFetching: true,
        shouldRefresh: false
      }
    case "IOTDEVICE_PLACEMENT_REQUESTED": {
      let { iotDeviceList, iotDeviceFiltered } = state

      const newList = iotDeviceList.map(iotDevice => {
        if (iotDevice.deviceId === action.iotDevice.deviceId) {
          return action.iotDevice
        } else {
          return iotDevice
        }
      })

      const newFiltered = iotDeviceFiltered.map(iotDevice => {
        if (iotDevice.deviceId === action.iotDevice.deviceId) {
          return action.iotDevice
        } else {
          return iotDevice
        }
      })

      return {
        ...state,
        isFetching: true,
        iotDevice: action.iotDevice,
        iotDeviceList: newList,
        iotDeviceFiltered: newFiltered,
        error: null
      }
    }
    case "IOTDEVICE_MODIFY_RECEIVED": {
      let { iotDeviceList, iotDeviceFiltered } = state
      const newList = iotDeviceList.map(iotDevice => {
        if (iotDevice.deviceId === action.iotDevice.deviceId) {
          return action.iotDevice
        } else {
          return iotDevice
        }
      })

      const newFiltered = iotDeviceFiltered.map(iotDevice => {
        if (iotDevice.deviceId === action.iotDevice.deviceId) {
          return action.iotDevice
        } else {
          return iotDevice
        }
      })
      return {
        ...state,
        isFetching: false,
        iotDevice: action.iotDevice,
        iotDeviceList: newList,
        iotDeviceFiltered: newFiltered,
        error: null,
        lastUpdated: Date.now()
      }
    }
    case "IOTDEVICE_ADD_RECEIVED": {
      const { iotDeviceList, iotDeviceFiltered } = state
      const newList = [...iotDeviceList, action.iotDevice]
      const newFiltered = [...iotDeviceFiltered, action.iotDevice]

      return {
        ...state,
        isFetching: false,
        iotDevice: action.iotDevice,
        iotDeviceList: newList,
        iotDeviceFiltered: newFiltered,
        error: null,
        lastUpdated: Date.now(),
        lastSite: action.iotDevice.site
      }
    }
    case "IOTDEVICE_LIST_RECEIVED":
      return {
        ...state,
        isFetching: false,
        shouldRefresh: false,
        iotDeviceList: action.iotDeviceList,
        lastUpdated: Date.now()
      }
    case "IOTDEVICE_DATA_RECEIVED": {
      console.log("data received")
      const list = [...state.iotDeviceData, action.iotDeviceData]
      return (state = {
        ...state,
        isFetching: false,
        shouldRefresh: false,
        iotDeviceData: list
      })
    }

    case "IOTDEVICE_DELETE_RECEIVED": {
      const { iotDeviceList, iotDeviceFiltered } = state
      const newList = iotDeviceList.filter(
        item => item.deviceId !== action.deviceId
      )
      const newfiltered = iotDeviceFiltered.filter(
        item => item.deviceId !== action.deviceId
      )
      return {
        ...state,
        isFetching: false,
        iotDevice: action.iotDevice,
        iotDeviceList: newList,
        iotDeviceFiltered: newfiltered,
        error: null,
        lastUpdated: Date.now()
      }
    }
    case "IOTDEVICE_AJAX_SUCCESS":
      return { ...state, isFetching: false }

    case "IOTDEVICE_AJAX_ERROR":
      return {
        ...state,
        isFetching: false,
        shouldRefresh: true,
        error: action.error
      }

    case "IOTDEVICE_RESET_ERROR":
      return { ...state, error: null }

    case "IOTDEVICE_RESET":
      return {
        ...state,
        deviceFilter: [],
        iotDeviceList: [],
        iotDeviceFiltered: [],
        iotDeviceData: [],
        error: null
      }

    case "SET_PAGESIZE": {
      return {
        ...state,
        pageSize: action.pageSize
      }
    }

    case "IOTDEVICE_FILTER":
      return { ...state, iotDeviceFiltered: action.iotDeviceFiltered }
    case "DEVICE_FILTER":
      return { ...state, deviceFilter: action.deviceFilter }
    case "FAULT_DEVICE_FILTER":
      return {
        ...state,
        faultSites: action.faultSites
      }
    default:
      return state
  }
}
