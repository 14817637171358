import React, { useState, useEffect, useCallback } from "react"
import { useSelector } from "react-redux"
import { Fab, Grid, withStyles } from "@material-ui/core"
import ZoomInIcon from "@material-ui/icons/ZoomIn"
import ZoomOutIcon from "@material-ui/icons/ZoomOut"
import { useTheme } from "@material-ui/core/styles"
import GroundplanMaster from "./GroundplanMaster"
import { DevicePH, DeviceValue } from "./GroundplanMarker"
import defaultBackround from "../../images/default_groundplan.png"
import { platformBlue, platformWhite } from "../../services/colors"

// Grounplan screen scale values
const INITIAL_SCALE = 1.0
const MIN_SCALE = 0.1
const MAX_SCALE = 4.0

// Marker size increase values
const SIZE_INCREASE = 0.02
const MAX_SIZE = 1.5

const GroundplanMasterWrap = props => {
  const {
    classes,
    deviceMoving,
    editingOpen,
    onDeviceGripDelayed,
    onDeviceRelease,
    onAreaRelease,
    selected
  } = props

  const area = useSelector(state => state.areas.areaOpen)
  const floor = useSelector(state => state.areas.floorOpen)
  const temperatureDecimalPlaceSetting = useSelector(state => state.users.user.temperatureDecimalPlaceSetting)

  const iotDevices =
    useSelector(state => state.iotDevices.iotDeviceFiltered) || []

  const floorAreas = useSelector(state => state.areas.areaList.filter(curArea => 
    curArea.floor === floor && curArea.site === state.sites.siteOpen._id
  )) || []

  // List scaled to match the multi-area image
  const floorIotDevices = JSON.parse(JSON.stringify(iotDevices))


  for(let constDevice of iotDevices){
    let device = floorIotDevices.find(dev => dev._id === constDevice._id)
    let deviceArea = floorAreas.find(mArea => mArea._id === device.area)
    if(deviceArea && deviceArea.pos){
      device.pos.x += deviceArea.pos.x
      device.pos.y += deviceArea.pos.y
    }
  }
  const updatedDevices = useSelector(state => state.dataUpdate.updatedDevices)
  const lastUpdatedDeviceId = useSelector(state => state.dataUpdate.lastUpdatedDeviceId)
  const lastUpdated = useSelector(state => state.dataUpdate.lastUpdated)
  const deviceDataStore = useSelector(state => state.dataUpdate.deviceDataStore)

  const [scale, setScale] = useState(INITIAL_SCALE)
  const [markerSizes, setMarkerSizes] = useState({})
  const [deviceValues, setDeviceValues] = useState({})
  const [initialized, setInitialized] = useState(false)

  const theme = useTheme()

  // Change area or floor, need to re-init
  useEffect(()=>{
    setInitialized(false)
  },[floor, area])
  useEffect(
    () => {
      // updates from websocket data
      if (initialized) {
        const deviceFound = iotDevices.find(
          device => device.deviceId === lastUpdatedDeviceId
        )
        if (deviceFound) {
          const values = getDeviceValues(lastUpdatedDeviceId)
          updateValues(lastUpdatedDeviceId, values)
          // limit animations when dataupdates for all devices
          if (updatedDevices.length < 4) {
            markerAnimation(lastUpdatedDeviceId)
          }
        }
      }
    },
    // eslint-disable-next-line
    [updatedDevices, markerAnimation, getDeviceValues, updateValues]
  )

  useEffect(
    () => {
      if (!initialized) {
        // update all devices values in the beginning when devices receive information from the database
        updateAllLabels()
        // Init is finished only if danfoss or other bulk fetchable device has value
        for(const device of iotDevices){

          if(getDeviceValues(device.deviceId).temperature !== "" && device.deviceType === "Danfoss"){
            setInitialized(true)
            break
          } 
        }
      }
      else {
        // update one device value
        const values = {}
        iotDevices.forEach(device => {
          if (device.deviceId == lastUpdatedDeviceId) {
            values[device.deviceId] = getDeviceValues(device.deviceId)
            let values2 = getDeviceValues(lastUpdatedDeviceId)
            updateValues(lastUpdatedDeviceId, values2)
          }
        })  
      }
    },
    // eslint-disable-next-line
    [lastUpdatedDeviceId, lastUpdated]
  )

  useEffect(() => {
    // set initial markers
    const markers = {}
    iotDevices.forEach(device => {
      if (device.deviceId) {
        markers[device.deviceId] = INITIAL_SCALE
      }
    })
    setMarkerSizes(markers)
  }, [iotDevices])

  const updateValues = useCallback(
    (deviceId, values) => {
      const newValues = { ...deviceValues }
      newValues[deviceId] = values
      setDeviceValues(newValues)
    },
    [deviceValues, setDeviceValues]
  )

  const updateAllLabels =  () => {
    const values = {}
    iotDevices.forEach(device => {
      if (device.deviceId) {
        values[device.deviceId] = getDeviceValues(device.deviceId)
      }
    })
    setDeviceValues(values)
    
  }

  const getDeviceValues = useCallback(
    deviceId => {
      // find the latest update for the current device
      const dataSeries = deviceDataStore[deviceId] || []
      const latestEntry =
        dataSeries.length > 0 ? dataSeries[dataSeries.length - 1] : undefined
      const getMeasureValue = type => {
        return latestEntry[type] !== undefined && latestEntry[type] !== null
          ? `${latestEntry[type].toFixed(2)}`
          : "N/A"
      }
      if (latestEntry) {
        const values = {
          temperature:
            latestEntry.temperature !== undefined &&
            latestEntry.temperature !== null
              ? `${
                  latestEntry.temperature > 0 ? "+" : ""
                }${latestEntry.temperature.toFixed(temperatureDecimalPlaceSetting || 2)} ${"\u00b0"}C`
              : ``,
          pressure: getMeasureValue("pressure") + " " + "hPa",
          humidity: getMeasureValue("humidity") + " " + "%RH",
          ambientlight: getMeasureValue("ambientlight") + " " + "lx"
        }
        return values
      }
      return { temperature: "", pressure: "", humidity: "", ambientlight: "" }
    },
    [deviceDataStore]
  )

  const getValue = device => {
    const { deviceId, deviceType, mainValue = "temperature" } = device
    if (deviceType !== "TreonNode") {
      return deviceValues[deviceId] ? deviceValues[deviceId]["temperature"] : ""
    }
    return deviceValues[deviceId] ? deviceValues[deviceId][mainValue] : ""
  }

  const markerAnimation = useCallback(
    async deviceId => {
      // first increase size to maximum size in small steps
      for (let x = INITIAL_SCALE; x < MAX_SIZE; x = x + SIZE_INCREASE) {
        await sizeChange(deviceId, x)
      }
      // then reduce the size to normal
      for (let x = MAX_SIZE; x > INITIAL_SCALE; x = x - SIZE_INCREASE) {
        await sizeChange(deviceId, x)
      }
    },
    [sizeChange]
  )

  // decides the color/no color of the special inner circle marker
  const innerCircleColor = device => {
    const deviceValue = getValue(device)
    switch (device.deviceType) {
      case "TreonNode":
      case "TreonAssetNode":
        if(parseInt(deviceValue) < 0) // freezing temperatures in blue
          return platformBlue
        break
      default:
        return platformWhite
    }
  } 

  const sizeChange = useCallback(
    async (deviceId, size) => {
      // adjust one marker size individually
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          const markers = { ...markerSizes }
          markers[deviceId] = size
          resolve(setMarkerSizes(markers))
          reject()
        }, 5)
      })
    },
    [markerSizes]
  )

  // Groundplan control feature functions
  const handleScale = value => {
    const newScale = scale + value
    if (newScale > MIN_SCALE && newScale < MAX_SCALE) {
      setScale(newScale)
    }
  }

  const handleScaleUp = () => {
    handleScale(0.1)
  }

  const handleScaleDown = () => {
    handleScale(-0.1)
  }

  const handleWheel = event => {
    const value = event.deltaY / 1000
    if (value > 0) {
      handleScale(-0.1)
    } else {
      handleScale(0.1)
    }
  }

  const imageUrl = area && area.image ? area.image : defaultBackround

  return (
    <Grid item xs className={classes.root}>
      <div
        style={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        {area ? (
          <GroundplanMaster
            imageUrl={imageUrl}
            style={{
              minWidth: "100%",
              minHeight: "100%",
              flexShrink: "0",
              backgroundImage: theme.backgroundImage,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundAttachment: "fixed"
            }}
            scrolling={handleWheel}
            scale={scale}
            deviceMoving={deviceMoving}
            onDeviceRelease={onDeviceRelease}
          >
            {iotDevices.map((device, index) => {
              if (device.pos && device.pos.x && device.pos.y) {
                return (
                  <DevicePH
                    id={device.deviceId}
                    key={index}
                    x={device.pos.x}
                    y={device.pos.y}
                    color={
                      device.status === "FAULT"
                        ? theme.palette.error.dark
                        : device.status === "OK"
                          ? theme.palette.action.main
                          : theme.palette.action.disabled
                    }
                    color2={theme.palette.text.fixed}
                    innerCircle={innerCircleColor(device)}
                    scale={scale}
                    sizeModifier={markerSizes[device.deviceId] || INITIAL_SCALE}
                    onClick={editingOpen(device)}
                    onMouseDown={onDeviceGripDelayed(device)}
                    image={
                      device.deviceType ? device.deviceType : "commonlight"
                    }
                    selected={selected ? selected._id === device._id : false}
                    style={{ overflow: "auto" }}
                  />
                )
              }
            })}
            {iotDevices.map((device, index) => {
              if (
                device.pos &&
                device.pos.x &&
                device.pos.y &&
                getValue(device)
              ) {
                return (
                  <DeviceValue
                    id={device.deviceId}
                    key={index}
                    x={
                      device.pos.x -
                      (getValue(device).length > 10
                        ? 1.5
                        : 0.9 +
                          (markerSizes[device.deviceId] || INITIAL_SCALE) / 10)
                    }
                    y={
                      device.pos.y -
                      (1 + (markerSizes[device.deviceId] || INITIAL_SCALE) / 10)
                    }
                    scale={scale}
                    sizeModifier={markerSizes[device.deviceId] || INITIAL_SCALE}
                    selected={selected ? selected._id === device._id : false}
                    text={getValue(device)}
                    fillColor={theme.palette.markerLabel.background}
                    textColor={theme.palette.markerLabel.main}

                  />
                )
              }
            })}

          </GroundplanMaster>
        ) : (
          <GroundplanMaster
            imageUrl={imageUrl}
            areas={floorAreas}
            style={{
              minWidth: "100%",
              minHeight: "100%",
              flexShrink: "0",
              backgroundImage: theme.backgroundImage,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundAttachment: "fixed"
            }}
            scrolling={handleWheel}
            scale={scale}
            deviceMoving={deviceMoving}
            onDeviceRelease={onDeviceRelease}
            onAreaRelease={onAreaRelease}
          >
            {floorIotDevices.map((device, index) => {
              if (
                device.pos &&
                device.pos.x &&
                device.pos.y &&
                getValue(device)
              ) {
                return (
                  <DeviceValue
                    id={device.deviceId}
                    key={index}
                    x={
                      device.pos.x -
                      (getValue(device).length > 10
                        ? 1.5
                        : 0.9 +
                          (markerSizes[device.deviceId] || INITIAL_SCALE) / 10)
                    }
                    y={
                      device.pos.y -
                      (1 + (markerSizes[device.deviceId] || INITIAL_SCALE) / 10)
                    }
                    scale={scale}
                    sizeModifier={markerSizes[device.deviceId] || INITIAL_SCALE}
                    selected={selected ? selected._id === device._id : false}
                    text={getValue(device)}
                    fillColor={theme.palette.markerLabel.background}
                    textColor={theme.palette.markerLabel.main}
                  />
                )
              }
            })}
            {floorIotDevices.map((device, index) => {
              if (device.pos && device.pos.x && device.pos.y) {
                return (
                  <DevicePH
                    id={device.deviceId}
                    key={index}
                    x={device.pos.x}
                    y={device.pos.y}
                    color={
                      device.status === "FAULT"
                        ? theme.palette.error.dark
                        : theme.palette.action.main
                    }
                    color2={theme.palette.text.fixed}
                    scale={scale}
                    sizeModifier={markerSizes[device.deviceId] || INITIAL_SCALE}
                    onClick={editingOpen(device)}
                    onMouseDown={onDeviceGripDelayed(device)}
                    image={
                      device.deviceType ? device.deviceType : "commonlight"
                    }
                    selected={selected ? selected._id === device._id : false}
                    style={{ overflow: "auto" }}
                  />
                )
              }
            })}
          </GroundplanMaster>
        )}
      </div>
      <div
        style={{
          position: "absolute",
          right: "calc(50vw - 70px)",
          maxWidth: 140,
          marginTop: 80
        }}
      >
        <Fab
          className={classes.fab}
          fullwidth="true"
          size="large"
          variant="extended"
        >
          <Grid container justify="space-around">
            <ZoomOutIcon
              className={classes.zoomIcon}
              color="secondary"
              onClick={handleScaleDown}
            />
            <ZoomInIcon
              className={classes.zoomIcon}
              color="secondary"
              onClick={handleScaleUp}
            />
          </Grid>
        </Fab>
      </div>
    </Grid>
  )
}

const styles = theme => ({
  root: {
    padding: 20,
    height: "calc(100vh - 64px)",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent"
  },
  fab: {
    backgroundColor: theme.palette.unselected.main,
    width: 140
  },
  zoomIcon: {
    height: 30,
    width: 30
  }
})

export default withStyles(styles)(GroundplanMasterWrap)
