// import { authError } from "./auth"
import axios from "axios"
import { getApiUrl } from "../constants/config"
import { parseErrorData } from "../services/errors"
import { authHeader } from "./common"
import { stateType } from "../constants/actions"

const customerApiFailed = error => ({
  type: stateType.SET_ERROR,
  error
})

const customerListRequested = () => ({
  type: stateType.CUSTOMER_LIST_REQUESTED
})

const customerListReceived = customerList => ({
  type: stateType.CUSTOMER_LIST_RECEIVED,
  customerList
})

const customerAddRequested = () => ({
  type: stateType.CUSTOMER_ADD_REQUESTED
})

const customerAddReceived = customer => ({
  type: stateType.CUSTOMER_ADD_RECEIVED,
  customer
})

const customerModifyRequested = () => ({
  type: stateType.CUSTOMER_MODIFY_REQUESTED
})

const customerModifyReceived = customer => ({
  type: stateType.CUSTOMER_MODIFY_RECEIVED,
  customer
})

const customerDeleteRequested = () => ({
  type: stateType.CUSTOMER_DELETE_REQUESTED
})

const customerDeleteReceived = customerId => ({
  type: stateType.CUSTOMER_DELETE_RECEIVED,
  customerId
})

const displayInfo = info => ({
  type: stateType.SET_INFO,
  info
})

export const fetchCustomers = () => async (dispatch, getState) => {
  const state = getState()
  try {
    dispatch(customerListRequested())
    let customerUrl = getApiUrl() + "/api/customers"
    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    const { data } = await axios.get(customerUrl, authHeader(useToke))

    let customerList = null
    if (!data || !data.success || !data.customers) {
      throw (data && data.error) || "Failed to fetch customers"
    }

    customerList = data.customers

    return Promise.resolve(dispatch(customerListReceived(customerList)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(customerApiFailed(errorReply)))
  }
}

export const AddCustomers = (newCustomer /* selectedFile */) => async (
  dispatch,
  getState
) => {
  const state = getState()
  try {
    dispatch(customerAddRequested())
    let customerUrl = getApiUrl()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    axios
      .post(customerUrl + "/api/customers", newCustomer, authHeader(useToke))
      .then(res => {
        let customer = null
        const { data } = res
        if (!data || !data.success || !data.customer) {
          throw (data && data.error) || "Failed to add"
        }
        customer = data.customer
        let info = { description: "NEW_CUSTOMER_ADDED" }
        dispatch(displayInfo(info))

        return Promise.resolve(dispatch(customerAddReceived(customer)))
      })
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(customerApiFailed(errorReply)))
  }
}

const addImage = async (imageFile, name, useToke) => {
  try {
    let siteUrl = getApiUrl() + "/api/image/"

    const imageData = new FormData()

    if (imageFile) {
      imageData.append("image", imageFile, name + "-" + imageFile.name)
    }

    const { data } = await axios.post(siteUrl, imageData, authHeader(useToke))

    if (!data || !data.success) {
      throw (data && data.error) || "Failed to fetch"
    }

    return data
  } catch (err) {
    const errorReply = parseErrorData(err)
    return errorReply
  }
}

const removeImage = async (imageFile, useToke) => {
  try {
    let siteUrl = getApiUrl() + "/api/image/" + imageFile

    const { data } = await axios.delete(siteUrl, authHeader(useToke))

    if (!data || !data.success) {
      throw (data && data.error) || "Failed to delete"
    }

    return data
  } catch (err) {
    const errorReply = parseErrorData(err)
    return errorReply
  }
}

export const ModifyCustomers = existingCustomer => async (
  dispatch,
  getState
) => {
  try {
    dispatch(customerModifyRequested())
    let customerUrl = getApiUrl() + "/api/customers/" + existingCustomer._id

    const state = getState()
    const stateCustomer = state.customers.customerList.find((customer) => customer._id === existingCustomer._id)
    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }
    const logoFile = existingCustomer.logoFile
    existingCustomer.logoFile = null
    if(logoFile){
      if(stateCustomer.logo && stateCustomer.logo.length)
        await removeImage(stateCustomer.logo.replace(/^.*[\\/]/, ""), useToke)
      const imgResponse = await addImage(logoFile, "logo", useToke)
      if(imgResponse.success && imgResponse.url){
        existingCustomer.logo = imgResponse.url
      }
    }

    else if( existingCustomer.logo === "" && stateCustomer.logo && stateCustomer.logo.length){
      await removeImage(stateCustomer.logo.replace(/^.*[\\/]/, ""), useToke)
    }

    const bannerFile = existingCustomer.bannerFile
    existingCustomer.bannerFile = null
    if(bannerFile){
      if(stateCustomer.banner && stateCustomer.banner.length)
        await removeImage(stateCustomer.banner.replace(/^.*[\\/]/, ""), useToke)
      const imgResponse = await addImage(bannerFile, "banner", useToke)
      if(imgResponse.success && imgResponse.url){
        existingCustomer.banner = imgResponse.url
      }
    }
    else if( existingCustomer.banner === "" && stateCustomer.banner && stateCustomer.banner.length){
      await removeImage(stateCustomer.banner.replace(/^.*[\\/]/, ""), useToke)
    }


    const { data } = await axios.put(
      customerUrl,
      existingCustomer,
      authHeader(useToke)
    )
    let customer = null

    if (!data || !data.success || !data.customer) {
      throw (data && data.error) || "Failed to fetch"
    }

    customer = data.customer

    let info = { description: "CUSTOMER_MODIFIED" }
    dispatch(displayInfo(info))

    return Promise.resolve(dispatch(customerModifyReceived(customer)))
  } catch (err) {
    const errorReply = parseErrorData(err)

    return Promise.reject(dispatch(customerApiFailed(errorReply)))
  }
}

export const DeleteCustomers = existingCustomerId => async (
  dispatch,
  getState
) => {
  const state = getState()
  try {
    dispatch(customerDeleteRequested())

    let customerUrl = getApiUrl() + "/api/customers/" + existingCustomerId

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    const { data } = await axios.delete(customerUrl, authHeader(useToke))

    if (!data || !data.success) {
      throw (data && data.error) || "Failed to fetch"
    }
    let info = { description: "CUSTOMER_DELETED" }
    dispatch(displayInfo(info))

    return Promise.resolve(dispatch(customerDeleteReceived(existingCustomerId)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(customerApiFailed(errorReply)))
  }
}

const requestCustomerUpgrade = id => ({
  type: "CUSTOMER_DELETE_REQUESTED",
  id
})

const ajaxSuccess = () => ({ type: "CUSTOMER_AJAX_SUCCESS" })

const ajaxError = error => ({
  type: "CUSTOMER_AJAX_ERROR",
  error
})
const errorHandler = (err, response = "Internal server error") => {
  // if ((err && err.status === 401) || /401/.test(err)) return authError()

  const error =
    (err && err.message) || (err.toString && err.toString()) || response
  return ajaxError(error)
}
export const upgradeCustomer = id => async dispatch => {
  try {
    dispatch(requestCustomerUpgrade(id))
    let todochangename
    const { data } = await axios.patch(
      `/api/customers/${id}/upgrade`,
      todochangename
    )

    if (!data || !data.success)
      throw (data && data.error) || "Failed to delete todochangename"

    dispatch(ajaxSuccess())
    dispatch(fetchCustomers())
  } catch (err) {
    dispatch(errorHandler(err, "Failed to upgrade todochangename"))
  }
}
