import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"
import React, { useState, useEffect } from "react"
import useTheme from "@material-ui/core/styles/useTheme"

const TemperatureGraph = props => {
  const { value } = props

  const [percentage, setPercentage] = useState(50)
  const [positive, setPositive] = useState(true)

  const theme = useTheme()

  useEffect(() => {
    let min = 0
    let max = 0
    if (value >= 0) {
      max = 40
    } else {
      max = -40
    }
    let temperaturePercentage = ((value - min) / (max - min)) * 100
    temperaturePercentage = Math.max(Math.min(temperaturePercentage, 100), 0)
    setPercentage(temperaturePercentage)
    setPositive(value >= 0)
  }, [value])
  
  let output = value
  try {
    output = value.toFixed(2)
  }
  catch (err) {
    //
  }
  return (
    <div
      style={{
        height: "30px",
        minWidth: "150px",
        margin: "auto",
        textAlign: "left",
        background: `linear-gradient(to right, ${
          positive ? theme.palette.action.main : theme.palette.secondary.main
        } ${percentage}%, ${theme.palette.unselected.main} ${percentage}%`,
        color: "black"
      }}
    >
      <Typography variant="h6">{output} c</Typography>
    </div>
  )
}

const styles = () => ({})

export default withStyles(styles, { withTheme: true })(TemperatureGraph)
