// import { authError } from "./auth"
import axios from "axios"
import { getApiUrl } from "../constants/config"
import { parseErrorData } from "../services/errors"
import { authHeader } from "./common"
import { stateType } from "../constants/actions"

const siteApiFailed = error => ({
  type: stateType.SET_ERROR,
  error
})

const displayInfo = info => ({
  type: stateType.SET_INFO,
  info
})

const siteListRequested = () => ({
  type: stateType.SITE_LIST_REQUESTED
})

const siteListReceived = siteList => ({
  type: stateType.SITE_LIST_RECEIVED,
  siteList
})

const siteAddRequested = () => ({
  type: stateType.SITE_ADD_REQUESTED
})

const siteAddReceived = site => ({
  type: stateType.SITE_ADD_RECEIVED,
  site
})

const siteModifyRequested = () => ({
  type: stateType.SITE_MODIFY_REQUESTED
})

const siteModifyReceived = site => ({
  type: stateType.SITE_MODIFY_RECEIVED,
  site
})

const siteDeleteRequested = () => ({
  type: stateType.SITE_DELETE_REQUESTED
})

const siteDeleteReceived = siteId => ({
  type: stateType.SITE_DELETE_RECEIVED,
  siteId
})

export const siteSelected = site => ({
  type: stateType.SITE_SELECTED,
  siteOpen: site
})

export const fetchSites = () => async (dispatch, getState) => {
  const state = getState()
  try {
    dispatch(siteListRequested())
    let siteUrl = getApiUrl() + "/api/sites"
    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    const { data } = await axios.get(siteUrl, authHeader(useToke))

    let siteList = null
    if (!data || !data.success || !data.sites) {
      throw (data && data.error) || "Failed to fetch sites"
    }

    siteList = data.sites

    return Promise.resolve(dispatch(siteListReceived(siteList)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(siteApiFailed(errorReply)))
  }
}

export const AddSites = newSite => async (dispatch, getState) => {
  const state = getState()
  try {
    dispatch(siteAddRequested())
    let siteUrl = "/api/sites"

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    const { data } = await axios.post(siteUrl, newSite, authHeader(useToke))
    let site = null

    if (!data || !data.success || !data.site) {
      throw (data && data.error) || "Failed to fetch"
    }

    site = data.site

    let info = { description: "_site_creation_success" }
    dispatch(displayInfo(info))

    return Promise.resolve(dispatch(siteAddReceived(site)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(siteApiFailed(errorReply)))
  }
}

export const ModifySites = existingSite => async (dispatch, getState) => {
  try {
    dispatch(siteModifyRequested())
    let siteUrl = getApiUrl() + "/api/sites/" + existingSite._id

    const state = getState()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    const { data } = await axios.put(siteUrl, existingSite, authHeader(useToke))
    let site = null

    if (!data || !data.success || !data.site) {
      throw (data && data.error) || "Failed to fetch"
    }

    site = data.site

    return Promise.resolve(dispatch(siteModifyReceived(site)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(siteApiFailed(errorReply)))
  }
}

export const updateOpenSite = updatedSite => async (dispatch, getState) => {
  const state = getState()

  const selectedSite = state.sites.siteList.find(
    site => site._id === updatedSite
  )
  return Promise.resolve(dispatch(siteSelected(selectedSite)))
}

export const SubscripeAlerts = existingSite => async (dispatch, getState) => {
  try {
    dispatch(siteModifyRequested())
    let siteUrl = getApiUrl() + "/api/sites/" + existingSite._id + "/subscribe"

    const state = getState()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    const { data } = await axios.put(siteUrl, existingSite, authHeader(useToke))
    let site = null

    if (!data || !data.success || !data.site) {
      throw (data && data.error) || "Failed to fetch"
    }

    site = data.site

    return Promise.resolve(dispatch(siteModifyReceived(site)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(siteApiFailed(errorReply)))
  }
}

export const DeleteSites = existingSiteId => async (dispatch, getState) => {
  try {
    dispatch(siteDeleteRequested())

    let siteUrl = getApiUrl() + "/api/sites/" + existingSiteId

    const state = getState()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    const { data } = await axios.delete(siteUrl, authHeader(useToke))

    if (!data || !data.success) {
      throw (data && data.error) || "Failed to fetch"
    }

    let info = { description: "_site_deleted" }
    dispatch(displayInfo(info))

    return Promise.resolve(dispatch(siteDeleteReceived(existingSiteId)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(siteApiFailed(errorReply)))
  }
}
