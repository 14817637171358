import React, { useState, useEffect, useCallback, useRef } from "react"

import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Tooltip from "@material-ui/core/Tooltip"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import makeStyles from "@material-ui/core/styles/makeStyles"
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import InfoIcon from "@material-ui/icons/Info"
import { useSelector, useDispatch } from "react-redux"
import FilterModal from "./FilterModal"
import DeviceTablePagination from "./DeviceTablePagination"
import DeviceDetail from "../GroundPlan/DeviceDetail"
import EditDevice from "../GroundPlan/EditDevice"
import { tr } from "translations"
import DataGraph from "./DataGraphs"
import {
  ModifyIoTDevices,
  DeleteIoTDevices,
  fetchIoTDevices
} from "../../actions/iotDevices"
import { watchlistAdd, watchlistRemove } from "../../actions/websocket"
import ClearIcon from "@material-ui/icons/Clear"
import ViewColumnIcon from "@material-ui/icons/ViewColumn"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import DragHandleIcon from "@material-ui/icons/DragHandle"
import { fetchUserSelf, saveUser } from "../../actions/users"
import { useUnmount } from "../../services/customHooks"
import { setPageSize } from "../../actions/iotDevices"

const allColumns = [
  "status",
  "name",
  "type",
  "group",
  "data",
  "tempAvg",
  "tempMin",
  "tempMax",
  "ts",
  "company",
  "site",
  "location",
  "info"
]

const DeviceTable = props => {
  const { sitesWithColors, search } = props
  const [rows, setRows] = useState([])
  const [deviceRows, setDeviceRows] = useState([])
  const [dataRows, setDataRows] = useState([])
  const [sortedRows, setSortedRows] = useState([])
  const [shownRows, setShownRows] = useState([])
  const [filter, setFilter] = useState({})
  const [sort, setSort] = useState({ sortBy: "status", order: "desc" })
  const [anchorEl, setAnchorEl] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalOptions, setModalOptions] = useState([])
  const [modalHeader, setModalHeader] = useState("")
  const [page, setPage] = useState(0)
  const [deviceinfoOpen, setDeviceInfoOpen] = useState(false)
  const [device, setDevice] = useState({})
  const [editDeviceOpen, setEditDeviceOpen] = useState(false)
  const [columnModalOpen, setColumnModalOpen] = useState(false)
  const [columns, setColumns] = useState(allColumns)

  const sortRef = useRef(sort)
  const filterRef = useRef(filter)
  const columnsRef = useRef(columns)

  const classes = useStyles()
  const dispatch = useDispatch()

  const pageSize = useSelector(state => state.iotDevices.pageSize)
  const iotDeviceList = useSelector(state => state.iotDevices.iotDeviceList)
  const customerList = useSelector(state => state.customers.customerList)
  const siteList = useSelector(state => state.sites.siteList)
  const areaList = useSelector(state => state.areas.areaList)
  const deviceDataStore = useSelector(state => state.dataUpdate.deviceDataStore)
  const user = useSelector(state =>
    state.users.user ? state.users.user : undefined
  )

  useEffect(
    () => {
      dispatch(fetchIoTDevices())
    },
    // eslint-disable-next-line
    []
  )

  /*
  Fetch data array if possible (TreonAssetNode, Danfoss, Caplan)
  If not, fetch every device one by one (TreonNode)
  */
  useEffect(() => {
    let types = iotDeviceList.map(a => a.deviceType)
    let uniqueTypes = types.filter((v, i, a) => a.indexOf(v) === i)
    // Make a data structure for types with associated ids
    const typeIds = {}
    for(const type of uniqueTypes){
      if(!type){
        continue
      }
      const devices = iotDeviceList.filter(dev => dev.deviceType === type)
      const deviceIds = devices.map(dev => dev.deviceId)
      typeIds[type] = deviceIds
    }

    const deviceIds = iotDeviceList.map(device => device.deviceId)

    for (const [type, ids] of Object.entries(typeIds)) {
      try{
        if(type === "TreonAssetNode" ||
      type === "Caplan" ||
      type === "Danfoss"
        ){
        // Fetch device data for all devices with same type
          const { getDataUpdatesArray } = require("../../devicetypes/" +
            type +
              "/actions")
          dispatch(getDataUpdatesArray(ids))
        }
        else{
        // Fetch devices one by one
          for(const id of ids){
            const { getDataUpdates } = require("../../devicetypes/" +
              type +
              "/actions")
            dispatch(getDataUpdates(id))
          }
        }
      }
      catch(e){
        console.log("No update action found for devicetype " + type)
        console.log(e)
      }
      
    }

    dispatch(watchlistAdd(deviceIds))
    dispatch(fetchUserSelf())
      .then(res => {
        if (res.user && res.user.devicelistOptions) {
          if (res.user.devicelistOptions.columns) {
            setColumns(res.user.devicelistOptions.columns)
            columnsRef.current = res.user.devicelistOptions.columns
          }
          if (res.user.devicelistOptions.sort) {
            const userSort = JSON.parse(
              window.atob(res.user.devicelistOptions.sort)
            )
            setSort(userSort)
            sortRef.current = userSort
          }
          if (res.user.devicelistOptions.filter) {
            const userFilter = JSON.parse(
              window.atob(res.user.devicelistOptions.filter)
            )
            setFilter(userFilter)
            filterRef.current = userFilter
          }
        }
      })
      .catch(err => console.log(err))

    const unload = event => {
      event.preventDefault()
      saveDevicelistOptions()
    }

    window.addEventListener("beforeunload", unload)

    return function cleanup() {
      dispatch(watchlistRemove(deviceIds))
      window.removeEventListener("beforeunload", unload)
    }
    // eslint-disable-next-line
  }, [dispatch])

  useEffect(
    () => {
      if (
        !pageSize ||
        pageSize > 1000 ||
        pageSize < 1 ||
        typeof pageSize === "string" ||
        pageSize instanceof String
      ) {
        dispatch(setPageSize(50))
      }
    },
    // eslint-disable-next-line
    []
  )

  // Initial data setup
  useEffect(() => {
    const tempRows = []
    for (const iotDevice of iotDeviceList) {
      const {
        status,
        name,
        deviceType,
        group,
        valueOne,
        site,
        area,
        updatedDate,
        _id,
        deviceId
      } = iotDevice

      const nameNotNull = name || ""

      const typeNotNull = deviceType || ""

      const dataSeries = deviceDataStore[deviceId] || []

      let tempData = null
      if (
        (deviceType === "Generic_Dali" || deviceType === "Generic_LED") &&
        valueOne !== undefined
      ) {
        tempData = valueOne
      } else if (
        deviceType === "TreonNode" ||
        deviceType === "TreonAssetNode"
      ) {
        const treonNodeData = dataSeries[0]
        if (treonNodeData && treonNodeData.temperature) {
          tempData = treonNodeData.temperature
        }
      } else if (deviceType === "Caplan") {
        const firstData = dataSeries[0]
        tempData = firstData && firstData.value ? firstData.value : tr("_Nodata")
      } else if(deviceType === "Danfoss") {
        // Get last element
        if(dataSeries && dataSeries.length){
          tempData = dataSeries[dataSeries.length - 1].temperature
        }
      }

      const siteFilt = siteList.find(siteFound => siteFound._id === site)

      const company = siteFilt
        ? customerList.find(customer => customer._id === siteFilt.customer).name
        : ""

      const siteName = siteFilt ? siteFilt.name : ""

      const locationObject = areaList.find(areaFound => areaFound._id === area)
      const location = locationObject === undefined ? "" : locationObject.name

      // TODO: get this from site
      const timeZone = "Europe/Helsinki"

      const options = {
        month: "numeric",
        day: "numeric",
        timeZone,
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
      }

      // use german time presentation, because Finnish shows klo 12:00 etc.
      const formattedDate = updatedDate
        ? new Intl.DateTimeFormat("de", options).format(new Date(updatedDate))
        : "N/A"

      const row = {
        id: _id,
        deviceId,
        status,
        name: nameNotNull,
        type: typeNotNull,
        group,
        data: tempData,
        tempAvg: null,
        tempMin: null,
        tempMax: null,
        ts: formattedDate,
        company,
        site: siteName,
        location
      }
      tempRows.push(row)
    }

    setRows(tempRows)
    // eslint-disable-next-line
  }, [iotDeviceList])

  // Filtering useeffect, runs when filter or search changes
  useEffect(() => {
    let tempRows = [...rows]
    if (Object.keys(filter).length > 0) {
      Object.keys(filter).forEach(key => {
        tempRows = tempRows.filter(row => filter[key].includes(row[key]))
      })
    }

    if (search.length > 0) {
      const searchedRows = []
      const lowerCaseSearch = search.toLowerCase()
      for (let i = 0; i < tempRows.length; i++) {
        const keys = Object.keys(tempRows[i]).filter(key =>
          columns.includes(key)
        )
        for (let j = 0; j < keys.length; j++) {
          if (
            tempRows[i][keys[j]] !== null &&
            tempRows[i][keys[j]] !== undefined
          ) {
            if (
              tempRows[i][keys[j]]
                .toString()
                .toLowerCase()
                .indexOf(lowerCaseSearch) !== -1 &&
              keys[j] !== "id"
            ) {
              searchedRows.push(tempRows[i])
              break
            }
          }
        }
      }

      tempRows = searchedRows
    }

    filterRef.current = filter

    tempRows.forEach(row => {
      const rowDevice = iotDeviceList.find(
        value => value.deviceId === row.deviceId
      )
      if (rowDevice) {
        row.status = rowDevice.status
        row.name = rowDevice.name
        row.group = rowDevice.group
        row.type = rowDevice.deviceType
      }
    })
    setDeviceRows(tempRows)
    // eslint-disable-next-line
  }, [filter, rows, search])

  // Data useeffect, runs when data changes
  useEffect(() => {
    const tempRows = [...deviceRows]
    tempRows.forEach(row => {
      const dataSeries = deviceDataStore[row.deviceId] || []
      //dataSeries.sort((a, b) => b.ts - a.ts)
      if (row.type === "TreonNode" || row.type === "TreonAssetNode") {
        if (dataSeries && dataSeries.length > 0) {
          // assume the last entry is the latest
          const latestData = dataSeries[dataSeries.length - 1]
          row.data = latestData.temperature
          row.tempAvg = latestData.tempAvg
          row.tempMin = latestData.tempMin
          row.tempMax = latestData.tempMax
        }
      } else if (row.type === "Caplan") {
        if (dataSeries && dataSeries.length > 0) {
          // assume the last entry is the latest
          const latestData = dataSeries[dataSeries.length - 1]
          row.data = latestData.value
          row.tempAvg = latestData.tempAvg
          row.tempMin = latestData.tempMin
          row.tempMax = latestData.tempMax

        }
      }
      else if (row.type === "Danfoss") {
        if (dataSeries && dataSeries.length > 0) {
          const latestData = dataSeries[dataSeries.length - 1]
          row.data = latestData.temperature
        }
      }
    })
    setDataRows(tempRows)
  }, [deviceDataStore, deviceRows])

  // Sorting useeffect, runs when sorting changes
  useEffect(() => {
    if (sort.sortBy !== "manual") {
      // Only sort rows that have actual values, concat rest of the rows to end or start
      const rowsWithData = dataRows.filter(row => row[sort.sortBy] !== null)
      const nullRows = dataRows.filter(row => row[sort.sortBy] === null)

      rowsWithData.sort((a, b) => {
        if (typeof a[sort.sortBy] === "string") {
          return sort.order === "asc"
            ? a[sort.sortBy].localeCompare(b[sort.sortBy])
            : -a[sort.sortBy].localeCompare(b[sort.sortBy])
        }
        if (typeof a[sort.sortBy] === "number") {
          return sort.order === "asc"
            ? a[sort.sortBy] - b[sort.sortBy]
            : b[sort.sortBy] - a[sort.sortBy]
        }
        return 1
      })

      if (sort.order === "desc") setSortedRows(rowsWithData.concat(nullRows))
      else setSortedRows(nullRows.concat(rowsWithData))
    } else {
      const tempRows = [...dataRows]
      tempRows.sort((a, b) => {
        return sort.order.indexOf(a.id) - sort.order.indexOf(b.id)
      })
      setSortedRows(tempRows)
    }

    sortRef.current = sort
  }, [sort, dataRows])

  // Pagefilter useeffect, runs when page or pagesize changes
  useEffect(() => {
    let tempRows = [...sortedRows]

    if (tempRows.length > 0) {
      if (page >= Math.ceil(tempRows.length / pageSize))
        setPage(Math.ceil(tempRows.length / pageSize) - 1)

      if (page === Math.ceil(tempRows.length / pageSize) - 1)
        tempRows = tempRows.slice(page * pageSize)
      else
        tempRows = tempRows.slice(page * pageSize, page * pageSize + pageSize)
    }
    setShownRows(tempRows)
  }, [page, pageSize, sortedRows])

  useUnmount(() => saveDevicelistOptions())

  const handleSort = value => {
    if (sort.sortBy === value) {
      const order = sort.order === "asc" ? "desc" : "asc"
      setSort({ sortBy: value, order: order })
    } else {
      setSort({ sortBy: value, order: "asc" })
    }
  }

  const handleModalOpen = (event, value) => {
    setAnchorEl(event.currentTarget)
    setModalOpen(true)
    setModalHeader(value)

    const options = [...new Set(rows.map(row => row[value]))]
    const noNullsOptions = options.filter(option => option !== null)
    const noEmptyStringsOptions = noNullsOptions.filter(
      option => option.length !== 0
    )
    setModalOptions(noEmptyStringsOptions)
  }

  const handleModalClose = () => {
    setAnchorEl(null)
    setModalOptions([])
    setModalHeader("")
    setModalOpen(false)
    setColumnModalOpen(false)
  }

  const handleFilterChange = (event, value) => {
    const tempFilter = JSON.parse(JSON.stringify(filter))
    if (tempFilter[modalHeader] !== undefined) {
      const index = tempFilter[modalHeader].findIndex(
        filterValue => filterValue === value
      )
      if (index < 0) tempFilter[modalHeader].push(value)
      else tempFilter[modalHeader].splice(index, 1)

      if (tempFilter[modalHeader].length === 0) delete tempFilter[modalHeader]
    } else {
      tempFilter[modalHeader] = []
      tempFilter[modalHeader].push(value)
    }
    setFilter(tempFilter)
  }

  const handleFilterClear = () => {
    const tempFilter = JSON.parse(JSON.stringify(filter))
    if (tempFilter[modalHeader] !== undefined) delete tempFilter[modalHeader]
    setFilter(tempFilter)
  }

  const clearFilter = () => {
    setFilter({})
  }

  const handlePageChange = value => {
    if (value >= 0 && value <= Math.ceil(sortedRows.length / pageSize)) {
      setPage(value)
    }
  }

  const infoOpen = (event, row) => {
    const device = iotDeviceList.find(iotdevice => iotdevice._id === row.id)
    setAnchorEl(event.currentTarget)
    setDeviceInfoOpen(true)
    setDevice(device)
  }

  const infoClose = () => {
    setAnchorEl(null)
    setDeviceInfoOpen(false)
    setEditDeviceOpen(false)
    setDevice(null)
  }

  const handleColumnModalOpen = event => {
    setAnchorEl(event.currentTarget)
    setColumnModalOpen(true)
    setModalOpen(true)
    setModalHeader("columns")

    setModalOptions(allColumns)
  }

  const handleColumnChange = (event, value) => {
    const tempColumns = [...columns]
    const index = tempColumns.indexOf(value)
    if (index < 0) {
      tempColumns.push(value)
    } else {
      tempColumns.splice(index, 1)
    }
    setColumns(tempColumns)
    columnsRef.current = tempColumns
  }

  const handleColumnClear = () => {
    setColumns(allColumns)
    columnsRef.current = allColumns
  }

  const handleManualSort = (startIndex, endIndex) => {
    const tempData = [...sortedRows]
    const draggedItem = tempData[startIndex + page * pageSize]

    tempData.splice(startIndex + page * pageSize, 1)
    tempData.splice(endIndex + page * pageSize, 0, draggedItem)
    setDeviceRows(tempData)
    const order = tempData.map(row => row.id)
    setSort({ sortBy: "manual", order: order })
  }

  const onDragEnd = result => {
    if (!result.destination) {
      return
    }
    handleManualSort(result.source.index, result.destination.index)
  }

  const getDragStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,

    ...(isDragging && {
      display: "flex",
      justifyContent: "space-around"
    })
  })

  const handleRemove = () => {
    dispatch(ModifyIoTDevices({ ...device, area: null }, { x: null, y: null }))
      .then(res => setDevice(res.iotDevice))
      .catch(err => console.log(err))
  }

  const handleDelete = () => {
    dispatch(DeleteIoTDevices(device.deviceId)).catch(err => console.log(err))
    infoClose()
  }

  const saveDevicelistOptions = useCallback(() => {
    const sortString = window.btoa(JSON.stringify(sortRef.current))
    const filterString = window.btoa(JSON.stringify(filterRef.current))
    dispatch(
      saveUser({
        _id: user._id,
        devicelistOptions: {
          columns: columnsRef.current,
          sort: sortString,
          filter: filterString
        }
      })
    )
      .then(() => dispatch(fetchUserSelf()))
      .catch(err => console.log(err))
  }, [sortRef, filterRef, dispatch, user, columnsRef])

  return (
    <Grid container className={classes.grid} spacing={3}>
      <Grid item xs={12}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.tableHead}>
              {columns &&
                allColumns
                  .filter(column => columns.includes(column))
                  .map(value => (
                    <TableCell
                      align="center"
                      className={classes.tableHeadCell}
                      key={value}
                    >
                      {value !== "info" && (
                        <TableSortLabel
                          classes={{
                            icon:
                              sort.sortBy === value
                                ? classes.activeSortIcon
                                : classes.inactiveSortIcon
                          }}
                          active={sort.sortBy === value}
                          onClick={() => handleSort(value)}
                          direction={sort.sortBy === value ? sort.order : "asc"}
                        />
                      )}
                      {[
                        "status",
                        "type",
                        "group",
                        "ts",
                        "company",
                        "site",
                        "location"
                      ].includes(value) ? (
                            <FormControlLabel
                              className={classes.controlLabel}
                              control={<ExpandMoreIcon fontSize="large" />}
                              label={
                                <Typography variant="h6">
                                  {value === "company"
                                    ? tr(`Company`)
                                    : tr(`_${value}`)}
                                </Typography>
                              }
                              labelPlacement="start"
                              onClick={e => handleModalOpen(e, value)}
                            />
                          ) : (
                            <Typography
                              variant="h6"
                              style={{ display: "inline-block" }}
                            >
                              {["data", "info"].includes(value)
                                ? value.charAt(0).toUpperCase() + value.slice(1)
                                : tr(`_${value}`)}
                            </Typography>
                          )}
                    </TableCell>
                  ))}
              <TableCell align="center" className={classes.clearFiltersCell}>
                <Tooltip title={tr("_clear_filter")}>
                  <ClearIcon
                    className={classes.iconButton}
                    onClick={clearFilter}
                  />
                </Tooltip>
                <Tooltip title={tr("_choose_columns")}>
                  <ViewColumnIcon
                    className={classes.iconButton}
                    onClick={handleColumnModalOpen}
                  />
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="1" direction="vertical">
              {provided => (
                <TableBody
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  {shownRows.map((row, index) => (
                    <Draggable key={row.id} draggableId={row.id} index={index}>
                      {(provided, snapshot) => (
                        <TableRow
                          onClick={event => infoOpen(event, row)}
                          className={classes.tableRow}
                          hover={true}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={getDragStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          {columns && columns.includes("status") && (
                            <TableCell
                              align="center"
                              className={classes.tableCell}
                            >
                              <FiberManualRecordIcon
                                className={classes[`status${row.status}`]}
                              />
                            </TableCell>
                          )}
                          {columns &&
                            ["name", "type", "group"]
                              .filter(column => columns.includes(column))
                              .map(value => (
                                <TableCell
                                  align="center"
                                  className={classes.tableCell}
                                  key={value}
                                >
                                  <Typography>{row[value]}</Typography>
                                </TableCell>
                              ))}
                          {columns &&
                            ["data", "tempAvg", "tempMin", "tempMax"]
                              .filter(column => columns.includes(column))
                              .map(value => (
                                <TableCell
                                  className={classes.dataCell}
                                  key={value}
                                  align="center"
                                >
                                  {row[value] === null ||
                                    row[value] === undefined ? (
                                        <Typography>{tr("_unknown")}</Typography>
                                      ) : (
                                        <DataGraph
                                          value={row[value]}
                                          type={row.type}
                                        />
                                      )}
                                </TableCell>
                              ))}
                          {columns &&
                            ["ts"]
                              .filter(column => columns.includes(column))
                              .map(value => (
                                <TableCell
                                  align="center"
                                  className={classes.tableCell}
                                  key={value}
                                >
                                  <Typography>{row[value]}</Typography>
                                </TableCell>
                              ))}
                          {columns &&
                            ["company", "site", "location"]
                              .filter(column => columns.includes(column))
                              .map(value => (
                                <TableCell
                                  align="center"
                                  className={classes.tableCell}
                                  key={value}
                                >
                                  <Typography
                                    style={{
                                      color: sitesWithColors[row[value]]
                                        ? sitesWithColors[row[value]]
                                        : "inherit"
                                    }}
                                  >
                                    {row[value]}
                                  </Typography>
                                </TableCell>
                              ))}
                          {columns && columns.includes("info") && (
                            <TableCell
                              align="center"
                              className={classes.tableCell}
                            >
                              <InfoIcon
                                className={classes.infoIcon}
                                onClick={event => infoOpen(event, row)}
                              />
                            </TableCell>
                          )}
                          <TableCell
                            align="center"
                            {...provided.dragHandleProps}
                            className={classes.tableCell}
                          >
                            <DragHandleIcon className={classes.dragIcon} />
                          </TableCell>
                          {provided.placeholder}
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </Grid>
      <Grid item xs={12} className={classes.pagination}>
        {sortedRows.length > pageSize && (
          <DeviceTablePagination
            page={page}
            maxPage={Math.ceil(sortedRows.length / pageSize) - 1}
            changePage={handlePageChange}
          />
        )}
      </Grid>
      <FilterModal
        anchorEl={anchorEl}
        open={modalOpen}
        handleClose={handleModalClose}
        filter={columnModalOpen ? { columns: columns } : filter}
        handleChange={columnModalOpen ? handleColumnChange : handleFilterChange}
        handleClear={columnModalOpen ? handleColumnClear : handleFilterClear}
        options={modalOptions}
        labels={
          columnModalOpen
            ? [
                tr("_status"),
                tr("_name"),
                tr("_type"),
                tr("_group"),
                "Data",
                tr("_tempAvg"),
                "Min",
                "Max",
                tr("_ts"),
                tr("Company"),
                tr("_site"),
                tr("_location"),
                "Info"
              ]
            : modalOptions
        }
        header={modalHeader}
        sitesWithColors={sitesWithColors}
      />
      {device && (
        <DeviceDetail
          isOpen={deviceinfoOpen}
          anchorEl={anchorEl}
          details={device}
          onClose={infoClose}
          handleEdit={() => {
            setDeviceInfoOpen(false)
            setEditDeviceOpen(true)
          }}
        />
      )}
      {device && editDeviceOpen && (
        <EditDevice
          isOpen={editDeviceOpen}
          device={device}
          onClose={infoClose}
          removeDevice={handleRemove}
          deleteDevice={handleDelete}
        />
      )}
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  grid: {
    background: "transparent",
    margin: "auto",
    height: "100%",
    flexGrow: 1,
    overflowX: "auto",
    overflowY: "hidden",
    maxWidth: "96%"
  },
  table: {
    borderSpacing: "0px 10px",
    borderCollapse: "separate",
    padding: "0px 2px 0px 2px"
  },
  tableHead: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.dark
  },
  tableHeadCell: {
    color: theme.palette.text.tablehead,
    borderBottom: "none",
    fontSize: "16pt",
    padding: "5px",
    whiteSpace: "nowrap"
  },
  tableRow: {
    background: theme.palette.table.tableRowBackground
  },
  tableCell: {
    borderBottom: "none",
    padding: "0px 0px 0px 0px"
  },
  dataCell: {
    borderBottom: "none",
    padding: "0px 10px 0px 10px"
  },
  infoIcon: {
    "&:hover": {
      cursor: "pointer"
    }
  },
  activeSortIcon: {
    opacity: 1
  },
  inactiveSortIcon: {
    opacity: 0.5
  },
  clearFiltersCell: {
    borderBottom: "none",
    maxWidth: "80px",
    whiteSpace: "nowrap"
  },
  controlLabel: {
    margin: "1px"
  },
  pagination: {
    marginBottom: "2%",
    marginTop: "auto"
  },
  iconButton: {
    "&:hover": {
      cursor: "pointer"
    },
    padding: "0px",
    margin: "0px 5px 0px 5px",
    color: theme.palette.text.primary
  },
  dragIcon: {
    color: theme.palette.text.primary
  },
  statusOK: {
    color: theme.palette.action.main,
    fontSize: "16px"
  },
  statusFAULT: {
    color: theme.palette.error.threatening,
    fontSize: "16px"
  },
  statusDISABLED: {
    color: theme.palette.action.disabled,
    fontSize: "16px"
  }
}))

export default DeviceTable
