import React, { useState } from "react"

import CircularProgress from "@material-ui/core/CircularProgress"
import TextField from "@material-ui/core/TextField"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"

import useTheme from "@material-ui/core/styles/useTheme"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { tr } from "translations"
import { Close } from "../SvgLib"

/**
 * Modal to add attachment
 */

export default function ForgotPasswordModal(props) {
  const { onSubmitEmail, onClose, saving, isOpen } = props

  const [email, setEmail] = useState("")

  const theme = useTheme()
  const classes = useStyles()

  const handleChange = ({ target }) => {
    setEmail(target.value)
  }

  /**
   * Save or clear form button handlers
   */
  const handleSubmit = button => {
    switch (button) {
      case "save":
        onSubmitEmail(email).then(() => {
          setEmail("")
        })
        break
      case "reject":
        setEmail("")
        onClose()
        break
    }
  }

  return (
    <Dialog
      modal="true"
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
      className={classes.dialogRoot}
      fullWidth={true}
      maxWidth={"lg"}
    >
      <Paper className={classes.modalPaper} elevation={2}>
        <Toolbar className={classes.root}>
          <IconButton
            className={classes.buttonGroupRight}
            aria-label="Print"
            onClick={() => handleSubmit("reject")}
          >
            <Close
              style={{ height: "30px" }}
              color={theme.palette.text.primary}
            />
          </IconButton>
        </Toolbar>
        <Grid container justify="center" className={classes.container}>
          <Grid container item xs={10}>
            <Typography
              align="center"
              variant="h6"
              noWrap
              className={classes.infoTitle}
            >
              {tr("_password_lost")}
            </Typography>

            <Typography
              align="center"
              variant="subtitle1"
              className={classes.infoText}
            >
              {tr("_recover_text")}
            </Typography>
            <Grid item xs={12}>
              <TextField
                required
                align="center"
                id="outlined-email"
                label={tr("_email")}
                type="email"
                name="email"
                className={classes.textField}
                InputProps={{
                  classes: {
                    input: classes.inputColor,
                    underline: classes.underline
                  }
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.labelColor,
                    focused: classes.labelColor,
                    error: classes.labelColor
                  }
                }}
                value={email}
                onChange={handleChange}
                margin="normal"
                variant="standard"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.footer}>
            <Grid container spacing={0} justify="center">
              <Grid item className={classes.buttonSend}>
                <Button
                  disabled={email.length < 1}
                  variant="contained"
                  color="secondary"
                  onClick={() => handleSubmit("save")}
                >
                  {saving ? <CircularProgress size={22} /> : tr("_send")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    minWidth: 300,
    maxWidth: 600,
    margin: "auto"
  },
  modalPaper: {
    // backgroundColor: theme.palette.primary.dark
  },
  textField: {
    color: theme.palette.gray,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: "100%"
  },
  root: {
    margin: 0
  },
  infoTitle: {
    color: theme.palette.text.primary
  },
  infoText: {
    paddingTop: theme.spacing(2),
    color: theme.palette.text.primary
  },
  buttonGroupRight: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.text.primary
  },
  buttonSend: {
    color: theme.palette.getContrastText(theme.palette.primary.dark),
    margin: theme.spacing(2)
  },
  inputColor: {
    color: theme.palette.grey.main
  },
  labelColor: {
    color: theme.palette.grey.main
  },
  underline: {
    borderBottom: "1px solid #898989"
  }
}))
