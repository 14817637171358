import React, { useState, useEffect } from "react"

import GridList from "@material-ui/core/GridList"
import GridListTile from "@material-ui/core/GridListTile"
import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"

import Fade from "@material-ui/core/Fade"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import withStyles from "@material-ui/core/styles/withStyles"

import HideButton from "./HideButton"
import SearchBox from "./SearchBox"
import SiteInfoCard from "./SiteInfoCard"
import { tr } from "../../translations"

const MapSide = props => {
  const {
    classes,
    sites,
    sideWidth,
    sideHeight,
    openSite,
    handleDialog,
    siteDevices,
    alertSiteList,
    warningSiteList,
    selectedMarker,
    setMarker,
    iconSize,
    logo
  } = props

  const [selected, setSelected] = useState(null)
  const [hidden, setHidden] = useState(false)
  const [filtered, setFiltered] = useState(sites)
  const [value, setValue] = useState(0)
  const [alertSites, setAlertSites] = useState(alertSiteList)
  const [warningSites, setWarningSites] = useState(warningSiteList)

  useEffect(() => {
    setAlertSites(alertSiteList)
  }, [alertSiteList])

  useEffect(() => {
    setWarningSites(warningSiteList)
  }, [warningSiteList])

  function handleSelected(id, pos) {
    setMarker(id, pos)
  }

  useEffect(() => {
    if (selectedMarker !== selected) {
      setSelected(selectedMarker)
    }
  }, [selectedMarker, selected])

  const handleSearch = event => {
    const searched = sites.filter(site =>
      site.name.toLowerCase().includes(event.target.value.toLowerCase())
    )
    const foundAlerts = searched.filter(site =>
      alertSiteList.find(alertSite => alertSite._id === site._id)
    )
    const foundWarnings = searched.filter(site =>
      warningSiteList.find(warningSite => warningSite._id === site._id)
    )
    setFiltered(searched)
    setAlertSites(foundAlerts)
    setWarningSites(foundWarnings)
    setMarker(null)
  }

  const handleHidden = () => {
    setHidden(!hidden)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
    setMarker(null)
  }

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <SearchBox handleSearch={handleSearch} width={sideWidth} />
      </Grid>
      {!hidden && (
        <Grid container item xs={12}>
          <Fade in={true} timeout={600}>
            <Paper
              className={classes.sidePaper}
              style={{
                width: sideWidth
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab
                  label={`${tr("_all") + " " + tr("_sites")} (${
                    filtered.length
                  })`}
                  className={value ? classes.allTab : classes.allTabSelected}
                />
                <Tab
                  label={`${tr("_alerts")} (${alertSites.length})`}
                  className={
                    value === 1 ? classes.alertTabSelected : classes.alertTab
                  }
                />
                <Tab
                  label={`${tr("_unknown")} (${warningSites.length})`}
                  className={
                    value === 2
                      ? classes.warningTabSelected
                      : classes.warningTab
                  }
                />
              </Tabs>

              <GridList
                cols={1}
                spacing={5}
                cellHeight={sideHeight}
                className={classes.gridList}
              >
                {value === 0 &&
                  filtered.map(site => {
                    return (
                      <Fade key={site._id.toString()} in={true} timeout={400}>
                        <GridListTile>
                          <SiteInfoCard
                            key={site._id.toString()}
                            cardSelected={site._id === selected}
                            site={site}
                            onClick={() => handleSelected(site._id, site.pos)}
                            height={sideHeight}
                            width={sideWidth}
                            openSite={openSite}
                            handleDialog={handleDialog}
                            devices={siteDevices(site._id)}
                            iconSize={iconSize}
                            logo={logo}
                          />
                        </GridListTile>
                      </Fade>
                    )
                  })}

                {value === 1 &&
                  alertSites.map(site => {
                    return (
                      <Fade key={site._id.toString()} in={true} timeout={400}>
                        <GridListTile>
                          <SiteInfoCard
                            key={site._id.toString()}
                            cardSelected={site._id === selected}
                            site={site}
                            onClick={() => handleSelected(site._id, site.pos)}
                            height={sideHeight}
                            width={sideWidth}
                            openSite={openSite}
                            handleDialog={handleDialog}
                            devices={siteDevices(site._id)}
                            iconSize={iconSize}
                            logo={logo}
                          />
                        </GridListTile>
                      </Fade>
                    )
                  })}
                {value === 2 &&
                  warningSites.map(site => {
                    return (
                      <Fade key={site._id.toString()} in={true} timeout={400}>
                        <GridListTile>
                          <SiteInfoCard
                            key={site._id.toString()}
                            cardSelected={site._id === selected}
                            site={site}
                            onClick={() => handleSelected(site._id, site.pos)}
                            height={sideHeight}
                            width={sideWidth}
                            openSite={openSite}
                            handleDialog={handleDialog}
                            devices={siteDevices(site._id)}
                            iconSize={iconSize}
                            logo={logo}
                          />
                        </GridListTile>
                      </Fade>
                    )
                  })}
              </GridList>
            </Paper>
          </Fade>
        </Grid>
      )}
      <Grid container item xs={12}>
        <HideButton
          handleHidden={handleHidden}
          hidden={hidden}
          width={sideWidth}
        ></HideButton>
      </Grid>
    </Grid>
  )
}

const styles = theme => ({
  root: {
    maxHeight: "calc(100vh - 64px)", // Note to me... Dont touch this. apparently it is keeping all together.
    paddingTop: theme.spacing(3)
  },
  gridList: {
    maxHeight: "calc(100vh - 280px)"
  },
  sidePaper: {
    backgroundColor: theme.palette.primary.dark
  },
  allTab: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.text.primary,
    minWidth: 0
  },
  allTabSelected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    minWidth: 0
  },
  alertTab: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.error.main,
    minWidth: 0
  },
  alertTabSelected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.error.main,
    minWidth: 0
  },
  warningTab: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.error.light,
    minWidth: 0
  },
  warningTabSelected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.error.light,
    minWidth: 0
  }
})

export default withStyles(styles)(MapSide)
