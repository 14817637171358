import React from "react"
import { tr } from "translations"
import { connect } from "react-redux"
import * as authActions from "../actions/auth"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"


export class LogoutScreen extends React.Component {
  constructor(props) {
    super(props)

    const { logOut } = props
    setTimeout(logOut, 2000)
  }

  render() {
    return (
      <Grid
        container
        direction={"column"}
        alignItems="center"
        style={{ padding: 40 }}
      >
        <Grid item xs />
        <Grid item xs>
          <Typography noWrap>{tr("_logout_text")}</Typography>
        </Grid>
        <Grid item xs />
      </Grid>
    )
  }
}

export default connect(null, authActions)(LogoutScreen)
