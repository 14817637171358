const initialState = {
  isFetching: false,
  error: null,
  maintenance: null // Let's save the reference for created maintenance here.
}

export default (state = initialState, action) => {
  switch (action.type) {
    case "COMMAND_REQUESTED": {
      return {
        ...state,
        maintenance: null,
        error: null,
        isFetching: true
      }
    }
    case "COMMAND_SUCCESS": {
      return {
        ...state,
        error: null,
        isFetching: false,
        maintenance: action.maintenance
      }
    }
    case "SET_ERROR":
    case "COMMAND_FAILED": {
      return {
        ...state,
        error: action.error,
        isFetching: false,
        maintenance: null
      }
    }
    case "COMMAND_RESET": {
      return initialState
    }

    default:
      return state
  }
}
