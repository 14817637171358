import React, { useState, useEffect } from "react"
import Hidden from "@material-ui/core/Hidden"
import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"

import LocationOnIcon from "@material-ui/icons/LocationOn"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import DeviceListHeader from "../components/DeviceList/DeviceListHeader"
import SiteSummary from "../components/DeviceList/SiteSummary"
import DeviceTable from "../components/DeviceList/DeviceTable"
import Datahistory from "../components/DeviceList/Datahistory/Datahistory"
import HistorySettingsModal from "../components/DeviceList/Datahistory/HistorySettingsModal"
import { useSelector, useDispatch } from "react-redux"
import { tr } from "translations"
import { fetchCustomers } from "../actions/customers"
import axios from "axios"
import { getApiUrl } from "../constants/config"
import { useHistory, Redirect } from "react-router-dom"

const siteColors = ["#70aa4e", "#9e70cb", "#cdb671", "#dc7768"]

const DeviceList = (props) => {
  const { classes, match } = props

  const [expanded, setExpanded] = useState(false)
  const [search, setSearch] = useState("")
  const [loaded, setLoaded] = useState(false)
  const [sitesWithColors, setSitesWithColors] = useState({})
  const [historyData, setHistoryData] = useState([])
  const [historyModalOpen, setHistoryModalOpen] = useState(false)
  const [historyLoading, setHistoryLoading] = useState(false)
  const [historyCompression, setHistoryCompression] = useState("hourly")
  const [noHistoryData, setNoHistoryData] = useState(false)
  const [historyInfo, setHistoryInfo] = useState({})

  const devicelist = useSelector((state) => state.iotDevices.iotDeviceList)
  const sites = useSelector((state) => state.sites.siteList)
  const token = useSelector((state) => state.auth.token)
  const customerList = useSelector((state) => state.customers.customerList)
  const customer = (customerList && customerList.length === 1) ? customerList[0] : null
  const browserhistory = useHistory()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCustomers())
      .then(() => setLoaded(true))
      .catch((err) => console.log(err))
  }, [dispatch])

  useEffect(() => {
    const tempSitesWithColors = {}
    sites.forEach((site, index) => {
      tempSitesWithColors[site.name] = siteColors[index % siteColors.length]
    })
    setSitesWithColors(tempSitesWithColors)
  }, [sites])

  const handleExpandChange = () => {
    setExpanded(!expanded)
  }

  const handleSearch = (value) => {
    setSearch(value)
  }

  async function handleHistoryFetch(
    start,
    end,
    filter,
    compression,
    reportType
  ) {
    setHistoryLoading(true)
    setHistoryModalOpen(false)
    setHistoryCompression(compression)
    let useToke = ""
    if (token) {
      useToke = token
    }
    let startMs = new Date(start).getTime()
    let stopMs = new Date(end).getTime()

    const newHistoryData = []
    const newHistoryInfo = {
      start: start,
      end: end,
      compression: compression,
      reportType: reportType,
      devices: []
    }
    const promises = []

    for (let i = 0; i < filter.length; i++) {
      let deviceUrl =
        getApiUrl() +
        `/api/devicetype/${filter[i].deviceType.toLowerCase()}/history/` +
        filter[i].deviceId

      promises.push(
        axios
          .get(deviceUrl, {
            params: {
              start: startMs,
              end: stopMs,
              type: compression,
              measurement: reportType
            },
            headers: { Authorization: `${useToke}` }
          })
          .then((response) => {
            if (!response.data || !response.data.success) {
              throw (
                (response.data && response.data.error) ||
                "Failed to fetch iotDevice data"
              )
            }
            response.data.data.forEach((value) => {
              value.device = devicelist.find(
                (device) => device.deviceId === value.deviceId
              ).name
              newHistoryData.push(value)
            })
            if (response.data.data.length !== 0) {
              newHistoryInfo.devices.push(filter[i])
            }
          })
          .catch((err) => console.log(err))
      )
    }

    await Promise.allSettled(promises)
      .then(setHistoryData(newHistoryData))
      .finally(() => {
        setHistoryLoading(false)
        if (newHistoryData.length === 0) {
          setNoHistoryData(true)
          setTimeout(() => setNoHistoryData(false), 3000)
        } else {
          setHistoryInfo(newHistoryInfo)
          browserhistory.push("/devicelist/history")
        }
      })
  }
  if (!match.params.sub) {
    return (
      <div className={classes.div}>
        {loaded && (
          <Grid container spacing={3} className={classes.grid}>
            <Grid item xs={12}>
              <DeviceListHeader
                title={`${tr("_deviceList")} (${devicelist.length})`}
                addNewText={tr("_add_device")}
                handleSearch={handleSearch}
                site={sites.length !== 0 ? sites[0]._id : null}
                openHistory={() => setHistoryModalOpen(true)}
                historyLoading={historyLoading}
                noHistoryData={noHistoryData}
              />
            </Grid>
            <Grid item xs={12}>
              <Accordion
                className={classes.expansionPanel}
                expanded={expanded}
                onChange={handleExpandChange}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    variant={expanded ? "h5" : "h6"}
                    className={classes.expansionPanelTitle}
                  >
                    <LocationOnIcon />
                    {tr("_areas")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    className={classes.grid}
                    justify="center"
                    alignItems="center"
                    spacing={3}
                  >
                    {sites.map((site) => (
                      <Grid item key={site._id}>
                        <SiteSummary
                          site={site}
                          customer={customer}
                          siteColor={sitesWithColors[site.name]}
                          deviceCount={
                            devicelist.filter(
                              (device) => device.site === site._id
                            ).length
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12} >
              <Hidden smDown>
                <DeviceTable
                  sitesExpanded={expanded}
                  search={search}
                  sitesWithColors={sitesWithColors}
                />
              </Hidden>
            </Grid>
          </Grid>
        )}
        <HistorySettingsModal
          isOpen={historyModalOpen}
          closeMenu={() => setHistoryModalOpen(false)}
          handleHistoryFetch={handleHistoryFetch}
        />
      </div>
    )
  } else if (match.params.sub === "history") {
    return (
      <div>
        {historyData.length !== 0 ? (
          <Datahistory
            data={historyData}
            closeHistory={() => browserhistory.push("/devicelist")}
            compression={historyCompression}
            historyInfo={historyInfo}
          />
        ) : (
          <Redirect to="/devicelist" />
        )}
      </div>
    )
  } else {
    return <Redirect to="/devicelist" />
  }
}

const styles = (theme) => ({
  div: {
    width: "100%",
    height: "100vh",
    backgroundImage: theme.backgroundImage,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    flexGrow: 1,
    overflowY: "auto",
    overflowX: "hidden"
  },
  expansionPanel: {
    backgroundColor: theme.palette.table.tableRowBackground,
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.unselected.main,
    borderTop: "1px solid",
    borderTopColor: theme.palette.unselected.main
  },
  expansionPanelTitle: {
    display: "inline-block",
    paddingLeft: "3rem"
  }
})

export default withStyles(styles, { withTheme: true })(DeviceList)
