import React from "react"
import Typography from "@material-ui/core/Typography"
import TemperatureGraph from "./TemperatureGraph"
import LightGraph from "./LightGraph"
import HumidityGraph from "./HumidityGraph"
import { tr } from "translations"

const DataGraph = props => {
  const { value, type } = props
  switch (type) {
    case "TreonNode":
      return <TemperatureGraph value={value} />
    case "TreonAssetNode":
      return <TemperatureGraph value={value} />
    case "Danfoss":
      return <TemperatureGraph value={value} />
    case "Caplan":
      return <TemperatureGraph value={value} />
    case "Generic_Dali":
    case "Generic_LED":
      return <LightGraph value={value} />
    case "Default":
      return <HumidityGraph value={value} />
    default:
      return <Typography>{tr("_unknown")}</Typography>
  }
}

export default DataGraph
