import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"

import Grid from "@material-ui/core/Grid"
import GridListTile from "@material-ui/core/GridListTile"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"
import CancelIcon from "@material-ui/icons/Cancel"
import { tr } from "translations"

const AlertItem = props => {
  const { classes, item, openDevice } = props
  const [fault, setFault] = useState(null)
  const faults = useSelector(state => state.faults)

  useEffect(() => {
    setFault(
      faults.activeFaultList.filter(active => {
        return active.device === item.deviceId
      })
    )
  }, [faults, item.deviceId])
  return (
    <GridListTile key={item._id.toString()}>
      <Paper
        onClick={openDevice(item)}
        className={classes.alertItemPaper}
        square={true}
      >
        <Grid container alignItems="center">
          <Grid item xs={3}>
            <CancelIcon
              className={classes.iconAlert}
              style={{ fontSize: 24 }}
            />
          </Grid>
          <Grid item xs={9}>
            <Typography color="textPrimary">{item.name ?? item.deviceId}</Typography>

            <Grid item xs={12}>
              <Typography className={classes.itemText} variant="body2">
                {fault && fault.length > 0 && fault[0].description
                  ? fault[0].description
                  : tr("_unknownFault")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </GridListTile>
  )
}

const styles = theme => ({
  alertItemPaper: {
    width: 220,
    height: 50,
    padding: 5,
    borderColor: theme.palette.error.dark,
    borderStyle: "solid",
    borderWidth: 1
  },
  iconAlert: {
    color: theme.palette.error.dark
  },
  itemText: {
    color: theme.palette.text.error
  }
})

export default withStyles(styles, { withTheme: true })(AlertItem)
