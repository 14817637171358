import { compose, createStore, applyMiddleware } from "redux"
import { persistReducer, persistStore } from "redux-persist"
import thunk from "redux-thunk"
import * as authActions from "./actions/auth"
import { rootReducer } from "./reducers"
import storage from "redux-persist/lib/storage"
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2"

const config = {
  key: "platform", // name can be anything
  storage,
  version: 1,
  stateReconciler: autoMergeLevel2,
  blacklist: ["websocket"]
}

const enhancers = []

const persistedReducer = persistReducer(config, rootReducer)
if (
  process.env.NODE_ENV === "development" &&
  "__REDUX_DEVTOOLS_EXTENSION__" in window
) {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__
  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension())
  }
}

const init = async () => {
  await testToken()
}

const testToken = async () => {
  try {
    // Test if token is still valid
    const {
      auth: { token }
    } = store.getState()

    if (!token) {
      return
    }

    await store.dispatch(authActions.validateToken())
  } catch (err) {
    console.log(err.message)
  }
}

const composeEnhancers =
  process.env.NODE_ENV === "production"
    ? compose
    : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export let store = createStore(
  persistedReducer,
  undefined,
  composeEnhancers(applyMiddleware(thunk))
)

export let persistor = persistStore(store, null, () => init())
