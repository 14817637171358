import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import TextField from "@material-ui/core/TextField"
import ButtonBase from "@material-ui/core/ButtonBase"
import Dialog from "@material-ui/core/Dialog"
import Grid from "@material-ui/core/Grid"
import ListItemText from "@material-ui/core/ListItemText"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import Typography from "@material-ui/core/Typography"

import useTheme from "@material-ui/core/styles/useTheme"
import makeStyles from "@material-ui/core/styles/makeStyles"
import CloseIcon from "@material-ui/icons/Close"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { tr } from "translations"
import { AddIoTDevices, fetchIoTDevices } from "../../actions/iotDevices"
import { AddDeviceDashboard } from "../../actions/dashboard"
import { fetchSites } from "../../actions/sites"
import { typeOptions } from "../../devicetypes/typeOptions"
import SettingsButtons from "../Dashboard/DashboardWidgets/settingModals/parts/settingsButtons"

const AddDevice = props => {
  const { onClose, site, fullScreen, isOpen } = props

  const classes = useStyles()
  const theme = useTheme()

  const dispatch = useDispatch()
  const [type, setType] = useState("Default")

  const customerList = useSelector((state) => state.customers?.customerList)
  const deviceTypes = customerList ? customerList[0]?.deviceTypes : undefined

  let types = []
  if(deviceTypes && deviceTypes.length !== 0){
    types = deviceTypes
  }
  else{
    types = typeOptions
  }

  // redux states
  const siteList = useSelector(state => state.sites.siteList)
  //const iotDevices = useSelector(state => state.iotDevices)
  const isDark = useSelector(state => state.ui.currentTheme === "dark")

  // state
  const [chosenSite, setChosenSite] = useState(site)
  const [deviceId, setDeviceId] = useState("")
  const [name, setName] = useState("")

  

  useEffect(() => {
    Promise.all([dispatch(fetchSites())])
  }, [dispatch])

  const handleType = (event) => {
    setType(event.target.value)
  }

  const handleChange = event => {
    //console.log(event)
    const { id, name, value } = event.target
    if (name === "site") {
      setChosenSite(value)
    }
    if (id === "deviceId") {
      setDeviceId(value)
    }
    if (name === "name") {
      setName(value)
    }
  }

  const handleSave = () => {
    // Tbd: proper validation for deviceId, regexp
    if (deviceId !== "choose") {
      const site = chosenSite
      return dispatch(AddIoTDevices({ 
        site, 
        deviceId,
        name,
        deviceType: type === "choose" ? "Default" : type 
      }))
        .then(res => {
          //console.log(res)
          dispatch(AddDeviceDashboard(res.iotDevice._id))
          dispatch(fetchIoTDevices())
          onClose()
        })
        .catch(err => console.log("Adding device failed", err))
    }
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      modal="true"
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
      className={classes.dialogRoot}
      fullWidth={true}
      maxWidth={"lg"}
    >
      <Grid container className={classes.container}>
        <Grid
          item
          xs={12}
          container
          justify="space-between"
          alignItems="center"
          className={classes.topicTextField}
        >
          <Typography variant="h6">{tr("_add_device")}</Typography>
          <ButtonBase size="large" onClick={onClose}>
            <CloseIcon fontSize="large" className={classes.icon} />
          </ButtonBase>
        </Grid>

        <Grid item xs={12}>
          <FormControl className={classes.textField}>
            <InputLabel
              classes={{
                root: classes.inputRoot,
                focused: classes.inputFocus
              }}
              htmlFor="site-controlled-open-select"
            >
              {tr("_selectSite")}
            </InputLabel>
            <Select
              id="site-controlled-open-select"
              required
              value={chosenSite}
              onChange={handleChange}
              inputProps={{
                name: "site",
                id: "site-controlled-open-select"
              }}
              classes={{ root: classes.root, icon: classes.icon }}
              IconComponent={ExpandMoreIcon}
              disableUnderline
              displayEmpty
              MenuProps={{
                PaperProps: {
                  style: {
                    backgroundColor: theme.palette.popup.background
                  }
                }
              }}
            >
              <MenuItem value="choose">
                <Typography className={classes.icon}>
                  <em>{tr("_selectSite")}</em>
                </Typography>
              </MenuItem>
              {siteList
                ? siteList.map(site => {
                    return (
                      <MenuItem value={site._id} key={site._id}>
                        <ListItemText
                          primary={site.name}
                          primaryTypographyProps={
                            isDark
                              ? {
                                  color: "primary"
                                }
                              : {
                                  color: "textPrimary"
                                }
                          }
                        />
                      </MenuItem>
                    )
                  })
                : ""}
            </Select>
          </FormControl>
        </Grid>
        
        <Grid item xs={12}>
          <FormControl className={classes.textField}>
            <InputLabel
              classes={{
                root: classes.inputRoot,
                focused: classes.inputFocus
              }}
              htmlFor="type-controlled-open-select"
            >
              {tr("_selectType")}
            </InputLabel>
            <Select
              id="type-controlled-open-select"
              required
              value={type}
              onChange={handleType}
              inputProps={{
                name: "type",
                id: "type-controlled-open-select"
              }}
              classes={{ root: classes.root, icon: classes.icon }}
              IconComponent={ExpandMoreIcon}
              disableUnderline
              displayEmpty
              MenuProps={{
                PaperProps: {
                  style: {
                    backgroundColor: theme.palette.popup.background
                  }
                }
              }}
            >
              <MenuItem value="choose">
                <Typography className={classes.icon}>
                  <em>{tr("_selectType")}</em>
                </Typography>
              </MenuItem>
              {types
                ? types.map(type => {
                    return (
                      <MenuItem value={type} key={type}>
                        <ListItemText
                          primary={type}
                          primaryTypographyProps={
                            isDark
                              ? {
                                  color: "primary"
                                }
                              : {
                                  color: "textPrimary"
                                }
                          }
                        />
                      </MenuItem>
                    )
                  })
                : ""}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="deviceName"
            label={tr("_name")}
            name="name"
            className={classes.textField}
            value={name}
            onChange={handleChange}
            margin="normal"
            InputLabelProps={{
              style: { color: theme.palette.text.fixed, fontWeight: 500 }
            }}
            InputProps={{
              style: { color: theme.palette.text.fixed}
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="deviceId"
            label={tr("_deviceid")}
            name="deviceId"
            className={classes.textField}
            value={deviceId}
            onChange={handleChange}
            margin="normal"
            InputLabelProps={{
              style: { color: theme.palette.text.fixed, fontWeight: 500 }
            }}
            InputProps={{
              style: { color: theme.palette.text.fixed }
            }}
          />
        </Grid>
        
        <SettingsButtons
          handleSave={handleSave}
          closeMenu={onClose}
          disabled={!chosenSite || chosenSite === "choose" || !deviceId}
          selectText={tr("_add_device")}
        />
      </Grid>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.popup.background,
    padding: theme.spacing(2)
  },
  dialogRoot: {
    minWidth: 500,
    maxWidth: 600,
    margin: "auto"
  },
  fileUploadArea: {
    marginTop: theme.spacing(2)
  },
  textField: {
    color: theme.palette.text.fixed,
    minWidth: 90
  },
  topicTextField: {
    marginBottom: 25,
    color: theme.palette.text.fixed
  },
  icon: {
    color: theme.palette.text.fixed
  },
  inputRoot: {
    color: theme.palette.text.fixed + " !important"
  },
  inputFocus: {
    color: theme.palette.text.fixed + " !important"
  }
}))

export default AddDevice
