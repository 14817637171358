
import Button from "@material-ui/core/Button"
import FormLabel from "@material-ui/core/FormLabel"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import Grid from "@material-ui/core/Grid"
import withStyles from "@material-ui/core/styles/withStyles"
import React from "react"
import DeleteIcon from "@material-ui/icons/Close"

// Editable text field with a cross symbol for deleting
const TextFieldWithCross = props => {
  const { fieldName, id, onChange, value, fieldLabel, theme, classes, disabled, onCrossClick } = props
  return (
    <Grid item>
      <FormLabel
        component="legend"
        style={{
          fontWeight: "bold",
          marginTop: "5px",
          width: "100%",
          textColor: theme.palette.text.field
        }}
      >
        {fieldLabel}
      </FormLabel>
      <TextField
        id={id}
        name={fieldName}
        style={{
          marginTop: "5px",
          width: "100%",
          textColor: theme.palette.text.field
        }}
        value={value}
        disabled={disabled ? true : false}
        onChange={onChange}
        margin="normal"
        InputProps={{
          classes: {
            input: classes.inputColor,
            underline: classes.underline
          },
          endAdornment: (
            <InputAdornment position="end">
              <Button
                aria-label="clear"
                onClick={onCrossClick}
              >
                <DeleteIcon style={{ color: "red" }} />
              </Button>
            </InputAdornment>
          )
        }}
        InputLabelProps={{
          classes: {
            root: classes.labelColor,
            focused: classes.labelColor,
            error: classes.labelColor
          }
        }}
      />
    </Grid>
  )
}

const styles = theme => ({
  inputColor: {
    color: theme.palette.text.primary
  },
  labelColor: {
    color: theme.palette.text.field
  },
  underline: {
    color: theme.palette.text.primary,
    "&::after": {
      border: "1px solid " + theme.palette.text.primary
    }
  }
})

export default withStyles(styles, { withTheme: true })(TextFieldWithCross)
