// device specific actions here, eg. send command to turn lights on

import axios from "axios"
import { getApiUrl } from "../../constants/config"
import { parseErrorData } from "../../services/errors"
import { authHeader } from "../../actions/common"
import { stateType } from "../../constants/actions"

export const deviceAction = id => {
  console.log("Action for device:", id)
}

const iotDeviceDataRequested = () => ({
  type: stateType.IOTDEVICE_DATA_REQUESTED
})

const iotDeviceDataReceived = iotDeviceData => ({
  type: stateType.IOTDEVICE_DATA_RECEIVED,
  iotDeviceData
})

const iotDeviceApiFailed = error => ({
  type: stateType.SET_ERROR,
  error
})

const dataUpdatesRequested = () => ({
  type: stateType.DEVICEDATA_DATA_REQUESTED
})

const dataUpdatesReceived = iotDeviceData => ({
  type: stateType.DEVICEDATA_DATA_RECEIVED,
  iotDeviceData
})

const datalistUpdatesRequested = () => ({
  type: stateType.DEVICEDATA_DATALIST_REQUESTED
})

const datalistUpdatesReceived = iotDeviceData => ({
  type: stateType.DEVICEDATA_DATALIST_RECEIVED,
  iotDeviceData
})

export const getDataUpdates = (id, days = 1) => async (dispatch, getState) => {
  try {
    dispatch(dataUpdatesRequested())
    const state = getState()
    let deviceFilterHours = state.dataUpdate.deviceFilterHours
    if(deviceFilterHours && deviceFilterHours[id]){
      days = Math.ceil(deviceFilterHours[id] / 24)
    }
    let deviceUrl =
      getApiUrl() + "/api/devicetype/treonassetnode/" + id + "?days=" + days
    console.log("GET", deviceUrl)

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }
    const { data } = await axios.get(deviceUrl, authHeader(useToke))
    const dataItem = { data: data.data, nodeId: id }
    return Promise.resolve(dispatch(dataUpdatesReceived(dataItem)))
  } catch (err) {
    let errorReply = parseErrorData(err)
    if (!errorReply) {
      errorReply = err
    }
    return Promise.reject(dispatch(iotDeviceApiFailed(errorReply)))
  }
}

export const getDataUpdatesArray = (ids, days = 1) => async (dispatch, getState) => {
  try {
    dispatch(datalistUpdatesRequested())
    let deviceUrl =
      getApiUrl() + "/api/devicetype/treonassetnode/?days=" + days
    const state = getState()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }
    const { data } = await axios.post(deviceUrl, {ids}, authHeader(useToke))
    const dataItem = { data: data.nodes, nodes: ids }
    return Promise.resolve(dispatch(datalistUpdatesReceived(dataItem)))
  } catch (err) {
    let errorReply = parseErrorData(err)
    if (!errorReply) {
      errorReply = err
    }
    return Promise.reject(dispatch(iotDeviceApiFailed(errorReply)))
  }
}

export const getIoTDeviceData = id => async (dispatch, getState) => {
  try {
    dispatch(iotDeviceDataRequested())
    let deviceUrl = getApiUrl() + "/api/devicetype/treonassetnode/" + id
    console.log("GET", deviceUrl)
    const state = getState()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }
    const { data } = await axios.get(deviceUrl, authHeader(useToke))

    let deviceData = null
    deviceData = data.data
    return Promise.resolve(dispatch(iotDeviceDataReceived(deviceData)))
  } catch (err) {
    let errorReply = parseErrorData(err)
    if (!errorReply) {
      errorReply = err
    }
    console.log("HELLO", err)
    return Promise.reject(dispatch(iotDeviceApiFailed(errorReply)))
  }
}
