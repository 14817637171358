import React from "react"

import Grid from "@material-ui/core/Grid"
import GridListTile from "@material-ui/core/GridListTile"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"
import FastRewindIcon from "@material-ui/icons/FastRewind"

const WarningItem = props => {
  const { classes, item, onDrag } = props

  return (
    <GridListTile>
      <Paper
        className={classes.warningItemPaper}
        onMouseDown={onDrag(item)}
        onTouchStart={onDrag(item)}
        square={true}
      >
        <Grid container alignItems="center">
          <Grid item xs={3}>
            <FastRewindIcon
              className={classes.iconWarning}
              style={{ fontSize: 24 }}
            />
          </Grid>
          <Grid item xs={9}>
            <Typography color="textPrimary">{item.name ?? item.deviceId}</Typography>

            <Grid item xs={12}>
              <Typography className={classes.device} variant="body2">
                {item.deviceType}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </GridListTile>
  )
}

const styles = theme => ({
  warningItemPaper: {
    width: 220,
    height: 50,
    padding: 5,
    borderColor: theme.palette.error.light,
    borderStyle: "solid",
    borderWidth: 1
  },
  iconWarning: {
    color: theme.palette.error.light
  },
  device: {
    color: theme.palette.error.light
  }
})

export default withStyles(styles, { withTheme: true })(WarningItem)
