import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"
import React, { useState, useEffect } from "react"

const LightGraph = props => {
  const { classes, value } = props

  const [full, setFull] = useState(0)
  const [partial, setPartial] = useState(0)
  const [empty, setEmpty] = useState(0)

  useEffect(() => {
    const valueTemp = Math.min(Math.max(value, 0), 100)

    const fullTemp = Math.floor(valueTemp / 10)
    const partialTemp = valueTemp - Math.floor(valueTemp / 10) * 10
    const emptyTemp = 10 - fullTemp - Math.ceil(partialTemp / 10)

    setFull(fullTemp)
    setPartial(partialTemp)
    setEmpty(emptyTemp)
  }, [value])

  return (
    <div className={classes.div}>
      <div className={classes.text}>
        <Typography variant="body1">{value} %</Typography>
      </div>
      <div className={classes.container}>
        {new Array(full).fill(0).map((item, index) => (
          <div key={index} className={classes.full}>
            &nbsp;
          </div>
        ))}
        {partial !== 0 && (
          <div
            style={{
              width: "20%",
              height: "40%",
              margin: "1px",
              background: `linear-gradient(to right, #4166ad ${partial *
                10}%, #CCD4D9 ${partial * 10}%`
            }}
          ></div>
        )}
        {new Array(empty).fill(0).map((item, index) => (
          <div key={index} className={classes.empty}>
            &nbsp;
          </div>
        ))}
      </div>
    </div>
  )
}

const styles = () => ({
  div: {
    minWidth: "150px",
    display: "flex",
    alignItems: "center"
  },
  text: {
    display: "inline-block",
    width: "25%",
    whiteSpace: "nowrap",
    textAlign: "left"
  },
  container: {
    width: "70%",
    height: "30px",
    display: "flex",
    flexFlow: "column wrap"
  },
  full: {
    width: "20%",
    height: "40%",
    margin: "1px",
    background: "#4166ad"
  },
  empty: {
    width: "20%",
    height: "40%",
    margin: "1px",
    background: "#CCD4D9"
  }
})

export default withStyles(styles, { withTheme: true })(LightGraph)
