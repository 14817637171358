import React, { useState } from "react"

import TextField from "@material-ui/core/TextField"
import Link from "@material-ui/core/Link"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import useTheme from "@material-ui/core/styles/useTheme"
import makeStyles from "@material-ui/core/styles/makeStyles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useSelector, useDispatch } from "react-redux"
import { Redirect } from "react-router-dom"
import { tr } from "translations"
import { fetchToken } from "../actions/auth"
import { resetPassword } from "../actions/users"
import ForgotPasswordModal from "../components/modals/ForgotPassword"
import ForgotPasswordSentModal from "../components/modals/ForgotPasswordSent"
import { AJRLogoLogin, IoTStationLogo } from "../components/SvgLib"

const LoginScreen = () => {
  const [inputValues, setInputValues] = useState({
    username: "",
    password: ""
  })
  const [forgotModalOpen, setForgotModalOpen] = useState(false)
  const [forgotSentModalOpen, setForgotSentModalOpen] = useState(false)
  const [saving, setSaving] = useState(false)

  const token = useSelector(state => state.auth.token)
  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down("sm"))
  const dispatch = useDispatch()

  const handleOnChange = event => {
    const { id, value } = event.target
    setInputValues({ ...inputValues, [id]: value })
  }

  const onHandleReturn = event => {
    if (event.key == "Enter") {
      _loginPressed()
    }
  }

  const _loginPressed = () => {
    const { username, password } = inputValues
    dispatch(fetchToken({ username, password }))
  }

  const onResetPassword = async email => {
    setSaving(true)
    dispatch(resetPassword(email))
      .then(() => setSaving(false))
      .then(() => setForgotSentModalOpen(true))
      .then(() => setForgotModalOpen(false))
      .catch(err => console.log(err))
  }

  // If we have token, let's redirect to dashboard.
  if (token) {
    return <Redirect to={"/dashboard"} />
  } else {
    return (
      <Grid
        container
        justify={"center"}
        alignItems="center"
        className={classes.root}
      >
        <Grid
          container
          item
          xs={10}
          sm={6}
          md={4}
          lg={3}
          xl={2}
          alignItems="center"
          direction="column"
        >
          <Typography variant="h6" className={classes.mainLabel}>
            {tr("_login")}
          </Typography>
          <TextField
            id="username"
            label={tr("_username")}
            className={classes.textInput}
            value={inputValues.username}
            onChange={handleOnChange}
            margin="normal"
            InputProps={{
              classes: {
                input: classes.inputColor
              }
            }}
            InputLabelProps={{
              classes: {
                root: classes.labelColor,
                focused: classes.labelColor,
                error: classes.labelColor
              }
            }}
            variant="filled"
            fullWidth
          />

          <TextField
            id="password"
            label={tr("_password")}
            className={classes.textInput}
            value={inputValues.password}
            type="password"
            onChange={handleOnChange}
            margin="normal"
            onKeyUp={onHandleReturn}
            InputProps={{
              classes: {
                input: classes.inputColor
              }
            }}
            InputLabelProps={{
              classes: {
                root: classes.labelColor,
                focused: classes.labelColor,
                error: classes.labelColor
              }
            }}
            variant="filled"
            fullWidth
          />

          <Typography
            variant={"subtitle2"}
            className={classes.labelTextSecondary}
          >
            <Link
              className={classes.smallLabel}
              component="button"
              onClick={() => setForgotModalOpen(!forgotModalOpen)}
            >
              {tr("Reset_password")}
            </Link>
          </Typography>

          <Button
            variant="contained"
            color="secondary"
            onClick={_loginPressed}
            size="large"
            style={{ color: theme.palette.secondary.light }}
            fullWidth
          >
            {tr("_log_In")}
          </Button>
        </Grid>

        {forgotModalOpen && (
          <ForgotPasswordModal
            onClose={() => setForgotModalOpen(!forgotModalOpen)}
            saving={saving}
            isOpen={forgotModalOpen}
            onSubmitEmail={onResetPassword}
          />
        )}
        {forgotSentModalOpen && (
          <ForgotPasswordSentModal
            onClose={() => setForgotSentModalOpen(!forgotSentModalOpen)}
            isOpen={forgotSentModalOpen}
          />
        )}
        <div style={{ position: "absolute", left: "30px", bottom: "30px" }}>
          <IoTStationLogo
            style={{ height: matches ? 20 : 35, margin: "5px 0px" }}
          />
        </div>
        <div style={{ position: "absolute", right: "30px", bottom: "30px" }}>
          <AJRLogoLogin
            style={{ height: matches ? 35 : 60, margin: "5px 0px" }}
          />
        </div>
      </Grid>
    )
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    height: "100vh",
    backgroundImage: theme.backgroundImage,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed"
  },
  mainLabel: {
    fontWeight: "bold",
    fontSize: "25px",
    color: theme.palette.loginLabel.primary
  },
  labelTextSecondary: {
    fontWeight: "bold",
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  textInput: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  inputColor: {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.unselected.main
  },
  labelColor: {
    color: theme.palette.secondary.main
  },
  smallLabel: {
    color: theme.palette.loginLabel.primary
  }
}))

export default LoginScreen
