
import InputAdornment from "@material-ui/core/InputAdornment"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"

import VisibilityIcon from "@material-ui/icons/Visibility"
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import * as React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { tr } from "translations"
import { validate } from "../actions/registration"
import { logOut } from "../actions/auth"
import { registerUser } from "../actions/users"
import TextLine from "../components/TextLine"
import { platformBlue, platformWhite } from "../services/colors"
import { checkPassword } from "../services/utils"

class Register extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: "",
      password: "",

      phoneNumber: "",
      error: null,
      isSending: false,
      submitSuccessful: false,
      info: false,
      infoText: "",

      showPassword: false
    }
    this.resetError = this.resetError.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.onInfoTextLinePress = this.onInfoTextLinePress.bind(this)
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this)
  }

  componentDidMount() {
    this.setState({
      submitSuccessful: false,
      info: false,
      infoText: ""
    })
    if (this.props.stateToken) {
      this.props.logOut()
    }
  }

  onInfoTextLinePress() {
    this.setState({ info: false, infoText: "" })
  }
  handleChange({ target }) {
    this.setState({ [target.name]: target.value, formUpdated: true })
  }

  resetError() {
    this.setState({ error: null })
  }

  handleClickShowPassword() {
    this.setState(state => ({
      showPassword: !state.showPassword
    }))
  }

  async handleSubmit(e) {
    e.preventDefault()
    const { username, password, phoneNumber } = this.state

    const passwordChecked = checkPassword(password)
    if (this.props.token && passwordChecked === "ok") {
      this.props
        .registerUser(this.props.token, password, {
          username,
          phoneNumber
        })
        .then(() => {
          console.log("User registered")
          this.setState({
            submitSuccessful: true
          })
          setTimeout(() => {
            this.props.history.push("/login")
          }, 5000)
        })
        .catch(err => {
          console.log("Error", err)
          this.setState({
            info: true,
            infoText: "Jotain meni vikaan tai käyttäjätunnus on jo käytössä.",
            submitSuccessful: false
          })
        })
    } else {
      this.setState({
        info: true,
        infoText: passwordChecked,
        submitSuccessful: false
      })
    }
  }

  callBack(msg) {
    console.log(msg)
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.stateToken &&
      prevProps.stateToken !== this.props.stateToken
    ) {
      this.props.logOut()
    }
  }

  render() {
    const { classes } = this.props

    if (this.props.stateToken) {
      return <div>Waiting to log out...</div>
    }

    const {
      /* customer, email, role, */ username,
      password,
      phoneNumber,
      isSending,
      submitSuccessful,
      info,
      infoText,
      showPassword
    } = this.state
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          className={classes.signupForm}
          spacing={3}
        >
          <Grid item className={classes.headerItem}>
            <Typography
              color="primary"
              variant={"h5"}
              className={classes.labelText}
              align="left"
            >
              {tr("_registerheadlinestep1")}
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              id="username"
              name="username"
              label={tr("_username")}
              className={classes.textInput}
              value={username}
              onChange={this.handleChange}
              InputProps={{
                classes: {
                  input: classes.inputColor
                }
              }}
              InputLabelProps={{
                classes: {
                  root: classes.labelColor,
                  focused: classes.labelColor,
                  error: classes.labelColor
                }
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              id="outlined-phoneNumber"
              label={tr("_phone")}
              name="phoneNumber"
              className={classes.textInput}
              value={phoneNumber}
              onChange={this.handleChange}
              variant="outlined"
              InputProps={{
                classes: {
                  input: classes.inputColor
                }
              }}
              InputLabelProps={{
                classes: {
                  root: classes.labelColor,
                  focused: classes.labelColor,
                  error: classes.labelColor
                }
              }}
            />
          </Grid>

          <Grid item>
            <TextField
              name="password"
              label={tr("_password")}
              className={classes.textInput}
              value={password}
              type={showPassword ? "text" : "password"}
              onChange={this.handleChange}
              onKeyUp={this.onHandleReturn}
              variant="outlined"
              InputProps={{
                classes: {
                  input: classes.inputColor
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </Button>
                  </InputAdornment>
                )
              }}
              InputLabelProps={{
                classes: {
                  root: classes.labelColor,
                  focused: classes.labelColor,
                  error: classes.labelColor
                }
              }}
            />
          </Grid>

          <Grid item>
            <Button
              disabled={
                this.props.token && !isSending && !submitSuccessful
                  ? false
                  : true
              }
              className={classes.buttonPadding}
              onClick={this.handleSubmit}
            >
              <Typography variant="h6" color={platformWhite}>
                {tr("_sign_up").toUpperCase()}
              </Typography>
            </Button>
          </Grid>

          {submitSuccessful && (
            <div className="alert alert-success">
              <strong>{tr("_registrationSuccessful")}</strong>
            </div>
          )}

          {info && (
            <TextLine
              mode={0}
              text={infoText}
              onClick={this.onInfoTextLinePress}
            />
          )}
        </Grid>
      </Grid>
    )
  }
}

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  dense: {
    marginTop: 16
  },
  menu: {
    width: 200
  },
  root: {
    flexGrow: 1,
    width: "100vw",
    height: "100vh",
    backgroundImage: theme.loginBackground,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed"
  },
  headerItem: {
    width: 400
  },
  labelText: {
    fontWeight: "bold",
    letterSpacing: "3px"
  },
  labelTextSecondary: {
    fontWeight: "bold",
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  textInput: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
    backgroundColor: "#FFFFFF"
  },
  padding: {
    paddingTop: 10,
    paddingBottom: 10
  },
  buttonPadding: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: "10%",
    paddingRight: "10%",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
    textTransform: "capitalize",
    color: platformWhite,
    // background: "linear-gradient(to right, #46c77f, #18ba60)",
    backgroundColor: platformBlue,
    "&:hover": {
      backgroundColor: platformBlue
    }
  },
  inputColor: {
    color: "black"
  },
  labelColor: {
    color: "gray",
    "&$focused": {
      color: "gray"
    }
  },
  underline: {
    borderBottom: "1px solid black"
  },
  customTextField: {
    border: "0px"
  }
})

const mapDispatchToProps = dispatch => ({
  validate: token => dispatch(validate(token)),
  registerUser: (token, password, userdata, successFunc, failureFunc) =>
    dispatch(registerUser(token, password, userdata, successFunc, failureFunc)),
  logOut: () => dispatch(logOut())
})

const mapStateToProps = (state, ownProps) => {
  return {
    userList: state.users.userList,
    token: ownProps.match.params.token,
    stateToken: state.auth.token
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles, { withTheme: true })(Register))
)
