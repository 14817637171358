import axios from "axios"

export const getApiUrl = () => {
  return process.env.REACT_APP_BACKEND_URL || "" // "http://localhost:3000"
}

export async function getWsUrl() {
  const host = window.location.hostname
  let protocol = window.location.protocol
  if (protocol.length > 5) protocol = "wss://"
  else protocol = "ws://"
  const path = "/api/ws"

  let siteUrl = process.env.REACT_APP_BACKEND_URL || ""
  siteUrl += "/api/config"
  const { data } = await axios.get(siteUrl)
  const port = ":" + data.wsPort

  const url = protocol + host + port + path
  return url
}

export const GoogleApiKey = () => {
  return process.env.REACT_APP_GOOGLE_KEY || ""
}
