import React from "react"
import Grid from "@material-ui/core/Grid"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"
import useTheme from "@material-ui/core/styles/useTheme"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { tr } from "translations"
import WidgetSiteSelector from "./WidgetSiteSelector"
import { localeTime } from "../../../../services/utils"

const WidgetHeader = props => {
  const {
    classes,
    title,
    secondaryTitle = undefined,
    lastUpdate = undefined,
    sites = [],
    filteredDev = undefined,
    handleSite = undefined,
    site = undefined
  } = props

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("qhd"))

  return (
    <Grid container alignItems="flex-start">
      <Grid container item xs={12} className={classes.topBar}>
        <Grid item xs={sites.length > 0 ? 8 : 12} style={{ display: "flex" }}>
          <Tooltip
            title={`${title} ${secondaryTitle ? secondaryTitle : ""}`}
            placement="top"
          >
            <Typography
              noWrap
              variant={matches ? "h5" : "h6"}
              className={classes.title}
            >
              {title}
            </Typography>
          </Tooltip>
          {secondaryTitle && (
            <Typography
              noWrap
              variant={matches ? "h5" : "h6"}
              className={classes.secondaryTitle}
            >
              &nbsp;{secondaryTitle}
            </Typography>
          )}
          {filteredDev && (
            <Typography
              variant={matches ? "h5" : "h6"}
              color="error"
              className={classes.devices}
            >
              {" (" + filteredDev.length + ")"}
            </Typography>
          )}
        </Grid>
        {sites.length > 0 && (
          <Grid item xs={4}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="flex-end"
            >
              {sites.length > 1 && (
                <WidgetSiteSelector
                  onSelect={handleSite}
                  selected={site}
                  sites={sites}
                />
              )}
              {sites.length == 1 && (
                <Tooltip
                  title={sites[0].address ? sites[0].address : ""}
                  placement="top"
                >
                  <Typography noWrap>
                    {sites[0].address ? sites[0].address : ""}
                  </Typography>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        )}
        {lastUpdate ? (
          <Grid item xs={12} className={classes.lastUpdate}>
            <Typography variant="caption" noWrap>
              {tr("_last_update") + ": "} {localeTime(lastUpdate)}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  )
}

const styles = theme => ({
  devices: {
    fontWeight: 500
  },
  title: {
    fontWeight: 500
  },
  secondaryTitle: {
    fontWeight: 300
  },
  topBar: {
    paddingTop: 10
  },
  lastUpdate: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("xl")]: {
      marginBottom: theme.spacing(0)
    }
  }
})

export default withStyles(styles)(WidgetHeader)
