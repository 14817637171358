import React from "react"

import ThemeProvider from "@material-ui/styles/ThemeProvider"
import withTheme from "@material-ui/core/styles/withTheme"
import responsiveFontSizes from "@material-ui/core/styles/responsiveFontSizes"
import createMuiTheme from "@material-ui/core/styles/createMuiTheme"
import {
  platformAlmostWhite,
  platformBlue,
  platformDarkBlue,
  platformDarkBlueSelected,
  platformRed,
  platformYellow,
  platformTextPrimary,
  platformTextSecondary,
  platformLightAlert,
  platformLightRed,
  platformLightAdditional,
  platformLightMain,
  platformLightDark,
  platformLightTextPrimary,
  platformLightTextSecondary,
  platformLightWarning,
  platformLoginLabel,
  tableHeadLabelDark,
  threateningAlert,
  platformPaleBlue,
  platformPurple
} from "../services/colors"
import imageBackgroundDark from "./../images/background.png"
import imageBackgroundLight from "./../images/white_background.png"

// TODO: create palette objects for each theme to import
// use e.g. switch-case structure to return correct palette object
// one possible solution below
/*

import { darkTheme, lightTheme } from "../services/themes"
function selectTheme(type) {
  switch (type) {
    case "dark":
      return darkTheme
    case "light":
      return lightTheme
    default:
      return darkTheme
  }
}

const theme = type => {
  return createMuiTheme(selectTheme(type))
}
*/

const themeCreator = baseType => {
  const isDark = baseType === "dark"
  return createMuiTheme({
    backgroundImage: isDark
      ? `url(${imageBackgroundDark})`
      : `url(${imageBackgroundLight})`,
    loginBackground: `url(${imageBackgroundLight})`,
    palette: {
      type: baseType,
      primary: {
        main: isDark ? platformDarkBlueSelected : platformLightMain,
        light: isDark ? platformBlue : platformLightAdditional,
        dark: isDark ? platformDarkBlue : platformLightDark,
        contrastText: "#000000",
        darkMain: platformDarkBlueSelected,
        lightMain: platformLightMain
      },
      secondary: {
        main: platformBlue,
        light: platformTextPrimary,
        themed: isDark ? platformDarkBlue : platformLightAdditional,
        contrasted: isDark ? platformLightAdditional : platformDarkBlue,
        contrastText: "#000000"
      },
      error: {
        main: isDark ? platformLightRed : platformLightAlert,
        light: isDark ? platformYellow : platformPurple,
        dark: platformLightAlert,
        themed: isDark ? platformRed : platformYellow,
        threatening: isDark ? threateningAlert : threateningAlert, // mm. MapMarkerPoint alerts
        fixed: platformRed
      },
      popup: {
        background: platformTextPrimary,
        text: platformDarkBlue
      },
      action: {
        light: isDark ? platformYellow : platformLightWarning,
        main: isDark ? platformTextSecondary : platformLightTextSecondary,
        disabled: "gray"
      },
      background: {
        paper: isDark ? platformDarkBlueSelected : platformLightMain,
        default: isDark ? platformDarkBlue : platformLightDark
      },
      nav: {
        // Nav bar attachment on profile page, doesn't follow color conventions.
        secondary: isDark ? platformDarkBlue : "#EBEEF0",
        secondaryText: isDark
          ? platformLightAdditional
          : platformLightTextPrimary,
        secondaryIcon: isDark ? platformTextPrimary : platformBlue
      },
      unselected: {
        main: platformAlmostWhite
      },
      button: {
        default: "#cdd4d8", // gray,
        caption: platformLightTextPrimary,
        fixed: platformTextPrimary,
        hide: isDark ? platformLoginLabel : platformBlue,
        delete: "gray",
        fixedAction: platformLightTextSecondary,
        disabled: platformLightDark
      },
      table: {
        // Tables on profile pages
        tableRowBackground: isDark ? "#031320" : "#CDD3D9"
      },
      text: {
        primary: isDark ? platformTextPrimary : platformLightTextPrimary,
        secondary: isDark ? platformBlue : platformLightTextSecondary,
        field: isDark ? platformTextPrimary : platformBlue, // Field labels on profile page.
        // Dashboard
        working: isDark ? platformTextSecondary : platformLightTextSecondary,
        error: isDark ? platformLightRed : platformLightAlert,
        warning: isDark ? platformYellow : platformLightWarning,
        activeNav: isDark ? platformYellow : platformLightWarning,
        tablehead: isDark ? tableHeadLabelDark : platformLightTextPrimary, // task 1521 : task 3149
        fixed: platformLightTextPrimary
      },
      loginLabel: {
        primary: platformLoginLabel
      },
      widgetColor: {
        limitBelow: isDark ? platformPaleBlue : platformBlue,
        limitAbove: isDark ? platformYellow : platformPurple
      },
      lineDefaultColors: [
        ...isDark ? "#D391A3": "#C75A78",
        ...isDark ? "#7DBD65" : "#54AA34",
        "#8BB9DA",
        "#A66CD1",
        platformYellow,
        platformLightDark
      ],
      markerLabel: {
        main: isDark ? "#000000" : "#ffffff",
        background: isDark ? "#ffffff" : "#000000",
      }
    },
    typography: {},
    // set global style for scrollbars
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "::-webkit-scrollbar": {
            height: "6px",
            width: "6px"
          },
          "::-webkit-scrollbar-track-piece": {
            backgroundColor: platformTextPrimary
          },
          "::-webkit-scrollbar-thumb:vertical": {
            background: platformDarkBlueSelected,
            borderRadius: "20px"
          },
          "::-webkit-scrollbar-thumb:horizontal": {
            background: platformDarkBlueSelected,
            borderRadius: "20px"
          },
          "::-webkit-scrollbar-button": {
            backgroundColor: platformTextPrimary,
            height: 2,
            width: 8
          }
        }
      }
    },
    // https://material-ui.com/customization/breakpoints/#default-breakpoints
    breakpoints: {
      values: {
        xs: 0,
        sm: 900,
        md: 1000,
        lg: 1280,
        xl: 1920,
        qhd: 2560,
        uhd: 3840
      }
    }
  })
}

const ThemeWrapper = ({ children, isDark }) => {
  let baseType = isDark ? "dark" : "light"
  const theme = themeCreator(baseType)

  // adjust font size factor of smaller screen
  const options = {
    factor: 24,
    disableAlign: true,
    breakPoints: ["xs", "sm", "md", "lg", "xl"]
  }
  return (
    <ThemeProvider theme={responsiveFontSizes(theme, options)}>
      <div>{children}</div>
    </ThemeProvider>
  )
}
export default withTheme(ThemeWrapper)
