import React from "react"
import CircularProgress from "@material-ui/core/CircularProgress"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { tr } from "translations"

export default function SettingsButtons({
  handleSave,
  closeMenu,
  disabled = false,
  selectText = "",
  isSaving = false,
  groundPlan = false
}) {
  const classes = useStyles()
  return (
    <Grid
      item
      xs={12}
      container
      className={groundPlan ? classes.groundPlan : classes.buttons}
      justify="flex-end"
    >
      <Button
        variant="outlined"
        className={classes.cancelButton}
        onClick={closeMenu}
      >
        {tr("_cancel")}
      </Button>
      <Button
        variant="contained"
        className={classes.saveButton}
        disabled={disabled}
        onClick={handleSave}
      >
        {isSaving ? (
          <CircularProgress size={22} />
        ) : selectText.length > 0 ? (
          selectText
        ) : (
          tr("_save")
        )}
      </Button>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  cancelButton: {
    margin: theme.spacing(1),
    width: 125,
    height: 40,
    backgroundColor: theme.palette.button.delete,
    color: theme.palette.button.fixed,
    "&:hover": {
      color: theme.palette.text.fixed,
      borderColor: theme.palette.text.fixed
    }
  },
  saveButton: {
    margin: theme.spacing(1),
    minWidth: 125,
    height: 40,
    backgroundColor: theme.palette.button.fixedAction,
    color: theme.palette.button.fixed,
    "&:hover": {
      color: theme.palette.text.fixed
    },
    "&:disabled": {
      backgroundColor: theme.palette.button.disabled,
      color: "gray"
    }
  },
  buttons: {
    marginTop: 40
  },
  groundPlan: {
    marginTop: theme.spacing(1)
  }
}))
