import React from "react"


import ListItemText from "@material-ui/core/ListItemText"
import Select from "@material-ui/core/Select"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import Typography from "@material-ui/core/Typography"
import useTheme from "@material-ui/core/styles/useTheme"
import withStyles from "@material-ui/core/styles/withStyles"

import { tr } from "translations"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

const SiteAreaSelection = props => {
  const {
    classes,
    area,
    floor,
    site,
    areas,
    sites,
    handleSite,
    handleArea,
    map,
    customer
  } = props

  const theme = useTheme()

  // Get the list of floors of this site's areas
  let floors = []
  const siteAreas = areas.areaList.filter(mArea => mArea.site === site)
  for(const loopArea of siteAreas){
    if(!floors.includes(loopArea.floor)){
      floors.push(loopArea.floor)
    }
  }
  return (
    <Grid container item xs={12}>
      <Grid item xs={6}>
        <Typography
          variant="h6"
          noWrap={true}
          className={map ? classes.marginMap : classes.margin}
        >
          <Select
            value={site}
            onChange={handleSite}
            inputProps={{
              name: "site",
              id: "site-id"
            }}
            style={{ color: map ? theme.palette.primary.contrastText : theme.palette.primary.lightMain }}
            classes={{ root: map ? classes.rootMap : classes.root }}
            IconComponent={ExpandMoreIcon}
            disableUnderline
            MenuProps={{
              PaperProps: {
                style: {
                  backgroundColor: theme.palette.primary.main
                }
              }
            }}
          >
            <MenuItem value="landing">
              <em>{tr("_selectSite")}</em>
            </MenuItem>
            {sites.siteList.map(site => {
              return (
                <MenuItem key={site._id} value={site._id}>
                  <ListItemText primary={site.name} />
                </MenuItem>
              )
            })}
          </Select>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography
          variant="h6"
          noWrap={true}
          className={map ? classes.marginMap : classes.margin}
        >
          <Select
            value={area || floor || "area"}
            disabled={site !== "landing" ? false : true}
            onChange={handleArea}
            inputProps={{
              name: "area",
              id: "area-id"
            }}
            style={{ color: map ? theme.palette.primary.contrastText : theme.palette.primary.lightMain }}
            classes={{ root: map ? classes.rootMap : classes.root }}
            IconComponent={ExpandMoreIcon}
            disableUnderline
            MenuProps={{
              PaperProps: {
                style: {
                  backgroundColor: theme.palette.primary.main
                }
              }
            }}
          >
            <MenuItem value="area">
              <em>{tr("_selectArea")}</em>
            </MenuItem>
            {customer && customer.showFullFloor && floors
              .map(floor => {
                return (
                  <MenuItem key={floor} value={floor}>
                    <ListItemText primary={tr("_floor") + " " + floor + " " + tr("_overview")} />
                  </MenuItem>
                )
              })}
            {areas.areaList
              .filter(area => area.site === site)
              .map(area => {
                return (
                  <MenuItem key={area._id} value={area._id}>
                    <ListItemText primary={area.floor + " " + area.name} />
                  </MenuItem>
                )
              })}
          </Select>
        </Typography>
      </Grid>
    </Grid>
  )
}

const styles = theme => ({
  margin: {
    marginLeft: "15px",
    marginRight: "15px"
  },
  marginMap: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0)
  },
  root: {
    minWidth: 250,
    minHeight: 30,
    marginTop: 10
  },
  rootMap: {
    minWidth: 220,
    minHeight: 15
  }
})

export default withStyles(styles)(SiteAreaSelection)
