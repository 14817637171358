import React from "react"
import { useSelector } from "react-redux"

import MenuItem from "@material-ui/core/MenuItem"
import Menu from "@material-ui/core/Menu"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import withStyles from "@material-ui/core/styles/withStyles"

import DeleteIcon from "@material-ui/icons/Delete"
import FileCopyIcon from "@material-ui/icons/FileCopy"
import SettingsIcon from "@material-ui/icons/Settings"
import EditIcon from "@material-ui/icons/Edit"
import AutorenewIcon from "@material-ui/icons/Autorenew"
import SaveAltIcon from "@material-ui/icons/SaveAlt"
import { tr } from "translations"

const DashboardMenu = props => {
  const {
    classes,
    isOpen,
    anchorEl,
    closeMenu,
    handleDefine,
    handleDelete,
    handleEdit,
    handleCopy,
    handleSaveFavourite,
    dashboard,
    handleOpenFavourite
  } = props

  const isDark = useSelector(state => state.ui.currentTheme === "dark")

  return (
    <Menu
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      disableAutoFocusItem={true}
      open={isOpen}
      onClose={closeMenu}
      classes={{ paper: classes.menuPaper }}
    >
      <MenuItem name="define" onClick={handleDefine}>
        <ListItemIcon>
          <SettingsIcon
            className={isDark ? classes.listIcon : classes.listIconLight}
          />
        </ListItemIcon>
        <ListItemText
          primary={tr("_new_widget")}
          primaryTypographyProps={
            isDark
              ? {
                  color: "primary",
                  variant: "h6"
                }
              : {
                  color: "textPrimary",
                  variant: "h6"
                }
          }
        />
      </MenuItem>
      {dashboard && dashboard.type && dashboard.type !== "DefaultWidget" ? (
        <MenuItem name="edit" onClick={() => handleEdit(dashboard)}>
          <ListItemIcon>
            <EditIcon
              className={isDark ? classes.listIcon : classes.listIconLight}
            />
          </ListItemIcon>
          <ListItemText
            primary={tr("_edit2")}
            primaryTypographyProps={
              isDark
                ? {
                    color: "primary",
                    variant: "h6"
                  }
                : {
                    color: "textPrimary",
                    variant: "h6"
                  }
            }
          />
        </MenuItem>
      ) : null}
      <MenuItem name="open" onClick={handleOpenFavourite}>
        <ListItemIcon>
          <AutorenewIcon
            className={isDark ? classes.listIcon : classes.listIconLight}
          />
        </ListItemIcon>
        <ListItemText
          primary={tr("_load")}
          primaryTypographyProps={
            isDark
              ? {
                  color: "primary",
                  variant: "h6"
                }
              : {
                  color: "textPrimary",
                  variant: "h6"
                }
          }
        />
      </MenuItem>
      <MenuItem name="save" onClick={handleSaveFavourite}>
        <ListItemIcon>
          <SaveAltIcon
            className={isDark ? classes.listIcon : classes.listIconLight}
          />
        </ListItemIcon>
        <ListItemText
          primary={tr("_save")}
          primaryTypographyProps={
            isDark
              ? {
                  color: "primary",
                  variant: "h6"
                }
              : {
                  color: "textPrimary",
                  variant: "h6"
                }
          }
        />
      </MenuItem>
      {dashboard && dashboard.type && dashboard.type !== "DefaultWidget" ? (
        <MenuItem name="copy" onClick={handleCopy}>
          <ListItemIcon>
            <FileCopyIcon
              className={isDark ? classes.listIcon : classes.listIconLight}
            />
          </ListItemIcon>
          <ListItemText
            primary={tr("_copy")}
            primaryTypographyProps={
              isDark
                ? {
                    color: "primary",
                    variant: "h6"
                  }
                : {
                    color: "textPrimary",
                    variant: "h6"
                  }
            }
          />
        </MenuItem>
      ) : null}
      {dashboard && dashboard.type && dashboard.type !== "DefaultWidget" ? (
        <MenuItem name="delete" onClick={handleDelete}>
          <ListItemIcon>
            <DeleteIcon
              className={isDark ? classes.listIcon : classes.listIconLight}
            />
          </ListItemIcon>
          <ListItemText
            primary={tr("_delete")}
            primaryTypographyProps={
              isDark
                ? {
                    color: "primary",
                    variant: "h6"
                  }
                : {
                    color: "textPrimary",
                    variant: "h6"
                  }
            }
          />
        </MenuItem>
      ) : null}
    </Menu>
  )
}

const styles = theme => ({
  menuPaper: {
    backgroundColor: theme.palette.popup.background,
    width: 275
  },
  listIcon: {
    color: theme.palette.primary.dark
  },
  listIconLight: {
    color: theme.palette.text.primary
  }
})

export default withStyles(styles)(DashboardMenu)
