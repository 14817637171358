import React from "react"

import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"
import { tr } from "translations"

import MUIDataTable from "mui-datatables"

const Datahistory = props => {
  const { classes, data, closeHistory, compression, historyInfo } = props

  function timeRenderer(value) {
    let date = new Date(value)
    if (compression === "daily") {
      return date.toLocaleDateString()
    } else if (compression === "all") {
      return date.toLocaleString()
    } else {
      date.setMinutes(0)
      date.setSeconds(0)
      return date.toLocaleString()
    }
  }

  function getColumns() {
    if (compression !== "all") {
      return [
        { name: "device", label: tr("_device") },
        {
          name: "time",
          label: tr("_time"),
          options: { filter: false, customBodyRender: timeRenderer }
        },
        { name: "min", label: "Min", options: { filter: false } },
        { name: "max", label: "Max", options: { filter: false } },
        { name: "avg", label: tr("_average"), options: { filter: false } }
      ]
    } else {
      return [
        { name: "device", label: tr("_device") },
        {
          name: "time",
          label: tr("_time"),
          options: { filter: false, customBodyRender: timeRenderer }
        },
        { name: "value", label: tr("_value"), options: { filter: false } }
      ]
    }
  }

  return (
    <div className={classes.div}>
      <Button
        variant="contained"
        className={classes.button}
        onClick={closeHistory}
      >
        {tr("_backToDevicelist")}
      </Button>
      <div className={classes.info}>
        <Typography variant="h5">
          {tr("_" + historyInfo.reportType) + " " + tr("_report")}
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Typography variant="body1">
              {tr("_from")}:{" "}
              {compression === "daily"
                ? historyInfo.start.toLocaleDateString()
                : historyInfo.start.toLocaleString()}{" "}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body1">
              {tr("_to")}:{" "}
              {compression === "daily"
                ? historyInfo.end.toLocaleDateString()
                : historyInfo.end.toLocaleString()}
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="body1">
          {tr(`_${historyInfo.compression}`)}
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={1}>
            <Typography variant="h6">{tr("_devices")}:</Typography>
          </Grid>
          {historyInfo.devices.map(device => (
            <Grid item xs={1} key={device._id}>
              <Typography>{device.name}</Typography>
            </Grid>
          ))}
        </Grid>
      </div>
      <MUIDataTable
        className={classes.table}
        data={data}
        columns={getColumns()}
        options={{
          filterType: "checkbox",
          selectableRows: "none",
          viewColumns: false,
          fixedHeaderOptions: {
            xAxis: false,
            yAxis: true
          },
          elevation: 0,
          pagination: true,
          rowsPerPageOptions: [10, 15, 25, 50, 100],
          responsive: "scrollFullHeight",
          downloadOptions: {
            filename: "report_" + historyInfo.reportType + ".csv"
          },
          textLabels: {
            toolbar: {
              search: tr("_search"),
              downloadCsv: tr("_downloadCSV"),
              filterTable: tr("_filter"),
              print: tr("_print")
            },
            body: {
              noMatch: tr("_noMatches"),
              toolTip: tr("_sort")
            }
          }
        }}
      />
    </div>
  )
}

const styles = theme => ({
  div: {
    backgroundImage: theme.backgroundImage,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    height: "calc(100vh - 64px)",
    overflow: "auto"
  },
  table: {
    backgroundImage: theme.backgroundImage,
    margin: "auto",
    maxWidth: "96%",
    width: "96%",
    display: "table",
    tableLayout: "fixed"
  },
  button: {
    marginLeft: "2%",
    marginTop: "2%",
    backgroundColor: theme.palette.primary.light
  },
  info: {
    marginLeft: "2%",
    marginTop: "20px"
  },
  devices: {
    display: "inline-block"
  }
})

export default withStyles(styles, { withTheme: true })(Datahistory)
