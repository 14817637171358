import React from "react"
import { platformBlue } from "../../services/colors"
import { GroundPlanDeviceMarker } from "../SvgLib"

export const DevicePH = ({
  color,
  color2,
  innerCircle,
  x,
  y,
  scale,
  onClick,
  onMouseDown,
  image,
  id,
  selected,
  sizeModifier,
  style
}) => (
  <g
    id={id}
    onClick={onClick}
    onTouchStart={onMouseDown}
    onMouseDown={onMouseDown}
    transform={`translate(${x}, ${y}) scale(${sizeModifier *
      (1 / scale)}, ${sizeModifier * (1 / scale)})`}
  >
    <filter id="dropShadow">
      <feGaussianBlur in="SourceAlpha" stdDeviation={2} />
      <feOffset dx="2" dy="4" />
      <feMerge>
        <feMergeNode />
        <feMergeNode in="SourceGraphic" />
      </feMerge>
    </filter>
    <g
      transform={`translate(${selected ? -1.9 * scale : -1.4 * scale}, ${
        selected ? -2.8 * scale : -2.0 * scale
      }) scale(${selected ? 0.04 * scale : 0.03 * scale}, ${
        selected ? 0.04 * scale : 0.03 * scale
      })`}
      filter="url(#dropShadow)"
    >
      <GroundPlanDeviceMarker
        color={color}
        iconName={image}
        selected={selected}
        innerColor={color2}
        innerCircle={innerCircle}
        style={style}
      />
    </g>
  </g>
)
export const DevicePHAir = ({ style, color, onMouseUp, image }) => (
  <svg style={style} viewBox="0 0 24 24" pointerEvents="none">
    <g onMouseUp={onMouseUp}>
      <GroundPlanDeviceMarker
        color={color}
        iconName={image}
        selected={false}
        innerColor={color}
      />
    </g>
  </svg>
)

export const DeviceValue = ({
  x,
  y,
  scale,
  id,
  selected,
  sizeModifier,
  text,
  fillColor,
  textColor
}) => (
  <g
    id={id}
    transform={`translate(${x}, ${y}) scale(${sizeModifier *
      (1 / scale)}, ${sizeModifier * (1 / scale)})`}
  >
    <g
      transform={`translate(${selected ? -1.9 * scale : -1.4 * scale}, ${
        selected ? -2.8 * scale : -2.0 * scale
      }) scale(${selected ? 0.04 * scale : 0.03 * scale}, ${
        selected ? 0.04 * scale : 0.03 * scale
      })`}
    >
      <svg height="34" width={text.length > 10 ? "210" : "170"}>
        <rect
          rx="6"
          ry="6"
          width="100%"
          height="100%"
          fill={fillColor}
          opacity="0.9"
          stroke={textColor}
          strokeWidth="2"
        />

        <g
          style={{
            textAnchor: "middle",
            fontSize: "32px",
          }}
        >
          <text
            x={text.length > 10 ? "105" : "85"}
            y="28"
            style={{
              fill: textColor
            }}
          >
            {text}
          </text>
        </g>
      </svg>
    </g>
  </g>
)
