import axios from "axios"
import { getApiUrl } from "../constants/config"
import { parseErrorData } from "../services/errors"
import { authHeader } from "./common"
import { stateType } from "../constants/actions"

const faultApiFailed = error => ({
  type: stateType.SET_ERROR,
  error
})

const faultListRequested = () => ({
  type: stateType.FAULT_LIST_REQUESTED
})

const faultListReceived = faultList => ({
  type: stateType.FAULT_LIST_RECEIVED,
  faultList
})

const activeListReceived = faultList => ({
  type: stateType.ACTIVE_LIST_RECEIVED,
  faultList
})

const faultAddRequested = () => ({
  type: stateType.FAULT_ADD_REQUESTED
})

const faultAddReceived = fault => ({
  type: stateType.FAULT_ADD_RECEIVED,
  fault
})

export const fetchFaults = deviceId => async (dispatch, getState) => {
  try {
    dispatch(faultListRequested())
    let faultUrl = getApiUrl() + "/api/faults"

    if (deviceId) {
      faultUrl = faultUrl + "/" + deviceId
    }

    const state = getState()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    const { data } = await axios.get(faultUrl, authHeader(useToke))

    let faultList = null
    if (!data || !data.success || !data.faults) {
      throw (data && data.error) || "Failed to fetch faults"
    }

    faultList = data.faults

    return Promise.resolve(dispatch(faultListReceived(faultList)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(faultApiFailed(errorReply)))
  }
}

export const addFaults = newFault => async (dispatch, getState) => {
  try {
    dispatch(faultAddRequested())
    let faultUrl = getApiUrl() + "/api/faults"

    const state = getState()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }
    const { data } = await axios.post(faultUrl, newFault, authHeader(useToke))
    let fault = null

    if (!data || !data.success || !data.fault) {
      throw (data && data.error) || "Failed to fetch"
    }

    fault = data.fault

    return Promise.resolve(dispatch(faultAddReceived(fault)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(faultApiFailed(errorReply)))
  }
}

export const fetchActiveFaults = deviceId => async (dispatch, getState) => {
  try {
    dispatch(faultListRequested())
    let faultUrl = getApiUrl() + "/api/faults?active=true"

    if (deviceId) {
      faultUrl = faultUrl + "/" + deviceId
    }

    const state = getState()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    const { data } = await axios.get(faultUrl, authHeader(useToke))

    let faultList = null
    if (!data || !data.success || !data.faults) {
      throw (data && data.error) || "Failed to fetch faults"
    }

    faultList = data.faults

    return Promise.resolve(dispatch(activeListReceived(faultList)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(faultApiFailed(errorReply)))
  }
}

export const checkFault = checkedFault => async (dispatch, getState) => {
  try {
    dispatch(faultAddRequested())
    let faultUrl = getApiUrl() + "/api/faults"
    faultUrl += "/" + checkedFault._id

    const state = getState()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }
    const { data } = await axios.put(
      faultUrl,
      checkedFault,
      authHeader(useToke)
    )
    let fault = null

    if (!data || !data.success || !data.fault) {
      throw (data && data.error) || "Failed to fetch"
    }

    fault = data.fault
    dispatch(fetchActiveFaults())
    return Promise.resolve(dispatch(faultAddReceived(fault)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(faultApiFailed(errorReply)))
  }
}

export const wsFaultUpdate = faultData => async (dispatch, getState) => {
  const state = getState()

  try {
    const activeFaults = state.faults.activeFaultList

    // clear all active faults related to this device..
    let newActiveFaults = activeFaults.filter(
      f => f.device != faultData.data.device
    )

    // If fault is still active, add it back to the list
    if (faultData.data.active) {
      newActiveFaults.push(faultData.data)
    }

    dispatch(activeListReceived(newActiveFaults))
  } catch (err) {
    console.log("wsFault error", err)
  }
}
