import React, { useEffect, useState } from "react"

import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import withStyles from "@material-ui/core/styles/withStyles"

import DashboardMenu from "./DashboardMenu"
import loadable from "@loadable/component"
import { widgetTypes } from "./DashboardWidgets/widgetTypes"
import ErrorBoundary from "../ErrorBoundary"

const SelectedDevice = loadable(
  props => import("./DashboardWidgets/" + props.page),
  {
    cacheKey: props => props.page
  }
)

const DashboardWidget = props => {
  const { classes, square, handleMenu, data } = props

  const [page, setPage] = useState(null)

  useEffect(() => {
    if (data.type && widgetTypes.includes(data.type)) {
      setPage(null)
      setTimeout(() => {
        setPage(data.type)
      }, 200)
    }
  }, [data.type])

  const openMenu = event => {
    handleMenu(square, event)
  }
  return (
    <Paper className={classes.paperbox} elevation={2}>
      <DashboardMenu openMenu={openMenu} />
      {page && (
        <Grid container className={classes.container}>
          <ErrorBoundary onError={ () => { return null } } >
            <SelectedDevice {...data} page={page} />
          </ErrorBoundary>
        </Grid>
      )}
    </Paper>
  )
}

const styles = theme => ({
  paperbox: {
    height: "100%",
    width: "100%",
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    paddingTop: theme.spacing(0)
  },
  container: {
    width: "100%",
    height: "100%",
    marginTop: -theme.spacing(3),
    padding: theme.spacing(3),
    paddingTop: theme.spacing(0),
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0)
    }
  }
})

export default withStyles(styles)(DashboardWidget)
