import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import ButtonBase from "@material-ui/core/ButtonBase"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Popover from "@material-ui/core/Popover"
import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import Zoom from "@material-ui/core/Zoom"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import withStyles from "@material-ui/core/styles/withStyles"

import CloseIcon from "@material-ui/icons/Close"
import { tr } from "translations"
import SiteAreaSelection from "../AppBarWithLogo/SiteAreaSelection"
import { FilterArea, updateOpenArea } from "../../actions/areas"
import { FilterDevices } from "../../actions/iotDevices"
import DeviceListItem from "./DeviceListItem"

const MapDeviceList = props => {
  const { classes, open, handleClose, selected } = props

  const [site, setSite] = useState(selected)
  const [area, setArea] = useState(undefined)
  const [value, setValue] = useState(0)
  const [allDevices, setAllDevices] = useState(0)
  const [alerts, setAlerts] = useState([])
  const [unknowns, setunknowns] = useState([])
  const [actives, setActives] = useState([])

  const dispatch = useDispatch()

  const iotDevices = useSelector(state => state.iotDevices.iotDeviceFiltered)
  const faults = useSelector(state => state.faults.activeFaultList)
  const areas = useSelector(state => state.areas)
  const sites = useSelector(state => state.sites)

  useEffect(() => {
    const filteredAlerts = []
    const filteredActives = []
    const filteredunknowns = []

    const filterSelection = () => {
      iotDevices.forEach(device => {
        if (!device.area || !device.pos || !device.pos.x || !device.pos.y) {
          filteredunknowns.push(device)
        } else if (device.status === "FAULT") {
          filteredAlerts.push(device)
        } else {
          filteredActives.push(device)
        }
      })
    }
    filterSelection()
    setActives(filteredActives)
    setunknowns(filteredunknowns)
    setAlerts(filteredAlerts)
    setAllDevices(iotDevices.length)
  }, [iotDevices, faults, area])

  useEffect(() => {
    if (areas.areaOpen && areas.areaOpen._id) {
      dispatch(FilterDevices(areas.areaOpen.site, areas.areaOpen, []))
      setSite(areas.areaOpen.site)
      setArea(areas.areaOpen._id)
    } else {
      setArea(undefined)
    }
  }, [areas, dispatch])

  const handleAreaOpen = event => {
    const { value } = event.target
    dispatch(updateOpenArea(value))
    if (value === "area") {
      dispatch(FilterDevices(site, undefined, []))
    }
  }

  const handleSiteOpen = event => {
    const target = event.target
    dispatch(FilterArea(target.value, 1))
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Popover
      id={open ? "simple-popover" : undefined}
      open={open}
      anchorReference="anchorPosition"
      anchorPosition={{ top: 300, left: window.innerWidth / 2 }}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      TransitionComponent={Zoom}
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle id="customized-dialog-title" className={classes.title}>
        <Grid container item xs={12} justify="flex-end">
          <ButtonBase size="small" onClick={handleClose}>
            <CloseIcon fontSize="small" className={classes.icon} />
          </ButtonBase>
        </Grid>
        {tr("_device_list")}
        <SiteAreaSelection
          area={area}
          areas={areas}
          site={site}
          sites={sites}
          handleArea={handleAreaOpen}
          handleSite={handleSiteOpen}
          map={true}
        />
      </DialogTitle>
      <DialogContent dividers>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab
            label={`${tr("_all")} (${allDevices})`}
            className={value ? classes.allTab : classes.allTabSelected}
          />
          <Tab
            label={`${tr("_alerts")} (${alerts.length})`}
            className={
              value === 1 ? classes.alertTabSelected : classes.alertTab
            }
          />
          <Tab
            label={`${tr("_unknown")} (${unknowns.length})`}
            className={
              value === 2 ? classes.unknownTabSelected : classes.unknownTab
            }
          />
          <Tab
            label={`${tr("_active")} (${actives.length})`}
            className={
              value === 3 ? classes.activeTabSelected : classes.activeTab
            }
          />
        </Tabs>

        <Paper className={classes.content}>
          {(value === 0 || value === 1) &&
            alerts.map(device => {
              return (
                <DeviceListItem
                  key={device._id.toString()}
                  device={device}
                  type={"alert"}
                />
              )
            })}
          {(value === 0 || value === 3) &&
            actives.map(device => {
              return (
                <DeviceListItem
                  key={device._id.toString()}
                  device={device}
                  type={"active"}
                />
              )
            })}
          {(value === 0 || value === 2) &&
            unknowns.map(device => {
              return (
                <DeviceListItem
                  key={device._id.toString()}
                  device={device}
                  type={"unknown"}
                />
              )
            })}
        </Paper>
      </DialogContent>
    </Popover>
  )
}

const styles = theme => ({
  title: {
    marginTop: 0
  },
  icon: {
    color: theme.palette.text.primary
  },
  dialog: {
    backgroundColor: theme.palette.primary.dark,
    maxHeight: "calc(100vh - 400px)",
    minHeight: "450px"
  },
  allTab: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.text.primary,
    minWidth: 0
  },
  allTabSelected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    minWidth: 0
  },
  alertTab: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.error.main,
    minWidth: 0
  },
  alertTabSelected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.error.main,
    minWidth: 0
  },
  unknownTab: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.error.light,
    minWidth: 0
  },
  unknownTabSelected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.error.light,
    minWidth: 0
  },
  activeTab: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.action.main,
    minWidth: 0
  },
  activeTabSelected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.action.main,
    minWidth: 0
  },
  content: {
    backgroundColor: theme.palette.primary.dark
  }
})

export default withStyles(styles)(MapDeviceList)
