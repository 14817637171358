import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"

import AddCircle from "@material-ui/icons/AddCircle"
import * as React from "react"

const ManageHeaderComponent = ({
  addNew,
  addNewText,
  classes,
  title,
  titleIcon
}) => (
  <div className={classes.div}>
    <Grid container spacing={0} className={classes.root} justify="center">
      <Grid item xs={6}>
        <Typography variant={"h5"} className={classes.pageTitle}>
          {titleIcon}
          {title}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        {addNew ? (
          <div style={{ textAlign: "right", paddingRight: "2rem" }}>
            {/* <Typography variant={"subtitle1"} className={classes.pageTitle}>
              {addNewText}
              <IconButton onClick={addNew} style={{ marginLeft: "1rem" }}>
                <AddCircleIcon fontSize="large" color="secondary" />
              </IconButton>
            </Typography> */}
            <Button variant="outlined" onClick={addNew}>
              <Grid container direction="row" spacing={1} alignItems="center">
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    className={classes.saveButton}
                    style={{
                      textTransform: "none" // Disable capitalized text on button.
                    }}
                  >
                    {addNewText}
                  </Typography>
                </Grid>
                <Grid
                  item
                  style={{ height: "100%", marginTop: "10px" }}
                  // alignItems="center"
                >
                  <AddCircle
                    className={classes.saveButton}
                    style={{
                      marginTop: "-8px"
                    }}
                  />
                </Grid>
              </Grid>
            </Button>
          </div>
        ) : null}
      </Grid>
    </Grid>
  </div>
)

const styles = theme => ({
  pageTitle: {
    display: "inline-block",
    paddingBottom: "1rem",
    // paddingTop: "2rem",
    paddingLeft: "2rem",
    fontWeight: "bold"
  },
  root: {
    flexGrow: 1
  },
  saveButton: {
    color: theme.palette.action.main
  },
  div: {
    paddingLeft: "1rem",
    paddingRight: "1rem"
  }
})

export const ManageHeader = withStyles(styles, { withTheme: true })(
  ManageHeaderComponent
)
