import Grid from "@material-ui/core/Grid"
import makeStyles from "@material-ui/core/styles/makeStyles"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { fetchAreas } from "../actions/areas"
import { fetchIoTDevices } from "../actions/iotDevices"
import { fetchModels } from "../actions/types"
import { fetchSites } from "../actions/sites"
import { fetchActiveFaults, fetchFaults } from "../actions/faults"
import { fetchUserDashboard, ModifyUserDashboard } from "../actions/dashboard"
import { fetchFavouriteWidgets } from "../actions/favouriteWidgets"
import { webSocketWatch } from "../actions/websocket"
import DashboardWidget from "../components/Dashboard/DashboardWidget"
import DashboardMenuModal from "../components/Dashboard/DashboardMenuModal"
import DefineModal from "../components/Dashboard/DefineModal"
import DefineSettingsModal from "../components/Dashboard/DefineSettingsModal"
import CopyModal from "../components/Dashboard/CopyModal"
import LoadModal from "../components/Dashboard/LoadModal"
import { fetchUserSelf } from "../actions/users"
import { changeLanguage, changeTheme, changeAlertHistoryVisibility } from "../actions/ui"
import {
  modifyFavouriteWidget,
  deleteFavourite
} from "../actions/favouriteWidgets"
import { widgetTypes } from "../components/Dashboard/DashboardWidgets/widgetTypes"
import { fetchCustomers } from "../actions/customers"

const widgets = [
  "widgetOne",
  "widgetTwo",
  "widgetThree",
  "widgetFour",
  "widgetFive",
  "widgetSix"
]
export default function Dashboard() {
  const [loadFavOpen, setLoadFavOpen] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [defineOpen, setDefineOpen] = useState(false)
  const [defineSettingsOpen, setDefineSettingsOpen] = useState(false)
  const [copyOpen, setCopyOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [target, setTarget] = useState(null)
  const [dashboard, setDashboard] = useState(dashboards)
  const [saved, setSaved] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [widget, setWidget] = useState(null)
  const [editValues, setEdit] = useState(null)

  const dispatch = useDispatch()
  const history = useHistory()

  const classes = useStyles()

  const dashboards = useSelector(state => state.dashboard.dashboards)
  const role = useSelector(state => state.auth.role)

  const favouriteWidgets = useSelector(
    state => state.favouriteWidgets.favouriteWidgets
  )

  // TODO: update devices and faults in interval, see groundplanscreen for details

  useEffect(
    () => {
      // no dashboard view for superadmin
      if (role === "superAdmin") {
        history.push("/manage/profile")
      }
    },
    // eslint-disable-next-line
    []
  )

  useEffect(() => {
    dispatch(fetchAreas())
    dispatch(fetchIoTDevices())
    dispatch(fetchActiveFaults())
    dispatch(fetchFaults())
    dispatch(fetchModels())
    dispatch(fetchSites())
    dispatch(fetchCustomers())
    dispatch(fetchFavouriteWidgets())
    dispatch(fetchUserDashboard())
      .then(res => {
        if (res && res.dashboards) {
          setDashboard(res.dashboards)
          setLoaded(true)
        }
      })
      .catch(err => console.log(err))
    dispatch(fetchUserSelf())
      .then(res => {
        dispatch(changeLanguage(res.user.language))
        dispatch(changeTheme(res.user.currentTheme))
        dispatch(changeAlertHistoryVisibility(res.user.showAlertHistory))
      })
      .catch(err => console.log(err))
  }, [dispatch])

  useEffect(() => {
    if (saved) {
      dispatch(ModifyUserDashboard(dashboard)).catch(err => console.log(err))
      setSaved(false)
    }
  }, [saved, dispatch, dashboard])

  const handleMenu = (square, event) => {
    event.preventDefault()
    setTarget(square)
    setMenuOpen(!menuOpen)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = event => {
    event.preventDefault()
    setMenuOpen(false)
    setAnchorEl(null)
  }

  const handleDefine = () => {
    setMenuOpen(false)
    setAnchorEl(null)
    setDefineOpen(!defineOpen)
  }

  const handleEdit = editTarget => {
    setMenuOpen(false)
    setAnchorEl(null)
    if (editTarget && editTarget.type && editTarget.type !== "DefaultWidget") {
      setWidget(editTarget.type)
      setEdit(editTarget)
      setDefineSettingsOpen(true)
    }
  }

  const handleDefineSettings = () => {
    setDefineSettingsOpen(false)
  }

  const handleDelete = () => {
    handleSave({ type: "DefaultWidget" })
    setMenuOpen(false)
  }

  const handleCopy = () => {
    setMenuOpen(false)
    setAnchorEl(null)
    setCopyOpen(!copyOpen)
  }

  const handleSaveFavourite = () => {
    setMenuOpen(false)
    setAnchorEl(null)

    const widgetSettings = dashboard[target]
    dispatch(modifyFavouriteWidget(widgetSettings))
  }

  const handleOpenFavourite = () => {
    setMenuOpen(false)
    setAnchorEl(null)
    // const widgetSettings = dashboard[target]
    setLoadFavOpen(!loadFavOpen)
  }

  const handleCloseFavourite = () => {
    setMenuOpen(false)
    setAnchorEl(null)
    setLoadFavOpen(!loadFavOpen)
  }

  const handleDeleteFavourite = widget => {
    dispatch(deleteFavourite(widget))
  }

  const handleLoadFavourite = (widget, target) => {
    if (widget && target) {
      handleSave(widget)
    }
  }

  const handleWidgetSelection = widget => {
    setWidget(widget)
    setDefineOpen(false)
    setEdit(null)
    setDefineSettingsOpen(true)
  }

  const handleSave = definition => {
    dispatch(webSocketWatch(definition.devices ? [definition.devices[0]] : []))
    setDashboard({ ...dashboard, [target]: definition })
    setSaved(true)
  }

  const handleCopySave = copyTarget => {
    handleSave(dashboard[copyTarget])
  }

  return (
    <Grid container direction="row" className={classes.root}>
      {loaded &&
        widgets.map(widget => (
          <Grid
            container
            item
            xs={12}
            sm={6}
            lg={4}
            key={widget}
            className={classes.gridList}
          >
            <DashboardWidget
              square={widget}
              handleMenu={handleMenu}
              data={dashboard ? dashboard[widget] : {}}
            />
          </Grid>
        ))}
      {menuOpen && (
        <DashboardMenuModal
          isOpen={menuOpen}
          anchorEl={anchorEl}
          closeMenu={handleClose}
          handleDefine={handleDefine}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handleCopy={handleCopy}
          handleSaveFavourite={handleSaveFavourite}
          dashboard={dashboard ? dashboard[target] : {}}
          handleOpenFavourite={handleOpenFavourite}
        />
      )}

      {defineOpen && (
        <DefineModal
          isOpen={defineOpen}
          closeMenu={handleDefine}
          onSave={handleWidgetSelection}
        />
      )}
      {defineSettingsOpen && (
        <DefineSettingsModal
          isOpen={defineSettingsOpen}
          closeMenu={handleDefineSettings}
          onSave={handleSave}
          widget={widget}
          editValues={editValues}
        />
      )}
      {copyOpen && (
        <CopyModal
          isOpen={copyOpen}
          closeMenu={handleCopy}
          onSave={handleCopySave}
          target={target}
        />
      )}
      {loadFavOpen && (
        <LoadModal
          isOpen={loadFavOpen}
          closeMenu={handleCloseFavourite}
          onSave={handleLoadFavourite}
          deleteFavourite={handleDeleteFavourite}
          target={target}
          favourites={favouriteWidgets}
          widgetTypes={widgetTypes}
        />
      )}
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(3)
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1)
    },
    backgroundImage: theme.backgroundImage,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    height: "100%",
    overflow: "auto"
  },
  gridList: {
    height: "50%",
    minHeight: 360,
    padding: theme.spacing(1)
  }
}))
