import { stateType } from "../constants/actions"

export default (
  state = {
    isFetching: false,
    role: null,
    username: null,
    error: null
  },
  { type, error, role, username }
) => {
  switch (type) {
    case stateType.VALIDATION_REQUESTED:
      return { ...state, isFetching: true }

    case stateType.VALIDATION_SUCCEEDED: {
      return {
        ...state,
        role,
        username,
        isFetching: false,
        error: null
      }
    }
    case stateType.TRIAL_VALIDATION_SUCCEEDED: {
      return {
        ...state,
        isFetching: false,
        error: null
      }
    }
    case stateType.VALIDATION_FAILED:
      return {
        ...state,
        isFetching: false,
        error
      }

    default:
      return state
  }
}
