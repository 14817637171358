import React from "react"
import Grid from "@material-ui/core/Grid"
import Popover from "@material-ui/core/Popover"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"
import ClearIcon from "@material-ui/icons/Clear"
import { tr } from "translations"

const FilterModal = props => {
  const {
    anchorEl,
    open,
    handleClose,
    classes,
    options,
    labels,
    filter,
    header,
    handleChange,
    handleClear,
    sitesWithColors
  } = props

  return (
    <Popover
      transitionDuration={{ appear: 20, enter: 20, exit: 0 }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
    >
      <Paper className={classes.paper}>
        <Grid container>
          {options.map((option, index) => (
            <Grid item xs={12} key={index}>
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checkbox}
                    name={option}
                    checked={
                      filter[header] !== undefined &&
                      filter[header].includes(option)
                    }
                  />
                }
                onChange={event => handleChange(event, option)}
                label={
                  <Typography
                    style={{
                      color:
                        sitesWithColors && sitesWithColors[option]
                          ? sitesWithColors[option]
                          : "inherit"
                    }}
                  >
                    {labels ? labels[index] : option}
                  </Typography>
                }
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <hr />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<ClearIcon />}
              onClick={filter[header] === undefined ? null : handleClear}
              label={<p style={{ fontSize: "9pt" }}>{tr("_clear_filter")}</p>}
            />
          </Grid>
        </Grid>
      </Paper>
    </Popover>
  )
}

const styles = () => ({
  paper: {
    backgroundColor: "#F9FAFA",
    color: "#000000",
    padding: "10px",
    maxWidth: "200px"
  },
  checkbox: {
    color: "#000000",
    "&:checked": {
      color: "#32CD32"
    }
  }
})

export default withStyles(styles, { withTheme: true })(FilterModal)
