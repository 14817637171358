export default (
  state = {
    userList: [],
    user: null,
    isFetching: false,
    error: null,
    shouldRefresh: false
  },
  action
) => {
  switch (action.type) {
    case "USER_LIST_REQUESTED":
    case "USER_ADD_REQUESTED":
    case "USER_UPDATE_REQUESTED":
    case "USER_DELETE_REQUESTED":
    case "USER_UPGRADE_REQUESTED":
      return {
        ...state,
        isFetching: true,
        shouldRefresh: false
      }

    case "USER_LIST_RECEIVED":
      return {
        ...state,
        isFetching: false,
        shouldRefresh: false,
        userList: action.userList,
        lastUpdated: Date.now()
      }

    case "USER_SELF_RECEIVED":
      return {
        ...state,
        isFetching: false,
        shouldRefresh: false,
        user: action.user,
        lastUpdated: Date.now()
      }

    case "USER_RESET":
      return {
        ...state,
        user: null,
        userList: []
      }

    case "USER_AJAX_SUCCESS":
      return { ...state, isFetching: false }

    case "USER_AJAX_ERROR":
      return {
        ...state,
        isFetching: false,
        shouldRefresh: true,
        error: action.error
      }

    case "USER_RESET_ERROR":
      return { ...state, error: null }

    default:
      return state
  }
}
