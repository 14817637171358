import React, { useState, useEffect } from "react"

import Grid from "@material-ui/core/Grid"
import withStyles from "@material-ui/core/styles/withStyles"

import LimitsListHeader from "../../../components/LimitsList/LimitsListHeader"
import LimitsTable from "../../../components/LimitsList/LimitsTable"
import { useSelector, useDispatch } from "react-redux"
import { tr } from "translations"
import {
  fetchLimits,
  addLimits,
  modifyLimit,
  deleteLimit
} from "../../../actions/limits"

const siteColors = ["#70aa4e", "#9e70cb", "#cdb671", "#dc7768"]

const LimitsList = props => {
  const { classes } = props

  const [expanded /*setExpanded*/] = useState(false)
  const [search, setSearch] = useState("")
  // const [loaded, setLoaded] = useState(false)
  const [sitesWithColors, setSitesWithColors] = useState({})

  const siteList = useSelector(state => state.sites.siteList)
  const limitList = useSelector(state => state.limits.limits)
  //const token = useSelector(state => state.auth.token)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchLimits())
      .then(/*() => setLoaded(true)*/)
      .catch(err => console.log(err))
  }, [dispatch])

  useEffect(() => {
    const tempSitesWithColors = {}
    siteList.forEach((site, index) => {
      tempSitesWithColors[site.name] = siteColors[index % siteColors.length]
    })
    setSitesWithColors(tempSitesWithColors)
  }, [siteList])

  /*const handleExpandChange = () => {
    setExpanded(!expanded)
  }*/

  const handleSearch = value => {
    setSearch(value)
  }

  // create new limit
  const handleAddLimit = () => {
    if (siteList.length > 0) {
      const limit = {
        siteId: siteList[0]._id,
        name: "",
        minValue: 0,
        maxValue: 0,
        valueType: 0,
        limitType: "average"
      }
      // setLoaded(false)
      dispatch(addLimits(limit))
        .then(() => dispatch(fetchLimits()))
        //.then(() => setLoaded(true))
        .catch(err => console.log(err))
    }
  }

  // modify existing limit
  const handleSave = limit => {
    const lim = { ...limit }
    const devIds = []
    limit.devices.forEach(dev => {
      devIds.push(dev._id)
    })
    lim.deviceIds = devIds
    lim._id = lim.id
    lim.valueType = lim.typeId

    dispatch(modifyLimit(lim)).catch(err => console.log(err))
  }

  const handleDelete = limit => {
    const lim = { ...limit }
    lim._id = lim.id

    //setLoaded(false)
    dispatch(deleteLimit(lim))
      .then(() => dispatch(fetchLimits()))
      //.then(() => setLoaded(true))
      .catch(err => console.log(err))
  }

  return (
    <div className={classes.div}>
      <Grid container spacing={1} className={classes.grid}>
        <Grid item xs={12}>
          <LimitsListHeader
            title={`${tr("_limitsList")} (${limitList.length})`}
            addNewText={tr("_add_new")}
            handleSearch={handleSearch}
            site={siteList[0] ? siteList[0]._id : null}
            addLimit={handleAddLimit}
          />
        </Grid>

        <Grid item xs={12}>
          <LimitsTable
            sitesExpanded={expanded}
            handleSave={handleSave}
            handleDelete={handleDelete}
            search={search}
            sitesWithColors={sitesWithColors}
          />
        </Grid>
      </Grid>
    </div>
  )
}

const styles = theme => ({
  div: {
    width: "100%",
    height: "100vh",
    backgroundImage: theme.backgroundImage,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    flexGrow: 1,
    overflow: "auto"
  },
  expansionPanel: {
    backgroundColor: "#031321",
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.unselected.main,
    borderTop: "1px solid",
    borderTopColor: theme.palette.unselected.main
  },
  expansionPanelTitle: {
    display: "inline-block",
    paddingLeft: "3rem"
  }
})

export default withStyles(styles, { withTheme: true })(LimitsList)
