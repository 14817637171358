import axios from "axios"
import { tr } from "translations"
import { parseErrorData } from "../services/errors"
import { getApiUrl } from "../constants/config"
import { stateType } from "../constants/actions"
import { webSocketAuth, webSocketDrop } from "./websocket"

// import { userDeactivated, userActivated, isUserTypeAllowed} from "../native/native"

const requestFailed = error => ({
  type: stateType.SET_ERROR,
  error
})

const displayInfo = info => ({
  type: stateType.SET_INFO,
  info
})

const displayTrial = showTrial => ({
  type: stateType.TRIALTOKEN_SHOWTRIAL,
  showTrial
})

const tokenRequested = () => ({ type: "TOKEN_REQUESTED" })

const tokenSucceeded = (token, admin, role, user) => ({
  type: stateType.TOKEN_SUCCEEDED,
  token,
  admin,
  role,
  user
})

// const tokenFailed = error => ({
//   type: stateType.TOKEN_FAILED,
//   error
// })

export const resetError = () => ({
  type: stateType.AUTH_RESET_ERROR
})

const logoutAction = () => ({
  type: "LOG_OUT"
})

const tokenReset = () => ({
  type: "TOKEN_RESET"
})

const iotDeviceReset = () => ({
  type: "IOTDEVICE_RESET"
})

const dataReset = () => ({
  type: "DEVICEDATA_RESET"
})

const faultReset = () => ({
  type: "FAULT_RESET"
})

export const setRedirectUrl = redirectUrl => ({
  type: "SET_REDIRECT_URL",
  redirectUrl
})

export const fetchToken = auth => async dispatch => {
  try {
    if (auth.username === "" || auth.password === "") {
      let info = { description: "USERNAME_OR_PASSWORD_ERROR" }
      return Promise.resolve(dispatch(displayInfo(info)))
    }

    dispatch(tokenRequested())
    const authUrl = getApiUrl() + "/api/authenticate"
    const { data } = await axios.post(authUrl, auth)

    if (!data || !data.success)
      throw (data && data.error) || tr("_failed_to_log_in")

    if (!data.role) {
      throw Error(tr("_user_type_not_allowed"))
    }

    dispatch(tokenSucceeded(data.token, data.admin, data.role, data._id))
    if (data.trial) {
      dispatch(displayTrial(data.trial))
    }
    return Promise.resolve(dispatch(webSocketAuth()))
  } catch (err) {
    // const errorReply = { status: "", description: err.message }
    // console.error(errorReply)
    // dispatch(tokenFailed(errorReply))
    let errorReply = parseErrorData(err)
    errorReply.description = tr("USERNAME_OR_PASSWORD_ERROR")
    return Promise.reject(dispatch(requestFailed(errorReply)))
  }
}

export const logOut = () => async dispatch => {
  try {
    // Logout action is in top level component resetting application state.
    dispatch(webSocketDrop())
    dispatch(tokenReset())
    dispatch(iotDeviceReset())
    dispatch(dataReset())
    dispatch(faultReset())
    dispatch(logoutAction())
  } catch (err) {
    const errorReply = parseErrorData(err)
    console.error(errorReply)
  }
}

export const validateToken = () => async (dispatch, getState) => {
  try {
    const state = getState()
    const {
      auth: { token }
    } = state
    const authUrl = getApiUrl() + "/api/register/validate/"
    const { data } = await axios.post(authUrl, { token })

    if (!data || !data.success) {
      throw Error("_token_not_valid")
    }
  } catch (err) {
    dispatch(logOut())
    console.log(err.message)
  }
}
