import React, { useState } from "react"
import { useSelector } from "react-redux"

import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import Fade from "@material-ui/core/Fade"
import Grid from "@material-ui/core/Grid"
import ListItemText from "@material-ui/core/ListItemText"
import MenuItem from "@material-ui/core/MenuItem"
import Paper from "@material-ui/core/Paper"
import Select from "@material-ui/core/Select"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import useTheme from "@material-ui/core/styles/useTheme"
import withStyles from "@material-ui/core/styles/withStyles"

import { DatePicker } from "@material-ui/pickers/DatePicker"
import MuiPickersUtilsProvider from "@material-ui/pickers/MuiPickersUtilsProvider"
import DeleteIcon from "@material-ui/icons/Delete"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { tr } from "translations"
import MomentUtils from "@date-io/moment"
import moment from "moment"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import createMuiTheme from "@material-ui/core/styles/createMuiTheme"
import DefineDeviceDialog from "../../Dashboard/DashboardWidgets/settingModals/parts/settingDeviceByTypesSites"

moment.updateLocale("fi", {
  week: {
    dow: 1
  }
})

const HistorySettingsModal = props => {
  const { classes, isOpen, closeMenu, handleHistoryFetch } = props

  const [start, setStart] = useState(
    new Date(
      moment()
        .subtract(1, "days")
        .startOf("day")
    )
  )
  const [end, setEnd] = useState(new Date(moment().endOf("day")))
  const [filter, setFilter] = useState([])
  const [compression, setCompression] = useState("daily")
  const [reportType, setReportType] = useState("temperature")
  const [showDeviceSelect, setShowDeviceSelect] = useState(false)

  const isDark = useSelector(state => state.ui.currentTheme === "dark")
  const language = useSelector(state => state.ui.language)

  moment.updateLocale(language.toLowerCase(), {
    week: {
      dow: 1
    }
  })

  const theme = useTheme()

  const handleClose = () => {
    setFilter([])
    setCompression("daily")
    setReportType("temperature")
    setStart(
      new Date(
        moment()
          .subtract(1, "days")
          .startOf("day")
      )
    )
    setEnd(new Date(moment().endOf("day")))
    closeMenu()
  }

  const handleFetch = () => {
    handleHistoryFetch(start, end, filter, compression, reportType)
    handleClose()
  }

  const toggleShowDeviceSelect = () => {
    setShowDeviceSelect(!showDeviceSelect)
  }

  const saveValues = values => {
    setShowDeviceSelect(!showDeviceSelect)
    setFilter(values)
  }

  const removeFromFilter = value => {
    const tempFilter = [...filter]
    tempFilter.splice(
      tempFilter.findIndex(
        filterValue => filterValue.deviceId === value.deviceId
      ),
      1
    )
    setFilter(tempFilter)
  }
  const selections = [
    { value: "all", label: "_all" },
    { value: "daily", label: "_daily" },
    { value: "hourly", label: "_hourly" }
  ]
  const typeSelections = [
    { value: "temperature", label: "_temperature" },
    { value: "humidity", label: "_humidity" },
    { value: "pressure", label: "_pressure" },
    { value: "ambientlight", label: "_ambientlight" }
  ]

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale={"fi"}>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
      >
        <Fade in={isOpen} timeout={700}>
          {showDeviceSelect ? (
            <DefineDeviceDialog
              cancelValues={toggleShowDeviceSelect}
              saveValues={saveValues}
              deviceValues={filter}
              deviceTypes={["TreonAssetNode", "TreonNode"]}
            />
          ) : (
            <Paper className={classes.menuPaper}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    color={isDark ? "primary" : "textPrimary"}
                  >
                    {tr("_reports")}
                  </Typography>
                </Grid>
                {/** widget name */}
                <Grid item xs={12}>
                  <Select
                    value={compression}
                    onChange={e => setCompression(e.target.value)}
                    inputProps={{
                      name: "type",
                      id: "type-id"
                    }}
                    classes={{ root: classes.root, icon: classes.icon }}
                    IconComponent={ExpandMoreIcon}
                    disableUnderline
                    displayEmpty
                    MenuProps={{
                      PaperProps: {
                        style: {
                          backgroundColor: theme.palette.popup.background
                        }
                      }
                    }}
                  >
                    {selections.map(selection => {
                      return (
                        <MenuItem key={selection.value} value={selection.value}>
                          <ListItemText
                            primary={tr(selection.label)}
                            primaryTypographyProps={
                              isDark
                                ? {
                                  color: "primary"
                                }
                                : {
                                  color: "textPrimary"
                                }
                            }
                          />
                        </MenuItem>
                      )
                    })}
                  </Select>
                </Grid>

                {/* Select for measured variable (temp, humid...) */}
                <Grid item xs={12}>
                  <Select
                    value={reportType}
                    onChange={e => setReportType(e.target.value)}
                    inputProps={{
                      name: "type",
                      id: "type-id"
                    }}
                    classes={{ root: classes.root, icon: classes.icon }}
                    IconComponent={ExpandMoreIcon}
                    disableUnderline
                    displayEmpty
                    MenuProps={{
                      PaperProps: {
                        style: {
                          backgroundColor: theme.palette.popup.background
                        }
                      }
                    }}
                  >
                    {typeSelections.map(selection => {
                      return (
                        <MenuItem key={selection.value} value={selection.value}>
                          <ListItemText
                            primary={tr(selection.label)}
                            primaryTypographyProps={
                              isDark
                                ? {
                                  color: "primary"
                                }
                                : {
                                  color: "textPrimary"
                                }
                            }
                          />
                        </MenuItem>
                      )
                    })}
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    autoOk
                    disableToolbar
                    disableFuture
                    maxDate={end}
                    variant="inline"
                    format="DD/MM/YYYY"
                    margin="normal"
                    id="start"
                    label={tr("_from")}
                    value={start}
                    onChange={value =>
                      setStart(new Date(value.startOf("day")))
                    }
                    InputProps={{
                      className: classes.date
                    }}
                    InputLabelProps={{
                      className: classes.date
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    autoOk
                    disableToolbar
                    disableFuture
                    minDate={start}
                    variant="inline"
                    format="DD/MM/YYYY"
                    margin="normal"
                    id="end"
                    label={tr("_to")}
                    value={end}
                    onChange={value => setEnd(new Date(value.endOf("day")))}
                    InputProps={{
                      className: classes.date
                    }}
                    InputLabelProps={{
                      className: classes.date
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  {" "}
                  <Button
                    variant="outlined"
                    className={classes.bigbutton}
                    onClick={toggleShowDeviceSelect}
                    style={{
                      backgroundColor: theme.palette.action.main
                    }}
                  >
                    {tr("_defineDevices")}
                  </Button>
                </Grid>
                {filter.map((value, i) => {
                  return (
                    <Grid container item key={i} xs={12}>
                      <Grid item xs={9}>
                        <Typography
                          variant="body2"
                          className={classes.subTitle}
                          color={isDark ? "primary" : "textPrimary"}
                        >
                          {value.deviceId +
                            ", " +
                            value.name +
                            " - " +
                            value.type}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <IconButton
                          onClick={() => removeFromFilter(value)}
                          aria-label="delete"
                          className={classes.removeIcon}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  )
                })}
              </Grid>
              <Grid
                item
                xs={12}
                container
                className={classes.buttons}
                justify="flex-end"
              >
                <Button
                  variant="outlined"
                  className={classes.button}
                  onClick={handleClose}
                  style={{
                    backgroundColor: "gray"
                  }}
                >
                  {tr("_cancel")}
                </Button>
                <Button
                  disabled={filter.length === 0}
                  variant="contained"
                  className={classes.button}
                  style={{
                    backgroundColor: theme.palette.action.main
                  }}
                  onClick={handleFetch}
                >
                  {tr("_getData")}
                </Button>
              </Grid>
            </Paper>
          )}
        </Fade>
      </Dialog>
    </MuiPickersUtilsProvider>
  )
}

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
    width: 125,
    height: 40
  },
  buttons: {
    marginTop: 40
  },
  menuPaper: {
    backgroundColor: theme.palette.popup.background,
    maxWidth: 500,
    // minHeight: 400,
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3)
  },
  listIcon: {
    //color: theme.palette.primary.dark
  },
  root: {
    maxWidth: 600,
    minHeight: 30,
    marginTop: 10
  },
  icon: {
    color: theme.palette.popup.text
  },
  subTitle: {
    marginTop: theme.spacing(2)
  },
  innerContent: {
    margin: theme.spacing(1)
  },
  underline: {
    borderBottom: `1px solid  ${theme.palette.popup.text}`,
    fontSize: 16,
    color: theme.palette.popup.text
  },
  selectButton: {
    color: theme.palette.primary.main,
    margin: theme.spacing(1)
  },
  date: {
    color: theme.palette.popup.text
  },
  bigbutton: {
    margin: theme.spacing(1),
    width: "80%",
    height: 40
  },
  removeIcon: {
    color: theme.palette.error.main
  }
})

export default withStyles(styles)(HistorySettingsModal)
