import { useDispatch, useSelector } from "react-redux"
import React, { useEffect, useState } from "react"
import Grid from "@material-ui/core/Grid"
import withStyles from "@material-ui/core/styles/withStyles"
import useTheme from "@material-ui/core/styles/useTheme"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { tr } from "translations"
import { Chart } from "react-google-charts"
import WidgetHeader from "./parts/WidgetHeader"
import WidgetLoading from "./parts/WidgetLoading"
import moment from "moment"
import { watchlistAdd, watchlistRemove } from "../../../actions/websocket"

const LineWidget = (props) => {
  const { classes, name, values, devices } = props

  const [chartData2, setChartData2] = useState(null)

  const dispatch = useDispatch()
  const iotDeviceList = useSelector((state) => state.iotDevices.iotDeviceList)
  const limits = useSelector((state) => state.limits.limits)

  const updatedDevices = useSelector(
    (state) => state.dataUpdate.updatedDevices || []
  )
  const deviceDataStore = useSelector(
    (state) => state.dataUpdate.deviceDataStore || {}
  )

  const [devs, setDevs] = useState([])
  const [valueData, setValueData] = useState([])
  const [colorarray, setColors] = useState(["red", "blue", "green"])
  const [min, setMin] = useState()
  const [max, setMax] = useState()
  const [lastUpdate, setLastUpdate] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [widgetLimits, setWidgetLimits] = useState(null)
  const [initCompleted, setInitCompleted] = useState(false)
  const [dataType, setDataType] = useState("temperature")
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("qhd"))

  useEffect(() => {
    if (values !== undefined && values[7]) {
      setDataType(values[7]) //"temperature" humidity pressure ambientlight
    }
    if (values !== undefined && values[8]) {
      setWidgetLimits(JSON.parse(values[8]))

    }
    try {
      const originalData = devices ? JSON.parse(devices[0]) : []
      const newDevs = []
      const filteredValueData = originalData.filter((device) => {
        const dev = iotDeviceList.find(
          (iotdevice) => iotdevice._id === device._id
        )
        if (dev) {
          newDevs.push(dev)
          return true
        }
      })
      setDevs(newDevs)
      setValueData(filteredValueData)
    } catch {
      // TODO: ERROR HANDLING
    }
    // eslint-disable-next-line
  }, [values])

  useEffect(() => {
    async function fetchData(promises) {
      await Promise.all(promises)
      setInitCompleted(true)
    }
    if (valueData.length > 0 && devs.length > 0 && !initCompleted) {
      if (Object.keys(deviceDataStore).length) {
        // if we have earlier data, render chart immediatelly
        // then we have quicker chart rendering if we return from another page
        setInitCompleted(true)
      }
      // if no earlier data, wait for all data to load before rendering the chart
      // for cases coming to page for the first time, eg. after login
      const promises = []
      devs.forEach((dev) => {
        if (dev.deviceId && dev.deviceType) {
          const { getDataUpdates } = require("../../../devicetypes/" +
            dev.deviceType +
            "/actions")
          promises.push(dispatch(getDataUpdates(dev.deviceId)))
        }
      })
      fetchData(promises)
    }
    // eslint-disable-next-line
  }, [valueData, devs])

  useEffect(
    () => {
      if (initCompleted) {
        const foundDevice = devs.find((dev) =>
          updatedDevices.includes(dev.deviceId)
        )
        if (foundDevice) {
          const chartData = []
          const x = ["x"]
          const limitValues = []
          const lineController = []
          let datamass = []
          const colors = []
          valueData.forEach((vdata) => {
            vdata.values.forEach((value) => {
              if (value.selected) {
                const dev = devs.find((dev) => dev._id === vdata._id)
                if (dev && dev.deviceId) {
                  const dataSeries = deviceDataStore[dev.deviceId]
                  if (dataSeries && dataSeries.length > 0) {
                    x.push((dev.name ?? dev.deviceId) + " / " + tr("_" + dataType))
                    lineController.push([dev.deviceId, dataType])
                    datamass.push(...dataSeries)
                    colors.push(value.color)
                  }
                }
              }
            })
          })

          if(widgetLimits){
            widgetLimits.forEach((limData)=> {
              const limit = limits.find((lim) => lim._id === limData.limitId)
              limitValues.push(limit.minValue)
              limitValues.push(limit.maxValue)
              x.push(limit.name + " (min)")
              x.push(limit.name + " (max)")
              colors.push(limData.color)
              colors.push(limData.color)
            })
          }

          chartData.push(x)
          //datamass = datamass.sort((a, b) => a.ts - b.ts)
          let lowest = 0
          let highest = 100

          let dataOK = true

          if (
            datamass.length > 0 &&
            values &&
            values.length > 5 &&
            (values[5] === true || values[5] === "true")
          ) {
            lowest = datamass.reduce(
              (min, p) => (p[dataType] < min ? p[dataType] : min),
              datamass[0][dataType]
            )
            highest = datamass.reduce(
              (max, p) => (p[dataType] > max ? p[dataType] : max),
              datamass[0][dataType]
            )
            lowest = lowest < 0 ? lowest * 1.2 : lowest / 1.2
            highest = highest < 0 ? highest / 1.2 : highest * 1.2
            setMin(lowest)
            setMax(highest)
          } else {
            setMin(
              values &&
                values.length > 5 &&
                (values[5] === "false" || values[5] === false)
                ? values[2]
                : 0
            )
            setMax(
              values &&
                values.length > 5 &&
                (values[5] === "false" || values[5] === false)
                ? values[3]
                : 100
            )
          }
          // Check if time outside of limited range, do most of the manipulations outside of loop
          const limitOldData = values[9] === "true" && values[10]
          const limitNewData = values[11] === "true" && values[12]
          const oldDateTS = limitOldData 
            ? moment(Date()).subtract(parseInt(values[10]), "hours").valueOf() 
            : undefined
          const newDateTS = limitNewData 
            ? moment(Date()).subtract(parseInt(values[12]), "hours").valueOf() 
            : undefined

          // Generate data for chart
          lineController.forEach((lineSettings, lineIndex) => {
            const lineDeviceId = lineSettings[0]
            const dataSeries = deviceDataStore[lineDeviceId] || []
            dataSeries.sort((a, b) => a.ts - b.ts)
            dataSeries.forEach((entry) => {
              
              // Limit old data
              if(limitOldData && oldDateTS > entry.ts){
                return
              }
              // Limit new data
              if(limitNewData && newDateTS < entry.ts){
                return
              }

              const newLine = [null]
              lineController.forEach(() => {
                newLine.push(null)
              })
              newLine[0] = new Date(entry.ts)
              newLine[lineIndex + 1] = entry[dataType]
              dataOK = entry[dataType] !== undefined
              for(const limitVal of limitValues){
                newLine.push(limitVal)
              }

              chartData.push(newLine)
            })
          })
          setColors(colors)
          setChartData2(chartData)
          setLastUpdate(Date.now())
          if (isLoading && dataOK) {
            setIsLoading(false)
          }
          if (!dataOK) {
            setIsLoading(true)
          }
        }
      }
    },

    // eslint-disable-next-line
    [updatedDevices, initCompleted, valueData, devs]
  )

  useEffect(() => {
    const ids = []
    valueData.forEach((dev) => {
      ids.push(dev.deviceId)
    })
    dispatch(watchlistAdd(ids))
    return function cleanup() {
      dispatch(watchlistRemove(ids))
    }
    // eslint-disable-next-line
  }, [valueData])

  return values ? (
    <Grid container className={classes.container}>
      <WidgetHeader
        title={name ? name : tr("_LineWidget")}
        lastUpdate={lastUpdate}
        valueData={valueData}
        dataLength={valueData.length}
      />
      {matches && (
        <Grid container item xs={12} style={{ marginTop: theme.spacing(4) }} />
      )}
      {isLoading ? (
        <WidgetLoading />
      ) : ( 
        <Chart
          chartType="LineChart"
          data={chartData2}
          width="100%"
          height="90%"
          options={{
            chartArea: { top: 0, width: "85%", height: "85%" },
            interpolateNulls: true,
            backgroundColor: theme.palette.background.paper,
            axisTitlesPosition: "none",
            vAxis: {
              textStyle: { color: theme.palette.text.primary },
              baselineColor: theme.palette.text.primary,
              gridlines: {
                color: theme.palette.text.primary,
              },
              titleTextStyle: {
                color: theme.palette.text.working,
              },
              viewWindow: {
                max: max,
                min: min,
              },
            },
            hAxis: {
              textStyle: {
                color: theme.palette.text.primary,
                fontSize: 10,
              },
              format: "HH:mm",
              gridlines: {
                color: theme.palette.background.paper,
              },
            },
            legend: {
              position: "bottom",
              textStyle: { color: theme.palette.text.primary, fontSize: 12 },
              alignment: "end",
            },
            tooltip: {
              showColorCode: true,
            },
            colors: [...colorarray],
          }}
        />
      )}
    </Grid>
  ) : null
}

const styles = () => ({
  container: {
    height: "100%",
  },
})

export default withStyles(styles)(LineWidget)
