import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableBody from "@material-ui/core/TableBody"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import IconButton from "@material-ui/core/IconButton"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"

import { tr } from "translations"
import ProfileTextField from "./../../../../components/elements/ProfileTextField"
// import TextFieldWithCross from "./../../../../components/elements/TextFieldWithCross"
import { fetchSites, ModifySites } from "../../../../actions/sites"
import VisibilityIcon from "@material-ui/icons/Visibility"
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff"

export const useChange = (initialValue, setFormUpdated, validateNumber) => {
  const [value, setValue] = useState(initialValue)
  return {
    value,
    setValue,
    reset: () => setValue(""),
    bind: {
      value,
      onChange: event => {
        if (event.target.name === "sitePhoneNumber")
          validateNumber(event.target.value)
        setValue(event.target.value)
        setFormUpdated(true)
      }
    }
  }
}

function SiteDetails(props) {
  const validateNumber = number => {
    if (number != "" && number != undefined) {
      let rgp = /^\+[ ]?([0-9]{1,3})[- ]?([0-9]{1,3})([- ]?([0-9]{1,10}))*$/
      if (number.match(rgp)) setNumberValid(true)
      else setNumberValid(false)
    } else setNumberValid(true)
  }

  const { classes, selectedSiteId } = props
  const [formUpdated, setFormUpdated] = useState(false)
  const {
    value: siteName,
    setValue: setSiteName,
    bind: bindSiteName
  } = useChange("", setFormUpdated)
  const {
    value: siteGroup,
    setValue: setSiteGroup,
    bind: bindSiteGroup
  } = useChange("", setFormUpdated)
  const {
    value: siteStreetAddress,
    setValue: setSiteStreetAddress,
    bind: bindSiteStreetAddress
  } = useChange("", setFormUpdated)
  const {
    value: sitePostalCode,
    setValue: setSitePostalCode,
    bind: bindSitePostalCode
  } = useChange("", setFormUpdated)
  const {
    value: siteCity,
    setValue: setSiteCity,
    bind: bindSiteCity
  } = useChange("", setFormUpdated)
  const {
    value: siteCountry,
    setValue: setSiteCountry,
    bind: bindSiteCountry
  } = useChange("", setFormUpdated)
  const {
    value: siteLatitude,
    setValue: setSiteLatitude,
    bind: bindSiteLatitude
  } = useChange("", setFormUpdated)
  const {
    value: siteLongitude,
    setValue: setSiteLongitude,
    bind: bindSiteLongitude
  } = useChange("", setFormUpdated)
  const {
    value: siteContactPerson,
    setValue: setSiteContactPerson,
    bind: bindSiteContactPerson
  } = useChange("", setFormUpdated)
  const {
    value: siteEmail,
    setValue: setSiteEmail,
    bind: bindSiteEmail
  } = useChange("", setFormUpdated)
  const {
    value: sitePhoneNumber,
    setValue: setSitePhoneNumber,
    bind: bindSitePhoneNumber
  } = useChange("", setFormUpdated, validateNumber)

  const [numberValid, setNumberValid] = useState(true)

  const dispatch = useDispatch()
  const siteList = useSelector(state => state.sites.siteList)
  const site = siteList.find(site => site._id === selectedSiteId)
  const userList = useSelector(state => state.users.userList)
  const user = useSelector(state => state.users.user)

  const siteUsersArray = userList.filter(
    userToList => userToList.customer === site.customer
  )
  useEffect(() => {
    dispatch(fetchSites())
  }, [dispatch])

  useEffect(() => {
    setSiteName(site.name)
    setSiteGroup(site.siteGroup ? site.siteGroup : "")
    setSiteStreetAddress(site.address)
    setSitePostalCode(site.zip)
    setSiteCity(site.city)
    setSiteCountry(site.country)
    setSiteLatitude(site.pos ? site.pos.lat : "")
    setSiteLongitude(site.pos ? site.pos.lng : "")
    setSiteContactPerson(site.contactPerson ? site.contactPerson : "")
    setSiteEmail(site.contactEmail ? site.contactEmail : "")
    setSitePhoneNumber(site.contactPhone ? site.contactPhone : "")
  }, [
    setSiteCity,
    setSiteContactPerson,
    setSiteCountry,
    setSiteEmail,
    setSiteGroup,
    setSiteLatitude,
    setSiteLongitude,
    setSiteName,
    setSitePhoneNumber,
    setSitePostalCode,
    setSiteStreetAddress,
    site.address,
    site.city,
    site.contactEmail,
    site.contactPerson,
    site.contactPhone,
    site.country,
    site.group,
    site.name,
    site.pos,
    site.zip,
    siteList,
    site.siteGroup
  ])

  // const handleFileBrowser = () => {}

  const handleCancel = () => {
    props.onSelectView(0) // Change view to SitesAddNew
  }

  const handleSave = async () => {
    setFormUpdated(false)
    let updatedSite = {
      _id: selectedSiteId,
      name: siteName,
      siteGroup: siteGroup,
      address: siteStreetAddress,
      zip: sitePostalCode,
      city: siteCity,
      country: siteCountry,
      pos: { lat: siteLatitude, lng: siteLongitude },
      contactPerson: siteContactPerson,
      contactEmail: siteEmail,
      contactPhone: sitePhoneNumber
    }
    dispatch(ModifySites(updatedSite))
    dispatch(fetchSites())
  }

  const handleSiteVisibilityToUser = (siteId, group, userId) => {
    let newGroup = Object.assign([], group)
    const index = newGroup.indexOf(userId)
    if (index > -1) {
      newGroup.splice(index, 1)
    } else {
      newGroup.push(userId)
    }
    dispatch(
      ModifySites({
        _id: siteId,
        group: newGroup
      })
    ).then(() => {
      dispatch(fetchSites())
    })
  }

  return (
    <Grid container spacing={1} className={classes.root}>
      {/* Sites - <site name> */}
      <Grid item xs={1} />
      <Grid item xs={10}>
        <Typography className={classes.pageTitle}>
          {tr("_sites")} - {siteName}
        </Typography>
      </Grid>
      <Grid item xs={1} />
      {/* Header and cancel/save buttons */}
      <Grid item xs={1} />
      <Grid item xs={10}>
        <Grid container direction="row" justify="space-between">
          <Grid item></Grid>
          <Grid item>
            <Grid
              container
              style={{ height: "100%" }}
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={handleCancel}
                >
                  {tr("_back")}
                </Button>
              </Grid>
              {user && user.role !== "user" && (
                <Grid item>
                  <Button
                    variant="contained"
                    className={classes.saveButton}
                    disabled={!formUpdated || !numberValid}
                    onClick={handleSave}
                  >
                    {tr("_save")}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1} />
      {/* End header */}
      {/* Start form */}
      <Grid item xs={1} />
      <Grid item xs={10}>
        {/* Form container */}
        <Grid container spacing={2}>
          {/* Site name, Site group */}
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <ProfileTextField
                  id="siteName"
                  fieldName="siteName"
                  fieldLabel={tr("_site_name")}
                  {...bindSiteName}
                  // {...props}
                  uneditable={user && user.role === "user"}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <ProfileTextField
                  id="siteGroup"
                  fieldName="siteGroup"
                  fieldLabel={tr("_site_group")}
                  {...bindSiteGroup}
                  // {...props}
                  uneditable={user && user.role === "user"}
                />
              </Grid>
            </Grid>

            {/* Street address, Postal code, City, Country */}
            <Grid item xs={12}>
              <Grid container spacing={3} alignContent="space-between">
                <Grid item xs={12} md={3}>
                  <ProfileTextField
                    id="siteStreetAddress"
                    fieldName="siteStreetAddress"
                    fieldLabel={tr("_street_address")}
                    {...bindSiteStreetAddress}
                    // {...props}
                    uneditable={user && user.role === "user"}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <ProfileTextField
                    id="sitePostalCode"
                    fieldName="sitePostalCode"
                    fieldLabel={tr("_postal_code")}
                    {...bindSitePostalCode}
                    // {...props}
                    uneditable={user && user.role === "user"}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <ProfileTextField
                    id="siteCity"
                    fieldName="siteCity"
                    fieldLabel={tr("_city")}
                    {...bindSiteCity}
                    // {...props}
                    uneditable={user && user.role === "user"}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <ProfileTextField
                    id="siteCountry"
                    fieldName="siteCountry"
                    fieldLabel={tr("_country")}
                    {...bindSiteCountry}
                    // {...props}
                    uneditable={user && user.role === "user"}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Latitude, Longitude */}
            <Grid item xs={12}>
              <Grid container spacing={3} alignContent="space-between">
                <Grid item xs={12} md={3}>
                  <ProfileTextField
                    id="siteLatitude"
                    fieldName="siteLatitude"
                    fieldLabel={tr("_latitude")}
                    {...bindSiteLatitude}
                    // {...props}
                    uneditable={user && user.role === "user"}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <ProfileTextField
                    id="siteLongitude"
                    fieldName="siteLongitude"
                    fieldLabel={tr("_longitude")}
                    {...bindSiteLongitude}
                    // {...props}
                    uneditable={user && user.role === "user"}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Contact person, Email, Phone number */}
            <Grid item xs={12}>
              <Grid container spacing={3} alignContent="space-between">
                <Grid item xs={12} md={3}>
                  <ProfileTextField
                    id="siteContactPerson"
                    fieldName="siteContactPerson"
                    fieldLabel={tr("_contact_person")}
                    {...bindSiteContactPerson}
                    // {...props}
                    uneditable={user && user.role === "user"}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <ProfileTextField
                    id="siteEmail"
                    fieldName="siteEmail"
                    fieldLabel={tr("_email")}
                    {...bindSiteEmail}
                    // {...props}
                    uneditable={user && user.role === "user"}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <ProfileTextField
                    error={!numberValid}
                    helperText={
                      !numberValid
                        ? "International format (eg. +11 222 333)"
                        : null
                    }
                    id="sitePhoneNumber"
                    fieldName="sitePhoneNumber"
                    fieldLabel={tr("_phone_number")}
                    {...bindSitePhoneNumber}
                    // {...props}
                    uneditable={user && user.role === "user"}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* Logo upload subheader */}
            {/* <Grid item xs={10}>
              <Grid container alignItems="left">
                <Grid item>
                  <Typography
                    justify="left"
                    className={classes.pageTitle}
                  >
                    {tr("_upload_logo")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid> */}

            {/* "Logo upload" subheader */}
            {/* <Grid item xs={12}>
              <Grid container> */}
            {/* Logo placeholder */}
            {/* <Grid item xs={12} md={3}>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      style={{
                        width: "48px",
                        height: "48px",
                        backgroundColor: "white",
                        border: "solid 1px black",
                        marginLeft: props.theme.spacing(1)
                      }}
                    />
                    <TextFieldWithCross {...props} />
                  </Grid>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    component="label"
                    className={classes.button}
                    justify="center"
                  >
                    {tr("_browse")}
                    <Input
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleFileBrowser}
                    ></Input>
                  </Button>
                </Grid>
              </Grid>
            </Grid> */}

            {/* "Users of the site" subheader */}
            {user && user.role !== "user" && (
              <Grid item xs={12}>
                <Grid container>
                  <Grid item>
                    <Typography justify="left" className={classes.pageTitle}>
                      {tr("_users_of_the_site")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {/*  */}
            {user && user.role !== "user" && (
              <Grid item xs={12}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="body1">
                          {tr("_firstname")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {tr("_lastname")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">{tr("_role")}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {tr("_site_visibility_to_user")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {siteUsersArray.map(userToList => {
                      return (
                        <TableRow key={userToList.username}>
                          <TableCell>
                            <Typography variant="body1">
                              {userToList.firstname}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1">
                              {userToList.lastname}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1">
                              {userToList.role}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1">
                              <IconButton
                                disabled={
                                  userToList.role === "admin" ||
                                  (user && user.role === "user")
                                    ? true
                                    : false
                                }
                                onClick={() =>
                                  handleSiteVisibilityToUser(
                                    site._id,
                                    site.group,
                                    userToList._id
                                  )
                                }
                              >
                                {site.group.indexOf(userToList._id) > -1 ||
                                userToList.role === "admin" ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOffIcon />
                                    )}
                              </IconButton>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  )
}

const styles = theme => ({
  saveButton: {
    float: "right",
    backgroundColor: theme.palette.action.main,
    color: theme.palette.secondary.themed,
    "&:hover": {
      color: theme.palette.text.primary
    }
  },
  pageTitle: {
    display: "inline-block",
    paddingBottom: "3rem",
    paddingTop: "3rem",
    fontWeight: "bold"
  },
  root: {
    flexGrow: 1,
    height: "calc(100vh - 122px)",
    width: "100%",
    backgroundImage: theme.backgroundImage,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    overflow: "auto"
  }
})

export default withStyles(styles, { withTheme: true })(SiteDetails)
