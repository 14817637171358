import React, { useState, useEffect } from "react"

import Grid from "@material-ui/core/Grid"
import GridList from "@material-ui/core/GridList"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"

import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { tr } from "translations"
import AlertItem from "./AlertItem"
import WarningItem from "./WarningItem"
import FaultHistoryItem from "./FaultHistoryItem"

const AlertMenu = props => {
  const {
    menuType,
    classes,
    iotDevices,
    onDrag,
    openDevice,
    open,
    handleOpen
  } = props
  const [items, setItems] = useState(null)

  useEffect(() => {

    switch (menuType) {
      case "alert":
        setItems(iotDevices.filter(item => item.status === "FAULT"))
        break;
      case "faultLog":
        setItems(iotDevices)
        break;
      case "warning":
        setItems(iotDevices.filter(item => !item.pos || !item.pos.x || !item.pos.y)) 
        break;
      default:
        break;
    }
  }, [menuType, iotDevices])

  return (
    <>
      <Paper
        className={menuType == 'alert' ? classes.alertPaper : menuType == 'faultLog' ? classes.faultLogPaper : classes.warningPaper  }
        onClick={handleOpen(menuType)}
        square={true}
      >
        <Grid container className={classes.grid}>
          <Grid
            container
            justify="space-around"
            alignItems="center"
            item
            xs={12}
          >
            
            <Typography color="primary" variant="h5">
              {`${menuType !== 'faultLog'? (items ? items.length : 0) : ''}  ${menuType == 'alert' ? tr("_alerts") : menuType == 'faultLog' ?  tr("_faultlogs") : tr("_unknown")}`}</Typography>

            {open ? (
              <ExpandLessIcon color="primary" fontSize="large" />
            ) : (
              <ExpandMoreIcon color="primary" fontSize="large" />
            )}
          </Grid>
        </Grid>
      </Paper>
      {open && (
        <Grid container item xs={12}>
          <GridList
            cols={1}
            spacing={0}
            cellHeight={50}
            className={classes.gridList}
          >
            {items && items.map(item => {
              switch (menuType) {
                case "alert":
                  return (
                    <AlertItem
                      item={item}
                      key={item._id.toString()}
                      openDevice={openDevice}
                    />
                  )
                case "faultLog":
                  return (
                    <FaultHistoryItem
                      item={item}
                      key={item._id.toString()}
                      openDevice={openDevice}
                    />
                  )
                case "warning":
                  return (
                    <WarningItem
                      item={item}
                      onDrag={onDrag}
                      key={item._id.toString()}
                    />
                  )
                default:
                  break;
              }


            })}
          </GridList>
        </Grid>
      )}
    </>
  )
}

const styles = theme => ({
  alertPaper: {
    backgroundColor: theme.palette.error.dark,
    width: 220,
    height: 55
  },
  faultLogPaper: {
    backgroundColor: theme.palette.error.dark,
    width: 220,
    height: 55
  },
  warningPaper: {
    backgroundColor: theme.palette.error.light,
    width: 220,
    height: 55
  },
  grid: {
    flexGrow: 1,
    height: "100%"
  },
  gridList: {
    maxHeight: "calc(100vh - 230px)"
  }
})

export default withStyles(styles, { withTheme: true })(AlertMenu)
