import React from "react"
import { useSelector } from "react-redux"
import Radio from "@material-ui/core/Radio"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"

import { tr } from "translations"

const getName = value => {
  switch (value) {
    case "widgetOne":
      return "1"
    case "widgetTwo":
      return "2"
    case "widgetThree":
      return "3"
    case "widgetFour":
      return "4"
    case "widgetFive":
      return "5"
    case "widgetSix":
      return "6"
  }
}

const RadioButton = props => {
  const { classes, value, target, selection, handleChange } = props

  const isDark = useSelector(state => state.ui.currentTheme === "dark")

  return (
    <Grid
      item
      xs={12}
      id={value}
      container
      alignItems="center"
      onClick={target === value ? null : handleChange}
    >
      <Radio
        checked={selection === value}
        disabled={target === value}
        onChange={handleChange}
        value={value}
        name={`radio-box-${value}`}
        inputProps={{ "aria-label": value }}
        color="default"
        classes={{
          root: classes.root,
          checked: classes.checked
        }}
      />
      <Typography
        id={value}
        color={isDark ? "primary" : "textPrimary"}
        variant="body1"
        classes={{
          colorPrimary: target === value ? classes.disabled : null
        }}
      >{`${tr("_dashboard_widget")} ${getName(value)}`}</Typography>
    </Grid>
  )
}

const styles = theme => ({
  root: {
    color: theme.palette.popup.text,
    "&$checked": {
      color: theme.palette.popup.text
    }
  },
  checked: {},
  disabled: {
    color: theme.palette.action.disabled
  }
})

export default withStyles(styles)(RadioButton)
