import React, { useEffect, useState } from "react"

import Grid from "@material-ui/core/Grid"
import Input from "@material-ui/core/Input"
import Button from "@material-ui/core/Button"
import { tr } from "translations"
import TextFieldWithCross from "./TextFieldWithCross"

import withStyles from "@material-ui/core/styles/withStyles"

function ImageInput(props) {
  const { defaultImage, onFileChanged, classes, previewImgStyle, onReset } = props
  const [tempLogo, setTempLogo] = useState(null)
  const [logoFile, setLogoFile] = useState(null)

  useEffect(()=>{
    setTempLogo(null)
    setLogoFile(null)
  },[props.defaultImage, setLogoFile, setTempLogo])

  return (
    <Grid container direction="row" alignItems="center" spacing={1}>
      <Grid
        item
        className={classes.previewImg}
        style={{...previewImgStyle, backgroundImage: tempLogo ? `url(${tempLogo})` : defaultImage ? `url(${defaultImage})` : ""}}
      >
      </Grid>
      <Grid item>
        <TextFieldWithCross 
          value={logoFile ? logoFile.name : defaultImage ? defaultImage.split("/").slice(-1)[0] : ""} 
          onCrossClick={handleCrossClick} 
          disabled={true} 
        />    
      </Grid>      
      <Grid item>
        <Button
          variant="contained"
          component="label"
          style={styles.button}
          justify="center"
        >
          {tr("_browse")}
          <Input
            type="file"
            accept=".jpg,.png,.jpeg,.bmp"
            style={{ display: "none" }}
            onChange={handleFileBrowser}
          ></Input>
        </Button>
      </Grid>
    </Grid>
              
  )

  function handleCrossClick(){
    clearLogoField()
    onReset()
  }

  function clearLogoField(){
    setLogoFile(null)
    setTempLogo(null)
    onFileChanged(null)
  }

  function handleFileBrowser(e){
    if(e.target.files.length !== 1){
      clearLogoField()
      return
    }
    const logoFile = e.target.files[0]
    const reader = new window.FileReader()
    reader.onload = () =>{
      setLogoFile(logoFile)
      setTempLogo(reader.result)
      onFileChanged(logoFile)
    }
    reader.readAsDataURL(logoFile)
  }
}

const styles = theme => ({
  button: {
    backgroundColor: theme.palette.button.default,
    color: theme.palette.button.caption
  },
  previewImg:{
    width: "48px",
    height: "48px",
    backgroundColor: "white",
    border: "solid 1px black",
    marginLeft: theme.spacing(1),
    backgroundSize: "cover",
    backgroundPosition: "center"
  }
})

export default withStyles(styles, { withTheme: true })(ImageInput)
