import React, { Component } from "react"
import CircularProgress from "@material-ui/core/CircularProgress"
import withTheme from "@material-ui/core/styles/withTheme"

class Loading extends Component {
  render() {
    return (
      <div style={{ color: this.props.theme.palette.primary.main }}>
        <h1>Loading {<CircularProgress color="primary" thickness={7} />}</h1>
      </div>
    )
  }
}

export default withTheme(Loading)
