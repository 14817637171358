import axios from "axios"
import { getApiUrl } from "../constants/config"
import { parseErrorData } from "../services/errors"
import { authHeader } from "./common"
import { stateType } from "../constants/actions"

const favouriteWidgetRequestFailed = error => ({
  type: stateType.SET_ERROR,
  error
})

const favouriteWidgetListRequested = () => ({
  type: stateType.FAVOURITEWIDGET_LIST_REQUESTED
})

const favouriteWidgetListReceived = favouriteWidgetList => ({
  type: stateType.FAVOURITEWIDGET_LIST_RECEIVED,
  favouriteWidgetList
})

const favouriteWidgetAddReceived = widget => ({
  type: stateType.FAVOURITEWIDGET_ADD_RECEIVED,
  widget
})

const displayInfo = info => ({
  type: stateType.SET_INFO,
  info
})

export const fetchFavouriteWidgets = () => async (dispatch, getState) => {
  try {
    dispatch(favouriteWidgetListRequested())
    let theUrl = getApiUrl() + "/api/favouriteWidgets"

    const state = getState()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    const { data } = await axios.get(theUrl, authHeader(useToke))

    let widgetList = null
    if (!data || !data.success || !data.favourites) {
      throw (data && data.error) || "Failed to fetch faults"
    }

    widgetList = data.favourites

    return Promise.resolve(dispatch(favouriteWidgetListReceived(widgetList)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(favouriteWidgetRequestFailed(errorReply)))
  }
}

export const modifyFavouriteWidget = newWidget => async (
  dispatch,
  getState
) => {
  try {
    dispatch(favouriteWidgetListRequested())
    let theUrl = getApiUrl() + "/api/favouriteWidgets"

    const state = getState()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    const { data } = await axios.post(theUrl, newWidget, authHeader(useToke))
    let widget = null

    if (!data || !data.success || !data.fav) {
      throw (data && data.error) || "Failed to Save"
    }

    widget = data.fav

    let info = { description: "_favourite_saved" }
    dispatch(displayInfo(info))

    return Promise.resolve(dispatch(favouriteWidgetAddReceived(widget)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(favouriteWidgetRequestFailed(errorReply)))
  }
}

export const deleteFavourite = widget => async (dispatch, getState) => {
  try {
    let theUrl = getApiUrl() + "/api/favouriteWidgets/"

    const state = getState()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    const { data } = await axios.delete(
      theUrl + widget._id,
      widget,
      authHeader(useToke)
    )

    if (!data || !data.success) {
      throw (data && data.error) || "Failed to Delete"
    }

    let info = { description: "_favourite_deleted" }
    dispatch(displayInfo(info))

    return Promise.resolve(dispatch(fetchFavouriteWidgets()))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(favouriteWidgetRequestFailed(errorReply)))
  }
}
