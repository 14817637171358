import { tr } from "translations"

export const error = {
  MISSING_DATA_TWO_FACTOR_REPLY: { type: 1, userError: "0x9001", code: 1 },
  TWO_FACTOR_REPLY_BROKEN: { type: 1, userError: "0x9001", code: 2 },
  AUTH_REPLY_BROKEN: { type: 1, userError: "0x9001", code: 3 },
  PIN_QUERY_REPLY_BROKEN: { type: 1, userError: "0x9001", code: 4 }
}

export const parseErrorMsg = err => {
  if (err && err.response && err.response.status === 503) {
    return tr("HTTP_503")
  }
  if (err && err.type) {
    return tr(err.userError) + " " + tr("code") + " : " + err.code
  }

  if (err && err.response && err.response.data)
    return tr(err.response.data.id, err.response.data.description)

  if (err && err.message) return tr(err.message)
}

export const parseErrorData = err => {
  if (!err) {
    return
  }

  let status = 400
  if (err.response && err.response.status) {
    status = err.response.status
  }

  if (err.response && err.response.data) {
    return {
      status: status,
      description: tr(err.response.data.error, err.response.data.description)
    }
  }

  if (err && err.type) {
    console.log("DEBUG err.type", err.type)
    return {
      status: status,
      description: tr(err.userError) + " " + tr("code") + " : " + err.code
    }
  }

  if (err && err.message) {
    console.log("DEBUG err.message", err.message)
    return {
      status: status,
      description: err.message
    }
  }
}
