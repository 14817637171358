import axios from "axios"
import { parseErrorData } from "../services/errors"
import { stateType } from "../constants/actions"
// import { userDeactivated, userActivated, isUserTypeAllowed} from "../native/native"
import { getApiUrl } from "../constants/config"
import { getLanguage } from "../translations"

const validationRequested = () => ({ type: stateType.VALIDATION_REQUESTED })

const validationSucceeded = (role, username) => ({
  type: stateType.VALIDATION_SUCCEEDED,
  role,
  username
})

const trialValidationSucceeded = () => ({
  type: stateType.TRIAL_VALIDATION_SUCCEEDED
})

const validationFailed = error => ({
  type: stateType.VALIDATION_FAILED,
  error
})

export const validate = token => async dispatch => {
  try {
    dispatch(validationRequested())
    const regUrl = getApiUrl()
    const response = await axios.post(regUrl + "/api/register/validate", {
      token
    })

    const { data } = response

    if (!data || !data.success) {
      const errorMsg = parseErrorData(data.error)
      dispatch(validationFailed(errorMsg))
      return
    }

    const {
      token: { role, username }
    } = data

    dispatch(validationSucceeded(role, username))
  } catch (err) {
    const errorMsg = parseErrorData(err)
    dispatch(validationFailed(errorMsg))
  }
}

export const validateTrialcode = token => async dispatch => {
  try {
    dispatch(validationRequested())
    const regUrl = getApiUrl()
    const response = await axios.post(regUrl + "/api/trialToken/check", {
      trialToken: token
    })
    const { data } = response

    if (!data || !data.success) {
      const errorMsg = parseErrorData(data.error)
      dispatch(validationFailed(errorMsg))
      throw (data && data.error) || "Code is not valid"
    }
    return Promise.resolve(dispatch(trialValidationSucceeded()))
  } catch (err) {
    const errorMsg = parseErrorData(err)
    return Promise.reject(dispatch(validationFailed(errorMsg)))
  }
}

export const registerTrial = (token, email) => async dispatch => {
  try {
    dispatch(validationRequested())
    const regUrl = getApiUrl()
    const language = getLanguage()
    const response = await axios.post(regUrl + "/api/registertrial", {
      token,
      email,
      language
    })

    const { data } = response
    if (!data || !data.success) {
      const errorMsg = parseErrorData(data.error)
      dispatch(validationFailed(errorMsg))
      throw (data && data.error) || "Code is not valid"
    }

    return Promise.resolve(dispatch(trialValidationSucceeded()))
  } catch (err) {
    const errorMsg = parseErrorData(err)
    return Promise.reject(dispatch(validationFailed(errorMsg)))
  }
}

export const changeTrialEmail = (token, email) => async dispatch => {
  try {
    dispatch(validationRequested())
    const regUrl = getApiUrl()
    const language = getLanguage()
    const response = await axios.post(regUrl + "/api/changeTrialEmail", {
      token,
      email,
      language
    })

    const { data } = response
    if (!data || !data.success) {
      const errorMsg = parseErrorData(data.error)
      dispatch(validationFailed(errorMsg))
      throw (data && data.error) || "Code is not valid"
    }

    return Promise.resolve(dispatch(trialValidationSucceeded()))
  } catch (err) {
    const errorMsg = parseErrorData(err)
    return Promise.reject(dispatch(validationFailed(errorMsg)))
  }
}

// check user used in trial account creation. Valid Trial token is required for successful check... for safety measure.
export const checkUser = (username, trialToken) => async dispatch => {
  try {
    dispatch(validationRequested())
    const regUrl = getApiUrl()
    const response = await axios.post(regUrl + "/api/checkuser", {
      username: username,
      trialToken: trialToken
    })
    const { data } = response

    if (!data || !data.success) {
      const errorMsg = parseErrorData(data.error)
      dispatch(validationFailed(errorMsg))
      throw (data && data.error) || "Code is not valid"
    }
    return Promise.resolve(dispatch(trialValidationSucceeded()))
  } catch (err) {
    const errorMsg = parseErrorData(err)
    return Promise.reject(dispatch(validationFailed(errorMsg)))
  }
}

export const trialActivate = (token, user, org, site) => async dispatch => {
  try {
    dispatch(validationRequested)
    const regUrl = getApiUrl()
    const response = await axios.post(regUrl + "/api/activateTrial", {
      token,
      user,
      org,
      site
    })
    const { data } = response

    if (!data || !data.success) {
      const errorMsg = parseErrorData(data.error)
      dispatch(validationFailed(errorMsg))
      throw (data && data.error) || "Code is not valid"
    }

    return Promise.resolve(dispatch(trialValidationSucceeded()))
  } catch (err) {
    const errorMsg = parseErrorData(err)
    return Promise.reject(dispatch(validationFailed(errorMsg)))
  }
}
