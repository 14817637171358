import TextField from "@material-ui/core/TextField"
import CircularProgress from "@material-ui/core/CircularProgress"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"
import SearchIcon from "@material-ui/icons/Search"
import React, { useState, useEffect } from "react"
import { tr } from "translations"
import AddDeviceModal from "../modals/AddDevice"
import AddCircle from "@material-ui/icons/AddCircle"

const DeviceListHeader = props => {
  const {
    addNewText,
    classes,
    title,
    titleIcon,
    handleSearch,
    site,
    openHistory,
    historyLoading,
    noHistoryData
  } = props

  const [search, setSearch] = useState("")
  const [timer, setTimer] = useState(null)
  const [addDeviceOpen, setAddDeviceOpen] = useState(false)
  const changeSearch = event => {
    clearTimeout(timer)
    setSearch(event.target.value)
  }

  useEffect(() => {
    let timeout = setTimeout(() => handleSearch(search), 150)
    setTimer(timeout)
  }, [search, handleSearch])

  const handleAddDevice = () => {
    setAddDeviceOpen(false)
  }

  return (
    <div>
      <Grid
        container
        spacing={1}
        className={classes.root}
        alignItems="center"
        justify="center"
      >
        <Grid item xs={12} md={8}>
          <Typography variant={"h5"} className={classes.pageTitle}>
            {titleIcon}
            {title}
          </Typography>
          {!historyLoading ? (
            <Button
              variant="contained"
              className={classes.historyButton}
              onClick={openHistory}
            >
              {tr("_reports")}
            </Button>
          ) : (
            <CircularProgress
              style={{ marginLeft: "60px" }}
              size={25}
              color="secondary"
            />
          )}
          {noHistoryData && (
            <Typography variant="h6" className={classes.error}>
              {tr("_noMatches")}
            </Typography>
          )}
        </Grid>
        <Grid item xs={6} md={2}>
          <Grid
            container
            alignItems="flex-end"
            className={classes.saveButtonBox}
          >
            <Grid item xs={2}>
              <SearchIcon style={{ marginBottom: "10px" }} />
            </Grid>
            <Grid item xs={10}>
              <TextField
                id="search"
                label={tr("_search")}
                InputProps={{ disableUnderline: false }}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot
                  }
                }}
                style={{ background: "transparent", marginBottom: "10px" }}
                onChange={changeSearch}
                value={search}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} md={2}>
          {/* <Button
            className={classes.button}
            endIcon={<AddCircleIcon fontSize="large" />}
            variant="outlined"
            onClick={() => setAddDeviceOpen(true)}
          >
            <Typography variant={"h6"}>{addNewText}</Typography>
          </Button> */}
          <Button
            variant="outlined"
            onClick={() => setAddDeviceOpen(true)}
            className={classes.saveButtonBox}
          >
            <Grid
              container
              direction="row"
              spacing={1}
              alignItems="center"
              justify="center"
            >
              <Grid item>
                <Typography
                  variant="subtitle2"
                  className={classes.saveButton}
                  style={{
                    textTransform: "none" // Disable capitalized text on button.
                  }}
                >
                  {addNewText}
                </Typography>
              </Grid>
              <Grid
                item
                style={{ height: "100%", marginTop: "10px" }}
                // alignItems="center"
              >
                <AddCircle
                  className={classes.saveButton}
                  style={{
                    marginTop: "-8px"
                  }}
                />
              </Grid>
            </Grid>
          </Button>
        </Grid>
      </Grid>
      <AddDeviceModal
        fullScreen={false}
        onClose={() => handleAddDevice()}
        isOpen={addDeviceOpen}
        site={site}
      />
    </div>
  )
}

const styles = theme => ({
  pageTitle: {
    display: "inline-block",
    paddingBottom: "1rem",
    // paddingTop: "2rem",
    paddingLeft: "2rem",
    fontWeight: "bold"
  },
  error: {
    display: "inline-block",
    paddingBottom: "1rem",
    // paddingTop: "2rem",
    paddingLeft: "2rem",
    fontWeight: "bold",
    color: theme.palette.text.error
  },
  root: {
    flexGrow: 1,
    alignItems: "center",
    paddingTop: "2rem"
  },
  historyButton: {
    backgroundColor: theme.palette.primary.light,
    marginLeft: "20px"
  },
  searchInput: {
    // paddingTop: "-1rem",
    // font: "bold",
    color: theme.palette.action.main
  },
  labelRoot: {
    // fontSize: 25,
    color: theme.palette.action.main
  },
  saveButtonBox: {
    color: theme.palette.action.main,
    backgroundColor: theme.palette.primary.main,
    border: "solid 2px",
    borderRadius: "5px",
    borderColor: theme.palette.action.main,
    fontWeight: "bold"
  },
  saveButton: {
    color: theme.palette.action.main,
    fontWeight: "bold"
  }
})

export default withStyles(styles, { withTheme: true })(DeviceListHeader)
