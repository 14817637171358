import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import Zoom from "@material-ui/core/Zoom"
import Popover from "@material-ui/core/Popover"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { fetchModels } from "../../actions/types"
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord"
import { tr } from "translations"
import DeviceWidget from "./DeviceWidget"
import LineWidget from "../Dashboard/DashboardWidgets/LineWidget"


const DeviceDetail = props => {
  const { details, anchorEl, onClose, isOpen, handleEdit} = props

  const [fault, setFault] = useState(null)
  const [graphOpen, setGraphOpen] = useState(false)
  const [values, setValues] = useState(null)

  const dispatch = useDispatch()
  const classes = useStyles()
  const faults = useSelector(state => state.faults)

  useEffect(() => {
    dispatch(fetchModels())
  }, [dispatch])

  useEffect(()=> {
    const vals = [ 
      "WidgetBasicLineGraph", 
      "", 
      "20", 
      "10", 
      "50", 
      "true", 
      "", 
      details.mainValue ? details.mainValue : "temperature", 
      "[]", 
      "true", 
      "24", 
      "false", 
      "0"
    ]
    setValues(vals)
  },[details.mainValue])

  useEffect(() => {
    if (details && details.deviceId) {
      setFault(
        faults.activeFaultList.filter(active => {
          return active.device === details.deviceId
        })
      )
    }
  }, [faults, details])
  details.values = [{color: "#D391A3", selected: true}]
  let widgetDevices = [JSON.stringify([details])]
  return (
    <Popover
      id={open ? "simple-popover" : undefined}
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
      TransitionComponent={Zoom}
    >
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid container item xs={11}>
            <Grid
              container
              item
              xs={12}
              justify="space-between"
              className={classes.header}
            >
              <Typography
                variant="h6"
                className={
                  details.status === "DISABLED"
                    ? classes.colorDisabled
                    : fault && fault.length > 0
                      ? classes.colorError
                      : classes.colorConnected
                }
              >
                
                {details.status === "DISABLED" 
                  ? tr("_disabled")
                  : fault && fault.length > 0
                    ? fault[0].description
                    : tr("_connected")}
              </Typography>
              <FiberManualRecordIcon
                className={
                  details.status === "DISABLED"
                    ? classes.colorDisabled
                    : fault && fault.length > 0
                      ? classes.colorError
                      : classes.colorConnected
                }
                fontSize="small"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                {details && details.name ? details.name : tr("_unknown")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                size="small"
                className={classes.editButton}
                onClick={handleEdit}
              >
                {tr("_edit_device_info")}
              </Button>
              
            </Grid>
            <Grid item xs={12}>
              <em>
                <Typography>
                  {details && details.deviceId
                    ? details.deviceId
                    : tr("_unknown")}
                </Typography>
              </em>
            </Grid>
            
            <Grid item xs={12}>
              <Divider classes={{ root: classes.divider }} />
            </Grid>
            
            <Grid item container xs={12}>
              {DeviceWidget ? <DeviceWidget device={details} /> : null}
            </Grid>
            <Button
              size="small"
              className={classes.editButton}
              onClick={() => {setGraphOpen(!graphOpen)}}
            >
              {graphOpen ? tr("_hide_graph") : tr("_show_graph")}
            </Button>
            {graphOpen && <Grid item container xs={12}>
              <LineWidget
                values={values}
                devices={widgetDevices}
              />
            </Grid>}
          </Grid>
        </Grid>
      </Paper>
    </Popover>
  )
}

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    minWidth: 425,
    maxWidth: 490,
    margin: "auto"
  },
  paper: {
    width: 425,
    height: 400,
    padding: 15,
    boxShadow: "none"
  },
  divider: {
    backgroundColor: theme.palette.text.primary,
    marginTop: 10,
    marginBottom: 30,
    height: 2
  },
  header: {
    marginTop: 5,
    marginBottom: 15
  },
  colorError: {
    color: theme.palette.error.main
  },
  colorConnected: {
    color: theme.palette.action.main
  },
  colorDisabled: {
    color: theme.palette.action.disabled
  },
  colorMain: {
    color: theme.palette.text.primary
  },
  cardBottom: {
    marginLeft: 10
  },
  editButton: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.light,
    marginBottom: "1em"
  }
}))

export default DeviceDetail
