import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import withStyles from "@material-ui/core/styles/withStyles"
import Tooltip from "@material-ui/core/Tooltip"
import IconButton from "@material-ui/core/IconButton"
import Grid from "@material-ui/core/Grid"
import useTheme from "@material-ui/core/styles/useTheme"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import LanguageIcon from "@material-ui/icons/Language"
import MapIcon from "@material-ui/icons/Map"
import AccountCircleIcon from "@material-ui/icons/AccountCircle"
import TrackChangesIcon from "@material-ui/icons/TrackChanges"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import ListAltIcon from "@material-ui/icons/ListAlt"
import { tr } from "../../translations"
import { Logout } from "../SvgLib"
import NavigationMenu from "./NavigationMenu"
import { platformTextPrimary, platformYellow } from "../../services/colors"

const MainAppBar = props => {
  const { classes, history } = props

  const [anchorElAdd, setAnchorElAdd] = useState(null)
  const [mainMenuOpen, setMainMenuOpen] = useState(false)

  const role = useSelector(state => state.auth.role)
  const theme = useTheme()

  const matches = useMediaQuery(theme.breakpoints.down("sm"))

  let iconSize = 36
  if (matches) {
    iconSize = 25
  }

  const handleMainMenu = event => {
    setMainMenuOpen(!mainMenuOpen)
    if (event) {
      setAnchorElAdd(event.currentTarget)
    }
  }

  return (
    <Grid container direction="column" justify="space-between">
      {matches ? (
        <Grid container item justify="flex-end" spacing={0} xs={12}>
          <IconButton aria-haspopup="true" onClick={handleMainMenu}>
            <MoreVertIcon
              className={
                mainMenuOpen ? classes.iconTopBarSelected : classes.iconTopBar
              }
              style={{ fontSize: iconSize }}
            />
          </IconButton>
        </Grid>
      ) : (
        <Grid container item justify="flex-end" spacing={0} xs={12}>
          <Link
            replace={history.location.pathname === "/dashboard"}
            to="/dashboard"
          >
            <Tooltip title={tr("_dashboard")}>
              <IconButton className={classes.iconButton}>
                <TrackChangesIcon
                  className={
                    history.location.pathname === "/dashboard"
                      ? classes.iconTopBarSelected
                      : classes.iconTopBar
                  }
                  style={{ fontSize: iconSize }}
                />
              </IconButton>
            </Tooltip>
          </Link>
          <Link replace={history.location.pathname === "/map"} to="/map">
            <Tooltip title={tr("_map")}>
              <IconButton className={classes.iconButton}>
                <LanguageIcon
                  className={
                    history.location.pathname === "/map"
                      ? classes.iconTopBarSelected
                      : classes.iconTopBar
                  }
                  style={{ fontSize: iconSize }}
                />
              </IconButton>
            </Tooltip>
          </Link>
          <Link to="/groundplan">
            <Tooltip title={tr("_groundplan")}>
              <IconButton className={classes.iconButton}>
                <MapIcon
                  className={
                    history.location.pathname.includes("/groundplan")
                      ? classes.iconTopBarSelected
                      : classes.iconTopBar
                  }
                  style={{ fontSize: iconSize }}
                />
              </IconButton>
            </Tooltip>
          </Link>
          <Link to="/devicelist">
            <Tooltip title={tr("_deviceList")}>
              <IconButton className={classes.iconButton}>
                <ListAltIcon
                  className={
                    history.location.pathname.includes("/devicelist")
                      ? classes.iconTopBarSelected
                      : classes.iconTopBar
                  }
                  style={{ fontSize: iconSize }}
                />
              </IconButton>
            </Tooltip>
          </Link>
          <Link
            replace={history.location.pathname === "/manage/profile"}
            to="/manage/profile"
          >
            <Tooltip title={tr("_profile")}>
              <IconButton className={classes.iconButton}>
                <AccountCircleIcon
                  className={
                    history.location.pathname.startsWith("/manage/")
                      ? classes.iconTopBarSelected
                      : classes.iconTopBar
                  }
                  style={{ fontSize: iconSize }}
                />
              </IconButton>
            </Tooltip>
          </Link>
          <Link replace={history.location.pathname === "/logout"} to="/logout">
            <Tooltip title={tr("_logout")}>
              <IconButton className={classes.iconButton}>
                <Logout
                  color={theme.palette.secondary.light}
                  style={{ height: iconSize, width: iconSize }}
                />
              </IconButton>
            </Tooltip>
          </Link>
        </Grid>
      )}
      {mainMenuOpen && (
        <NavigationMenu
          role={role}
          anchorElAdd={anchorElAdd}
          mainMenuOpen={mainMenuOpen}
          closeMenu={handleMainMenu}
          history={history}
        />
      )}
    </Grid>
  )
}

const styles = theme => ({
  iconButton: {
    marginLeft: 15,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0
    }
  },
  iconTopBar: {
    color: platformTextPrimary
  },
  iconTopBarSelected: {
    color: platformYellow
  }
})

export default withStyles(styles)(MainAppBar)
