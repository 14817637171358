import React, { useEffect, useState } from "react"

import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormLabel from "@material-ui/core/FormLabel"
import Tooltip from "@material-ui/core/Tooltip"
import Checkbox from "@material-ui/core/Checkbox"
import TextField from '@material-ui/core/TextField'

import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"
import createStyles from "@material-ui/core/styles/createStyles"

import { useDispatch, useSelector } from "react-redux"
import { tr } from "translations"
import { fetchUserSelf, saveUser } from "../../../actions/users"
import { changeLanguage, changeTheme, changeAlertHistoryVisibility } from "../../../actions/ui"
import { fetchSites } from "../../../actions/sites"
import RoomIcon from "@material-ui/icons/Room"
import {views} from "../../../services/views"
import NotificationsActive from "@material-ui/icons/NotificationsActive"
import NotificationsOff from "@material-ui/icons/NotificationsOff"
import ProfileTextField from "./../../../components/elements/ProfileTextField"
import { ManageHeader } from "../../../components/manageMenu/manageHeader"
import CustomRadio from "../../../components/elements/CustomRadio"
import { Switch } from "@material-ui/core"


const initialUser = {
  email: "",
  firstname: "",
  lastname: "",
  phone: "",
  role: "",
  username: "",
  language: "EN",
  currentTheme: "dark",
  rotateViews: "false",
  temperatureDecimalPlaceSetting: 2,
  alerts: {
    instantEmail: false,
    instantSms: false,
    instantPush: false,
    delayedEmail: false,
    delayedSms: false,
    delayedPush: false
  },
  showAlertHistory: false,
}
const MyProfile = props => {
  const { classes } = props

  const [user, setUser] = useState(initialUser)
  const [formUpdated, setFormUpdated] = useState(false)

  const [numberValid, setNumberValid] = useState(false)

  const [temperatureDecimal, setTemperatureDecimal] = useState(true)
  const [intervalDecimal, setIntervalDecimal] = useState(true)

  //const hasDashboard = useSelector(state => state.dashboard.hasDashboard)
  let loggedInUser = useSelector(state => state.users.user)
  // const currentTheme = useSelector(state => state.ui.currentTheme)

  const dispatch = useDispatch()
  const siteList = useSelector(state => state.sites.siteList)

  useEffect(
    () => {
      if (loggedInUser) {
        setUser(loggedInUser)
        validateNumber(user["phone"])
      }
    },
    // eslint-disable-next-line
    [loggedInUser]
  )

  useEffect(() => {
    dispatch(fetchUserSelf())
    dispatch(fetchSites())
  }, [dispatch])

  const validateNumber = number => {
    if (number != "" && number != undefined) {
      let rgp = /^\+[ ]?([0-9]{1,3})[- ]?([0-9]{1,3})([- ]?([0-9]{1,10}))*$/
      if (number.match(rgp)) setNumberValid(true)
      else setNumberValid(false)
    } else setNumberValid(true)
  }

  const validateTemperatureDecimal = number => {
    //check that input is number, check that it is between 1-5
    if (number !== "" && number !== undefined) {
      let regex = /^[1-5]$/
      if (number.match(regex)) {  
        setTemperatureDecimal(true)
      } else {
        setTemperatureDecimal(false)
      }
    } else {
      setTemperatureDecimal(false)
    }
  }

  const validateRotationInterval = number => {
    if (number !== "" && number !== undefined) {
      // 1 to 60 seconds
      let regex = /^([1-9]|[1-5][0-9]|60)$/
      if (number.match(regex)) {  
        setIntervalDecimal(true)
      } else {
        setIntervalDecimal(false)
      }
    } else {
      setIntervalDecimal(false)
    }
  }

  const handleViewsChange = (view) => {
    let copyUser = {...user}
    copyUser.views = copyUser.views || []
    if(user.views && user.views.includes(view)){
      copyUser.views = copyUser.views.filter((item) => item !== view)
    }
    else{
      copyUser.views.push(view)
    }
    setUser(copyUser)
    setFormUpdated(true)
  }

  const handleChange = ({ target }) => {
    if (target.id === "temperatureDecimalPlaceSetting") validateTemperatureDecimal(target.value)
    if (target.name === "rotationInterval") validateRotationInterval(target.value)
    if (target.name === "phone") validateNumber(target.value)
    if (target.name === "showAlertHistory") {
      setUser({ ...user, showAlertHistory: target.checked })
      setFormUpdated(true)
      return;
    }
    if (target.type === "checkbox") {
      if(target.name === "rotateViews"){
        setUser({ ...user, [target.name]: target.checked })
      }
      else{
        setUser({
          ...user,
          alerts: { ...user.alerts, [target.id]: target.checked }
        })
      }
      setFormUpdated(true)
      return
    }
    setUser({ ...user, [target.name]: target.value })
    setFormUpdated(true)
  }

  const handleSubmit = () => {
    const {
      _id,
      alerts,
      email,
      firstname,
      lastname,
      phone,
      language,
      currentTheme,
      showAlertHistory,
      rotateViews,
      views,
      rotationInterval,
      temperatureDecimalPlaceSetting
    } = user

    dispatch(
      saveUser({
        _id,
        alerts,
        email,
        firstname,
        lastname,
        phone,
        language,
        currentTheme,
        showAlertHistory,
        rotateViews,
        views,
        rotationInterval,
        temperatureDecimalPlaceSetting
      })
    ).then(() => {
      // after saving, update redux state
      dispatch(fetchUserSelf())
      dispatch(changeLanguage(language))
      dispatch(changeTheme(currentTheme))
      dispatch(changeAlertHistoryVisibility(showAlertHistory))
      setFormUpdated(false)
    })
  }

  const handleChangeLanguage = e => {
    handleChange(e)
    // dispatch(changeLanguage())
  }

  const handleChangeTheme = e => {
    handleChange(e)
    // dispatch(changeTheme(e.currentTarget.value))
  }


  //const handleDashboard = () => {
  //  dispatch(AddUserDashboard(user._id))
  //}

  const handleCancel = () => {
    setUser(loggedInUser)
    setFormUpdated(false)
  }

  return (
    <div className={classes.root}>
      <ManageHeader title={tr("_my_account")} />
      <Grid container spacing={0} className={classes.formArea} justify="center">
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <Grid container direction="row" justify="space-between">
            <Grid item>
              <Typography variant={"h5"} className={classes.pageTitle}>
                {user && user.username ? user.username : ""}
              </Typography>
            </Grid>

            <Grid item>
              <Grid
                container
                style={{ height: "100%" }}
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={handleCancel}
                    className={classes.button}
                  >
                    {tr("_cancel")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    className={classes.saveButton}
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={!formUpdated || !numberValid || !temperatureDecimal || !intervalDecimal}
                  >
                    {tr("_save")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}></Grid>

        <Grid item xs={1} />
        <Grid item xs={10}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <ProfileTextField
                id="outlined-firstname"
                fieldName="firstname"
                fieldLabel={tr("_firstname")}
                value={user.firstname || ""}
                onChange={handleChange}
              // {...props}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <ProfileTextField
                id="outlined-lastname"
                fieldName="lastname"
                fieldLabel={tr("_lastname")}
                value={user.lastname || ""}
                onChange={handleChange}
              // {...props}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <ProfileTextField
                id="outlined-role"
                fieldName="role"
                fieldLabel={tr("_role")}
                value={user.role || ""}
              // onChange={handleChange}
              // {...props}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <ProfileTextField
                id="outlined-email"
                fieldName="email"
                fieldLabel={tr("_email")}
                value={user.email || ""}
                onChange={handleChange}
              // {...props}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <ProfileTextField
                error={!numberValid}
                id="outlined-phone"
                fieldName="phone"
                type="tel"
                fieldLabel={tr("_phone")}
                value={user.phone || ""}
                onChange={handleChange}
                helperText={
                  !numberValid ? "International format (eg. +11 222 333)" : null
                }
              // {...props}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Grid>
                <FormLabel component="legend" className={classes.labelColor}>
                  {tr("_select_language")}
                </FormLabel>
                <RadioGroup
                  row
                  aria-label={tr("_select_language")}
                  name="language"
                  className={classes.group}
                  value={user.language}
                  onChange={handleChangeLanguage}
                >
                  <FormControlLabel
                    value="FI"
                    control={
                      <CustomRadio
                        outer={classes.customRadio}
                        inner={classes.customRadioInner}
                      />
                    }
                    label="FI"
                  />
                  <FormControlLabel
                    value="EN"
                    control={
                      <CustomRadio
                        outer={classes.customRadio}
                        inner={classes.customRadioInner}
                      />
                    }
                    label="EN"
                  />
                </RadioGroup>
              </Grid>
            </Grid>

            {/* UI color */}
            <Grid item xs={12} md={3}>
              <FormLabel component="legend" className={classes.labelColor}>
                {tr("_select_theme")}
              </FormLabel>
              <RadioGroup
                row
                aria-label={tr("_select_theme")}
                name="currentTheme"
                className={classes.group}
                value={user.currentTheme}
                onChange={handleChangeTheme}
              >
                <FormControlLabel
                  value="dark"
                  control={
                    <CustomRadio
                      outer={classes.customRadio}
                      inner={classes.customRadioInner}
                    />
                  }
                  label={tr("_theme_dark")}
                />
                <FormControlLabel
                  value="light"
                  control={
                    <CustomRadio
                      outer={classes.customRadio}
                      inner={classes.customRadioInner}
                    />
                  }
                  label={tr("_theme_light")}
                />
              </RadioGroup>
            </Grid>

            {/* Temperature decimal place */}
            <Grid item xs={12} md={3}>
              <FormLabel component="legend" className={classes.labelColor}>
                {tr("_select_temperature_decimal_place")}
              </FormLabel>
         
              <TextField 
                aria-label="Temperature decimal place"
                id="temperatureDecimalPlaceSetting" 
                name="temperatureDecimalPlaceSetting"
                variant="standard" 
                type="number"  
                onChange={handleChange}
                InputProps={{
                  inputProps: { min: 1, max: 5}
                }}
                value={user.temperatureDecimalPlaceSetting || 2}
                error={user.temperatureDecimalPlaceSetting<0 || user.temperatureDecimalPlaceSetting>5 ? true : false}
                helperText={
                  user.temperatureDecimalPlaceSetting<1 || user.temperatureDecimalPlaceSetting>5 ? "Select range between 1 and 5" : null
                }
              />
            </Grid>


            {/* Rotate views for salesperson */}
            { user.salesPerson && 
              <Grid item xs={12} md={3}>
                <FormLabel component="legend" className={classes.labelColor}>
                  {tr("_rotate_views")}
                </FormLabel>

                {/* Rotation on/off */}
                <Grid className={classes.paddingContainer}>
                  <Checkbox
                    color="default"
                    className={classes.checkbox}
                    id="rotateViews"
                    name="rotateViews"
                    checked={user && user.rotateViews}
                    onChange={handleChange}
                  />
                  <label htmlFor="rotateViews" className={classes.checkBoxLabel}>
                    {tr("_enabled")}
                  </label>
                </Grid>

                {/* Rotation interval */}
                <Grid className={classes.paddingContainerMore}>
                  <FormLabel component="legend" className={classes.labelColor}>
                    {tr("_rotation_interval")}
                  </FormLabel>
         
                  <TextField 
                    name="rotationInterval"
                    variant="standard" 
                    type="number"  
                    onChange={handleChange}
                    InputProps={{
                      inputProps: { min: 1, max: 60}
                    }}
                    value={user.rotationInterval ?  user.rotationInterval : 20}
                  />
                </Grid>
                
                {/* Select views for rotation */}
                <Grid>
                  <FormLabel component="legend" className={classes.labelColor}>
                    {tr("_rotation_views")}
                  </FormLabel>
                  {Object.keys(views).map((view) => {
                    return (
                      <Grid key={view}>
                        <Checkbox
                          color="default"
                          className={classes.checkbox}
                          name="rotateViews"
                          id={view}
                          checked={user.views === undefined || user.views.includes(view)}
                          onChange={()=> {handleViewsChange(view)}} />
                        <label htmlFor={view} className={classes.checkBoxLabel}>
                          {view}
                        </label>
                      </Grid>
                    )
                  
                  })}
                </Grid>
              </Grid>
            }
            
          

            {/* Alert communication */}
            <Grid item xs={12} md={3}>
              <FormLabel component="legend" className={classes.labelColor}>
                {tr("_alert_communication")}
              </FormLabel>
              <Tooltip title={tr("_first_alert_tooltip")}>
                <p style={{ marginBottom: "0px" }}>{tr("_first_alert")}</p>
              </Tooltip>
              <Checkbox
                color="default"
                className={classes.checkbox}
                id="instantEmail"
                checked={user && user.alerts && user.alerts.instantEmail}
                onChange={handleChange}
              />
              <label htmlFor="instantEmail" className={classes.checkBoxLabel}>
                {tr("_email")}
              </label>

              <Checkbox
                color="default"
                className={classes.checkbox}
                id="instantSms"
                checked={user && user.alerts && user.alerts.instantSms}
                onChange={handleChange}
              />
              <label htmlFor="instantSms" className={classes.checkBoxLabel}>
                SMS
              </label>

              <Checkbox
                color="default"
                className={classes.checkbox}
                id="instantPush"
                checked={user && user.alerts && user.alerts.instantPush}
                onChange={handleChange}
              />
              <label htmlFor="instantPush" className={classes.checkBoxLabel}>
                Push
              </label>
              <Tooltip title={tr("_resend_alert_tooltip")}>
                <p style={{ marginBottom: "0px" }}>{tr("_resend_alerts")}</p>
              </Tooltip>
              <Checkbox
                color="default"
                className={classes.checkbox}
                id="delayedEmail"
                checked={user && user.alerts && user.alerts.delayedEmail}
                onChange={handleChange}
              />
              <label htmlFor="delayedEmail" className={classes.checkBoxLabel}>
                {tr("_email")}
              </label>

              <Checkbox
                color="default"
                className={classes.checkbox}
                id="delayedSms"
                checked={user && user.alerts && user.alerts.delayedSms}
                onChange={handleChange}
              />
              <label htmlFor="delayedSms" className={classes.checkBoxLabel}>
                SMS
              </label>

              <Checkbox
                color="default"
                className={classes.checkbox}
                id="delayedPush"
                checked={user && user.alerts && user.alerts.delayedPush}
                onChange={handleChange}
              />
              <label htmlFor="delayedPush" className={classes.checkBoxLabel}>
                Push
              </label>
              {/* 24h alert on off */}
              <FormLabel component="legend" className={classes.labelColor}>
                {tr("_show_alertlogs")}
              </FormLabel>
              <Switch
                checked={user.showAlertHistory}
                id="showAlertHistory"
                name="showAlertHistory"
                onChange={handleChange}
                value={user.showAlertHistory}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>

          </Grid>
          {/* Sites header */}
          <Grid container alignItems="center">
            <Grid item>
              <RoomIcon style={{ marginRight: "5px" }} fontSize="default" />
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                justify="left"
                className={classes.pageTitle}
              >
                {tr("_assigned_sites")}
              </Typography>
            </Grid>
          </Grid>
          {/* Sites list. Using Grid instead Table for easier style customization. */}
          <Grid container direction="column" className={classes.tableBase}>
            <Grid item className={classes.tableHeaderRow}>
              <Grid container direction="row" justify="space-between">
                <Grid item xs={3}>
                  <Typography variant="h6" className={classes.tableHeaderText}>
                    {tr("_site")}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h6" className={classes.tableHeaderText}>
                    {tr("_address")}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h6" className={classes.tableHeaderText}>
                    {tr("_city")}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h6" className={classes.tableHeaderText}>
                    {tr("_limitAlerts")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {siteList &&
              siteList.map(site => {
                return (
                  <Grid key={site._id} item>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      className={classes.tableRowBody}
                    >
                      <Grid item xs={3}>
                        <Typography
                          variant="body1"
                          className={classes.tableRowText}
                        >
                          {site.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography
                          variant="body1"
                          className={classes.tableRowText}
                        >
                          {site.address}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography
                          variant="body1"
                          className={classes.tableRowText}
                        >
                          {site.city}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography
                          variant="body1"
                          className={classes.tableRowText}
                        >
                          {/*TODO Is this necessary? alertsEnabled is obsolete but also
                          it shows alert icon on site list tile but condition is based on user settings. 
                          --> Same misleading status icon for all sites*/
                            loggedInUser /* && loggedInUser.alertsEnabled */ ? (
                              <NotificationsActive />
                            ) : (
                              <NotificationsOff />
                            )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              })}
          </Grid>
        </Grid>
        <Grid item xs={1} />
      </Grid>

      <Grid
        container
        spacing={0}
        direction="row"
        justify="flex-end"
        className={classes.formAreaButton}
      >
        {/* Uncomment if you need to create a dashboard */}
        {/* <Grid item xs={1} />
        <Grid item xs={5}>
          <Button
            disabled={hasDashboard}
            onClick={handleDashboard}
            variant="contained"
            type="submit"
            className={classes.saveButton}
          >
            {tr("_create_dashboard")}
          </Button>
        </Grid>
        <Grid item xs={1} /> */}
      </Grid>
    </div>
  )
}

const styles = theme =>
  createStyles({
    tableHeaderRow: {
      // marginBottom: -theme.spacing(3)
    },
    tableBase: {
      width: "100",
      display: "flex"
    },
    tableCell: {
      flex: 1
    },
    tableRowBody: {
      marginTop: theme.spacing(1),
      backgroundColor: theme.palette.table.tableRowBackground,
      alignContent: "center",
      padding: theme.spacing(1)
    },
    tableHeaderText: {
      color: theme.palette.text.field
    },
    customRadio: {
      borderRadius: "50%",
      width: 20,
      height: 20,
      border: "1px solid " + theme.palette.text.primary
    },
    customRadioInner: {
      position: "absolute",
      borderRadius: "50%",
      height: "10px",
      width: "10px",
      top: "50%",
      left: "50%",
      margin: "-5px 0px 0px -5px",
      backgroundColor: theme.palette.action.main
    },
    formAreaButton: {
      paddingTop: "4rem"
    },
    root: {
      flexGrow: 1,
      backgroundImage: theme.backgroundImage,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      backgroundSize: "cover",
      backgroundAttachment: "fixed",
      height: "calc( 100vh - 122px)",
      overflow: "auto"
    },
    cancelButton: {
      padding: theme.spacing(1)
    },
    paddingContainer: {
      paddingBottom: theme.spacing(1)
    },
    paddingContainerMore: {
      paddingBottom: theme.spacing(2)
    },
    saveButton: {
      float: "right",
      backgroundColor: theme.palette.action.main,
      color: theme.palette.secondary.themed,
      "&:hover": {
        color: theme.palette.text.primary
      }
    },
    button: {
      backgroundColor: theme.palette.button.default,
      color: theme.palette.button.caption
    },
    temperatureDecimalPlace: {
    //     
    },
    radio: {
      "&$checked": {
        color: theme.palette.action.main
      },
      color: theme.palette.text.primary
    },
    checked: {},
    textField: {
      marginTop: "5px",
      width: "100%",
      textColor: theme.palette.text.field
    },
    pageTitle: {
      display: "inline-block",
      paddingBottom: "3rem",
      paddingTop: "3rem",
      fontWeight: "bold"
    },
    inputColor: {
      color: theme.palette.text.primary
    },
    labelColor: {
      color: theme.palette.text.field,
      fontWeight: "bold"
    },
    inputRoot: {
      color: theme.palette.text.field + " !important"
    },
    inputFocus: {
      color: theme.palette.text.warning + " !important"
    },
    checkBoxLabel: {
      fontSize: "16px",
      verticalAlign: "middle"
    },
    checkbox: {
      color: theme.palette.action.main
    },
    underline: {
      "&::before": {
        color: theme.palette.text.primary
      },
      "&::hover": {
        borderBottom: "2px solid " + theme.palette.text.primary
      },
      "&::after": {
        borderBottom: "2px solid " + theme.palette.text.warning
      }
    }
  })

export default withStyles(styles, { withTheme: true })(MyProfile)
