import * as React from "react"

import Checkbox from "@material-ui/core/Checkbox"
import TextField from "@material-ui/core/TextField"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableBody from "@material-ui/core/TableBody"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import IconButton from "@material-ui/core/IconButton"


import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import withStyles from "@material-ui/core/styles/withStyles"

// import VisibilityIcon from "@material-ui/icons/Visibility"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"

import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { tr } from "translations"
import { fetchSites, ModifySites } from "../../../actions/sites"
import {
  deleteUser,
  fetchUsers,
  fetchUsersIfNeeded,
  saveUser
} from "../../../actions/users"
import { ManageHeader } from "../../../components/manageMenu/manageHeader"
import { ExpansionPanelSubheading } from "../../../components/parts/ExpansionPanelSubheading"

const roleList = [
  { key: "user", value: "user" },
  { key: "admin", value: "admin" },
  { key: "superAdmin", value: "superAdmin" }
]

class UserManage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      _id: "",
      username: "",
      password: "",
      phone: "",
      email: "",
      role: "user",
      customer: "",
      firstname: "",
      lastname: "",
      error: null,
      isSending: false,
      submitSuccessful: false,
      expanded: null,
      userActiveChecked: false,
      formUpdated: false,
      userSites: [],
      numberValid: true
    }

    this.resetError = this.resetError.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleChangePanel = this.handleChangePanel.bind(this)
    this.handleChechbox = this.handleChechbox.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleChangeUserView = this.handleChangeUserView.bind(this)
    this.validateNumber = this.validateNumber.bind(this)
  }

  componentDidMount() {
    this.props.fetchSites().then(() => {
      this.props.fetchUsers()
    })
  }

  handleChangeUserView() {
    this.props.handleChangeUserView()
  }

  validateNumber(number) {
    if (number != "" && number != undefined) {
      let rgp = /^\+[ ]?([0-9]{1,3})[- ]?([0-9]{1,3})([- ]?([0-9]{1,10}))*$/
      let match = false
      match = number.match(rgp) !== null
      this.setState({ numberValid: match })
    } else {
      this.setState({ numberValid: true })
    }
  }

  handleChange({ target }) {
    if (target.name === "phone") this.validateNumber(target.value)
    this.setState({ [target.name]: target.value, formUpdated: true })
  }

  handleChangePanel(expanded, user) {
    let currentSiteList = []
    if (user._id && user.role === "user") {
      currentSiteList = this.props.siteList.filter(
        (site) => site.group.indexOf(user._id) !== -1
      )
    } else if (user.customer && user.role === "admin") {
      // These are mine sites, but each admin of customer has same sites...
      currentSiteList = this.props.siteList.filter(
        (site) => site.customer === user.customer
      )
    } else {
      currentSiteList = this.props.siteList
    }

    this.validateNumber(user.phone)

    this.setState({
      expanded: expanded ? user._id : false,
      _id: user._id || "",
      email: user.email || "",
      role: user.role || "",
      firstname: user.firstname || "",
      lastname: user.lastname || "",
      phone: user.phone || "",
      username: user.username || "",
      userSites: currentSiteList,
      formUpdated: false
    })
  }

  handleChechbox(event, _id, name) {
    const target = event.target

    let user = { _id }
    switch (name) {
      case "userActiveChecked":
        user.active = target.checked
        break
    }
    this.props
      .saveUser(user)
      .then(() => {
        this.props.fetchUsers()
        this.setState({
          error: null,
          formUpdated: false
        })
      })
      .catch((err) => {
        console.log("Error", err)
      })
  }

  handleDelete(user) {
    this.props
      .deleteUser(user._id)
      .then(() => {
        // this.props.fetchUsers()
        // console.log("handleDelete", res)
        this.setState({
          error: null,
          formUpdated: false,
          isSending: false
        })
      })
      .catch((err) => {
        console.log("Error", err)
      })
  }

  removeUserFromSite(id, site) {
    const { role } = this.state
    const { ModifySites } = this.props

    // Only users can be removed from group
    if (role !== "user") {
      return
    }

    // Let's remove user from group
    const group = site.group.filter((i) => i !== id)

    // And send update to BE
    ModifySites({ ...site, group }).then(() => {
      // Removing user form group was successful...
      // Let's re filter the site list

      const userSites = this.state.userSites.filter((s) => s._id !== site._id)

      this.props.fetchSites()
      this.setState({
        error: null,
        formUpdated: false,
        isSending: false,
        userSites
      })
    })
  }

  resetError() {
    this.setState({ error: null })
  }

  handleSubmit() {
    const { _id, email, firstname, lastname, phone, role } = this.state
    this.props
      .saveUser({ _id, email, firstname, lastname, phone, role })
      .then(() => {
        this.props.fetchUsers()
        this.setState({
          error: null,
          formUpdated: false,
          isSending: false
        })
      })
      .catch((err) => {
        console.log("Error", err)
      })
  }

  render() {
    const { classes, userList, currentUser } = this.props
    const {
      expanded,
      formUpdated,
      submitSuccessful,
      _id,
      role,
      numberValid
    } = this.state

    return (
      <div className={classes.root}>
        <ManageHeader
          addNew={this.handleChangeUserView}
          addNewText={tr("Add_new_user")}
          title={tr("UserManage")}
        />

        <Grid container justify="center">
          <Grid item xs={11} lg={10}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.headingPart1}>
                {tr("_firstname")}
              </Typography>
              <Typography className={classes.headingPart2}>
                {tr("_lastname")}
              </Typography>
              <Typography className={classes.headingPart3}>
                {tr("_role")}
              </Typography>
            </AccordionSummary>
            {userList
              ? userList.map((user) => {
                  return (
                    <Accordion
                      key={user._id}
                      expanded={expanded === user._id}
                      onChange={(event, expanded) =>
                        this.handleChangePanel(expanded, user)
                      }
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.headingPart1}>
                          {user.firstname}
                        </Typography>
                        <Typography className={classes.headingPart2}>
                          {user.lastname}
                        </Typography>
                        <Typography className={classes.headingPart3}>
                          {user.role}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Typography className={classes.title}>
                              {tr("_username")}: {user.username}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} md={6} lg={4}>
                            <TextField
                              label={tr("_firstname")}
                              name="firstname"
                              className={classes.textField}
                              InputLabelProps={{
                                classes: {
                                  root: classes.inputRoot,
                                  focused: classes.inputFocus
                                }
                              }}
                              InputProps={{
                                classes: {
                                  underline: classes.underline
                                }
                              }}
                              value={this.state.firstname}
                              onChange={this.handleChange}
                              margin="normal"
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            <TextField
                              label={tr("_lastname")}
                              name="lastname"
                              className={classes.textField}
                              InputLabelProps={{
                                classes: {
                                  root: classes.inputRoot,
                                  focused: classes.inputFocus
                                }
                              }}
                              InputProps={{
                                classes: {
                                  underline: classes.underline
                                }
                              }}
                              value={this.state.lastname}
                              onChange={this.handleChange}
                              margin="normal"
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            <TextField
                              id={"email" + user._id}
                              label={tr("_email")}
                              type="email"
                              name="email"
                              className={classes.textField}
                              InputLabelProps={{
                                classes: {
                                  root: classes.inputRoot,
                                  focused: classes.inputFocus
                                }
                              }}
                              InputProps={{
                                classes: {
                                  underline: classes.underline
                                }
                              }}
                              value={this.state.email}
                              onChange={this.handleChange}
                              margin="normal"
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            <TextField
                              error={!numberValid}
                              helperText={
                                !numberValid
                                  ? "International format (eg. +11 222 333)"
                                  : null
                              }
                              id={"phone" + user._id}
                              label={tr("_phone")}
                              name="phone"
                              className={classes.textField}
                              InputLabelProps={{
                                classes: {
                                  root: classes.inputRoot,
                                  focused: classes.inputFocus
                                }
                              }}
                              InputProps={{
                                classes: {
                                  underline: classes.underline
                                }
                              }}
                              value={this.state.phone}
                              onChange={this.handleChange}
                              margin="normal"
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            <FormControl className={classes.textFieldForm}>
                              <InputLabel
                                classes={{
                                  root: classes.inputRoot,
                                  focused: classes.inputFocus
                                }}
                                htmlFor={"role-select" + user._id}
                              >
                                {tr("_role")}
                              </InputLabel>
                              <Select
                                required
                                value={this.state.role}
                                onChange={this.handleChange}
                                inputProps={{
                                  name: "role",
                                  id: `role-select${user._id}`
                                }}
                              >
                                <MenuItem value="">
                                  <em>{tr("_select_role")}</em>
                                </MenuItem>
                                {roleList.map((role) => {
                                  return (
                                    <MenuItem value={role.value} key={role.key}>
                                      {role.value}
                                    </MenuItem>
                                  )
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            {String(user._id) !== String(currentUser._id) && (
                              <Typography>User Active? </Typography>
                            )}

                            {String(user._id) !== String(currentUser._id) && (
                              <Checkbox
                                checked={user.active}
                                onChange={(event) =>
                                  this.handleChechbox(
                                    event,
                                    user._id,
                                    "userActiveChecked"
                                  )
                                }
                                value="userActiveChecked"
                                // color="primary"
                              />
                            )}
                          </Grid>

                          <Grid container justify="flex-end" item xs={12}>
                            {String(user._id) !== String(currentUser._id) && (
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => this.handleDelete(user)}
                                className={classes.removeButton}
                              >
                                {tr("_remove_user")}
                              </Button>
                            )}
                            <Button
                              disabled={!formUpdated || !numberValid}
                              variant="contained"
                              color="primary"
                              onClick={this.handleSubmit}
                              className={classes.saveButton}
                            >
                              {tr("_save")}
                            </Button>
                          </Grid>

                          <ExpansionPanelSubheading title={tr("Users_sites")} />

                          <Grid item xs={12}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>{tr("_site_name")}</TableCell>
                                  <TableCell>{tr("_address")}</TableCell>
                                  <TableCell>{tr("_city")}</TableCell>
                                  <TableCell>
                                    {tr("_remove_site_from_user")}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {this.state.userSites
                                  ? this.state.userSites.map((site) => {
                                      return (
                                        <TableRow key={site._id}>
                                          <TableCell>{site.name}</TableCell>
                                          <TableCell>{site.address}</TableCell>
                                          <TableCell>{site.city}</TableCell>
                                          <TableCell>
                                            {role === "user" ? (
                                              <IconButton
                                                onClick={() =>
                                                  this.removeUserFromSite(
                                                    _id,
                                                    site
                                                  )
                                                }
                                              >
                                                <HighlightOffIcon />
                                              </IconButton>
                                            ) : null}
                                          </TableCell>
                                        </TableRow>
                                      )
                                    })
                                  : null}
                              </TableBody>
                            </Table>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  )
                })
              : ""}
            {submitSuccessful && (
              <div className="alert alert-success">
                <strong>Success!</strong>
              </div>
            )}
          </Grid>
        </Grid>

        {submitSuccessful && (
          <div className="alert alert-success">
            <strong>Registration done!</strong>
          </div>
        )}
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  deleteUser: (id) => dispatch(deleteUser(id)),
  fetchUsersIfNeeded: () => dispatch(fetchUsersIfNeeded()),
  fetchUsers: () => dispatch(fetchUsers()),
  saveUser: (obj) => dispatch(saveUser(obj)),
  fetchSites: () => dispatch(fetchSites()),
  ModifySites: (obj) => dispatch(ModifySites(obj))
})

const mapStateToProps = (state) => {
  return {
    siteList: state.sites.siteList,
    userList: state.users.userList,
    currentUser: state.users.user
  }
}

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%"
  },
  textFieldForm: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
    color: theme.palette.text.field
  },
  dense: {
    marginTop: 16
  },
  menu: {
    width: 200
  },
  root: {
    flexGrow: 1,
    height: "calc(100vh - 130px)",
    backgroundImage: theme.backgroundImage,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    overflow: "auto"
  },
  title: {
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 20
  },
  button: {
    margin: 10
  },
  inputRoot: {
    color: theme.palette.text.field + " !important"
  },
  inputFocus: {
    color: theme.palette.text.warning + " !important"
  },
  underline: {
    "&::before": {
      color: theme.palette.text.primary
    },
    "&::hover": {
      borderBottom: "2px solid " + theme.palette.text.primary
    },
    "&::after": {
      borderBottom: "2px solid " + theme.palette.text.warning
    }
  },
  headingPart1: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    color: theme.palette.text.field
  },
  headingPart2: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    color: theme.palette.text.field
  },
  headingPart3: {
    color: theme.palette.text.field,
    flexBasis: "10%",
    fontSize: theme.typography.pxToRem(15)
  },
  saveButton: {
    margin: 10,
    backgroundColor: theme.palette.action.main,
    color: theme.palette.secondary.themed,
    "&:hover": {
      color: theme.palette.text.primary
    }
  },
  removeButton: {
    margin: 10,
    color: theme.palette.secondary.themed,
    "&:hover": {
      color: theme.palette.text.primary
    }
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles, { withTheme: true })(UserManage)))
