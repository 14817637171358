import React, { useState } from "react"
import { Link, NavLink } from "react-router-dom"
import Grid from "@material-ui/core/Grid"
import withStyles from "@material-ui/core/styles/withStyles"
import Typography from "@material-ui/core/Typography"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import useTheme from "@material-ui/core/styles/useTheme"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { platformTextPrimary, platformYellow } from "../../services/colors"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import {
  Account,
  OfficeBuilding,
  Home,
  AccountGroup,
  Star,
  Alert,
  Layers
} from "../../components/SvgLib"
import { tr } from "../../translations"

const NarrowMenuItem = ({ caption, urlParam, icon, currentUrlParam }) => {
  const theme = useTheme()
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div>
        {React.createElement(icon, {
          color:
            currentUrlParam === urlParam
              ? theme.palette.text.activeNav
              : theme.palette.nav.secondaryIcon,
          style: { height: 25, width: 25 }
        })}
      </div>
      <div style={{ width: "1rem" }} />
      <Typography
        variant="subtitle2"
        style={{
          fontSize: 18,
          color:
            currentUrlParam === urlParam
              ? theme.palette.text.activeNav
              : theme.palette.nav.secondaryText
        }}
      >
        {caption}
      </Typography>
    </div>
  )
}
const NavItem = ({ caption, urlParam, icon, rest }) => {
  const { sub, theme, iconStyle } = rest
  const style = { ...iconStyle }

  return (
    <div style={{ display: "table-cell" }}>
      <Link to={`/manage/${urlParam}`} style={{ textDecoration: "none" }}>
        <Grid
          container
          alignItems="center"
          spacing={1}
          style={{ marginLeft: "30px" }}
        >
          <Grid item>
            {React.createElement(icon, {
              color:
                sub === urlParam
                  ? theme.palette.text.activeNav
                  : theme.palette.nav.secondaryIcon,
              style
            })}
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle2"
              style={{
                fontSize: 18,
                color:
                  sub === urlParam
                    ? theme.palette.text.activeNav
                    : theme.palette.nav.secondaryText
              }}
            >
              {caption}
            </Typography>
          </Grid>
        </Grid>
      </Link>
    </div>
  )
}

const ProfilePageAppBar = props => {
  const { classes, sub, role } = props

  const theme = useTheme()
  const isNarrowView = useMediaQuery(theme.breakpoints.down("sm"))
  const [narrowMenuAnchor, setNarrowMenuAnchor] = useState(null)
  const handleOpenNarrowMenu = event => {
    setNarrowMenuAnchor(event.target)
  }
  const handleCloseNarrowMenu = () => {
    setNarrowMenuAnchor(null)
  }
  const itemProps = {
    iconStyle: { height: 40, width: 40 },
    sub,
    classes,
    theme
  }

  return (
    <div
      className={classes.secondaryNavBar}
      style={{
        border: "!important 5px solid #000000",
        zIndex: "!important 1"
        //marginLeft: "30px"
      }}
    >
      {!isNarrowView && (
        <Grid style={{ display: "table" }}>
          {/* Sites */}
          <NavItem
            caption={tr("_areas")}
            urlParam="areas"
            icon={Home}
            rest={itemProps}
          />
          {/* Clients */}
          {role === "superAdmin" && (
            <NavItem
              caption={tr("_clients")}
              urlParam="customer"
              icon={Star}
              rest={itemProps}
            />
          )}
          {/* Users */}
          {(role === "superAdmin" || role === "admin") && (
            <NavItem
              caption={tr("Site_users")}
              urlParam="user"
              icon={AccountGroup}
              rest={itemProps}
            />
          )}
          {/* Account */}
          <NavItem
            caption={tr("_account")}
            urlParam="profile"
            icon={Account}
            rest={itemProps}
          />
          {/* Company info */}
          {(role === "superAdmin" || role === "admin") && (
            <NavItem
              caption={tr("_companyInfo")}
              urlParam="company"
              icon={OfficeBuilding}
              rest={itemProps}
            />
          )}
          {/* Limits List */}
          <NavItem
            caption={tr("_limits")}
            urlParam="limits"
            icon={Alert}
            rest={itemProps}
          />
          {/* Groups List */}
          <NavItem
            caption={tr("_groups")}
            urlParam="groups"
            icon={Layers}
            rest={itemProps}
          />
          {/* trial manage */}
          {role === "superAdmin" && (
            <NavItem
              caption={tr("_trialTokens")}
              urlParam="trial"
              icon={OfficeBuilding}
              rest={itemProps}
            />
          )}
          {/* Company info */}
          {role === "superAdmin" && (
            <NavItem
              caption={tr("_reports")}
              urlParam="reports"
              icon={OfficeBuilding}
              rest={itemProps}
            />
          )}
        </Grid>
      )}

      {isNarrowView && (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ flexGrow: 1 }} />
          <div style={{ padding: "0.5rem" }}>
            <MoreVertIcon onClick={handleOpenNarrowMenu} />
            <Menu
              id="simple-menu"
              anchorEl={narrowMenuAnchor}
              keepMounted
              open={Boolean(narrowMenuAnchor)}
              onClose={handleCloseNarrowMenu}
            >
              <MenuItem
                onClick={handleCloseNarrowMenu}
                component={NavLink}
                to={`/manage/areas`}
              >
                <NarrowMenuItem
                  caption={tr("_areas")}
                  urlParam="areas"
                  icon={Home}
                  currentUrlParam={sub}
                />
              </MenuItem>

              {role === "superAdmin" && (
                <MenuItem
                  onClick={handleCloseNarrowMenu}
                  component={NavLink}
                  to={`/manage/customer`}
                >
                  <NarrowMenuItem
                    caption={tr("_clients")}
                    urlParam="customer"
                    icon={Star}
                    currentUrlParam={sub}
                  />
                </MenuItem>
              )}

              {(role === "superAdmin" || role === "admin") && (
                <MenuItem
                  onClick={handleCloseNarrowMenu}
                  component={NavLink}
                  to={`/manage/user`}
                >
                  <NarrowMenuItem
                    caption={tr("Site_users")}
                    urlParam="user"
                    icon={AccountGroup}
                    currentUrlParam={sub}
                  />
                </MenuItem>
              )}
              <MenuItem
                onClick={handleCloseNarrowMenu}
                component={NavLink}
                to={`/manage/profile`}
              >
                <NarrowMenuItem
                  caption={tr("_account")}
                  urlParam="profile"
                  icon={Account}
                  currentUrlParam={sub}
                />
              </MenuItem>
              {(role === "superAdmin" || role === "admin") && (
                <MenuItem
                  onClick={handleCloseNarrowMenu}
                  component={NavLink}
                  to={`/manage/company`}
                >
                  <NarrowMenuItem
                    caption={tr("_companyInfo")}
                    urlParam="company"
                    icon={OfficeBuilding}
                    currentUrlParam={sub}
                  />
                </MenuItem>
              )}
              <MenuItem
                onClick={handleCloseNarrowMenu}
                component={NavLink}
                to={`/manage/limits`}
              >
                <NarrowMenuItem
                  caption={tr("_limits")}
                  urlParam="limits"
                  icon={Alert}
                  currentUrlParam={sub}
                />
              </MenuItem>
              <MenuItem
                onClick={handleCloseNarrowMenu}
                component={NavLink}
                to={`/manage/groups`}
              >
                <NarrowMenuItem
                  caption={tr("_groups")}
                  urlParam="groups"
                  icon={Layers}
                  currentUrlParam={sub}
                />
              </MenuItem>
              {role === "superAdmin" && (
                <MenuItem
                  onClick={handleCloseNarrowMenu}
                  component={NavLink}
                  to={`/manage/trial`}
                >
                  <NarrowMenuItem
                    caption={tr("_trialTokens")}
                    urlParam="trial"
                    icon={OfficeBuilding}
                    currentUrlParam={sub}
                  />
                </MenuItem>
              )}
            </Menu>
          </div>
        </div>
      )}
    </div>
  )
}

const styles = theme => ({
  iconButton: {
    marginLeft: 15,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0
    }
  },
  iconTopBar: {
    color: platformTextPrimary
  },
  iconTopBarSelected: {
    color: platformYellow
  },
  secondaryNavBar: {
    backgroundColor: theme.palette.nav.secondary,
    padding: theme.spacing(1)
    // borderBottom: `1px solid gray`
  },
  menuItemStyle: {
    color: theme.palette.popup.text,
    textDecoration: "none"
  }
})

export default withStyles(styles)(ProfilePageAppBar)
