import React, { useState } from "react"
import Grid from "@material-ui/core/Grid"
import withStyles from "@material-ui/core/styles/withStyles"
import useTheme from "@material-ui/core/styles/useTheme"
import { DevicePHAir } from "./GroundplanMarker"

const FloatingMarker = props => {
  const {
    classes,
    children,
    deviceMoving,
    onDeviceCancel,
    onDeviceRelease
  } = props
  const [mouseX, setMouseX] = useState(null)
  const [mouseY, setMouseY] = useState(null)

  const theme = useTheme()

  const _onMouseMove = event => {
    const { touches = [] } = event

    if (touches && touches.length > 0) {
      const { pageX, pageY } = touches[0]
      setMouseX(pageX)
      setMouseY(pageY)
    } else {
      setMouseX(event.pageX)
      setMouseY(event.pageY)
    }
  }

  const onTouchEnd = event => {
    // Here's tutorial for this method.
    // https://www.sitepoint.com/how-to-translate-from-dom-to-svg-coordinates-and-back-again/

    // First let's get the last touch before the element was released.
    const { changedTouches = [] } = event

    if (changedTouches.length === 0) {
      return
    }

    const { clientX, clientY } = changedTouches[0]

    // Get the svg and container.
    const svg = document.getElementById("groundplan-svg")

    const boundingRect = svg.getBoundingClientRect()

    if (
      clientX < boundingRect.left ||
      clientX > boundingRect.right ||
      clientY < boundingRect.top ||
      clientY > boundingRect.bottom
    ) {
      console.log("Out of bounds")

      onDeviceCancel()
      return
    }

    const controlRect = document.getElementById("groundplan-image-element")

    // Make reference point to the svg canvas.
    const point = svg.createSVGPoint()

    // Assign touch coordinates
    point.x = clientX
    point.y = clientY

    // Transform the point to element coordinates.
    const transformed = point.matrixTransform(
      controlRect.getScreenCTM().inverse()
    )

    onDeviceRelease(transformed.x, transformed.y)
  }

  return (
    <Grid
      container
      className={classes.floatArea}
      onTouchMove={deviceMoving ? _onMouseMove : null}
      onTouchEnd={deviceMoving ? onTouchEnd : null}
      onMouseMove={deviceMoving ? _onMouseMove : null}
      onMouseDown={_onMouseMove}
    >
      {deviceMoving ? (
        <DevicePHAir
          style={{
            position: "absolute",
            top: mouseY - 25,
            left: mouseX - 25,
            zIndex: "1200",
            height: "50px"
          }}
          color={theme.palette.text.primary}
          image={deviceMoving.deviceType}
        />
      ) : null}
      {children}
    </Grid>
  )
}

const styles = theme => ({
  floatArea: {
    flexGrow: 1,
    backgroundImage: theme.backgroundImage,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed"
  }
})

export default withStyles(styles)(FloatingMarker)
