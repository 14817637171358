import * as React from "react"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"

import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { tr } from "translations"
import TextLine from "../../../components/TextLine"
import { platformBlue, platformWhite } from "../../../services/colors"
import * as axios from "axios"

class SiteSub extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      customer: "",
      name: "",
      address: "",
      zip: "",
      city: "",
      country: "",

      error: null,
      info: false,
      infoText: "",

      showPassword: false
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleNext = this.handleNext.bind(this)
  }

  componentDidMount() {
    const { org } = this.props
    this.setState({
      customer: org.name,
      address: org.address,
      zip: org.zip,
      city: org.city,
      country: org.country
    })
  }

  handleChange({ target }) {
    this.setState({ [target.name]: target.value })
  }

  async handleNext() {
    const { nextStep } = this.props
    const { customer, name, address, zip, city, country } = this.state
    const { lat, lng } = await this.geocodeAddress()
    const pos = { lat, lng }
    const value = {
      customer,
      name,
      address,
      zip,
      city,
      country,
      pos
    }

    nextStep("site", value)
  }

  geocodeAddress() {
    return new Promise((resolve, reject) => {
      let key = "AIzaSyDqkPzWzLFtFaAHCmgiL4vAP1qO-LndYrk"
      let address =
        this.state.address +
        "+" +
        this.state.city +
        "+" +
        this.state.zip +
        "+" +
        this.state.country

      axios
        .get(
          "https://maps.googleapis.com/maps/api/geocode/json?address=" +
            address +
            "&key=" +
            key
        )
        .then(res => {
          const { lat, lng } = res.data.results[0].geometry.location
          resolve({ lat, lng })
        })
        .catch(err => {
          console.log("Geocode error: ", err)
          reject({
            error: "Geocode API error - use default location",
            lat: 61.446716,
            lng: 23.857413
          })
        })
    })
  }

  render() {
    const { classes } = this.props
    const { name, address, zip, city, country, info, infoText } = this.state
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          className={classes.signupForm}
          spacing={3}
        >
          <Grid item className={classes.headerItem}>
            <Typography
              color="primary"
              variant={"h5"}
              className={classes.labelText}
              align="left"
            >
              {tr("_trialregisterheadlinestep3")}
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              id="name"
              name="name"
              label={tr("_name")}
              className={classes.textInput}
              value={name}
              onChange={this.handleChange}
              variant="outlined"
              InputProps={{
                classes: {
                  input: classes.inputColor
                }
              }}
              InputLabelProps={{
                classes: {
                  root: classes.labelColor,
                  focused: classes.labelColor,
                  error: classes.labelColor
                }
              }}
              fullWidth
            />
          </Grid>

          <Grid item>
            <TextField
              id="address"
              name="address"
              label={tr("_address")}
              className={classes.textInput}
              value={address}
              onChange={this.handleChange}
              variant="outlined"
              InputProps={{
                classes: {
                  input: classes.inputColor
                }
              }}
              InputLabelProps={{
                classes: {
                  root: classes.labelColor,
                  focused: classes.labelColor,
                  error: classes.labelColor
                }
              }}
            />
          </Grid>

          <Grid item>
            <TextField
              id="zip"
              name="zip"
              label={tr("_postal_code")}
              className={classes.textInput}
              value={zip}
              onChange={this.handleChange}
              variant="outlined"
              InputProps={{
                classes: {
                  input: classes.inputColor
                }
              }}
              InputLabelProps={{
                classes: {
                  root: classes.labelColor,
                  focused: classes.labelColor,
                  error: classes.labelColor
                }
              }}
            />
          </Grid>

          <Grid item>
            <TextField
              id="city"
              name="city"
              label={tr("_city")}
              className={classes.textInput}
              value={city}
              onChange={this.handleChange}
              variant="outlined"
              InputProps={{
                classes: {
                  input: classes.inputColor
                }
              }}
              InputLabelProps={{
                classes: {
                  root: classes.labelColor,
                  focused: classes.labelColor,
                  error: classes.labelColor
                }
              }}
            />
          </Grid>

          <Grid item>
            <TextField
              id="country"
              name="country"
              label={tr("_country")}
              className={classes.textInput}
              value={country}
              onChange={this.handleChange}
              variant="outlined"
              InputProps={{
                classes: {
                  input: classes.inputColor
                }
              }}
              InputLabelProps={{
                classes: {
                  root: classes.labelColor,
                  focused: classes.labelColor,
                  error: classes.labelColor
                }
              }}
            />
          </Grid>

          <Grid item>
            <Button
              disabled={!name}
              className={classes.buttonPadding}
              onClick={this.handleNext}
            >
              <Typography variant="h6">
                {tr("_continue").toUpperCase()}
              </Typography>
            </Button>
          </Grid>

          {info && (
            <TextLine
              mode={0}
              text={infoText}
              onClick={this.onInfoTextLinePress}
            />
          )}
        </Grid>
      </Grid>
    )
  }
}

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  dense: {
    marginTop: 16
  },
  menu: {
    width: 200
  },
  root: {
    flexGrow: 1,
    width: "100vw",
    height: "100vh",
    backgroundImage: theme.loginBackground,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed"
  },
  headerItem: {
    width: 400
  },
  labelText: {
    fontWeight: "bold",
    letterSpacing: "3px"
  },
  labelTextSecondary: {
    fontWeight: "bold",
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  textInput: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
    backgroundColor: "#FFFFFF"
  },
  padding: {
    paddingTop: 10,
    paddingBottom: 10
  },
  buttonPadding: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: "10%",
    paddingRight: "10%",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
    textTransform: "capitalize",
    color: platformWhite,
    // background: "linear-gradient(to right, #46c77f, #18ba60)",
    backgroundColor: platformBlue,
    "&:hover": {
      backgroundColor: platformBlue
    }
  },
  inputColor: {
    color: "black"
  },
  labelColor: {
    color: "gray"
  },
  underline: {
    borderBottom: "1px solid black"
  },
  customTextField: {
    border: "0px"
  }
})

const mapDispatchToProps = () => ({})

const mapStateToProps = () => {
  return {}
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles, { withTheme: true })(SiteSub))
)
