// To create new widgets:
// 1. Create a copy from the TemplateWidget.js
// 2. Rename copied file to the name of the widget, e.g. MyWidget.js
// 3. Add name of the widget to this array without the ".js" file extension, e.g. "MyWidget"
// 4. Implement the new widget code to the copied file
// 5. Make a copy of the settingModals/settingDefaultWidget.js
// 6. Rename the file to e.g. settingMyWidget.js
// 7. Implement the required setting changes to the modal

export const widgetTypes = [
  "DefaultWidget",
  "AlertsWidget",
  "StatusWidget",
  "ValueAverageWidget",
  "LineWidget",
  "LimitWidget",
  "PillarChartWidget"
]
