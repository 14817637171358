import React from "react"

import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { tr } from "translations"

export default function AddFile({
  handleDeleteFile,
  handleselectedFile,
  selectedFile,
  title,
  id
}) {
  const classes = useStyles()
  return (
    <Grid container alignItems="center">
      <Grid item xs={12} className={classes.downloadAreaTop}>
        <Typography className={classes.textFieldTitle} variant="subtitle2">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.subFieldTitle} variant="caption">
          {tr("_supported_file_formats")}
        </Typography>
      </Grid>

      <Grid item xs={8}>
        <Typography className={classes.textField} variant="body2">
          {selectedFile ? (
            <React.Fragment>{selectedFile}</React.Fragment>
          ) : (
            tr("No_upload")
          )}
        </Typography>
      </Grid>

      <Grid item xs={4}>
        {selectedFile ? (
          <Button
            variant="contained"
            className={classes.buttonField}
            onClick={handleDeleteFile}
          >
            {tr("_delete")}
          </Button>
        ) : (
          <React.Fragment>
            <input
              style={{ display: "none" }}
              id={id}
              type="file"
              onChange={handleselectedFile}
              accept=".jpg,.png,.jpeg,.bmp"
            />
            <label htmlFor={id}>
              <Button
                variant="contained"
                component="span"
                className={classes.buttonField}
              >
                {tr("_browse")}
              </Button>
            </label>
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  downloadAreaTop: {
    marginTop: theme.spacing(1)
  },
  textField: {
    color: theme.palette.text.fixed
  },
  subFieldTitle: {
    color: theme.palette.text.fixed,
    fontStyle: "italic"
  },
  textFieldTitle: {
    color: theme.palette.text.fixed,
    fontWeight: "bold"
  },
  buttonField: {
    backgroundColor: theme.palette.button.delete,
    color: theme.palette.text.fixed,
    float: "right"
  }
}))
