import * as React from "react"

import Grid from "@material-ui/core/Grid"
import withStyles from "@material-ui/core/styles/withStyles"

import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import { connect } from "react-redux"
import { tr } from "translations"
import { saveTrialToken, saveTrialTokenBulk } from "../../../actions/trials"
import { ManageHeader } from "../../../components/manageMenu/manageHeader"

class TrialTokenManageAdd extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expiration: "",
      expiration2: "",
      code: "",
      quantity: "",
      error: null,
      isSending: false,
      expanded: null
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleSend = this.handleSend.bind(this)
    this.handleSendBulk = this.handleSendBulk.bind(this)
    this.resetError = this.resetError.bind(this)
    this.handleChangePanel = this.handleChangePanel.bind(this)
  }

  handleChangePanel(expanded, customer) {
    this.setState({
      expanded: expanded ? customer._id : false,
      _id: customer._id || ""
    })
  }

  handleChange(event) {
    const target = event.target
    return this.setState({ [target.name]: target.value })
  }

  async handleDelete(id) {
    this.props.DeleteCustomers(id).then(() => this.props.fetchCustomers())
  }

  resetError() {
    this.setState({ error: null })
  }

  async handleSend() {
    this.setState({ isSending: true })
    const { code, expiration } = this.state
    try {
      await this.props.saveTrialToken({ time: expiration * 86400, code })
      this.setState({ isSending: false })
      this.props.handleChangeTrialView()
    } catch (err) {
      this.setState({ isSending: false })
    }
  }

  async handleSendBulk() {
    this.setState({ isSending: true })
    const { quantity, expiration2 } = this.state
    try {
      await this.props.saveTrialTokenBulk(
        { time: expiration2 * 86400 },
        quantity
      )
      this.setState({ isSending: false })
      this.props.handleChangeTrialView()
    } catch (err) {
      this.setState({ isSending: false })
    }
  }

  render() {
    const { classes } = this.props
    const { isSending } = this.state

    return (
      <div className={classes.root}>
        <ManageHeader
          addNew={this.props.handleChangeTrialView}
          addNewText={tr("_return_to_trial_list")}
          title={tr("_add_new_trial_token")}
        />

        <Grid
          container
          className={classes.makeRoomBottom}
          spacing={0}
          justify="center"
        >
          <Grid item xs={11} lg={10}>
            <Grid container spacing={0}>
              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  required
                  label={tr("_custom_trial_code")}
                  name="code"
                  className={classes.textField}
                  InputLabelProps={{
                    classes: {
                      root: classes.inputRoot,
                      focused: classes.inputFocus
                    }
                  }}
                  InputProps={{
                    classes: {
                      underline: classes.underline
                    }
                  }}
                  value={this.state.code}
                  onChange={this.handleChange}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  required
                  label={tr("_custom_trial_expiration")}
                  name="expiration"
                  className={classes.textField}
                  InputLabelProps={{
                    classes: {
                      root: classes.inputRoot,
                      focused: classes.inputFocus
                    }
                  }}
                  InputProps={{
                    classes: {
                      underline: classes.underline
                    }
                  }}
                  value={this.state.expiration}
                  onChange={this.handleChange}
                  margin="normal"
                />
              </Grid>

              <Grid item xs={12} className={classes.formAreaButton}>
                <Button
                  disabled={isSending}
                  color="primary"
                  className={classes.saveButton}
                  variant="contained"
                  onClick={this.handleSend}
                >
                  {tr("_save")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ManageHeader title={tr("_bulk_create")} />
        <Grid
          container
          className={classes.makeRoomBottom}
          spacing={0}
          justify="center"
        >
          <Grid item xs={11} lg={10}>
            <Grid container spacing={0}>
              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  required
                  label={tr("_quantity")}
                  name="quantity"
                  className={classes.textField}
                  InputLabelProps={{
                    classes: {
                      root: classes.inputRoot,
                      focused: classes.inputFocus
                    }
                  }}
                  InputProps={{
                    classes: {
                      underline: classes.underline
                    }
                  }}
                  value={this.state.quantity}
                  onChange={this.handleChange}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  required
                  label={tr("_custom_trial_expiration")}
                  name="expiration2"
                  className={classes.textField}
                  InputLabelProps={{
                    classes: {
                      root: classes.inputRoot,
                      focused: classes.inputFocus
                    }
                  }}
                  InputProps={{
                    classes: {
                      underline: classes.underline
                    }
                  }}
                  value={this.state.expiration2}
                  onChange={this.handleChange}
                  margin="normal"
                />
              </Grid>

              <Grid item xs={12} className={classes.formAreaButton}>
                <Button
                  disabled={isSending}
                  color="primary"
                  className={classes.saveButton}
                  variant="contained"
                  onClick={this.handleSendBulk}
                >
                  {tr("_save_bulk")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const styles = theme => ({
  formAreaButton: {
    paddingTop: "4rem"
  },
  root: {
    flexGrow: 1,
    height: "calc(100vh - 130px)",
    width: "100%",
    backgroundImage: theme.backgroundImage,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed"
  },
  makeRoomBottom: {
    marginBottom: 50
  },
  inputRoot: {
    color: theme.palette.text.field + " !important"
  },
  inputFocus: {
    color: theme.palette.text.warning + " !important"
  },
  underline: {
    "&::before": {
      color: theme.palette.text.primary
    },
    "&::hover": {
      borderBottom: "2px solid " + theme.palette.text.primary
    },
    "&::after": {
      borderBottom: "2px solid " + theme.palette.text.warning
    }
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "95%"
  },
  saveButton: {
    backgroundColor: theme.palette.action.main,
    color: theme.palette.secondary.themed,
    "&:hover": {
      color: theme.palette.text.primary
    }
  }
})

const mapDispatchToProps = dispatch => ({
  saveTrialToken: obj => dispatch(saveTrialToken(obj)),
  saveTrialTokenBulk: (obj, quantity) =>
    dispatch(saveTrialTokenBulk(obj, quantity))
})

const mapStateToProps = () => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(TrialTokenManageAdd))
