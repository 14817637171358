// import { authError } from "./auth"
import axios from "axios"
import { getApiUrl } from "../constants/config"
import { parseErrorData } from "../services/errors"
import { authHeader } from "./common"
import { stateType } from "../constants/actions"

const areaApiFailed = error => ({
  type: stateType.SET_ERROR,
  error
})

const areaListRequested = () => ({
  type: stateType.AREA_LIST_REQUESTED
})

const areaListReceived = areaList => ({
  type: stateType.AREA_LIST_RECEIVED,
  areaList
})

const areaAddRequested = () => ({
  type: stateType.AREA_ADD_REQUESTED
})

const areaAddReceived = area => ({
  type: stateType.AREA_ADD_RECEIVED,
  area
})

const areaModifyRequested = () => ({
  type: stateType.AREA_MODIFY_REQUESTED
})

const areaModifyReceived = area => ({
  type: stateType.AREA_MODIFY_RECEIVED,
  area
})

const areaDeleteRequested = () => ({
  type: stateType.AREA_DELETE_REQUESTED
})

const areaDeleteReceived = areaId => ({
  type: stateType.AREA_DELETE_RECEIVED,
  areaId
})

const areaSelected = area => ({
  type: stateType.AREA_SELECT,
  areaOpen: area
})

const floorSelected = floor => ({
  type: stateType.FLOOR_SELECT,
  floorOpen: floor
})

const floorsFormed = floors => ({
  type: stateType.AREA_FLOORS,
  floorData: floors
})

const displayInfo = info => ({
  type: stateType.SET_INFO,
  info
})

const addImage = async (imageFile, name, useToke) => {
  try {
    let siteUrl = getApiUrl() + "/api/image/"

    const imageData = new FormData()

    if (imageFile) {
      imageData.append("image", imageFile, name + "-" + imageFile.name)
    }

    const { data } = await axios.post(siteUrl, imageData, authHeader(useToke))

    if (!data || !data.success) {
      throw (data && data.error) || "Failed to fetch"
    }

    return data
  } catch (err) {
    const errorReply = parseErrorData(err)
    return errorReply
  }
}

export const fetchAreas = () => async (dispatch, getState) => {
  const state = getState()
  try {
    dispatch(areaListRequested())
    let siteUrl = getApiUrl() + "/api/areas"
    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    const { data } = await axios.get(siteUrl, authHeader(useToke))

    let areaList = null
    if (!data || !data.success || !data.areas) {
      throw (data && data.error) || "Failed to fetch sites"
    }

    areaList = data.areas
    return Promise.resolve(dispatch(areaListReceived(areaList)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(areaApiFailed(errorReply)))
  }
}

export const AddArea = (site, name, info, floor, file1, file2) => async (
  dispatch,
  getState
) => {
  const state = getState()
  try {
    dispatch(areaAddRequested())
    let siteUrl = getApiUrl()
    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    const newArea = { site, name, info, floor }

    if (file1) {
      const dataSave = await addImage(file1, newArea.name, useToke)
      if (dataSave.url) {
        newArea.image = dataSave.url
      }
    }

    if (file2) {
      const dataSave = await addImage(file2, newArea.name, useToke)
      if (dataSave.url) {
        newArea.electricalDrawing = dataSave.url
      }
    }

    axios
      .post(siteUrl + "/api/areas", newArea, authHeader(useToke))
      .then(subres => {
        let area = null
        const { data } = subres
        if (!data || !data.success || !data.area) {
          throw (data && data.error) || "Failed to fetch"
        }

        area = data.area

        let info = { description: "NEW_AREA_ADDED" }
        dispatch(displayInfo(info))

        return Promise.resolve(dispatch(areaAddReceived(area)))
      })
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(areaApiFailed(errorReply)))
  }
}

export const ModifyArea = (existingArea, file1, file2) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(areaModifyRequested())

    let siteUrl = getApiUrl() + "/api/areas/" + existingArea._id
    console.log("areamodifyrequested", existingArea)
    const state = getState()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    if (file1) {
      const dataSave = await addImage(file1, existingArea.name, useToke)
      if (dataSave.url) {
        // eslint-disable-next-line require-atomic-updates
        existingArea.image = dataSave.url
      }
    }

    if (file2) {
      const dataSave = await addImage(file2, existingArea.name, useToke)
      if (dataSave.url) {
        // eslint-disable-next-line require-atomic-updates
        existingArea.electricalDrawing = dataSave.url
      }
    }

    const { data } = await axios.put(siteUrl, existingArea, authHeader(useToke))
    let area = null

    if (!data || !data.success || !data.area) {
      throw (data && data.error) || "Failed to fetch"
    }

    area = data.area
    let info = { description: "AREA_MODIFICATION_DONE" }
    dispatch(displayInfo(info))

    dispatch(areaSelected(area))
    return Promise.resolve(dispatch(areaModifyReceived(area)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(areaApiFailed(errorReply)))
  }
}

export const ModifyAreaAttachments = (existingArea, file1, file2) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(areaModifyRequested())

    let siteUrl = getApiUrl() + "/api/areas/" + existingArea._id

    const state = getState()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    if (file1) {
      const dataSave = await addImage(file1, existingArea.name, useToke)
      if (dataSave.url) {
        // eslint-disable-next-line require-atomic-updates
        existingArea.measurementReport = dataSave.url
      }
    }

    if (file2) {
      const dataSave = await addImage(file2, existingArea.name, useToke)
      if (dataSave.url) {
        // eslint-disable-next-line require-atomic-updates
        existingArea.commissioningMinutes = dataSave.url
      }
    }

    const { data } = await axios.put(siteUrl, existingArea, authHeader(useToke))
    let area = null

    if (!data || !data.success || !data.area) {
      throw (data && data.error) || "Failed to fetch"
    }

    area = data.area

    dispatch(areaSelected(area))
    return Promise.resolve(dispatch(areaModifyReceived(area)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(areaApiFailed(errorReply)))
  }
}

export const DeleteArea = existingArea => async (dispatch, getState) => {
  try {
    dispatch(areaDeleteRequested())

    let siteUrl = getApiUrl() + "/api/areas/" + existingArea

    const state = getState()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    const { data } = await axios.delete(siteUrl, authHeader(useToke))

    if (!data || !data.success) {
      throw (data && data.error) || "Failed to fetch"
    }

    let info = { description: "AREA_DELETED" }
    dispatch(displayInfo(info))

    return Promise.resolve(dispatch(areaDeleteReceived(existingArea)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(areaApiFailed(errorReply)))
  }
}

/**
 * Delete image from Blob storage and remove image reference from area
 */
export const deleteImage = (imagename, existingArea, whichImage) => async (
  dispatch,
  getState
) => {
  try {
    // dispatch(areaDeleteRequested())

    let siteUrl = getApiUrl() + "/api/image/" + imagename

    const state = getState()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    /** Remove image from Blob storage */
    const { dataImage } = await axios.delete(siteUrl, authHeader(useToke))
    console.log("action response", dataImage)
    /**
     * Do not throw error at this point if image does not exist
     * because if there is a reference to the image it won't be removed
     */

    /** Remove image reference from existingArea */
    siteUrl = getApiUrl() + "/api/areas/" + existingArea._id

    const updatedArea = { _id: existingArea._id }
    if (whichImage === "selectedFile") {
      updatedArea.image = null
    }
    if (whichImage === "selectedFile2") {
      updatedArea.electricalDrawing = null
    }
    if (whichImage === "measurementReport") {
      updatedArea.measurementReport = null
    }
    if (whichImage === "commissioningMinutes") {
      updatedArea.commissioningMinutes = null
    }

    const { data } = await axios.put(siteUrl, updatedArea, authHeader(useToke))
    let area = null

    if (!data || !data.success || !data.area) {
      throw (data && data.error) || "Failed to fetch"
    }

    area = data.area
    dispatch(areaSelected(area))

    return Promise.resolve(data)
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(errorReply)
  }
}

const requestAreaUpgrade = id => ({
  type: "SITE_DELETE_REQUESTED",
  id
})

const ajaxSuccess = () => ({ type: "SITE_AJAX_SUCCESS" })

const ajaxError = error => ({
  type: "SITE_AJAX_ERROR",
  error
})
const errorHandler = (err, response = "Internal server error") => {
  //  if ((err && err.status === 401) || /401/.test(err)) return authError()

  const error =
    (err && err.message) || (err.toString && err.toString()) || response
  return ajaxError(error)
}
export const upgradeArea = id => async dispatch => {
  try {
    dispatch(requestAreaUpgrade(id))
    let todochangename
    const { data } = await axios.patch(
      `/api/sites/${id}/upgrade`,
      todochangename
    )

    if (!data || !data.success)
      throw (data && data.error) || "Failed to delete todochangename"

    dispatch(ajaxSuccess())
    dispatch(fetchAreas())
  } catch (err) {
    dispatch(errorHandler(err, "Failed to upgrade todochangename"))
  }
}

export const FilterArea = (site, floor) => async (dispatch, getState) => {
  const state = getState()
  let area = state.areas.areaOpen

  area = state.areas.areaList.find(
    area => area.site === site && area.floor === floor
  )
  if (!area) {
    area = state.areas.areaList.find(area => area.site === site)
  }
  dispatch(FormHouse(site))

  if (area) dispatch(areaSelected(area))
  else {
    if (site !== "landing") {
      dispatch(AddArea(site, "default_area", "default", floor))
    }
  }
}

export const updateOpenArea = updatedArea => async (dispatch, getState) => {
  const state = getState()

  const selectedArea = state.areas.areaList.find(
    area => area._id === updatedArea
  )
  if(!selectedArea && updatedArea !== "area"){
    dispatch(floorSelected(updatedArea))
  }
  else{
    dispatch(floorSelected(undefined))

  }
  dispatch(areaSelected(selectedArea))
}

export const UpdateAreaCoordinates = (area, x, y) => async (dispatch) => {
  const updatedArea = {...area, pos: {x, y}}
  dispatch(ModifyArea(updatedArea))
}

export const FormHouse = siteID => async (dispatch, getState) => {
  const state = getState()
  const { iotDevices, areas } = state
  let newArrange = []
  const areaList = areas.areaList.filter(area => area.site === siteID)
  let exist = false
  let error = false
  let unplaced = false
  areaList.forEach(area => {
    error = false
    unplaced = false
    exist = true
    iotDevices.iotDeviceList.forEach(light => {
      if (light.area === area._id || (light.site === siteID && !light.area)) {
        if (!light.pos || !light.pos.x || !light.pos.y) {
          unplaced = true
        }
        if (light.status !== "OK") {
          error = true
        }
      }
    })
    if (area.floor) {
      newArrange[area.floor - 1] = {
        exists: exist,
        error:
          newArrange[area.floor - 1] && newArrange[area.floor - 1].error
            ? newArrange[area.floor - 1].error
            : error,
        unplaced:
          newArrange[area.floor - 1] && newArrange[area.floor - 1].unplaced
            ? newArrange[area.floor - 1].unplaced
            : unplaced
      }
    }
  })
  let i
  for (i = 0; i < newArrange.length; i++) {
    if (!newArrange[i]) {
      newArrange[i] = { exists: false, error: false, unplaced: false }
    }
  }
  dispatch(floorsFormed(newArrange.reverse()))
}
