import Radio from "@material-ui/core/Radio"
import React from "react"
// Custom radio button based on ui sketch
const CustomRadio = props => {
  const { outer, inner } = props
  return (
    <Radio
      checkedIcon={
        <span className={outer}>
          <div className={inner}></div>
        </span>
      }
      icon={<span className={outer} />}
      {...props}
    />
  )
}
export default CustomRadio
