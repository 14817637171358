import React, { useRef, useState } from "react"
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap"

const GroundplanMaster = props => {
  const {
    children,
    style,
    imageUrl,
    areas,
    disabled,
    readOnly,
    scrolling,
    scale,
    deviceMoving,
    onDeviceRelease,
    onAreaRelease
  } = props

  const [anchorX, setAnchorX] = useState(0)
  const [anchorY, setanchorY] = useState(0)
  const [x, setX] = useState(0)
  const [y, setY] = useState(0)
  const [panX, setPanX] = useState(0)
  const [panY, setPanY] = useState(0)
  const [pressing, setPressing] = useState(false)
  const [areaMoving, setAreaMoving] = useState(null)

  const svgRef = useRef()
  const controlRect = useRef() // Used to calculate new position for moved luminaire.

  const handleXYdim = evt => {
    let x
    let y

    const { touches, changedTouches } = evt

    if (touches && touches.length > 0) {
      x = touches[0].clientX
      y = touches[0].clientY
      // In case of touchEnd event we don't have any items in touches array. Instead
      // we have to use changedTouches array where last position is saved.
    } else if (changedTouches && changedTouches.length > 0) {
      // touchEnd event is catched in luminaire container (<g> element) because
      // the event does not bubble to siblings, which control rect is.
      const dim = controlRect.current.getBoundingClientRect()

      x = changedTouches[0].clientX - dim.left
      y = changedTouches[0].clientY - dim.top
    } else {
      const svgRoot = evt.target.farthestViewportElement
      const dim = svgRoot.getBoundingClientRect()
      x = evt.clientX - dim.left
      y = evt.clientY - dim.top
    }

    return { x, y }
  }

  const handleDown = event => {
    const xy = handleXYdim(event)
    setAnchorX(handleScaleFix(xy.x) - x)
    setanchorY(handleScaleFix(xy.y) - y)
    setPressing(true)
  }

  const handlePanDown = (event, area) => {
    setAreaMoving(area)
  }

  const handleUp = event => {
    const xy = handleXYdim(event)
    const fixedX = (handleScaleFix(xy.x) - (x + 50)) / scale
    const fixedY = (handleScaleFix(xy.y) - (y + 50)) / scale
    if (deviceMoving) {
      onDeviceRelease(
        fixedX,
        fixedY
      )
    } 
    else if (areaMoving){
      setAreaMoving(false)
      setPressing(false)
      onAreaRelease(
        areaMoving,
        fixedX,
        fixedY
      )
    }
    else {
      setPressing(false)
    }
  }

  const handleDrag = event => {
    if (pressing) {
      const xy = handleXYdim(event)
      setX(handleScaleFix(xy.x) - anchorX)
      setY(handleScaleFix(xy.y) - anchorY)
    }
    if (areaMoving){
      const xy = handleXYdim(event)
      const fixedX = (handleScaleFix(xy.x) - (x + 50)) / scale
      const fixedY = (handleScaleFix(xy.y) - (y + 50)) / scale
      setPanX(fixedX)
      setPanY(fixedY)
    }
  }

  const handleScaleFix = value => {
    // dimension scale fixes, second value is for firefox
    const dimW =
      svgRef.current.clientWidth || svgRef.current.parentNode.clientWidth
    const dimH =
      svgRef.current.clientHeight || svgRef.current.parentNode.clientWidth
    let dim
    if (dimW > dimH) {
      dim = dimH
    } else {
      dim = dimW
    }
    return (value / dim) * 100
  }

  return (
    <svg
      id="groundplan-svg"
      style={style}
      viewBox="-50 -50 100 100"
      ref={svgRef}
    >
      <g
        id="groundplan-image-element"
        transform={
          "translate(" + x + ", " + y + ") scale(" + scale + " " + scale + ")"
        }
      >
        <image xlinkHref={imageUrl} x="-50" y="-50" height="100" width="100" />
        {areas && areas.map((area) => 
          <image xlinkHref={area.image} key={area._id} x={-50 + (area?.pos?.x || 0)} y={-50 + (area?.pos?.y  || 0)} height="100" width="100" />
        )}

        {areaMoving  && (<image xlinkHref={areaMoving.image} x={-50 + panX} y={-50 + panY} height="100" width="100" />)}
      </g>
      <rect
        ref={controlRect}
        style={{
          stroke: "transparent",
          fill: "#fff",
          fillOpacity: "0",
          strokeOpacity: "0.5"
        }}
        x="-50"
        y="-50"
        width="100"
        height="100"
        onTouchMove={disabled || readOnly ? null : handleDrag}
        onTouchStart={disabled || readOnly ? null : handleDown}
        onTouchEnd={disabled || readOnly ? null : handleUp}
        onMouseMove={disabled || readOnly ? null : handleDrag}
        onMouseUp={disabled || readOnly ? null : handleUp}
        onMouseDown={disabled || readOnly ? null : handleDown}
        onMouseLeave={disabled || readOnly || deviceMoving ? null : handleUp}
        onWheel={disabled || readOnly ? null : scrolling}
      />

      <g
        id="groundplan-image-element"
        transform={
          "translate(" + x + ", " + y + ") scale(" + scale + " " + scale + ")"
        }
      >


        {areas && areas.map((area) => 
          <g 
            style={{pointerEvents: "bounding-box"}}
            onTouchStart={(e)=> {handlePanDown(e, area)}}
            onMouseDown={(e)=> {handlePanDown(e, area)}}
            onMouseUp={(e)=> {handleUp(e)}}
            onTouchEnd={(e)=> {handleUp(e)}}
            key={area._id + "move"} 
            x={-3 + (area?.pos?.x || 0)} 
            y={-3 + (area?.pos?.y  || 0)} 
            height="6" 
            width="6"
          >
            <ZoomOutMapIcon 
              style={{color: "black", "&:hover": {cursor: "grabbing"}}}
              height="6" 
              width="6"
              x={-3 + (area?.pos?.x || 0)} 
              y={-3 + (area?.pos?.y  || 0)} 
            />
          </g>
        )}
      </g>
      {/* We have to put the onTouchEnd handler here because the touch event won't propagate to siblings in DOM tree... 
            Instead it bubbles up to parent. */}
      <g
        transform={
          "translate(" + x + ", " + y + ") scale(" + scale + " " + scale + ")"
        }
      >
        {children}
      </g>
    </svg>
  )
}

export default GroundplanMaster
