import * as React from "react"
import InputAdornment from "@material-ui/core/InputAdornment"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"

import VisibilityIcon from "@material-ui/icons/Visibility"
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { tr } from "translations"
import TextLine from "../../../components/TextLine"
import { platformBlue, platformWhite } from "../../../services/colors"
import { checkUser, trialActivate } from "../../../actions/registration"

class UserSub extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: "",
      password: "",

      firstName: "",
      lastName: "",
      phoneNumber: "",

      error: null,
      isSending: false,
      submitSuccessful: false,
      info: false,
      infoText: "",

      showPassword: false,
      numberValid: true
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleEnd = this.handleEnd.bind(this)
    this.validateNumber = this.validateNumber.bind(this)
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this)

  }

  handleChange({ target }) {
    this.setState({ [target.name]: target.value })
  }

  validateNumber({ target }) {
    let valid = true
    if (target.value != "" && target.value != undefined) {
      let rgp = /^\+[ ]?([0-9]{1,3})[- ]?([0-9]{1,3})([- ]?([0-9]{1,10}))*$/
      if (!target.value.match(rgp)) {
        valid = false
      }
    }
    this.setState({ phoneNumber: target.value, numberValid: valid })
  }

  handleClickShowPassword() {
    this.setState(state => ({
      showPassword: !state.showPassword
    }))
  }

  async handleEnd() {
    const { username, password, firstName, lastName, phoneNumber } = this.state
    const value = {
      username,
      password,
      firstName,
      lastName,
      phoneNumber
    }
    const { org, site } = this.props
    this.props
      .trialActivate(this.props.match.params.token, value, org, site)
      .then(() => {
        this.setState({
          submitSuccessful: true
        })
        setTimeout(() => {
          this.props.history.push("/login")
        }, 5000)
      })
      .catch(err => {
        console.log("Error", err)
        this.setState({
          info: true,
          infoText: "trialTokenNotFound.",
          submitSuccessful: false
        })
      })
  }

  render() {
    const { classes } = this.props
    const {
      username,
      password,
      firstName,
      lastName,
      phoneNumber,
      isSending,
      numberValid,
      submitSuccessful,
      info,
      infoText,
      showPassword
    } = this.state
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          className={classes.signupForm}
          spacing={3}
        >
          <Grid item className={classes.headerItem}>
            <Typography
              color="primary"
              variant={"h5"}
              className={classes.labelText}
              align="left"
            >
              {tr("_trialregisterheadlinestep1")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography color="primary" variant={"body2"}>
              {tr("_trialregistrationUserDesc")}
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              id="username"
              name="username"
              label={tr("_username")}
              className={classes.textInput}
              value={username}
              onChange={this.handleChange}
              InputProps={{
                classes: {
                  input: classes.inputColor
                }
              }}
              InputLabelProps={{
                classes: {
                  root: classes.labelColor,
                  focused: classes.labelColor,
                  error: classes.labelColor
                }
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item>
            <TextField
              name="password"
              label={tr("_password")}
              className={classes.textInput}
              value={password}
              type={showPassword ? "text" : "password"}
              onChange={this.handleChange}
              onKeyUp={this.onHandleReturn}
              variant="outlined"
              InputProps={{
                classes: {
                  input: classes.inputColor
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </Button>
                  </InputAdornment>
                )
              }}
              InputLabelProps={{
                classes: {
                  root: classes.labelColor,
                  focused: classes.labelColor,
                  error: classes.labelColor
                }
              }}
            />
          </Grid>

          <Grid item>
            <TextField
              id="firstName"
              name="firstName"
              label={tr("_firstname")}
              className={classes.textInput}
              value={firstName}
              onChange={this.handleChange}
              InputProps={{
                classes: {
                  input: classes.inputColor
                }
              }}
              InputLabelProps={{
                classes: {
                  root: classes.labelColor,
                  focused: classes.labelColor,
                  error: classes.labelColor
                }
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item>
            <TextField
              id="lastName"
              name="lastName"
              label={tr("_lastname")}
              className={classes.textInput}
              value={lastName}
              onChange={this.handleChange}
              InputProps={{
                classes: {
                  input: classes.inputColor
                }
              }}
              InputLabelProps={{
                classes: {
                  root: classes.labelColor,
                  focused: classes.labelColor,
                  error: classes.labelColor
                }
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item>
            <TextField
              id="outlined-phoneNumber"
              label={tr("_phone")}
              name="phoneNumber"
              className={classes.textInput}
              value={phoneNumber}
              onChange={this.validateNumber}
              variant="outlined"
              InputProps={{
                classes: {
                  input: classes.inputColor
                }
              }}
              InputLabelProps={{
                classes: {
                  root: classes.labelColor,
                  focused: classes.labelColor,
                  error: classes.labelColor
                }
              }}
            />
          </Grid>

          <Grid item>
            <Button
              disabled={
                !password ||
                !username ||
                !numberValid ||
                isSending ||
                submitSuccessful
                  ? true
                  : false
              }
              className={classes.buttonPadding}
              onClick={this.handleEnd}
            >
              <Typography variant="h6" /*color={platformWhite}*/>
                {tr("_activateTrial").toUpperCase()}
              </Typography>
            </Button>
          </Grid>

          {submitSuccessful && (
            <Grid item>
              <Typography color="primary" variant="h6">
                {tr("_trialregistrationInProgress2")}
              </Typography>
            </Grid>
          )}

          {info && (
            <TextLine
              mode={0}
              text={infoText}
              onClick={this.onInfoTextLinePress}
            />
          )}
        </Grid>
      </Grid>
    )
  }
}

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  dense: {
    marginTop: 16
  },
  menu: {
    width: 200
  },
  root: {
    flexGrow: 1,
    width: "100vw",
    height: "100vh",
    backgroundImage: theme.loginBackground,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed"
  },
  headerItem: {
    width: 400
  },
  labelText: {
    fontWeight: "bold",
    letterSpacing: "3px"
  },
  labelTextSecondary: {
    fontWeight: "bold",
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  textInput: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
    backgroundColor: "#FFFFFF"
  },
  padding: {
    paddingTop: 10,
    paddingBottom: 10
  },
  buttonPadding: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: "10%",
    paddingRight: "10%",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
    textTransform: "capitalize",
    color: platformWhite,
    // background: "linear-gradient(to right, #46c77f, #18ba60)",
    backgroundColor: platformBlue,
    "&:hover": {
      backgroundColor: platformBlue
    }
  },
  inputColor: {
    color: "black"
  },
  labelColor: {
    color: "gray"
  },
  underline: {
    borderBottom: "1px solid black"
  },
  customTextField: {
    border: "0px"
  }
})

const mapDispatchToProps = dispatch => ({
  checkUser: (user, token) => dispatch(checkUser(user, token)),
  trialActivate: (token, user, org, site) =>
    dispatch(trialActivate(token, user, org, site))
})

const mapStateToProps = () => {
  return {}
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles, { withTheme: true })(UserSub))
)
