import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import Grid from "@material-ui/core/Grid"
import useTheme from "@material-ui/core/styles/useTheme"
import withStyles from "@material-ui/core/styles/withStyles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import GoogleMapReact from "google-map-react"
import { FilterArea } from "../actions/areas"
import DeviceDetail from "../components/GroundPlan/DeviceDetail"
import EditDevice from "../components/GroundPlan/EditDevice"

import { updateOpenSite } from "../actions/sites"
import { ModifyIoTDevices } from "../actions/iotDevices"
import MapMarkerPoint from "../components/MapView/MapMarkerPoint"
import MapDeviceMarker from "../components/MapView/MapDeviceMarker"
import MapSide from "../components/MapView/MapSide"
import MapDeviceList from "../components/MapView/MapDeviceList"
import { getLanguage } from "../translations"
import { tr } from "translations"

// default center location
const defaultCenter = { lat: 61.44949152877146, lng: 23.85264764645217 }

// default Zoom level
const zoom = 11

const MapScreen = props => {
  const { classes, history } = props

  const [selected, setSelected] = useState(null)
  const [open, setOpen] = useState(false)
  const [device, setDevice] = useState(null)
  const [selectedMarker, setSelectedMarker] = useState(null)
  const [center, setCenter] = useState(defaultCenter)
  const [alertSiteList, setAlertSiteList] = useState([])
  const [warningSiteList, setWarningSiteList] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const [deviceInfoOpen, setDeviceInfoOpen] = useState(false)
  const [editDeviceOpen, setEditDeviceOpen] = useState(false)
  const [map, setMap] = useState(null)
  const [rectangle, setRectangle] = useState(null)
  const [editRectangle, setEditRectangle] = useState(false)
  const [hoveringDeviceId, setHoveringDeviceId] = useState(false)

  // redux states
  const iotDevices = useSelector(state => state.iotDevices)
  const sites = useSelector(state => state.sites)
  
  const customerItem = useSelector(state => state.customers.customer)
  const customerListCustomer = useSelector(state => state.customers.customerList[0]) 
  const customer = customerItem ? customer : customerListCustomer
  const logo = customer?.logo
  const showDevicesOnMap = customer?.showDevicesOnMap

  const deviceDataStore = useSelector(
    (state) => state.dataUpdate.deviceDataStore || {}
  )

  const theme = useTheme()
  const dispatch = useDispatch()

  // adjust mapside box size on breakpoint
  const matches = useMediaQuery(theme.breakpoints.down("md"))

  let sideWidth = 350
  let sideHeight = "auto"
  let iconSize = 60

  useEffect(() => {

    let oysterIds = []
    iotDevices.iotDeviceList.forEach(dev => {
      if (dev && dev.deviceId && dev.deviceType) {
        if(dev.deviceType === "Oyster3"){
          oysterIds.push(dev.deviceId)
        }
      }
    })
    let { getDataUpdatesArray } = require("../devicetypes/Oyster3/actions")
    dispatch(getDataUpdatesArray(oysterIds))
    
    
  
  }, [ dispatch])


  useEffect(() => {
    if (sites.siteList && sites.siteList.length !== 0) {
      setCenter(sites.siteList[0].pos)
    }
  }, [sites])
  useEffect(() => {
    if (sites.siteList && sites.siteList.length !== 0) {
      const alertSites = sites.siteList.filter(site => {
        let alerts = iotDevices.iotDeviceList.filter(
          device => device.status === "FAULT" && device.site === site._id
        )
        return alerts.length > 0
      })
      setAlertSiteList(alertSites)
      //eslint-disable-next-line
      const warningSites = sites.siteList.filter((/*site*/) => {
        let warnings = iotDevices.iotDeviceList.filter(
          device =>
            !device.area || !device.pos || !device.pos.x || !device.pos.y // && device.site === site._id
        )
        return warnings.length > 0
      })
      setWarningSiteList(warningSites)
    }
  }, [iotDevices, sites])

  useEffect(() => {
    if(device){
      let newDevice = iotDevices.iotDeviceList.find(item => item.deviceId === device.deviceId)
      setDevice(newDevice)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iotDevices])

  if (matches) {
    iconSize = 45
    sideWidth = 325
    sideHeight = 100
  }

  const siteAlerts = siteId => {
    const alertDevices = iotDevices.iotDeviceList.filter(
      device => device.status === "FAULT" && device.site === siteId
    )
    return alertDevices
  }

  const siteWarnings = siteId => {
    const warningDevices = iotDevices.iotDeviceList.filter(
      device =>
        (!device.area || !device.pos || !device.pos.x || !device.pos.y) &&
        device.site === siteId
    )
    return warningDevices
  }

  const siteDevices = siteId => {
    const allDevices = iotDevices.iotDeviceList.filter(
      device => device.site === siteId
    )
    return allDevices
  }

  const handleApiLoaded = (map) => {
    setMap(map)
  }

  const getTooltip = (deviceId) => {
    if(deviceId === hoveringDeviceId){
      if(editRectangle){
        return tr("_click_to_edit_device")
      }
      else{
        return tr("_click_to_edit_geo_borders")
      }
    }
    else{
      return null
    }

  }

  const infoClose = () => {
    setAnchorEl(null)
    setDeviceInfoOpen(false)
    setEditDeviceOpen(false)
    hideBorders()
  }

  const handleSite = siteId => {
    dispatch(updateOpenSite(siteId))
    dispatch(FilterArea(siteId, 1))
    history.push("/groundplan/" + encodeURI(siteId))
  }

  const handleDialog = siteId => {
    if (siteId) {
      dispatch(updateOpenSite(siteId))
      dispatch(FilterArea(siteId, 1))
      setSelected(siteId)
    }
    setOpen(!open)
  }

  const handleDeviceClick = (e, nodeId) => {
    let deviceList = iotDevices.iotDeviceList
    const newDevice = deviceList.find(item => item.deviceId === nodeId)
    setAnchorEl(e.currentTarget)
    setDevice(newDevice)
    if(!newDevice.showGeoBorders){
      setDeviceInfoOpen(true)
    }
    else if(!editRectangle){
      setEditRectangle(true)
    }
    else{
      setEditRectangle(false)
      hideBorders()
      setDeviceInfoOpen(true)
    }
  }

  const handleSelectedMarker = (siteId, sitePos = null) => {
    hideBorders()
    setSelectedMarker(siteId)
    setCenter(sitePos)
  }

  const deviceMarkerHoverIn = (deviceDetails) => {
    if(editRectangle){
      return
    }
    if(!deviceDetails.showGeoBorders){
      return
    }
    // eslint-disable-next-line no-undef
    const bounds = new google.maps.LatLngBounds(
      {lat: deviceDetails.geoBorders.sw.lat , lng: deviceDetails.geoBorders.sw.lng},
      {lat: deviceDetails.geoBorders.ne.lat , lng: deviceDetails.geoBorders.ne.lng})
    // eslint-disable-next-line no-undef
    const newRectangle = new google.maps.Rectangle({
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      map,
      bounds,
      editable: true,
    })
    setHoveringDeviceId(deviceDetails.deviceId)
      
    newRectangle.addListener("bounds_changed", () => {
      boundsChanged(newRectangle, deviceDetails)

    })
    setRectangle(newRectangle)
  }

  const deviceMarkerHoverOut = () => {
    if(!editRectangle){
      hideBorders()

    }
  }

  const boundsChanged = (newRectangle, theDevice) => {
    // setRect etc. works async, we need to use fresh variable from func parameters
    let bounds = newRectangle.getBounds()
    let ne = bounds.getNorthEast()
    let sw = bounds.getSouthWest()
    const modifiedDevice = {
      ...theDevice,
      geoBorders: {
        ne: {lat: ne.lat(), lng: ne.lng()},
        sw: {lat: sw.lat(), lng: sw.lng()}
      }
    }
    dispatch(ModifyIoTDevices(modifiedDevice))
  }

  const hideBorders = () => {
    setHoveringDeviceId(null)
    setEditRectangle(false)
    if(rectangle){
      rectangle.setMap(null)
    }
  }

  return (
    <Grid container className={classes.root}>
      {device && (
        <DeviceDetail
          isOpen={deviceInfoOpen}
          anchorEl={anchorEl}
          details={device}
          onClose={infoClose}
          handleEdit={() => {
            setDeviceInfoOpen(false)
            setEditDeviceOpen(true)
          }}
        />
      )}
      {device && editDeviceOpen && (
        <EditDevice
          isOpen={editDeviceOpen}
          device={device}
          onClose={infoClose}

        />
      )}
      <Grid item xs={12} style={{ height: "calc(100vh - 64px)" }}>
        {getLanguage && (
          <GoogleMapReact
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            bootstrapURLKeys={{
              key: "AIzaSyCJZ8GVpYSp-vBJDi33twWZUTJDTKGSgVc",
              language: getLanguage
            }}
            options={{clickableIcons: false}}
            defaultCenter={defaultCenter}
            defaultZoom={zoom}
            hoverDistance={60}
            onClick={handleSelectedMarker}
            center={center}
          >
            {deviceDataStore && showDevicesOnMap &&
              Object.keys(deviceDataStore).map((nodeId) => {
                const node = deviceDataStore[nodeId]
                if(!node || !node.length){
                  return null
                }
                  
                const latestData = node[node.length -1]
                if(!latestData.location){

                  return null
                }
                  
                const deviceDetails = iotDevices.iotDeviceList.find(device => device.deviceId === nodeId)
                return (
                    
                  <MapDeviceMarker
                    key={nodeId}
                    lat={latestData.location.lat}
                    lng={latestData.location.lng}
                    name={deviceDetails.name}
                    latestData={latestData}
                    onMouseEnter={() => deviceMarkerHoverIn(deviceDetails)}
                    onMouseLeave={deviceMarkerHoverOut}
                    clicked={(e) => handleDeviceClick(e, nodeId)}
                    tooltip={getTooltip(deviceDetails.deviceId)}

                    iconSize={iconSize}
                  />

                )
              })}
            {sites.siteList &&
              sites.siteList.map((site, idx) => {
                const alerted = siteAlerts(site._id).length > 0
                const warned = siteWarnings(site._id).length > 0
                return (
                  <MapMarkerPoint
                    key={"site-" + idx}
                    lat={site.pos ? site.pos.lat : 0}
                    lng={site.pos ? site.pos.lng : 0}
                    site={site}
                    logo={logo}
                    unplaced={warned}
                    alerts={alerted}
                    selectMarker={handleSelectedMarker}
                    selected={selectedMarker === site._id}
                    iconSize={iconSize}
                  />
                )
              })}
          </GoogleMapReact>
        )}
      </Grid>
      <div
        style={{
          position: "absolute",
          left: 0,
          maxWidth: sideWidth,
          marginLeft: 5
        }}
      >
        <MapSide
          sites={sites.siteList}
          sideWidth={sideWidth}
          sideHeight={sideHeight}
          openSite={handleSite}
          handleDialog={handleDialog}
          alertSiteList={alertSiteList}
          warningSiteList={warningSiteList}
          siteDevices={siteDevices}
          selectedMarker={selectedMarker}
          setMarker={handleSelectedMarker}
          iconSize={iconSize}
          logo={logo}
        />
      </div>
      {open && (
        <MapDeviceList
          open={open}
          handleClose={() => handleDialog()}
          selected={selected}
        />
      )}
    </Grid>
  )
}

const styles = () => ({
  root: {
    flexGrow: 1
  }
})

export default withStyles(styles)(MapScreen)
