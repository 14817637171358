import React, { useEffect, useState } from "react"
import { HashRouter as Router, Route, Switch, Redirect } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import ReactTooltip from "react-tooltip"

import withStyles from "@material-ui/core/styles/withStyles"
import CssBaseline from "@material-ui/core/CssBaseline"
import Snackbar from "@material-ui/core/Snackbar"
import IconButton from "@material-ui/core/IconButton"
import useTheme from "@material-ui/core/styles/useTheme"
import CloseIcon from "@material-ui/icons/Close"

import { tr } from "translations"
import { clearError, clearInfo } from "./actions/ui"
import AppBarWithLogo from "./components/AppBarWithLogo"
import ErrorBoundary from "./components/ErrorBoundary"
import ErrorView from "./containers/Error"
import GroundPlanScreen from "./containers/GroundPlanScreen"
import LoginScreen from "./containers/LoginScreen"
import LogoutScreen from "./containers/LogoutScreen"
import TrialCodeScreen from "./containers/TrialCodeScreen"
import TrialActivationView from "./containers/TrialActivationView"
import ManageView from "./containers/ManageView"
import MapScreen from "./containers/MapScreen"
import PrivateRoute from "./components/PrivateRoute"
import Register from "./containers/Register"
import RegisterForgot from "./containers/RegisterForgot"
import ThemeWrapper from "./components/ThemeWrapper"
import Dashboard from "./containers/Dashboard"
import DeviceList from "./containers/DeviceList"
import TrialSnackbar from "./components/TrialSnackbar"
import { setLanguage } from "./translations"
import { views } from "./services/views"
import { webSocketConnect } from "./actions/websocket"
import { useIntervalWithDependencies } from "./services/customHooks"
import { conditionalFlushDataBuffer } from "./actions/dataUpdate"
const App = props => {
  const { classes, token } = props

  const [isDarkTheme, setIsDarkTheme] = useState(true)

  const error = useSelector(state => state.ui.error)
  const info = useSelector(state => state.ui.info)
  const language = useSelector(state => state.ui.language)
  const user = useSelector(state => state.users.user)
  const currentTheme = useSelector(state => state.ui.currentTheme === "dark")
  const { wsConnecting, wsConnected } = useSelector(state => state.websocket)
  const lastFlushTime = useSelector(state => state.dataUpdate.lastFlushTime)
  const theme = useTheme()
  const dispatch = useDispatch()


  // View rotation for salesperson, default 20 sec interval
  
  useEffect(() => {
    if(!user){
      return
    }
    const timeInterval = user.rotationInterval ? user.rotationInterval * 1000 : 20000 
    const interval = setInterval(() => {
      if(!user.rotateViews){
        return
      }
      
      // Views to rotate
      let urls = []
      if(user.views){
        for(let view of Object.keys(views)){
          if(user.views.includes(view)){
            urls.push(views[view])
          }
        }
      }
      else{
        urls = [Object.values(views)]
      }

      let newUrl = null
      const currentUrl = location.href
      for(let i = 0; i < urls.length; i++ ){
        if(currentUrl.includes(urls[i])){
          if(i === urls.length -1){
            // In last url of the array
            newUrl = urls[0]
          }
          else{
            newUrl = urls[i+1]
          }
          break
        }
      } 
      if(newUrl){
        window.location.replace(newUrl)
      }
    }, timeInterval)

    return () => clearInterval(interval)
  }, [user]) // End view rotation

  useEffect(() => {
    setLanguage(language)
  }, [language])

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    if (error) {
      dispatch(clearError())
    }
    if (info) {
      dispatch(clearInfo())
    }
  }

  useEffect(() => {
    if (!wsConnecting && !wsConnected) {
      dispatch(webSocketConnect())
    }
  }, [wsConnecting, wsConnected, dispatch])

  // Handle case where slow websocket updates sometimes cause too long delay for
  // buffer flush.
  const delay = 1000
  useIntervalWithDependencies(
    () => {
      conditionalFlushDataBuffer(dispatch, lastFlushTime)
    },
    delay,
    [delay, dispatch, lastFlushTime]
  )

  return (
    <ThemeWrapper isDark={currentTheme}>
      <div className="App">
        <CssBaseline />
        <Router>
          <div className={classes.root}>
            <ErrorBoundary onError={ErrorView}>
              {token ? (
                <AppBarWithLogo isDark={isDarkTheme} setDark={setIsDarkTheme} />
              ) : null}

              <main className={classes.content}>
                <Switch>
                  <Route exact={true} path="/login" component={LoginScreen} />
                  <Route
                    exact={true}
                    path="/activate/:token"
                    component={TrialActivationView}
                  />
                  <Route
                    exact={true}
                    path="/trial"
                    component={TrialCodeScreen}
                  />
                  <Route
                    exact={true}
                    path="/trial/:token"
                    component={TrialCodeScreen}
                  />
                  <Route
                    exact={true}
                    path="/register/:token"
                    component={Register}
                  />
                  <Route
                    exact={true}
                    path="/register/forgot/:token"
                    component={RegisterForgot}
                  />

                  {// Disable map screen in local environment

                    process.env.LOCAL ? null : (
                      <PrivateRoute
                        isValid={token}
                        failPath="/login"
                        exact={true}
                        path="/map"
                        component={MapScreen}
                      />
                    )}

                  <PrivateRoute
                    isValid={token}
                    failPath="/login"
                    path="/groundplan/:id"
                    component={GroundPlanScreen}
                  />
                  <PrivateRoute
                    isValid={false}
                    exact={true}
                    failPath="/groundplan/landing"
                    path="/groundplan"
                    component={null}
                  />
                  <PrivateRoute
                    isValid={token}
                    failPath="/login"
                    path="/manage/:sub"
                    component={ManageView}
                  />
                  <PrivateRoute
                    isValid={token}
                    failPath="/login"
                    exact={true}
                    path="/logout"
                    component={LogoutScreen}
                  />
                  {process.env.DASHBOARD ? (
                    <PrivateRoute
                      isValid={token}
                      failPath="/login"
                      path="/dashboard/:id"
                      component={Dashboard}
                    />
                  ) : null}

                  <PrivateRoute
                    isValid={token}
                    failPath="/login"
                    exact={true}
                    path="/dashboard"
                    component={Dashboard}
                  />

                  <PrivateRoute
                    isValid={token}
                    failPath="/login"
                    // exact={true}
                    path="/devicelist/:sub?"
                    component={DeviceList}
                  />
                  <Redirect to="/login" />
                </Switch>

                {(error || info) && (
                  <Snackbar
                    anchorOrigin={{
                      horizontal: "left",
                      vertical: "bottom"
                    }}
                    open={error || info ? true : false}
                    autoHideDuration={5000}
                    onClose={handleClose}
                    ContentProps={{
                      "aria-describedby": "message-id"
                    }}
                    message={
                      error ? (
                        <span
                          style={{ color: theme.palette.error.main }}
                          id="message-id-1"
                        >
                          {error ? error.description : null}
                        </span>
                      ) : (
                        <span
                          style={{ color: theme.palette.primary.main }}
                          id="message-id-1"
                        >
                          {info ? tr(info.description) : null}
                        </span>
                      )
                    }
                    action={[
                      <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleClose}
                      >
                        <CloseIcon />
                      </IconButton>
                    ]}
                  />
                )}
                <TrialSnackbar />
              </main>
              <ReactTooltip />
            </ErrorBoundary>
          </div>
        </Router>
      </div>
    </ThemeWrapper>
  )
}

const styles = theme => ({
  close: {
    padding: theme.spacing(0.5)
  },
  content: {
    height: "calc(100vh - 64px)",
    flexGrow: 1
  },
  root: {
    height: "100%",
    minHeight: "100vh",
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    width: "100%"
  },
  toolbar: { ...theme.mixins.toolbar }
})

export default withStyles(styles, { withTheme: true })(App)
