import axios from "axios"
import { stateType } from "../constants/actions"
import { getApiUrl } from "../constants/config"
import { authHeader } from "./common"
import { parseErrorData } from "../services/errors"

const requestFailed = error => ({
  type: stateType.SET_ERROR,
  error
})

export const closeTrialMessage = () => ({
  type: stateType.TRIALTOKEN_CLOSETRIAL
})

/* const errorHandler = (err, response = "Internal server error") => {
  const error =
    (err && err.message) || (err.toString && err.toString()) || response
  return ajaxError(error)
}*/

//const ajaxSuccess = () => ({ type: "TRIALTOKEN_SUCCESS" })

/*const ajaxError = error => ({
  type: "TRIALTOKEN_ERROR",
  error
})*/

export const resetUserError = () => ({ type: "TRIALTOKEN_RESET_ERROR" })

const trialTokenRequested = () => ({
  type: "TRIALTOKEN_REQUESTED"
})

const trialTokenListReceived = trialTokens => ({
  type: "TRIALTOKENLIST_RECEIVED",
  trialTokens
})

export const fetchTrialTokens = () => async (dispatch, getState) => {
  const state = getState()
  try {
    dispatch(trialTokenRequested())

    let backendUrl = getApiUrl() + "/api/trialTokens"
    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    const { data } = await axios.get(backendUrl, authHeader(useToke))

    if (!data || !data.success)
      throw (data && data.error) || "Failed to fetch trialTokens"
    return Promise.resolve(dispatch(trialTokenListReceived(data.trialTokens)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(requestFailed(errorReply)))
  }
}

export const saveTrialToken = trialObj => async (dispatch, getState) => {
  const state = getState()
  try {
    dispatch(trialTokenRequested())

    let trialUrl = getApiUrl() + "/api/trialToken"

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    const { data } = await axios.post(
      trialUrl,
      { trialObj, quantity: 1 },
      authHeader(useToke)
    )

    if (!data || !data.success)
      throw (data && data.error) || "Failed to save token"

    return Promise.resolve({ success: "ok" })
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(requestFailed(errorReply)))
  }
}

export const saveTrialTokenBulk = (trialObj, quantity) => async (
  dispatch,
  getState
) => {
  const state = getState()
  try {
    dispatch(trialTokenRequested())

    let trialUrl = getApiUrl() + "/api/trialToken"
    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }
    const { data } = await axios.post(
      trialUrl,
      { trialObj, quantity: parseInt(quantity) },
      authHeader(useToke)
    )

    if (!data || !data.success)
      throw (data && data.error) || "Failed to save token bulk"

    return Promise.resolve({ success: "ok" })
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(requestFailed(errorReply)))
  }
}

export const deleteTrialToken = id => async (dispatch, getState) => {
  const state = getState()
  try {
    dispatch(trialTokenRequested())
    let trialUrl = getApiUrl() + `/api/trialToken/${id}`

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }
    const { data } = await axios.delete(trialUrl, authHeader(useToke))

    if (!data || !data.success)
      throw (data && data.error) || "Failed to delete trial token"

    return Promise.resolve(dispatch(fetchTrialTokens()))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(requestFailed(errorReply)))
  }
}

export const premiumTrialToken = id => async (dispatch, getState) => {
  const state = getState()
  try {
    dispatch(trialTokenRequested())
    let trialUrl = getApiUrl() + `/api/trialprerium/${id}`

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }
    const { data } = await axios.post(trialUrl, authHeader(useToke))

    if (!data || !data.success)
      throw (data && data.error) || "Failed to make custome premium"

    return Promise.resolve(dispatch(fetchTrialTokens()))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(requestFailed(errorReply)))
  }
}

export const expireTrialToken = id => async (dispatch, getState) => {
  const state = getState()
  try {
    dispatch(trialTokenRequested())
    let trialUrl = getApiUrl() + `/api/trialexpire/${id}`

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }
    const { data } = await axios.post(trialUrl, authHeader(useToke))

    if (!data || !data.success)
      throw (data && data.error) || "Failed to expire token"

    return Promise.resolve(dispatch(fetchTrialTokens()))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(requestFailed(errorReply)))
  }
}

export const addTrialDays = (id, days) => async (dispatch, getState) => {
  const state = getState()
  try {
    dispatch(trialTokenRequested())
    let trialUrl = getApiUrl() + `/api/trialextend/${id}`

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }
    const body = { days }
    const { data } = await axios.post(trialUrl, body, authHeader(useToke))

    if (!data || !data.success)
      throw (data && data.error) || "Failed to extend token"

    return Promise.resolve(dispatch(fetchTrialTokens()))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(requestFailed(errorReply)))
  }
}
