// not in use yet

export function deviceDataConvert(deviceObj) {
  return deviceObj
}

// returns values that aren't in list
export function handleWatchlistAdd(addition, list) {
  const result = []
  addition.forEach(row => {
    const idx = list.indexOf(row)
    if (idx == -1) {
      result.push(row)
    }
  })
  return result
}

// returns values that aren't in list anymore
export function handleWatchlistReduce(removal, listIN) {
  const list = [...listIN]
  const result = []
  removal.forEach(row => {
    const idx = list.indexOf(row)
    if (idx != -1) {
      list.splice(idx, 1)
    }
    const idx2 = list.indexOf(row)
    if (idx2 == -1) {
      result.push(row)
    }
  })
  return result
}
