import React from "react"

import ButtonBase from "@material-ui/core/ButtonBase"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"

import { tr } from "translations"
import CloseIcon from "@material-ui/icons/Close"
import {
  platformTextSecondary,
  platformLightTextPrimary
} from "../../services/colors"

const ConfirmationDialog = props => {
  const { onClose, onAccept, header, text, classes, fullScreen, isOpen } = props

  const handleSubmit = button => {
    switch (button) {
      case "accept":
        onAccept()
        break
      case "reject":
        onClose()
        break
    }
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      modal="true"
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
      className={classes.dialogRoot}
      fullWidth={true}
      maxWidth={"lg"}
    >
      <Grid container className={classes.container}>
        <Grid
          item
          xs={12}
          container
          justify="space-between"
          alignItems="center"
          className={classes.topicTextField}
        >
          <Typography variant="h5">{header}</Typography>
          <ButtonBase size="large" onClick={onClose}>
            <CloseIcon fontSize="large" className={classes.icon} />
          </ButtonBase>
        </Grid>
        <Grid item xs={12} className={classes.textField}>
          <Typography variant="h6">{text}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            className={classes.addButtonField}
            onClick={() => handleSubmit("accept")}
          >
            {tr("_accept")}
          </Button>
          <Button
            variant="outlined"
            className={classes.cancelButtonField}
            onClick={() => handleSubmit("reject")}
          >
            {tr("_cancel")}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

const styles = theme => ({
  buttonField: { float: "right", marginLeft: theme.spacing(4) },
  container: {
    backgroundColor: theme.palette.popup.background
  },
  dialogRoot: {
    minWidth: 500,
    maxWidth: 600,
    margin: "auto"
  },
  topicTextField: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(2),
    color: platformLightTextPrimary
  },
  textField: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(2),
    color: platformLightTextPrimary
  },
  cancelButtonField: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(1),
    float: "right",
    color: platformLightTextPrimary
  },
  addButtonField: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(2),
    backgroundColor: platformTextSecondary,
    float: "right"
  }
})

export default withStyles(styles)(ConfirmationDialog)
