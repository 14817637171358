export default (
  state = {
    presets: [],
    isFetching: false,
    error: null,
    shouldRefresh: false,
    dimmingLights: []
  },
  action
) => {
  switch (action.type) {
    case "PRESETS_REQUESTED":
    case "PRESET_ADD_REQUESTED":
    case "PRESET_MODIFY_REQUESTED":
    case "PRESET_DELETE_REQUESTED":
      return {
        ...state,
        isFetching: true,
        shouldRefresh: false
      }
    case "PRESET_MODIFY_RECEIVED": {
      const { presets } = state

      const newList = presets.map(preset => {
        if (preset._id === action.preset._id) {
          return action.preset
        } else {
          return preset
        }
      })

      return {
        ...state,
        isFetching: false,
        presets: newList,
        error: null,
        lastUpdated: Date.now()
      }
    }
    case "PRESET_ADD_RECEIVED": {
      const { presets } = state
      const newList = [...presets, action.preset]

      return {
        ...state,
        isFetching: false,
        presets: newList,
        error: null,
        lastUpdated: Date.now()
      }
    }
    case "PRESETS_RECEIVED":
      return {
        ...state,
        isFetching: false,
        shouldRefresh: false,
        presets: action.presets,
        lastUpdated: Date.now()
      }
    case "PRESETS_LIGHTS_RECEIVED":
      return {
        ...state,
        isFetching: false,
        shouldRefresh: false,
        dimmingLights: action.dimmingLights
      }
    case "PRESET_DELETE_SUCCESS": {
      const { presets } = state

      const newPresets = presets.filter(
        preset => preset._id !== action.presetId
      )

      return {
        ...state,
        isFetching: false,
        shouldRefresh: false,
        error: null,
        presets: newPresets
      }
    }
    case "PRESETS_FAILED":
    case "PRESET_ADD_FAILED":
    case "PRESET_MODIFY_FAILED":
    case "PRESET_DELETE_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    case "PRESET_REMOVE_DEVICE": {
      const { dimmingLights } = state
      const { deviceId } = action

      const selected = dimmingLights.filter(
        light => light.deviceId !== deviceId
      )

      return {
        ...state,
        dimmingLights: selected
      }
    }
    default:
      return state
  }
}
