import { stateType } from "../constants/actions"

export default (
  state = {
    token: null,
    user: null,
    admin: false,
    isFetching: false,
    role: 0,
    error: null,
    redirectUrl: null
  },
  { type, token, admin, error, role, redirectUrl, user }
) => {
  switch (type) {
    case stateType.TOKEN_REQUESTED:
      return { ...state, isFetching: true }

    case stateType.TOKEN_SUCCEEDED: {
      return {
        ...state,
        user,
        token,
        admin,
        role,
        isFetching: false,
        error: null
      }
    }
    case stateType.TOKEN_FAILED:
      return {
        ...state,
        isFetching: false,
        error
      }
    case stateType.TOKEN_RESET:
      return {
        token: null,
        user: null,
        admin: false,
        isFetching: false,
        role: 0,
        error: null
      }
    case stateType.AUTH_RESET_ERROR:
      return { ...state, error: null }

    case "SET_REDIRECT_URL":
      return {
        ...state,
        redirectUrl
      }

    default:
      return state
  }
}
