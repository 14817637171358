import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import Checkbox from "@material-ui/core/Checkbox"
import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"

import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { tr } from "translations"
import {
  fetchCustomers
} from "../../../actions/customers"
import { fetchSites, ModifySites } from "../../../actions/sites"
import { fetchUsers, saveUser } from "../../../actions/users"
import { ManageHeader } from "../../../components/manageMenu/manageHeader"
import { ExpansionPanelSubheading } from "../../../components/parts/ExpansionPanelSubheading"



const siteColors = ["#70aa4e", "#9e70cb", "#cdb671", "#dc7768"]

const ReportsManage = props => {
  const { classes } = props
 
  const [expanded, setExpanded] = useState(null)


  const siteList = useSelector(state => state.sites.siteList)
  const customerList = useSelector(state => state.customers.customerList)
  const userList = useSelector(state =>state.users.userList)


  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCustomers())
      .then(/*() => setLoaded(true)*/)
      .catch(err => console.log(err))
    dispatch(fetchSites())
      .then(/*() => setLoaded(true)*/)
      .catch(err => console.log(err))
    dispatch(fetchUsers())
      .then(/*() => setLoaded(true)*/)
      .catch(err => console.log(err))
  }, [dispatch])

  useEffect(() => {
    const tempSitesWithColors = {}
    siteList.forEach((site, index) => {
      tempSitesWithColors[site.name] = siteColors[index % siteColors.length]
    })
    //setSitesWithColors(tempSitesWithColors)
  }, [siteList])



  const handleChangePanel = (expanded, customer) => {
    setExpanded(expanded ? customer._id : false,)
    //setID(customer._id || "")
  }

  const onSubmitUserReportChange = (value, user) => {
    let userData = user
    userData.reportsEnabled = value || false

    dispatch(saveUser(userData))
      .then(() => dispatch(fetchUsers()))
      .catch(err => console.log(err))
  }

  // 
  const onWeeklyReportChange = (value, site) => {
    let siteData = site
    siteData.weeklyReport = value || false

    dispatch(ModifySites(siteData))
      .then(() => dispatch(fetchSites()))
      .catch(err => console.log(err)) 
  }

  return (
    <div className={classes.root}>
      <ManageHeader
        addNewText={tr("Add_new_customer")}
        title={tr("_reportsManage")}
      />

      <Grid container justify="center">
        <Grid item xs={11} lg={10}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.headingPart1}>
              {tr("Company")}
            </Typography>
            <Typography className={classes.headingPart2}>
              {tr("Sites")}
            </Typography>
            <Typography className={classes.headingPart3}>
              {tr("Users")}
            </Typography>
          </AccordionSummary>

          {customerList
            ? customerList.map((customer ) => {
                const customerSites = siteList.filter(
                  c => c.customer === customer._id
                )
                const customerUsers = userList.filter(
                  c => c.customer === customer._id
                )

                return (
                  <Accordion
                    key={customer._id}
                    expanded={expanded === customer._id}
                    onChange={(event, expanded) =>
                      handleChangePanel(expanded, customer)
                    }
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.headingPart1}>
                        {customer.name}
                      </Typography>
                      <Typography className={classes.headingPart2}>
                        {customerSites.length}
                      </Typography>
                      <Typography className={classes.headingPart3}>
                        {customerUsers.length}
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      <Grid container spacing={0}>
                        <ExpansionPanelSubheading
                          title={tr("Customers_sites")}
                        />
                        <Grid item xs={12}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>{tr("_site_name")}</TableCell>
                                <TableCell>{tr("_address")}</TableCell>
                                <TableCell>{tr("_city")}</TableCell>
                                <TableCell>{tr("_reports")}</TableCell>
                                <TableCell></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {customerSites
                                ? customerSites.map(site => {
                                    return (
                                      <TableRow key={site._id}>
                                        <TableCell>{site.name}</TableCell>
                                        <TableCell>{site.address}</TableCell>
                                        <TableCell>{site.city}</TableCell>

                                        <TableCell>
                                          {tr("_weekly_reports")} <br/>
                                          <Checkbox checked={site.weeklyReport} onChange={(e, value) => onWeeklyReportChange(value, site) } />
                                        </TableCell>
                                        <TableCell>
                                          {tr("_monthly_reports") + " TODO"} <br/>
                                          <Checkbox disabled={true} checked={site.weeklyReport} onChange={(e, value) => onWeeklyReportChange(value, site) } />
                                        </TableCell>

                                      </TableRow>
                                    )
                                  })
                                : null}
                            </TableBody>
                          </Table>
                        </Grid>

                        <ExpansionPanelSubheading
                          title={tr("Customers_users")}
                        />

                        <Grid item xs={12}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>{tr("_firstname")}</TableCell>
                                <TableCell>{tr("_lastname")}</TableCell>
                                <TableCell>{tr("_role")}</TableCell>
                                <TableCell>
                                  {tr("_receive_reports")}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {customerUsers
                                ? customerUsers.map(user => {
                                    return (
                                      <TableRow key={user._id}>
                                        <TableCell>
                                          {user.firstname}
                                        </TableCell>
                                        <TableCell>{user.lastname}</TableCell>
                                        <TableCell>{user.role}</TableCell>
                                        <TableCell>                                        
                                          <Checkbox checked={user.reportsEnabled} onChange={(e, value) => onSubmitUserReportChange(value, user) } />
                                        </TableCell>
                                      </TableRow>
                                    )
                                  })
                                : null}
                            </TableBody>
                          </Table>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                )
              })
            : ""}
        </Grid>
      </Grid>
    </div>
  )
}

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  headingPart1: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    color: theme.palette.text.field
  },
  headingPart2: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    color: theme.palette.text.field
  },
  headingPart3: {
    color: theme.palette.text.field,
    flexBasis: "10%",
    fontSize: theme.typography.pxToRem(15)
  },
  subHeading: {
    padding: "2rem 0 1rem"
  },
  pageTitle: {
    display: "inline-block",
    paddingBottom: "1rem",
    paddingTop: "2rem"
  },
  root: {
    flexGrow: 1,
    height: "calc(100vh - 130px)",
    backgroundImage: theme.backgroundImage,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed"
  },
  dense: {
    marginTop: 16
  },
  menu: {
    width: 200
  }
})

export default withStyles(styles, { withTheme: true })(ReportsManage)
