import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"
import React from "react"

const LightGraph = props => {
  const { classes, value } = props

  return (
    <div className={classes.div}>
      <div className={classes.value}>
        <Typography variant="body1">{value} %</Typography>
      </div>
      {new Array(10).fill(0).map((item, index) => (
        <div
          key={index}
          className={index * 10 < value ? classes.filled : classes.unfilled}
        >
          &nbsp;
        </div>
      ))}
    </div>
  )
}

const styles = () => ({
  div: {
    minWidth: "150px",
    display: "flex",
    flexWrap: "nowrap",
    height: "30px"
  },
  value: {
    display: "inline-block",
    width: "25%",
    whiteSpace: "nowrap",
    textAlign: "left",
    paddingTop: "5px"
  },
  filled: {
    background: "#CCB772",
    margin: "1px",
    display: "inline-block",
    width: "7%"
  },
  unfilled: {
    background: "#CCD4D9",
    margin: "1px",
    display: "inline-block",
    width: "7%"
  }
})

export default withStyles(styles, { withTheme: true })(LightGraph)
