import axios from "axios"
import { getApiUrl } from "../constants/config"
import { parseErrorData } from "../services/errors"
import { authHeader } from "./common"
import { stateType } from "../constants/actions"
import { dataConvertAverage } from "../services/valueConverter"

const dashboardApiFailed = error => ({
  type: stateType.SET_ERROR,
  error
})

const dashboardListRequested = () => ({
  type: stateType.DASHBOARD_LIST_REQUESTED
})

const dashboardListReceived = dashboards => ({
  type: stateType.DASHBOARD_LIST_RECEIVED,
  dashboards
})

const dashboardAddRequested = () => ({
  type: stateType.DASHBOARD_ADD_REQUESTED
})

const dashboardAddReceived = dashboard => ({
  type: stateType.DASHBOARD_ADD_RECEIVED,
  dashboard
})

const dashboardModifyRequested = () => ({
  type: stateType.DASHBOARD_MODIFY_REQUESTED
})

const dashboardModifyReceived = dashboards => ({
  type: stateType.DASHBOARD_MODIFY_RECEIVED,
  dashboards
})

const dashboardDeleteRequested = () => ({
  type: stateType.DASHBOARD_DELETE_REQUESTED
})

const dashboardDeleteReceived = () => ({
  type: stateType.DASHBOARD_DELETE_RECEIVED
})

const dashboardDeviceReceived = dashboards => ({
  type: stateType.DASHBOARD_DEVICE_LIST_RECEIVED,
  dashboards
})

const dashboardDeviceAddReceived = dashboard => ({
  type: stateType.DASHBOARD_DEVICE_ADD_RECEIVED,
  dashboard
})

const dashboardDeviceModifyReceived = dashboards => ({
  type: stateType.DASHBOARD_DEVICE_MODIFY_RECEIVED,
  dashboards
})

const dashboardDeviceFilterHoursModified = deviceFilterHours => ({
  type: stateType.DEVICEDATA_DEVICE_FILTER_HOURS_MODIFY,
  payload: deviceFilterHours
})

const dashboardDeviceDeleteReceived = () => ({
  type: stateType.DASHBOARD_DEVICE_DELETE_RECEIVED
})

const dashboardSetLineWidget = graphData => ({
  widgetAdvanced: graphData,
  type: stateType.DASHBOARD_SET_LINEWIDGET
})

export const fetchUserDashboard = () => async (dispatch, getState) => {
  const state = getState()
  try {
    dispatch(dashboardListRequested())
    let dashboardUrl = getApiUrl() + "/api/dashboards"
    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    // get user's own dashboard

    const { data } = await axios.get(dashboardUrl, authHeader(useToke))

    let dashboards = null
    if (!data || !data.success) {
      throw (data && data.error) || "Failed to fetch dashboard"
    }
    if (!data.dashboard) {
      return
    }
    dashboards = checkAndConvert(data.dashboard, state, dispatch)
    setDeviceFetchPeriods(dashboards, dispatch)
    return Promise.resolve(dispatch(dashboardListReceived(dashboards)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(dashboardApiFailed(errorReply)))
  }
}

export const fetchDeviceDashboard = id => async (dispatch, getState) => {
  const state = getState()
  try {
    dispatch(dashboardListRequested())
    let dashboardUrl = getApiUrl() + "/api/dashboards/" + id
    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    // get device's dashboard

    const { data } = await axios.get(dashboardUrl, authHeader(useToke))

    let dashboards = null
    if (!data || !data.success || !data.dashboard) {
      throw (data && data.error) || "Failed to fetch dashboard"
    }

    dashboards = data.dashboard

    return Promise.resolve(dispatch(dashboardDeviceReceived(dashboards)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(dashboardApiFailed(errorReply)))
  }
}

// when user add new empty dashboard for it
export const AddUserDashboard = id => async (dispatch, getState) => {
  const state = getState()
  try {
    dispatch(dashboardAddRequested())
    let dashboardUrl = "/api/dashboards"

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    // create empty dashboard
    const emptyDashboard = {
      widgetOne: {},
      widgetTwo: {},
      widgetThree: {},
      widgetFour: {},
      widgetFive: {},
      widgetSix: {},
      owner: id
    }

    const { data } = await axios.post(
      dashboardUrl,
      emptyDashboard,
      authHeader(useToke)
    )
    let dashboard = null

    if (!data || !data.success || !data.dashboard) {
      throw (data && data.error) || "Failed to fetch"
    }

    dashboard = data.dashboard

    return Promise.resolve(dispatch(dashboardAddReceived(dashboard)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(dashboardApiFailed(errorReply)))
  }
}

// when device or site is created, add new empty dashboard for it
export const AddDeviceDashboard = id => async (dispatch, getState) => {
  const state = getState()
  try {
    dispatch(dashboardAddRequested())
    let dashboardUrl = "/api/dashboards"

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    // create empty dashboard
    const emptyDashboard = {
      widgetOne: {},
      widgetTwo: {},
      widgetThree: {},
      widgetFour: {},
      widgetFive: {},
      widgetSix: {},
      owner: id
    }

    const { data } = await axios.post(
      dashboardUrl,
      emptyDashboard,
      authHeader(useToke)
    )
    let dashboard = null

    if (!data || !data.success || !data.dashboard) {
      throw (data && data.error) || "Failed to fetch"
    }

    dashboard = data.dashboard

    return Promise.resolve(dispatch(dashboardDeviceAddReceived(dashboard)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(dashboardApiFailed(errorReply)))
  }
}

// modify user's own dashboard
export const ModifyUserDashboard = existingDashboard => async (
  dispatch,
  getState
) => {
  try {
    dispatch(dashboardModifyRequested())
    let dashboardUrl = getApiUrl() + "/api/dashboards/"

    const state = getState()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    const { data } = await axios.put(
      dashboardUrl,
      existingDashboard,
      authHeader(useToke)
    )
    let dashboard = null

    if (!data || !data.success || !data.dashboard) {
      throw (data && data.error) || "Failed to fetch"
    }

    dashboard = data.dashboard

    return Promise.resolve(dispatch(dashboardModifyReceived(dashboard)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(dashboardApiFailed(errorReply)))
  }
}

// modify device's or site's dashboard
export const ModifyDeviceDashboard = (existingDashboard, id) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(dashboardModifyRequested())

    if (!id) {
      throw new Error("INVALID_DATA")
    }
    let dashboardUrl = getApiUrl() + "/api/dashboards/" + id

    const state = getState()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    const { data } = await axios.put(
      dashboardUrl,
      existingDashboard,
      authHeader(useToke)
    )
    let dashboard = null

    if (!data || !data.success || !data.dashboard) {
      throw (data && data.error) || "Failed to fetch"
    }

    dashboard = data.dashboard

    return Promise.resolve(dispatch(dashboardDeviceModifyReceived(dashboard)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(dashboardApiFailed(errorReply)))
  }
}

// when user is deleted, delete it's dashboard
export const DeleteUserDashboard = existingDashboardId => async (
  dispatch,
  getState
) => {
  try {
    dispatch(dashboardDeleteRequested())

    let dashboardUrl = getApiUrl() + "/api/dashboards/" + existingDashboardId

    const state = getState()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    const { data } = await axios.delete(dashboardUrl, authHeader(useToke))

    if (!data || !data.success) {
      throw (data && data.error) || "Failed to fetch"
    }

    return Promise.resolve(dispatch(dashboardDeleteReceived()))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(dashboardApiFailed(errorReply)))
  }
}

// when device or site is deleted, delete it's dashboard
export const DeleteDeviceDashboard = deviceId => async (dispatch, getState) => {
  try {
    dispatch(dashboardDeleteRequested())

    let dashboardUrl = getApiUrl() + "/api/dashboards/" + deviceId

    const state = getState()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    const { data } = await axios.delete(dashboardUrl, authHeader(useToke))

    if (!data || !data.success) {
      throw (data && data.error) || "Failed to fetch"
    }

    return Promise.resolve(dispatch(dashboardDeviceDeleteReceived()))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(dashboardApiFailed(errorReply)))
  }
}

export const dashboardHandleLineWidget = graphData => async dispatch => {
  dispatch(dashboardSetLineWidget(graphData))
}

const checkAndConvert = (dashboards, state, dispatch) => {
  let output = dashboards
  Object.entries(dashboards).forEach(dashboard => {
    if (
      dashboard[1].type &&
      dashboard[1].type === "ValueAverageWidget" &&
      !dashboard[1].version &&
      dashboard[1].version !== 1
    ) {
      const unpack = dataConvertAverage(dashboard[1].values, state)

      console.log("converted and result:", unpack)
      output[dashboard[0]].values = [JSON.stringify(unpack)]
      output[dashboard[0]].version = 1
      dispatch(ModifyUserDashboard(output))
    }
  })
  return output
}

const setDeviceFetchPeriods = (dashboards, dispatch) => {
  let deviceFilterHours = {}
  Object.entries(dashboards).forEach(dashboard => {
    const dashboardData = dashboard[1]

    if (
      dashboardData.type &&
      dashboardData.type === "LineWidget" &&
      dashboardData.values.length > 10 &&
      dashboardData.values[9] === "true"
    ) {
      const hours = parseInt(dashboardData.values[10])
      const deviceList = JSON.parse(dashboardData.devices[0])
      for(const device of deviceList){
        if(deviceFilterHours[device.deviceId] && deviceFilterHours[device.deviceId] > hours){
          continue
        }
        deviceFilterHours[device.deviceId] = hours
      }
    }
  })
  dispatch(dashboardDeviceFilterHoursModified(deviceFilterHours))

}



