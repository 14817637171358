
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import useTheme from "@material-ui/core/styles/useTheme"
import withStyles from "@material-ui/core/styles/withStyles"

import AddCircleIcon from "@material-ui/icons/AddCircle"
import SearchIcon from "@material-ui/icons/Search"
import React, { useState, useEffect } from "react"
import { tr } from "translations"

const LimitsListHeader = props => {
  const {
    addNewText,
    classes,
    title,
    titleIcon,
    handleSearch,
    addLimit,
    site
  } = props
  const theme = useTheme()
  const [search, setSearch] = useState("")
  const [timer, setTimer] = useState(null)
  const changeSearch = event => {
    clearTimeout(timer)
    setSearch(event.target.value)
  }

  useEffect(() => {
    let timeout = setTimeout(() => handleSearch(search), 150)
    setTimer(timeout)
  }, [search, handleSearch])

  const handleAddDevice = () => {
    addLimit()
  }

  const addDisabled = site == null

  return (
    <div>
      <Grid container spacing={0} className={classes.root}>
        <Grid item xs={12} md={8}>
          <Typography variant={"h5"} className={classes.pageTitle}>
            {titleIcon}
            {title} {addDisabled ? "-- " + tr("_no_sites_note") : ""}
          </Typography>
          <br />
          <Typography
            variant={"body2"}
            style={{
              paddingTop: 0,
              display: "inline-block",
              paddingBottom: "1rem",
              paddingLeft: "2rem",
              color: theme.palette.text.field
            }}
          >
            <em>Set rules and limits and get notified about alarms</em>
          </Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Grid
            container
            spacing={2}
            alignItems="flex-end"
            style={{ color: theme.palette.action.main }}
          >
            <Grid item xs={2} className={classes.textAlignEnd}>
              <SearchIcon style={{ verticalAlign: "text-top" }} />
            </Grid>
            <Grid item xs={10}>
              <TextField
                id="search"
                label={tr("_search")}
                InputProps={{ disableUnderline: false }}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot
                  }
                }}
                style={{ background: "transparent" }}
                onChange={changeSearch}
                value={search}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} md={2} className={classes.textAlignEnd}>
          <Button
            disabled={addDisabled}
            className={classes.button}
            endIcon={<AddCircleIcon fontSize="large" />}
            variant="outlined"
            onClick={handleAddDevice}
          >
            <Typography variant={"h6"}>{addNewText}</Typography>
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

const styles = theme => ({
  pageTitle: {
    display: "inline-block",
    paddingBottom: "1rem",
    paddingTop: "1rem",
    paddingLeft: "2rem",
    fontWeight: "bold"
  },
  root: {
    flexGrow: 1,
    alignItems: "center"
  },
  historyButton: {
    backgroundColor: theme.palette.primary.light,
    marginLeft: "20px"
  },
  searchInput: {
    padding: "0.2rem",
    font: "bold #009C6B",
    color: theme.palette.action.main
  },
  labelRoot: {
    fontSize: 25,
    color: theme.palette.action.main
  },
  button: {
    marginBottom: "1rem",
    marginTop: "2rem",
    marginRight: "2rem",
    fontWeight: "bold",
    textTransform: "none",
    color: theme.palette.action.main,
    border: theme.palette.action.main + " solid 1px",
    borderRadius: "10px"
  }
})

export default withStyles(styles, { withTheme: true })(LimitsListHeader)
