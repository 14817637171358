import axios from "axios"
import { getApiUrl } from "../constants/config"
import { parseErrorData } from "../services/errors"
import { authHeader } from "./common"
import { stateType } from "../constants/actions"

const modelListApiFailed = error => ({
  type: stateType.SET_ERROR,
  error
})

const modelListRequested = () => ({
  type: stateType.MODEL_LIST_REQUESTED
})

const modelListReceived = modelList => ({
  type: stateType.MODEL_LIST_RECEIVED,
  modelList
})

const modelAddRequested = () => ({
  type: stateType.MODEL_ADD_REQUESTED
})

const modelAddReceived = model => ({
  type: stateType.MODEL_ADD_RECEIVED,
  model
})

const modelModifyRequested = () => ({
  type: stateType.MODEL_MODIFY_REQUESTED
})

const modelModifyReceived = model => ({
  type: stateType.MODEL_MODIFY_RECEIVED,
  model
})

const modelDeleteRequested = () => ({
  type: stateType.MODEL_DELETE_REQUESTED
})

const modelDeleteReceived = modelId => ({
  type: stateType.MODEL_DELETE_RECEIVED,
  modelId
})

const displayInfo = info => ({
  type: stateType.SET_INFO,
  info
})

export const fetchModels = modelId => async (dispatch, getState) => {
  try {
    // TODO: REMOVE THIS FUNCTION
    console.log("remove this", modelId != null ? "test" : "test")

    console.log("remove this", getState != null ? "test" : "test")
    dispatch(modelListRequested())
    /*
    let modelUrl = getApiUrl() + "/api/types"

    if (modelId) {
      modelUrl = modelUrl + "/" + modelId
    }

    const state = getState()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    const { data } = await axios.get(modelUrl, authHeader(useToke))

    let modelList = null
    if (!data || !data.success || !data.types) {
      throw (data && data.error) || "Failed to fetch types"
    }

    modelList = data.types
*/
    return Promise.resolve(dispatch(modelListReceived(null)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(modelListApiFailed(errorReply)))
  }
}

export const addModels = newModel => async (dispatch, getState) => {
  try {
    dispatch(modelAddRequested())
    let modelUrl = getApiUrl() + "/api/types"

    const state = getState()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }
    const { data } = await axios.post(modelUrl, newModel, authHeader(useToke))
    let model = null

    if (!data || !data.success || !data.type) {
      throw (data && data.error) || "Failed to fetch"
    }

    model = data.type
    let info = { description: "TYPE_ADDED" }
    dispatch(displayInfo(info))

    return Promise.resolve(dispatch(modelAddReceived(model)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(modelListApiFailed(errorReply)))
  }
}

export const modifyModels = existingModel => async (dispatch, getState) => {
  try {
    dispatch(modelModifyRequested())
    let modelUrl = getApiUrl() + "/api/types/" + existingModel._id

    const state = getState()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    const { data } = await axios.put(
      modelUrl,
      existingModel,
      authHeader(useToke)
    )
    let model = null

    if (!data || !data.success || !data.type) {
      throw (data && data.error) || "Failed to fetch"
    }

    model = data.type
    let info = { description: "TYPE_MODIFIED" }
    dispatch(displayInfo(info))

    return Promise.resolve(dispatch(modelModifyReceived(model)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(modelListApiFailed(errorReply)))
  }
}

export const deleteModels = _id => async (dispatch, getState) => {
  try {
    dispatch(modelDeleteRequested())

    let modelUrl = getApiUrl() + "/api/types/" + _id

    const state = getState()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    const { data } = await axios.delete(modelUrl, authHeader(useToke))

    if (!data || !data.success) {
      throw (data && data.error) || "Failed to fetch"
    }
    let info = { description: "TYPE_DELETED" }
    dispatch(displayInfo(info))

    return Promise.resolve(dispatch(modelDeleteReceived(_id)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(modelListApiFailed(errorReply)))
  }
}
