export default (
  state = {
    activeFaultList: [],
    faultList: [],
    isFetching: false,
    error: null,
    shouldRefresh: false
  },
  action
) => {
  switch (action.type) {
    case "FAULT_LIST_REQUESTED":
    case "FAULT_ADD_REQUESTED":
      return {
        ...state,
        isFetching: true,
        shouldRefresh: false
      }
    case "FAULT_ADD_RECEIVED": {
      const { faultList } = state
      const newList = [...faultList, action.fault]
      return {
        ...state,
        isFetching: false,
        fault: action.fault,
        faultList: newList,
        error: null,
        lastUpdated: Date.now()
      }
    }
    case "FAULT_LIST_RECEIVED":
      return {
        ...state,
        isFetching: false,
        shouldRefresh: false,
        faultList: action.faultList,
        lastUpdated: Date.now()
      }
    case "ACTIVE_LIST_RECEIVED":
      return {
        ...state,
        isFetching: false,
        shouldRefresh: false,
        activeFaultList: action.faultList
      }

    case "FAULT_RESET":
      return {
        ...state,
        activeFaultList: [],
        faultList: []
      }

    default:
      return state
  }
}
