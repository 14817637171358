import React, { useState, useEffect } from "react"

import ButtonBase from "@material-ui/core/ButtonBase"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"

import useTheme from "@material-ui/core/styles/useTheme"
import StarIcon from "@material-ui/icons/Star"
import StarBorderIcon from "@material-ui/icons/StarBorder"
import ListAltIcon from "@material-ui/icons/ListAlt"
import MapIcon from "@material-ui/icons/Map"
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord"
import { MapMarker } from "../SvgLib"
import { tr } from "translations"

const SiteInfoCard = props => {
  const {
    cardSelected,
    classes,
    handleDialog,
    onClick,
    site,
    width,
    height,
    openSite,
    devices,
    iconSize,
    logo
  } = props

  const theme = useTheme()

  const [alerts, setAlerts] = useState(0)
  const [warnings, setWarnings] = useState(0)
  const [star, setStar] = useState(false)

  useEffect(() => {
    const alertDevices = devices.filter(device => device.status === "FAULT")
      .length

    const warningDevices = devices.filter(
      device => !device.area || !device.pos || !device.pos.x || !device.pos.y
    ).length

    setAlerts(alertDevices)
    setWarnings(warningDevices)
  }, [devices])

  const handleStar = () => {
    setStar(!star)
  }

  return (
    <Paper
      onClick={onClick}
      className={cardSelected ? classes.selectedPaper : classes.defaultPaper}
      elevation={cardSelected ? 10 : 1}
      style={{ width, height }}
    >
      <Grid container>
        <Grid container item xs={3} alignItems="center">
          <MapMarker
            color={
              alerts > 0
                ? theme.palette.error.threatening
                : warnings > 0
                  ? theme.palette.error.light
                  : theme.palette.action.main
            }
            style={{
              height: iconSize,
              width: iconSize
            }}
            iconName={site.name.includes("AJR") ? "ajrlogo" : "partner"}
            logo={logo}
            innerColor={theme.palette.secondary.main}
          />
        </Grid>
        <Grid item xs={9}>
          <Grid container justify="space-between" item xs={12}>
            <Typography className={classes.siteName} color="textPrimary">
              {site.name}
            </Typography>
            {star ? (
              <StarIcon
                fontSize="small"
                className={classes.icons}
                onClick={handleStar}
              />
            ) : (
              <StarBorderIcon
                fontSize="small"
                className={classes.icons}
                onClick={handleStar}
              />
            )}
          </Grid>
          <Grid container justify="space-between" item xs={12}>
            <Grid item xs={6}>
              <Typography className={classes.siteDevices} color="textPrimary">
                {`${devices.length} ${tr("_devices")}`}
              </Typography>
            </Grid>
            <Grid container item xs={6} justify="flex-end" alignItems="center">
              {warnings > 0 && (
                <>
                  <Typography
                    className={classes.siteAlerts}
                    color="textPrimary"
                  >
                    {warnings}
                  </Typography>
                  <FiberManualRecordIcon
                    style={{ widht: 16, height: 16 }}
                    className={classes.warningIcon}
                  />
                </>
              )}
              {alerts > 0 && (
                <>
                  <Typography
                    className={classes.siteAlerts}
                    color="textPrimary"
                  >
                    {alerts}
                  </Typography>
                  <FiberManualRecordIcon
                    style={{ widht: 16, height: 16 }}
                    className={classes.alertIcon}
                  />
                </>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider classes={{ root: classes.divider }} />
          </Grid>
          <Grid container justify="center" item xs={12}>
            <Grid container item xs={6}>
              <ButtonBase onClick={() => openSite(site._id)}>
                <MapIcon className={classes.icons} />
                <Typography className={classes.cardBottom}>
                  {tr("_view_plan")}
                </Typography>
              </ButtonBase>
            </Grid>
            <Grid container item xs={6}>
              <ButtonBase onClick={() => handleDialog(site._id)}>
                <ListAltIcon className={classes.icons} />
                <Typography className={classes.cardBottom}>
                  {tr("_list")}
                </Typography>
              </ButtonBase>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

const styles = theme => ({
  defaultPaper: {
    padding: 15,
    [theme.breakpoints.down("md")]: {
      padding: 8,
      paddingRight: 15
    }
  },
  selectedPaper: {
    padding: 15,
    backgroundColor: theme.palette.primary.light,
    [theme.breakpoints.down("md")]: {
      padding: 8,
      paddingRight: 15
    },
    borderColor: theme.palette.text.primary,
    borderStyle: "solid",
    borderWidth: 1
  },
  icons: {
    color: theme.palette.text.primary
  },
  alertIcon: {
    color: theme.palette.error.main
  },
  warningIcon: {
    color: theme.palette.error.light
  },
  siteName: {
    fontSize: 18,
    [theme.breakpoints.down("md")]: {
      fontSize: 12
    }
  },
  cardBottom: {
    fontSize: 14,
    [theme.breakpoints.down("md")]: {
      fontSize: 8
    },
    marginLeft: 10
  },
  siteAlerts: {
    fontSize: 14,
    [theme.breakpoints.down("md")]: {
      fontSize: 8
    },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  siteDevices: {
    fontSize: 14,
    [theme.breakpoints.down("md")]: {
      fontSize: 8
    }
  },
  divider: {
    backgroundColor: theme.palette.text.primary,
    marginTop: 10,
    marginBottom: 15,
    height: 2
  }
})

export default withStyles(styles)(SiteInfoCard)
