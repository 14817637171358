import * as React from "react"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import withStyles from "@material-ui/core/styles/withStyles"

import Input from "@material-ui/core/Input"
import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import CheckIcon from "@material-ui/icons/Check"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { tr } from "translations"
import {
  deleteTrialToken,
  fetchTrialTokens,
  saveTrialToken,
  premiumTrialToken,
  expireTrialToken,
  addTrialDays
} from "../../../actions/trials"
import { registerTrial, changeTrialEmail } from "../../../actions/registration"
import { ManageHeader } from "../../../components/manageMenu/manageHeader"
import moment from "moment"

class TrialTokenManage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expiration: "",
      code: "",
      claimed: null,
      expiresIn: null,
      error: null,
      isSending: false,
      submitSuccessful: false,
      expanded: null,
      formUpdated: false,
      email: "",
      addDays: 0
    }

    this.resetError = this.resetError.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleChangePanel = this.handleChangePanel.bind(this)
    this.handleChechbox = this.handleChechbox.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleSend = this.handleSend.bind(this)
    this.validateNumber = this.validateNumber.bind(this)
    this.handlePremium = this.handlePremium.bind(this)
    this.handleExpire = this.handleExpire.bind(this)
    this.submitAddDays = this.submitAddDays.bind(this)
    this.handleAddDaysChange = this.handleAddDaysChange.bind(this)
  }

  componentDidMount() {
    this.props.fetchTrialTokens()
  }

  validateNumber(number) {
    if (number != "" && number != undefined) {
      let rgp = /^\+[ ]?([0-9]{1,3})[- ]?([0-9]{1,3})([- ]?([0-9]{1,10}))*$/
      let match = false
      match = number.match(rgp) !== null
      this.setState({ numberValid: match })
    } else {
      this.setState({ numberValid: true })
    }
  }

  handleChange({ target }) {
    if (target.name === "phone") this.validateNumber(target.value)
    this.setState({ [target.name]: target.value, formUpdated: true })
  }

  handleChangePanel(expanded, user) {
    let currentSiteList = []
    if (user._id && user.role === "user") {
      currentSiteList = this.props.siteList.filter(
        site => site.group.indexOf(user._id) !== -1
      )
    } else if (user.customer && user.role === "admin") {
      // These are mine sites, but each admin of customer has same sites...
      currentSiteList = this.props.siteList.filter(
        site => site.customer === user.customer
      )
    } else {
      currentSiteList = this.props.siteList
    }

    this.validateNumber(user.phone)

    this.setState({
      expanded: expanded ? user._id : false,
      _id: user._id || "",
      email: user.email || "",
      role: user.role || "",
      firstname: user.firstname || "",
      lastname: user.lastname || "",
      phone: user.phone || "",
      username: user.username || "",
      userSites: currentSiteList,
      formUpdated: false
    })
  }

  handleChechbox(event, _id, name) {
    const target = event.target

    let user = { _id }
    switch (name) {
      case "userActiveChecked":
        user.active = target.checked
        break
    }
    this.props
      .saveUser(user)
      .then(() => {
        this.props.fetchUsers()
        this.setState({
          error: null,
          formUpdated: false
        })
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  handleDelete(trial) {
    this.props
      .deleteTrialToken(trial._id)
      .then(() => {
        this.setState({
          error: null,
          formUpdated: false,
          isSending: false
        })
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  handlePremium(trial) {
    this.props
      .premiumTrialToken(trial._id)
      .then(() => {
        this.setState({
          error: null,
          formUpdated: false,
          isSending: false
        })
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  handleExpire(trial) {
    this.props
      .expireTrialToken(trial._id)
      .then(() => {
        this.setState({
          error: null,
          formUpdated: false,
          isSending: false
        })
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  resetError() {
    this.setState({ error: null })
  }

  handleSubmit() {
    // const { } = this.state
    this.props
      .saveTrial({})
      .then(() => {
        this.setState({
          error: null,
          formUpdated: false,
          isSending: false
        })
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  handleSend(token) {
    const { email } = this.state
    this.props
      .registerTrial(token, email)
      .then(() => {
        this.props.fetchTrialTokens()
        this.setState({
          error: null,
          formUpdated: false,
          isSending: false
        })
      })
      .catch(err => {
        console.log("Error", err)
      })
  }
  handleReSend(token) {
    const { email } = this.state
    this.props
      .changeTrialEmail(token, email)
      .then(() => {
        this.props.fetchTrialTokens()
        this.setState({
          error: null,
          formUpdated: false,
          isSending: false
        })
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  handleAddDaysChange(e){
    let num = parseInt(e.target.value)
    if(!isNaN(num)){
      this.setState({ addDays: num})
    }
  }


  submitAddDays(trial) {
    let days = this.state.addDays
    this.props
      .addTrialDays(trial._id, days)
      .then(() => {
        this.setState({
          error: null,
          formUpdated: false,
          isSending: false
        })
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  render() {
    const { classes, trialTokens } = this.props
    const { expanded, submitSuccessful, email } = this.state
    return (
      <div className={classes.root}>
        <ManageHeader
          addNew={this.props.handleChangeTrialView}
          addNewText={tr("_add_new_trial_token")}
          title={tr("_trial_token_manage")}
        />

        <Grid container justify="center">
          <Grid item xs={11} lg={10}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.headingPart1}>
                {tr("_code")}
              </Typography>
              <Typography className={classes.headingPart2}>
                {tr("_expiration")}
              </Typography>
              <Typography className={classes.headingPart3}>
                {tr("_claimed")}
              </Typography>
              <Typography className={classes.headingPart3}>
                {tr("_receiver")}
              </Typography>
              <Typography className={classes.headingPart3}>
                {tr("_expiresIn")}
              </Typography>
            </AccordionSummary>
            {trialTokens
              ? trialTokens.map((trial, i) => {
                  return (
                    <Accordion
                      key={trial._id}
                      expanded={expanded === trial._id}
                      onChange={(event, expanded) =>
                        this.handleChangePanel(expanded, trial)
                      }
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.headingPart1}>
                          {trial.trialToken}
                        </Typography>
                        <Typography className={classes.headingPart2}>
                          {moment
                            .duration(trial.orgExpire, "seconds")
                            .asDays() +
                            " " +
                            tr("_days")}
                        </Typography>
                        <Typography className={classes.headingPart3}>
                          {trial.claimedBy ? <CheckIcon/>: ""}
                        </Typography>
                        <Typography className={classes.headingPart3}>
                          {trial.sended}
                        </Typography>
                        <Typography className={classes.headingPart3}>
                          {trial.willExpireIn}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container>
                          <Grid item xs={12} md={5} lg={5}>
                            <TextField
                              id={"outlined-email" + i}
                              label={tr("_email")}
                              type="email"
                              name="email"
                              className={classes.textField}
                              InputLabelProps={{
                                classes: {
                                  root: classes.inputRoot,
                                  focused: classes.inputFocus
                                }
                              }}
                              InputProps={{
                                classes: {
                                  underline: classes.underline
                                }
                              }}
                              value={email}
                              onChange={this.handleChange}
                              margin="normal"
                            />
                          </Grid>
                          <Grid item xs={12} md={7} lg={7}>
                            {!trial.sended && <Button
                              disabled={
                                !email || trial.claimedBy
                                  ? true
                                  : false
                              }
                              variant="contained"
                              color="primary"
                              onClick={() => this.handleSend(trial.trialToken)}
                              className={classes.saveButton}
                            >
                              {tr("_send")}
                            </Button>}
                            {trial.sended && <Button
                              disabled={
                                !email || trial.claimedBy
                                  ? true
                                  : false
                              }
                              variant="contained"
                              color="primary"
                              onClick={() => this.handleReSend(trial.trialToken)}
                              className={classes.saveButton}
                            >
                              {tr("_resend")}
                            </Button>}
                            <Button
                              disabled={trial.claimedBy ? true : false}
                              variant="contained"
                              color="secondary"
                              onClick={() => this.handleDelete(trial)}
                              className={classes.removeButton}
                            >
                              {tr("_remove_trial")}
                            </Button>
                            <Button
                              disabled={trial.willExpireIn ? false : true}
                              variant="contained"
                              color="secondary"
                              onClick={() => this.handlePremium(trial)}
                              className={classes.removeButton}
                            >
                              {tr("_make_premium")}
                            </Button>
                            <Button
                              disabled={!trial.willExpireIn}
                              variant="contained"
                              color="secondary"
                              onClick={() => this.handleExpire(trial)}
                              className={classes.removeButton}
                            >
                              {tr("_expire")}
                            </Button>
                            <Input
                              value={this.addDays}
                              type="number"
                              onChange={this.handleAddDaysChange}
                              placeholder={tr("_days")}
                              className={classes.select}
                              classes={{ input: classes.underline }}
                              style={{ minWidth: 20 }}
                            />
                            <Button
                              disabled={!trial.claimedBy}

                              variant="contained"
                              color="secondary"
                              onClick={() => this.submitAddDays(trial)}
                              className={classes.removeButton}
                            >
                              {tr("Add_days")}
                            </Button>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  )
                })
              : ""}
            {submitSuccessful && (
              <div className="alert alert-success">
                <strong>Success!</strong>
              </div>
            )}
          </Grid>
        </Grid>

        {submitSuccessful && (
          <div className="alert alert-success">
            <strong>Registration done!</strong>
          </div>
        )}
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  deleteTrialToken: id => dispatch(deleteTrialToken(id)),
  fetchTrialTokens: () => dispatch(fetchTrialTokens()),
  saveTrial: obj => dispatch(saveTrialToken(obj)),
  registerTrial: (token, email) => dispatch(registerTrial(token, email)),
  changeTrialEmail: (token, email) => dispatch(changeTrialEmail(token, email)),
  premiumTrialToken: id => dispatch(premiumTrialToken(id)),
  expireTrialToken: id => dispatch(expireTrialToken(id)),
  addTrialDays: (id, days) => dispatch(addTrialDays(id, days)),
})

const mapStateToProps = state => {
  return {
    trialTokens: state.trials.trialTokens
  }
}

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%"
  },
  textFieldForm: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
    color: theme.palette.text.field
  },
  dense: {
    marginTop: 16
  },
  menu: {
    width: 200
  },
  root: {
    flexGrow: 1,
    height: "calc(100vh - 130px)",
    backgroundImage: theme.backgroundImage,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    overflow: "auto"
  },
  title: {
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 20
  },
  button: {
    margin: 10
  },
  inputRoot: {
    color: theme.palette.text.field + " !important"
  },
  inputFocus: {
    color: theme.palette.text.warning + " !important"
  },
  underline: {
    "&::before": {
      color: theme.palette.text.primary
    },
    "&::hover": {
      borderBottom: "2px solid " + theme.palette.text.primary
    },
    "&::after": {
      borderBottom: "2px solid " + theme.palette.text.warning
    }
  },
  headingPart1: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "25%",
    flexShrink: 0,
    color: theme.palette.text.field
  },
  headingPart2: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "25%",
    color: theme.palette.text.field
  },
  headingPart3: {
    color: theme.palette.text.field,
    flexBasis: "25%",
    fontSize: theme.typography.pxToRem(15)
  },
  saveButton: {
    margin: 10,
    backgroundColor: theme.palette.action.main,
    color: theme.palette.secondary.themed,
    "&:hover": {
      color: theme.palette.text.primary
    }
  },
  removeButton: {
    margin: 10,
    color: theme.palette.secondary.themed,
    "&:hover": {
      color: theme.palette.text.primary
    }
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles, { withTheme: true })(TrialTokenManage)))
