import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { tr } from "translations"
import CloseIcon from "@material-ui/icons/Close"
import Snackbar from "@material-ui/core/Snackbar"
import IconButton from "@material-ui/core/IconButton"
import useTheme from "@material-ui/core/styles/useTheme"
import withStyles from "@material-ui/core/styles/withStyles"
import moment from "moment"
import { closeTrialMessage } from "../actions/trials"

const TrialSnackbar = props => {
  const { classes } = props
  const trial = useSelector(state => state.trials.showTrial)
  const language = useSelector(state => state.ui.language)
  const theme = useTheme()
  const dispatch = useDispatch()

  return trial ? (
    <Snackbar
      anchorOrigin={{
        horizontal: "right",
        vertical: "bottom"
      }}
      open={trial ? true : false}
      onClose={() => {}}
      ContentProps={{
        "aria-describedby": "message-id"
      }}
      message={
        moment(trial.willExpireIn) > moment() ? (
          <span style={{ color: theme.palette.error.main }} id="message-id-1">
            {tr("_timeLeft") +
              moment
                .duration(moment(trial.willExpireIn).diff(moment()))
                .locale(language)
                .humanize() +
              tr("_timeLeft2")}
          </span>
        ) : (
          <span style={{ color: theme.palette.error.main }} id="message-id-1">
            {tr("_organizationExpired")}
          </span>
        )
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={() => {
            dispatch(closeTrialMessage())
          }}
        >
          <CloseIcon />
        </IconButton>
      ]}
    />
  ) : null
}

const styles = theme => ({
  close: {
    padding: theme.spacing(0.5)
  }
})

export default withStyles(styles)(TrialSnackbar)
