const initialState = {
  boxOne: {},
  boxTwo: {},
  boxThree: {},
  boxFour: {},
  boxFive: {},
  boxSix: {}
}

export default (
  state = {
    dashboards: initialState,
    deviceDashboard: initialState,
    hasDashboard: false,
    isFetching: false,
    error: null,
    shouldRefresh: false,
    widgetAdvanced: initialState
  },
  action
) => {
  switch (action.type) {
    case "DASHBOARD_LIST_REQUESTED":
    case "DASHBOARD_ADD_REQUESTED":
    case "DASHBOARD_UPDATE_REQUESTED":
    case "DASHBOARD_DELETE_REQUESTED":
    case "DASHBOARD_UPGRADE_REQUESTED":
      return {
        ...state,
        isFetching: true,
        shouldRefresh: false
      }
    case "DASHBOARD_MODIFY_RECEIVED": {
      return {
        ...state,
        isFetching: false,
        dashboards: action.dashboards,
        error: null,
        hasDashboard: true
      }
    }
    case "DASHBOARD_DEVICE_MODIFY_RECEIVED": {
      return {
        ...state,
        isFetching: false,
        deviceDashboard: action.dashboards,
        error: null,
        hasDashboard: true
      }
    }
    case "DASHBOARD_ADD_RECEIVED":
    case "DASHBOARD_LIST_RECEIVED":
      return {
        ...state,
        isFetching: false,
        shouldRefresh: false,
        dashboards: action.dashboards,
        hasDashboard: true
      }
    case "DASHBOARD_DEVICE_ADD_RECEIVED":
    case "DASHBOARD_DEVICE_LIST_RECEIVED":
      return {
        ...state,
        isFetching: false,
        shouldRefresh: false,
        deviceDashboard: action.dashboards
      }
    case "DASHBOARD_DELETE_RECEIVED": {
      return {
        ...state,
        isFetching: false,
        dashboards: initialState,
        hasDashboard: false,
        error: null
      }
    }
    case "DASHBOARD_DEVICE_DELETE_RECEIVED": {
      return {
        ...state,
        isFetching: false,
        deviceDashboard: initialState,
        error: null
      }
    }
    case "DASHBOARD_AJAX_SUCCESS":
      return { ...state, isFetching: false }

    case "DASHBOARD_AJAX_ERROR":
      return {
        ...state,
        isFetching: false,
        shouldRefresh: true,
        error: action.error
      }

    case "DASHBOARD_RESET_ERROR":
      return { ...state, error: null }

    case "DASHBOARD_SET_LINEWIDGET":
      return { ...state, widgetAdvanced: action.widgetAdvanced }
    default:
      return state
  }
}
