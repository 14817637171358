import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Grid from "@material-ui/core/Grid"
import useTheme from "@material-ui/core/styles/useTheme"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import SiteAreaSelection from "./SiteAreaSelection"
import { FilterArea, updateOpenArea } from "../../actions/areas"
import { FilterDevices, FilterFloorDevices } from "../../actions/iotDevices"
import { updateOpenSite } from "../../actions/sites"
import MainAppBar from "./MainAppBar"

const GroundPlanAppBar = props => {
  const { history, propsSite } = props

  const areas = useSelector(state => state.areas)

  const sites = useSelector(state => state.sites)
  const customerList = useSelector(state => state.customers.customerList)
  const customer = customerList ? customerList[0] : undefined
  const floor = useSelector(state => state.areas.floorOpen)
  const iotDevices = useSelector(state => state.iotDevices.iotDeviceFiltered)

  const [site, setSite] = useState(propsSite)
  const [area, setArea] = useState(undefined)

  const dispatch = useDispatch()

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down("sm"))

  useEffect(() => {
    if (areas.areaOpen && areas.areaOpen._id && area !== areas.areaOpen._id) {
      dispatch(FilterDevices(areas.areaOpen.site, areas.areaOpen, []))
      if (site !== areas.areaOpen.site) {
        dispatch(updateOpenSite(areas.areaOpen.site))
        setSite(areas.areaOpen.site)
        history.push("/groundplan/" + encodeURI(areas.areaOpen.site))
      }
      setArea(areas.areaOpen._id)
    } else if (!areas.areaOpen) {
      setArea(undefined)
    }
  }, [area, areas, site, dispatch, history])

  useEffect(() => {
    if (!sites.siteOpen && sites.siteList.length > 0) {
      dispatch(updateOpenSite(sites.siteList[0]._id))
      dispatch(FilterArea(sites.siteList[0]._id, 1))
    }
  }, [])

  useEffect(() => {
    if (area) {
      let danfossIds = []
      iotDevices.forEach(dev => {
        if (dev && dev.deviceId && dev.deviceType) {
          if(dev.deviceType === "Danfoss"){
            danfossIds.push(dev.deviceId)
          }
          else{
            let { getDataUpdates } = require("../../devicetypes/" +
              dev.deviceType +
              "/actions")
            dispatch(getDataUpdates(dev.deviceId))
          }
        }
      })
      let { getDataUpdatesArray } = require("../../devicetypes/Danfoss/actions")
      dispatch(getDataUpdatesArray(danfossIds))
    }
  }, [area, dispatch])

  const handleAreaOpen = event => {
    const { value } = event.target
    dispatch(updateOpenArea(value))
    if (value === "area") {
      dispatch(FilterDevices(site, undefined, []))
    }
    if (typeof value === "number"){
      dispatch(FilterFloorDevices(site, value, []))
    }
  }

  const handleSiteOpen = event => {
    const target = event.target
    dispatch(FilterArea(target.value, 1))
  }

  return (
    <>
      <Grid container item xs={12}>
        <Grid container item xs={matches ? 10 : 4}>
          <SiteAreaSelection
            area={area}
            areas={areas}
            floor={floor}
            site={site}
            sites={sites}
            customer={customer}
            handleArea={handleAreaOpen}
            handleSite={handleSiteOpen}
          />
        </Grid>
        <Grid container item justify="flex-end" xs={matches ? 2 : 8}>
          <MainAppBar history={history} />
        </Grid>
      </Grid>
    </>
  )
}

export default GroundPlanAppBar
