export default (
  state = {
    limits: [],
    isFetching: false
  },
  action
) => {
  switch (action.type) {
    case "LIMIT_LIST_REQUESTED":
      return {
        ...state,
        isFetching: true
      }
    case "LIMIT_ADD_RECEIVED": {
      const { limits } = state
      return {
        ...state,
        isFetching: false,
        limits
      }
    }
    case "LIMIT_LIST_RECEIVED":
      return {
        ...state,
        isFetching: false,
        limits: action.limitList
      }
    case "LIMIT_LIST_MODIFYED":
      return {
        ...state,
        isFetching: false,
        limits: action.limits
      }
    case "LIMIT_DELETE": {
      const { limits } = state
      return {
        ...state,
        isFetching: false,
        limits: limits
      }
    }
    default:
      return state
  }
}
