export default (
  state = {
    modelList: [],
    isFetching: false,
    error: null,
    shouldRefresh: false
  },
  action
) => {
  switch (action.type) {
    case "MODEL_LIST_REQUESTED":
    case "MODEL_ADD_REQUESTED":
    case "MODEL_UPDATE_REQUESTED":
    case "MODEL_DELETE_REQUESTED":
      return {
        ...state,
        isFetching: true,
        shouldRefresh: false
      }
    case "MODEL_MODIFY_RECEIVED": {
      let { modelList } = state
      const newList = modelList.map(model => {
        if (model._id === action.model._id) {
          return action.model
        } else {
          return model
        }
      })

      return {
        ...state,
        isFetching: false,
        model: action.model,
        modelList: newList,
        error: null,
        lastUpdated: Date.now()
      }
    }
    case "MODEL_ADD_RECEIVED": {
      const { modelList } = state
      const newList = [...modelList, action.model]

      return {
        ...state,
        isFetching: false,
        model: action.model,
        modelList: newList,
        error: null,
        lastUpdated: Date.now()
      }
    }
    case "MODEL_LIST_RECEIVED":
      return {
        ...state,
        isFetching: false,
        shouldRefresh: false,
        modelList: action.modelList,
        lastUpdated: Date.now()
      }
    case "MODEL_DELETE_RECEIVED": {
      let { modelList } = state

      let newList = modelList.filter(function(model) {
        if (model._id == action._id) {
          return false
        } else {
          return true
        }
      })
      return {
        ...state,
        isFetching: false,
        //model: action._id,
        modelList: newList,
        error: null,
        lastUpdated: Date.now()
      }
    }

    default:
      return state
  }
}
