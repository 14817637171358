import axios from "axios"
// import { authError } from "actions/auth"
import { success /* , error */ } from "react-notification-system-redux"
// import { tr } from "translations"
import { stateType } from "../constants/actions"
import { getApiUrl } from "../constants/config"
import { authHeader } from "./common"
import { parseErrorData } from "../services/errors"
import { getLanguage } from "../translations"

const requestFailed = error => ({
  type: stateType.SET_ERROR,
  error
})

const errorHandler = (err, response = "Internal server error") => {
  // if ((err && err.status === 401) || /401/.test(err)) return authError()

  const error =
    (err && err.message) || (err.toString && err.toString()) || response
  return ajaxError(error)
}

const ajaxSuccess = () => ({ type: "USER_AJAX_SUCCESS" })

const ajaxError = error => ({
  type: "USER_AJAX_ERROR",
  error
})

export const resetUserError = () => ({ type: "USER_RESET_ERROR" })

const userListRequested = () => ({
  type: "USER_LIST_REQUESTED"
})

const userListReceived = userList => ({
  type: "USER_LIST_RECEIVED",
  userList,
  lastUpdated: Date.now()
})

// const requestUserAdd = user => ({
//   type: "USER_ADD_REQUESTED",
//   user
// })

const requestUserUpdate = user => ({
  type: "USER_UPDATE_REQUESTED",
  user
})

const requestUserDelete = id => ({
  type: "USER_DELETE_REQUESTED",
  id
})

const requestUserUpgrade = id => ({
  type: "USER_DELETE_REQUESTED",
  id
})

export const fetchUsers = () => async (dispatch, getState) => {
  const state = getState()
  try {
    dispatch(userListRequested())

    let backendUrl = getApiUrl() + "/api/users"
    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    const { data } = await axios.get(backendUrl, authHeader(useToke))

    if (!data || !data.success)
      throw (data && data.error) || "Failed to fetch users"

    return Promise.resolve(dispatch(userListReceived(data.users)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(requestFailed(errorReply)))
    // return Promise.reject(dispatch(errorHandler(err, "Failed to fetch users")))
  }
}

const shouldFetchUsers = ({ users }) => {
  if (users.isFetching) return false
  if (!users.userList.length) return true
  if (Date.now() > users.lastUpdated + 60 * 1000) return true
  return false
}

export const fetchUsersIfNeeded = () => (dispatch, getState) => {
  if (shouldFetchUsers(getState())) return dispatch(fetchUsers())
}

const userSelfReceived = user => ({
  type: "USER_SELF_RECEIVED",
  user,
  lastUpdated: Date.now()
})

export const fetchUserSelf = () => async (dispatch, getState) => {
  const state = getState()
  try {
    dispatch(userListRequested())
    let customerUrl = getApiUrl() + "/api/users/me"
    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    const { data } = await axios.get(customerUrl, authHeader(useToke))

    if (!data || !data.success)
      throw (data && data.error) || "Failed to fetch user info"

    return Promise.resolve(dispatch(userSelfReceived(data.user)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(requestFailed(errorReply)))
    // return Promise.reject(
    //   dispatch(errorHandler(err, "Failed to fetch user info"))
    // )
  }
}

export const saveUser = (user /* , notifications */) => async (
  dispatch,
  getState
) => {
  const state = getState()
  try {
    dispatch(requestUserUpdate(user))

    let userUrl = getApiUrl() + "/api/users/"

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    const { data } = user._id
      ? await axios.put(userUrl + user._id, user, authHeader(useToke))
      : await axios.post(userUrl, user, authHeader(useToke))

    if (!data || !data.success)
      throw (data && data.error) || "Failed to update user"

    return Promise.resolve({ success: "ok" })
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(requestFailed(errorReply)))
    // return Promise.reject(err)
  }
}

export const deleteUser = id => async (dispatch, getState) => {
  const state = getState()
  try {
    dispatch(requestUserDelete(id))
    let customerUrl = getApiUrl() + `/api/users/${id}`

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }
    const { data } = await axios.delete(customerUrl, authHeader(useToke))

    if (!data || !data.success)
      throw (data && data.error) || "Failed to delete user"

    return Promise.resolve(dispatch(fetchUsers()))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(requestFailed(errorReply)))
    // return Promise.reject(err)
  }
}

export const resetPassword = email => async dispatch => {
  try {
    let customerUrl = getApiUrl() + "/api/resetpassword"
    const language = getLanguage()
    const { data } = await axios.post(customerUrl, { email, language })
    // console.log("reset", data)
    if (!data || !data.success)
      throw (data && data.error) || "Failed to reset user"

    return Promise.resolve(/* dispatch(fetchUsers()) */)
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(requestFailed(errorReply)))
    // return Promise.reject(dispatch(errorHandler(err, "Failed to reset user")))
  }
}

export const resetForgottenPassword = user => async dispatch => {
  try {
    let forgotUrl = getApiUrl() + "/api/resetpassword/reset"
    const { data } = await axios.post(forgotUrl, user)

    if (!data || !data.success)
      throw (data && data.error) || "Failed to reset user"

    return Promise.resolve(/* dispatch(fetchUsers()) */)
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(requestFailed(errorReply)))
    // return Promise.reject(dispatch(errorHandler(err, "Failed to reset user")))
  }
}

export const upgradeUser = id => async dispatch => {
  try {
    dispatch(requestUserUpgrade(id))
    let customerUrl = getApiUrl() + `/api/users/${id}/upgrade`
    const { data } = await axios.patch(customerUrl)

    if (!data || !data.success)
      throw (data && data.error) || "Failed to delete user"

    dispatch(ajaxSuccess())
    dispatch(fetchUsers())
  } catch (err) {
    dispatch(errorHandler(err, "Failed to upgrade user"))
  }
}

export const registerUser = (token, password, userdata) => async dispatch => {
  try {
    dispatch(requestUserUpdate(token))
    const username = userdata.username
    const userToApi = JSON.stringify(userdata)
    let customerUrl = getApiUrl() + "/api/register/user"
    const { data } = await axios.post(customerUrl, {
      token,
      username,
      password,
      userData: userToApi
    })
    if (!data || !data.success)
      throw (data && data.error) || "Failed to register user"

    return Promise.resolve(dispatch(success(data.success)))
  } catch (err) {
    console.error("register failed", err)
    // return Promise.reject(dispatch(errorHandler(err, tr("_registerFail"))))
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(requestFailed(errorReply)))
  }
}

export const inviteUser = newUser => async (dispatch, getState) => {
  const state = getState()
  const language = getLanguage()
  try {
    let customerUrl = getApiUrl() + "/api/register"
    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    const { data } = await axios.post(
      customerUrl,
      { ...newUser, language },
      authHeader(useToke)
    )

    if (!data || !data.success)
      throw (data && data.error) || "Failed to fetch user info"

    return Promise.resolve({ success: "ok" })
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(requestFailed(errorReply)))
    // return Promise.reject(
    //   dispatch(errorHandler(err, "Failed to fetch user info"))
    // )
  }
}
