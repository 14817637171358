import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"

import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import * as React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { tr } from "translations"
import { validateTrialcode, registerTrial } from "../actions/registration"
import TextLine from "../components/TextLine"
import { platformBlue, platformWhite } from "../services/colors"

class TrialCodeScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      trialToken: this.props.match.params.token
        ? this.props.match.params.token
        : "",
      error: null,
      isSending: false,
      submitSuccessful: false,
      sendSuccessful: false,
      info: false,
      infoText: "",
      showPassword: false,
      email: ""
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.onInfoTextLinePress = this.onInfoTextLinePress.bind(this)
    this.handleSend = this.handleSend.bind(this)
  }

  async handleSend(e) {
    e.preventDefault()
    const { trialToken, email } = this.state

    this.props
      .registerTrial(trialToken, email)
      .then(() => {
        console.log("code valid")
        this.setState({
          sendSuccessful: true
        })
        setTimeout(() => {
          this.props.history.push("/login")
        }, 5000)
      })
      .catch(err => {
        console.log("Error", err)
        this.setState({
          info: true,
          infoText: "trialTokenNotFound.",
          sendSuccessful: false
        })
      })
  }

  handleChange({ target }) {
    this.setState({ [target.name]: target.value })
  }

  async handleSubmit(e) {
    e.preventDefault()
    const { trialToken } = this.state

    this.props
      .validateTrialcode(trialToken)
      .then(() => {
        this.setState({
          submitSuccessful: true
        })
      })
      .catch(err => {
        console.log("Error", err)
        this.setState({
          info: true,
          infoText: "trialTokenNotFound.",
          submitSuccessful: false
        })
      })
  }

  onInfoTextLinePress() {
    this.setState({ info: false, infoText: "" })
  }

  render() {
    const { classes } = this.props
    const {
      trialToken,
      isSending,
      submitSuccessful,
      sendSuccessful,
      info,
      infoText,
      email
    } = this.state
    return (
      <Grid className={classes.root}>
        <Grid item style={{ overflowY: "auto" }}>
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.root2}
          >
            <Grid
              container
              justify="center"
              alignItems="center"
              direction="column"
              className={classes.signupForm}
              spacing={3}
            >
              <Grid item className={classes.headerItem}>
                <Typography
                  color="primary"
                  variant={"h5"}
                  className={classes.labelText}
                  align="left"
                >
                  {tr("_registertrialcode")}
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  id="trialToken"
                  name="trialToken"
                  label={tr("_trialToken")}
                  className={classes.textInput}
                  value={trialToken}
                  onChange={this.handleChange}
                  InputProps={{
                    classes: {
                      input: classes.inputColor
                    }
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelColor,
                      focused: classes.labelColor,
                      error: classes.labelColor
                    }
                  }}
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <Grid item>
                <Button
                  disabled={
                    trialToken && !isSending && !submitSuccessful ? false : true
                  }
                  className={classes.buttonPadding}
                  onClick={this.handleSubmit}
                >
                  <Typography variant="h6">
                    {tr("_checkToken").toUpperCase()}
                  </Typography>
                </Button>
              </Grid>

              {submitSuccessful && (
                <Grid item>
                  <Typography color="primary" variant="h6">
                    {tr("_trialregistrationInProgress")}
                  </Typography>
                </Grid>
              )}
              {submitSuccessful && (
                <Grid item>
                  <TextField
                    id="email"
                    name="email"
                    label={tr("_email")}
                    className={classes.textInput}
                    value={email}
                    onChange={this.handleChange}
                    InputProps={{
                      classes: {
                        input: classes.inputColor
                      }
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelColor,
                        focused: classes.labelColor,
                        error: classes.labelColor
                      }
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              )}
              {submitSuccessful && (
                <Grid item>
                  <Button
                    disabled={email === "" ? true : false}
                    className={classes.buttonPadding}
                    onClick={this.handleSend}
                  >
                    <Typography variant="h6">
                      {tr("_send").toUpperCase()}
                    </Typography>
                  </Button>
                </Grid>
              )}
              {sendSuccessful && (
                <Grid item>
                  <Typography color="primary" variant="h6">
                    {tr("_sendSuccess")}
                  </Typography>
                </Grid>
              )}

              {info && (
                <Grid item>
                  <TextLine
                    mode={0}
                    text={infoText}
                    onClick={this.onInfoTextLinePress}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: "100vh",
    width: "100vw"
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  dense: {
    marginTop: 16
  },
  menu: {
    width: 200
  },
  root2: {
    flexGrow: 1,
    width: "100vw",
    height: "100vh",
    backgroundImage: theme.loginBackground,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed"
  },
  headerItem: {
    width: 400
  },
  labelText: {
    fontWeight: "bold",
    letterSpacing: "3px"
  },
  labelTextSecondary: {
    fontWeight: "bold",
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  textInput: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
    backgroundColor: "#FFFFFF"
  },
  padding: {
    paddingTop: 10,
    paddingBottom: 10
  },
  buttonPadding: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: "10%",
    paddingRight: "10%",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
    textTransform: "capitalize",
    color: platformWhite,
    // background: "linear-gradient(to right, #46c77f, #18ba60)",
    backgroundColor: platformBlue,
    "&:hover": {
      backgroundColor: platformBlue
    }
  },
  inputColor: {
    color: "black"
  },
  labelColor: {
    color: "gray"
  },
  underline: {
    borderBottom: "1px solid black"
  },
  customTextField: {
    border: "0px"
  }
})

const mapDispatchToProps = dispatch => ({
  validateTrialcode: token => dispatch(validateTrialcode(token)),
  registerTrial: (token, email) => dispatch(registerTrial(token, email))
})

const mapStateToProps = () => {
  return {}
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles, { withTheme: true })(TrialCodeScreen))
)
