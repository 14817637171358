// import { authError } from "./auth"
import axios from "axios"
import { getApiUrl } from "../constants/config"
import { parseErrorData } from "../services/errors"
import { authHeader } from "./common"
import { stateType } from "../constants/actions"
import { deviceDataConvert } from "../services/dataConverter"
import { watchlistGroundplan } from "./websocket"

const iotDeviceApiFailed = error => ({
  type: stateType.SET_ERROR,
  error
})

export const setPageSize = pageSize => ({
  type: stateType.SET_PAGESIZE,
  pageSize
})

const iotDeviceListRequested = () => ({
  type: stateType.IOTDEVICE_LIST_REQUESTED
})

const iotDeviceListReceived = iotDeviceList => ({
  type: stateType.IOTDEVICE_LIST_RECEIVED,
  iotDeviceList
})

const iotDeviceAddRequested = () => ({
  type: stateType.IOTDEVICE_ADD_REQUESTED
})

const iotDeviceAddReceived = iotDevice => ({
  type: stateType.IOTDEVICE_ADD_RECEIVED,
  iotDevice
})

const iotDeviceModifyRequested = () => ({
  type: stateType.IOTDEVICE_MODIFY_REQUESTED
})

const iotDevicePlacementRequested = iotDevice => ({
  type: stateType.IOTDEVICE_PLACEMENT_REQUESTED,
  iotDevice
})
const iotDeviceModifyReceived = iotDevice => ({
  type: stateType.IOTDEVICE_MODIFY_RECEIVED,
  iotDevice
})

const iotDeviceDeleteRequested = () => ({
  type: stateType.IOTDEVICE_DELETE_REQUESTED
})

const iotDeviceDeleteReceived = (iotDeviceId, id) => ({
  type: stateType.IOTDEVICE_DELETE_RECEIVED,
  deviceId: iotDeviceId,
  id: id
})

const devicesFiltered = devices => ({
  type: stateType.IOTDEVICE_FILTER,
  iotDeviceFiltered: devices
})
const sitesFiltered = sitesDevices => ({
  type: stateType.FAULT_DEVICE_FILTER,
  faultSites: sitesDevices
})

const deviceFilter = filter => ({
  type: stateType.DEVICE_FILTER,
  deviceFilter: filter
})

const displayInfo = info => ({
  type: stateType.SET_INFO,
  info
})

export const fetchIoTDevices = deviceId => async (dispatch, getState) => {
  try {
    dispatch(iotDeviceListRequested())
    let deviceUrl = getApiUrl() + "/api/devices"

    if (deviceId) {
      deviceUrl = deviceUrl + "/" + deviceId
    }

    const state = getState()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    const { data } = await axios.get(deviceUrl, authHeader(useToke))

    let deviceList = null
    if (!data || !data.success || !data.devices) {
      throw (data && data.error) || "Failed to fetch iotDevices"
    }

    deviceList = data.devices

    return Promise.resolve(dispatch(iotDeviceListReceived(deviceList)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(iotDeviceApiFailed(errorReply)))
  }
}

export const AddIoTDevices = newIoTDevice => async (dispatch, getState) => {
  try {
    dispatch(iotDeviceAddRequested())
    let deviceUrl = getApiUrl() + "/api/devices"

    const state = getState()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }
    const { data } = await axios.post(
      deviceUrl,
      newIoTDevice,
      authHeader(useToke)
    )
    let device = null

    if (!data || !data.success || !data.device) {
      throw (data && data.error) || "Failed to fetch"
    }

    device = data.device
    let info = { description: "DEVICE_ADDED" }
    dispatch(displayInfo(info))

    return Promise.resolve(dispatch(iotDeviceAddReceived(device)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(iotDeviceApiFailed(errorReply)))
  }
}

export const ModifyIoTDevices = (existingDevice, position) => async (
  dispatch,
  getState
) => {
  try {
    if (position) {
      existingDevice = {
        ...existingDevice,
        pos: {
          x: position.x,
          y: position.y
        }
      }
    }
    dispatch(
      position
        ? iotDevicePlacementRequested(existingDevice)
        : iotDeviceModifyRequested()
    )
    let deviceUrl = getApiUrl() + "/api/devices/" + existingDevice.deviceId
    const state = getState()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    const { data } = await axios.put(
      deviceUrl,
      existingDevice,
      authHeader(useToke)
    )
    let device = null

    if (!data || !data.success || !data.device) {
      throw (data && data.error) || "Failed to fetch"
    }

    device = data.device
    let info = { description: "DEVICE_MODIFIED" }
    dispatch(displayInfo(info))

    return Promise.resolve(dispatch(iotDeviceModifyReceived(device)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(iotDeviceApiFailed(errorReply)))
  }
}

export const AssignIoTDevice = deviceId => async (dispatch, getState) => {
  try {
    dispatch(iotDeviceModifyRequested())
    let deviceUrl = getApiUrl() + "/api/devices/" + deviceId

    const state = getState()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    const { data } = await axios.put(
      deviceUrl,
      { assignmentDate: new Date() },
      authHeader(useToke)
    )
    let device = null

    if (!data || !data.success || !data.device) {
      throw (data && data.error) || "Failed to fetch"
    }

    device = data.device

    return Promise.resolve(dispatch(iotDeviceModifyReceived(device)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(iotDeviceApiFailed(errorReply)))
  }
}

export const DeleteIoTDevices = deviceId => async (dispatch, getState) => {
  try {
    dispatch(iotDeviceDeleteRequested())

    let deviceUrl = getApiUrl() + "/api/devices/" + deviceId

    const state = getState()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    const { data } = await axios.delete(deviceUrl, authHeader(useToke))

    if (!data || !data.success) {
      throw (data && data.error) || "Failed to fetch"
    }
    let info = { description: "DEVICE_DELETED" }
    dispatch(displayInfo(info))

    return Promise.resolve(dispatch(iotDeviceDeleteReceived(deviceId, data.id)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(iotDeviceApiFailed(errorReply)))
  }
}

const requestIoTDeviceUpgrade = id => ({
  type: "IOTDEVICE_DELETE_REQUESTED",
  id
})

const ajaxSuccess = () => ({ type: "IOTDEVICE_AJAX_SUCCESS" })

const ajaxError = error => ({
  type: "IOTDEVICE_AJAX_ERROR",
  error
})
const errorHandler = (err, response = "Internal server error") => {
  // if ((err && err.status === 401) || /401/.test(err)) return authError()

  const error =
    (err && err.message) || (err.toString && err.toString()) || response
  return ajaxError(error)
}

export const upgradeIoTDevice = id => async dispatch => {
  try {
    dispatch(requestIoTDeviceUpgrade(id))
    let deviceUrl = getApiUrl()
    let todochangename
    const { data } = await axios.patch(
      deviceUrl + `/api/devices/${id}/upgrade`,
      todochangename
    )

    if (!data || !data.success)
      throw (data && data.error) || "Failed to delete todochangename"

    dispatch(ajaxSuccess())
    dispatch(fetchIoTDevices())
  } catch (err) {
    dispatch(errorHandler(err, "Failed to upgrade todochangename"))
  }
}

export const updateFilter = type => async (dispatch, getState) => {
  const state = getState()
  try {
    let newFilter = Object.assign([], state.iotDevices.deviceFilter)
    const index = newFilter.indexOf(type)
    if (index > -1) {
      newFilter.splice(index, 1)
    } else {
      newFilter.push(type)
    }
    return Promise.resolve(dispatch(deviceFilter(newFilter)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(errorReply)
  }
}

export const FilterDevices = (targetSite, area, type) => async (
  dispatch,
  getState
) => {
  const state = getState()
  const site = state.sites.siteList.find(site => site._id === targetSite)
  const devices = state.iotDevices.iotDeviceList.filter(
    device =>
      site &&
      device.site === site._id &&
      (!device.area || (area && device.area === area._id)) &&
      (!type ||
        (type && (type.length === 0 || type.includes(device.deviceType))))
  )
  dispatch(devicesFiltered(devices))
  let watch = []
  devices.forEach(dev => {
    watch.push(dev.deviceId)
  })
  dispatch(watchlistGroundplan(watch))
}


export const FilterFloorDevices = (targetSite, targetFloor, type) => async (
  dispatch,
  getState
) => {
  const state = getState()
  const site = state.sites.siteList.find(site => site._id === targetSite)
  const devices = state.iotDevices.iotDeviceList.filter(
    device =>
      site &&
      device.site === site._id &&
      (state.areas.areaList.find(area => area._id === device.area))?.floor  === targetFloor
      &&
      (!type ||
        (type && (type.length === 0 || type.includes(device.deviceType))))
  )
  dispatch(devicesFiltered(devices))
  let watch = []
  devices.forEach(dev => {
    watch.push(dev.deviceId)
  })
  dispatch(watchlistGroundplan(watch))
}

export const FilterDevicesForSite = (site, type) => async (
  dispatch,
  getState
) => {
  try {
    const state = getState()

    const devices = state.iotDevices.iotDeviceList.filter(
      device =>
        site &&
        device.site === site._id &&
        (!type ||
          (type && (type.length === 0 || type.includes(device.deviceType))))
    )
    return Promise.resolve(dispatch(devicesFiltered(devices)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(errorReply)
  }
}

export const filterAlertDeviceSites = () => async (dispatch, getState) => {
  try {
    const state = getState()

    const sitesDevices = {}
    state.iotDevices.iotDeviceList.forEach(device => {
      let count = 1
      let faults = 0
      let unassigned = 0
      if (sitesDevices[device.site]) {
        count = sitesDevices[device.site].devices + 1

        if (device.status === "FAULT") {
          faults = sitesDevices[device.site].faults + 1
        } else {
          faults = sitesDevices[device.site].faults
        }

        if (device.area === null) {
          unassigned = sitesDevices[device.site].unassigned + 1
        } else {
          unassigned = sitesDevices[device.site].unassigned
        }
      } else {
        if (device.status === "FAULT") {
          faults = 1
        }

        if (device.area === null) {
          unassigned = 1
        }
      }

      sitesDevices[device.site] = {
        devices: count,
        faults,
        unassigned
      }
    })

    return Promise.resolve(dispatch(sitesFiltered(sitesDevices)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(errorReply)
  }
}

export const checkIoTDevices = update => async (dispatch, getState) => {
  try {
    let deviceUrl = getApiUrl() + "/api/devices"

    const state = getState()
    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    const { data } = await axios.get(deviceUrl, authHeader(useToke))

    let deviceList = null
    if (!data || !data.success || !data.devices) {
      throw (data && data.error) || "Failed to fetch iotDevices"
    }

    deviceList = data.devices
    let refresh = false
    deviceList.forEach((device, i) => {
      if (device.status !== state.iotDevices.iotDeviceList[i].status) {
        refresh = true
      }
    })
    if (refresh) {
      update()
      return Promise.resolve(dispatch(iotDeviceListReceived(deviceList)))
    } else {
      // console.log("all is good")
    }
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(iotDeviceApiFailed(errorReply)))
  }
}

export const wsDeviceUpdate = iotdata => async (dispatch, getState) => {
  //console.log("wsDeviceUpdate: ", iotdata)
  const state = getState()
  const devices = state.iotDevices
  //console.log("state.iotDevices: ", devices)
  let device = devices.iotDeviceList.find(d => {
    return d.deviceId === iotdata.deviceId
  })
  device = {
    ...device,
    ...iotdata.data
  }
  //console.log("device", device)
  dispatch(iotDeviceModifyReceived(deviceDataConvert(device)))
}
