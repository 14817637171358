export default (
  state = {
    favouriteWidgets: [],
    isFetching: false
  },
  action
) => {
  switch (action.type) {
    case "FAVOURITEWIDGET_LIST_REQUESTED":
      return {
        ...state,
        isFetching: true
      }
    case "FAVOURITEWIDGET_LIST_RECEIVED":
      return {
        ...state,
        isFetching: false,
        favouriteWidgets: action.favouriteWidgetList
      }
    case "FAVOURITEWIDGET_ADD_RECEIVED": {
      let { favouriteWidgets } = state

      const index = favouriteWidgets.findIndex(
        elem => elem.name === action.widget.name
      )
      if (index != -1) {
        favouriteWidgets[index] = action.widget
      } else {
        favouriteWidgets.push(action.widget)
      }
      return {
        ...state,
        isFetching: false,
        favouriteWidgets
      }
    }
    default:
      return state
  }
}
