// reducer for controlling and observing websocket states

const initialState = {
  wsConnecting: false,
  wsConnected: null,
  wsDisConnecting: false,
  dashboardDevices: [],
  groundplanDevices: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case "WS_CONNECT": {
      console.log("ws connect")
      return {
        ...initialState
      }
    }
    case "WS_CONNECTING": {
      console.log("ws connecting")
      return {
        ...state,
        wsConnecting: true
      }
    }
    case "WS_CONNECTED": {
      console.log("ws connected")
      return {
        ...state,
        wsConnecting: false,
        wsConnected: action.ws
      }
    }
    case "WS_DISCONNECT": {
      console.log("ws disconnect")
      return {
        ...state,
        wsDisConnecting: true
      }
    }
    case "WS_DISCONNECTED": {
      console.log("ws disconnected")
      return {
        ...initialState
      }
    }
    case "UPDATE_WATCHLIST": {
      // console.log("update watchlist", action)
      return {
        ...state,
        dashboardDevices: action.dashboardDevices,
        groundplanDevices: action.groundplanDevices
      }
    }
    case "CLEAN_WATCHLIST": {
      console.log("clean watchlist")
      return {
        ...state,
        dashboardDevices: [],
        groundplanDevices: []
      }
    }
    default:
      return state
  }
}
