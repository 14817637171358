import React from "react"

import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import withStyles from "@material-ui/core/styles/withStyles"

import { tr } from "translations"
import SearchIcon from "@material-ui/icons/Search"

const SearchBox = props => {
  const { classes, handleSearch, width } = props

  return (
    <Paper className={classes.searchPaper} style={{ width }}>
      <Grid container alignItems="flex-end" spacing={0}>
        <Grid item xs={2}>
          <SearchIcon style={{ marginRight: 15, height: 26, width: 26 }} />
        </Grid>
        <Grid item xs={8}>
          <TextField
            margin="none"
            label={tr("_search")}
            InputProps={{
              classes: {
                input: classes.inputColor
              }
            }}
            InputLabelProps={{
              classes: {
                root: classes.inputColor
              }
            }}
            onChange={handleSearch}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

const styles = theme => ({
  inputColor: {
    color: theme.palette.text.primary
  },
  searchPaper: {
    paddingLeft: 20,
    height: 60,
    [theme.breakpoints.down("md")]: {
      height: 50
    },

    backgroundColor: theme.palette.primary.dark
  }
})

export default withStyles(styles)(SearchBox)
