export const platformWhite = "#FFFFFF"
export const platformAlmostWhite = "#F9F9F9"
export const platformTextPrimary = "#CCD4D9" // lightgray
export const platformTextSecondary = "#4CBF9A" // turqoise
export const platformTextMinor = "#909090"
export const platformBlue = "#0066B2"
export const platformDarkBlue = "#00192D"
export const platformDarkBlueSelected = "#002643"
export const platformLightRed = "#DB9CAF"
export const platformRed = "#A60838"
export const platformYellow = "#FFF26E"

export const platformLightMain = "#F9FAFA" // very light gray
export const platformLightDark = "#EBEEF0" // light gray
export const platformLightAdditional = "#CCD4D9" // gray
export const platformLightTextPrimary = "#002643" // dark blue
export const platformLightTextSecondary = "#009C6B" // green
export const platformLightWarning = "#F3A200" // orange
export const platformLightAlert = "#A60838" // red
export const platformLightMinorAlert = "#DB9CAF" // light red
export const threateningAlert = "#C60838" // threatening red

export const platformPaleBlue = "#8BB9DA" // pale blue
export const platformPurple = "#832c90" // purple

export const platformLoginLabel = "#99C2E0"
export const tableHeadLabelDark = "#99C2E0"


// line widget default colors
export const lineDefaultColors = [
  "#D391A3", // red
  "#7DBD65",  // green
  "#8BB9DA",  // blue
  "#A66CD1",  // purple
  "#C7B50B",  // yellow
  "#B6B6B6"   // gray
]
