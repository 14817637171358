export default (
  state = {
    customerList: [],
    isFetching: false,
    error: null,
    shouldRefresh: false
  },
  action
) => {
  switch (action.type) {
    case "CUSTOMER_LIST_REQUESTED":
    case "CUSTOMER_ADD_REQUESTED":
    case "CUSTOMER_UPDATE_REQUESTED":
    case "CUSTOMER_DELETE_REQUESTED":
    case "CUSTOMER_UPGRADE_REQUESTED":
      return {
        ...state,
        isFetching: true,
        shouldRefresh: false
      }
    case "CUSTOMER_MODIFY_RECEIVED": {
      let { customerList } = state
      const newList = customerList.map(customer => {
        if (customer._id === action.customer._id) {
          return action.customer
        } else {
          return customer
        }
      })

      return {
        ...state,
        isFetching: false,
        customer: action.customer,
        customerList: newList,
        error: null,
        lastUpdated: Date.now()
      }
    }
    case "CUSTOMER_ADD_RECEIVED": {
      const { customerList } = state
      const newList = [...customerList, action.customer]
      return {
        ...state,
        isFetching: false,
        customer: action.customer,
        customerList: newList,
        error: null,
        lastUpdated: Date.now()
      }
    }
    case "CUSTOMER_LIST_RECEIVED":
      return {
        ...state,
        isFetching: false,
        shouldRefresh: false,
        customerList: action.customerList,
        lastUpdated: Date.now()
      }
    case "CUSTOMER_DELETE_RECEIVED": {
      let { customerList } = state

      let newList = customerList.filter(function(item) {
        if (item._id == action._id) {
          return false
        } else {
          return true
        }
      })
      return {
        ...state,
        isFetching: false,
        customer: action.customer,
        customerList: newList,
        error: null,
        lastUpdated: Date.now()
      }
    }
    case "CUSTOMER_AJAX_SUCCESS":
      return { ...state, isFetching: false }

    case "CUSTOMER_AJAX_ERROR":
      return {
        ...state,
        isFetching: false,
        shouldRefresh: true,
        error: action.error
      }

    case "CUSTOMER_RESET_ERROR":
      return { ...state, error: null }

    default:
      return state
  }
}
