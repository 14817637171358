import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"

import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import TextField from "@material-ui/core/TextField"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"

import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"

import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import FilterModal from "../DeviceList/FilterModal"
import LimitsTablePagination from "./LimitsTablePagination"
import DeviceDetail from "../GroundPlan/DeviceDetail"
import DefineDeviceDialog from "../Dashboard/DashboardWidgets/settingModals/parts/settingDeviceByTypesSites"
import { tr } from "translations"

import LimitData from "./LimitData"

const VALUE_TYPES = {
  1: "temperature",
  2: "humidity",
  3: "pressure",
  4: "voc",
  5: "co2",
  6: "custom"
}

const LimitsTable = (props) => {
  const {
    classes,
    sitesExpanded,
    sitesWithColors,
    search,
    handleSave,
    handleDelete
  } = props

  const [rows, setRows] = useState([])
  const [filteredRows, setFilteredRows] = useState([])
  const [sortedRows, setSortedRows] = useState([])
  const [shownRows, setShownRows] = useState([])
  const [filter, setFilter] = useState({})
  const [sort, setSort] = useState({ sortBy: "status", order: "desc" })
  const [anchorEl, setAnchorEl] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalOptions, setModalOptions] = useState([])
  const [modalHeader, setModalHeader] = useState("")
  const [pageSize, setPageSize] = useState(5)
  const [page, setPage] = useState(0)
  const [deviceinfoOpen, setDeviceInfoOpen] = useState(false)
  const [device, setDevice] = useState({})
  const [showValuetor, setshowValuetor] = useState(false)
  const [selectedDeviceRow, setSelectedDeviceRow] = useState(0)
  const [formUpdated, setFormUpdated] = useState([])
  const [validRows, setValidRows] = useState([])
  const [tableItems, setTableItems] = useState([])
  const [tableRows, setTableRows] = useState({})

  const limitsList = useSelector((state) => state.limits.limits)
  const iotDeviceList = useSelector((state) => state.iotDevices.iotDeviceList)
  const customerList = useSelector((state) => state.customers.customerList)
  const siteList = useSelector((state) => state.sites.siteList)
  const areaList = useSelector((state) => state.areas.areaList)

  useEffect(() => {
    const tempRows = []
    limitsList.forEach((lim) => {
      const {
        _id,
        siteId,
        deviceIds,
        name,
        minValue,
        maxValue,
        valueType, // 1=temperature, 2=humidity, 3=pressure, 4=voc, 5=co2, 6=custom
        limitType
      } = lim

      const siteItem = siteList.find((siteFound) => siteFound._id === siteId)
      const siteName = siteItem ? siteItem.name : ""

      const tempDevices = []
      deviceIds.forEach((deviceId) => {
        const device = iotDeviceList.find(
          (deviceFound) => deviceFound._id === deviceId
        )
        if (device != null) {
          tempDevices.push(device)
        }
      })

      const row = {
        id: _id,
        name,
        type: VALUE_TYPES[valueType],
        typeId: valueType,
        minValue,
        maxValue,
        site: siteName,
        siteId,
        limitType,
        devices: tempDevices
      }
      tempRows.push(row)
    })

    setRows(tempRows)
    setFilteredRows(tempRows)
    // eslint-disable-next-line
  }, [limitsList, customerList, siteList, areaList]) /*iotDeviceList,*/

  useEffect(() => {
    if (sitesExpanded)
      setPageSize(Math.max(4, Math.floor((window.innerHeight / 937) * 5)))
    else setPageSize(Math.max(4, Math.floor((window.innerHeight / 937) * 5)))
  }, [sitesExpanded])

  // Filtering useeffect, runs when filter or search changes
  useEffect(() => {
    let tempRows = [...rows]
    if (Object.keys(filter).length > 0) {
      Object.keys(filter).forEach((key) => {
        tempRows = tempRows.filter((row) => filter[key].includes(row[key]))
      })
    }

    if (search.length > 0) {
      const searchedRows = []
      const lowerCaseSearch = search.toLowerCase()
      for (let i = 0; i < tempRows.length; i++) {
        let keys = Object.keys(tempRows[i])
        for (let j = 0; j < keys.length; j++) {
          if (
            tempRows[i][keys[j]] !== null &&
            tempRows[i][keys[j]] !== undefined
          ) {
            if (
              tempRows[i][keys[j]]
                .toString()
                .toLowerCase()
                .indexOf(lowerCaseSearch) !== -1 &&
              keys[j] !== "id" &&
              keys[j] !== "siteId"
            ) {
              searchedRows.push(tempRows[i])
              break
            }
          }
        }
      }

      tempRows = searchedRows
    }

    setFilteredRows(tempRows)
  }, [filter, rows, search])

  // Sorting useeffect, run when sort changes
  useEffect(() => {
    // Only sort rows that have actual values, concat rest of the rows to end
    const dataRows = filteredRows.filter((row) => row[sort.sortBy] !== null)
    const nullRows = filteredRows.filter((row) => row[sort.sortBy] === null)

    dataRows.sort((a, b) => {
      if (typeof a[sort.sortBy] === "string") {
        return sort.order === "asc"
          ? a[sort.sortBy].localeCompare(b[sort.sortBy])
          : -a[sort.sortBy].localeCompare(b[sort.sortBy])
      }
      if (typeof a[sort.sortBy] === "number") {
        return sort.order === "asc"
          ? a[sort.sortBy] - b[sort.sortBy]
          : b[sort.sortBy] - a[sort.sortBy]
      }
      return 1
    })

    setSortedRows(dataRows.concat(nullRows))
  }, [sort, filteredRows])

  // Pagefilter useeffect, runs when page or pagesize changes
  useEffect(() => {
    let tempRows = [...sortedRows]

    if (tempRows.length > 0) {
      if (page >= Math.ceil(tempRows.length / pageSize))
        setPage(Math.ceil(tempRows.length / pageSize) - 1)

      if (page === Math.ceil(tempRows.length / pageSize) - 1)
        tempRows = tempRows.slice(page * pageSize)
      else
        tempRows = tempRows.slice(page * pageSize, page * pageSize + pageSize)
    }
    const itemsArray = []
    const tableObject = {}
    tempRows.forEach((row) => {
      tableObject[row.id] = row
      itemsArray.push(row.id)
    })
    setTableRows(tableObject)
    setTableItems(itemsArray)
    setShownRows(tempRows)
  }, [page, pageSize, sortedRows])

  useEffect(
    () => {
      const readyRows = []
      formUpdated.forEach((row) => {
        if (
          tableRows[row].name &&
          tableRows[row].type &&
          tableRows[row].site &&
          tableRows[row].devices.length &&
          parseInt(tableRows[row].maxValue, 10) >
            parseInt(tableRows[row].minValue, 10)
        ) {
          readyRows.push(row)
        }
      })

      setValidRows(readyRows)
    },
    // eslint-disable-next-line
    [formUpdated]
  )

  const handleSort = (value) => {
    if (sort.sortBy === value) {
      const order = sort.order === "asc" ? "desc" : "asc"
      setSort({ sortBy: value, order: order })
    } else {
      setSort({ sortBy: value, order: "asc" })
    }
  }

  const handleModalOpen = (event, value) => {
    setAnchorEl(event.currentTarget)
    setModalOpen(true)
    setModalHeader(value)

    const options = [...new Set(rows.map((row) => row[value]))]
    const noNullsOptions = options.filter((option) => option !== null)
    const noEmptyStringsOptions = noNullsOptions.filter(
      (option) => option.length !== 0
    )
    setModalOptions(noEmptyStringsOptions)
  }

  const handleModalClose = () => {
    setAnchorEl(null)
    setModalOptions([])
    setModalHeader("")
    setModalOpen(false)
  }

  const handleFilterChange = (event, value) => {
    const tempFilter = JSON.parse(JSON.stringify(filter))
    if (tempFilter[modalHeader] !== undefined) {
      const index = tempFilter[modalHeader].findIndex(
        (filterValue) => filterValue === value
      )
      if (index < 0) tempFilter[modalHeader].push(value)
      else tempFilter[modalHeader].splice(index, 1)

      if (tempFilter[modalHeader].length === 0) delete tempFilter[modalHeader]
    } else {
      tempFilter[modalHeader] = []
      tempFilter[modalHeader].push(value)
    }

    setFilter(tempFilter)
  }

  const handleFilterClear = () => {
    const tempFilter = JSON.parse(JSON.stringify(filter))
    if (tempFilter[modalHeader] !== undefined) delete tempFilter[modalHeader]
    setFilter(tempFilter)
  }

  const handlePageChange = (value) => {
    if (value >= 0 && value <= Math.ceil(sortedRows.length / pageSize)) {
      setPage(value)
    }
  }

  const infoClose = () => {
    setAnchorEl(null)
    setDeviceInfoOpen(false)
    setDevice(null)
  }

  const handleRowUpdate = (row, addRow = true) => {
    const updatedRows = [...formUpdated.filter((item) => item !== row)]
    if (addRow) {
      updatedRows.push(row)
    }
    setFormUpdated(updatedRows)
  }

  const handleSelectChange = ({ target }, element) => {
    if (element.props.rowid !== undefined) {
      const tempObject = { ...tableRows }
      tempObject[element.props.rowid][target.name] = target.value
      tempObject[element.props.rowid][target.name + "Id"] =
        element.props.elementkey
      setTableRows(tempObject)
      handleRowUpdate(element.props.rowid)
    }
  }
  const handleChange = (target, row, value) => {
    if (row !== undefined) {
      const tempObject = { ...tableRows }
      tempObject[row][target] = value
      setTableRows(tempObject)
    }
  }

  const handleLimitChange = (target, row, value) => {
    handleChange(target, row, value)
    handleRowUpdate(row)
  }

  const saveLimit = (row) => {
    const limit = shownRows.find((item) => item.id === row)
    if (limit !== null) {
      handleSave(limit)
      handleRowUpdate(row, false)
    }
  }

  const deleteLimit = (row) => {
    const limit = shownRows.find((item) => item.id === row)
    if (limit !== null) {
      handleDelete(limit)
      handleRowUpdate(row, false)
    }
  }

  // for device selection dialog
  const types = ["TreonNode", "TreonAssetNode"]
  const toggleValueitor = (row) => {
    if (row !== undefined) {
      setSelectedDeviceRow(row)
    }
    setshowValuetor(!showValuetor)
  }
  const saveValues = (values) => {
    setshowValuetor(!showValuetor)

    const tempRows = { ...tableRows }
    tempRows[selectedDeviceRow].devices = values
    setTableRows(tempRows)
    handleRowUpdate(selectedDeviceRow)
  }

  return (
    <Grid container className={classes.grid} spacing={3}>
      <Grid item xs={12} align="center">
        {showValuetor ? (
          <DefineDeviceDialog
            cancelValues={toggleValueitor}
            saveValues={saveValues}
            deviceValues={tableRows[selectedDeviceRow].devices}
            deviceTypes={types}
          />
        ) : (
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableHead}>
                {["name", "type", "alert_terms", "site", "devices"].map(
                  (value) => (
                    <TableCell
                      align="left"
                      className={classes.tableHeadCell}
                      key={value}
                    >
                      <TableSortLabel
                        classes={{
                          icon:
                            sort.sortBy === value
                              ? classes.activeSortIcon
                              : classes.inactiveSortIcon
                        }}
                        active={sort.sortBy === value}
                        onClick={() => handleSort(value)}
                        direction={sort.sortBy === value ? sort.order : "asc"}
                      />
                      {["type", "site"].includes(value) ? (
                        <FormControlLabel
                          className={classes.controlLabel}
                          control={<ExpandMoreIcon fontSize="large" />}
                          label={
                            <Typography variant="h6">
                              {value === "company"
                                ? tr(`Company`)
                                : tr(`_${value}`)}
                            </Typography>
                          }
                          labelPlacement="start"
                          onClick={(e) => handleModalOpen(e, value)}
                        />
                      ) : (
                        <Typography
                          variant="h6"
                          style={{ display: "inline-block" }}
                        >
                          {value === "data" ? "Data" : tr(`_${value}`)}
                        </Typography>
                      )}
                    </TableCell>
                  )
                )}
                <TableCell
                  align="right"
                  className={classes.clearFiltersCell}
                  onClick={() => setFilter({})}
                >
                  {tr("_clear_filter")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableItems.map((rowId) => (
                <TableRow key={rowId} className={classes.tableRowBody}>
                  <TableCell
                    align="left"
                    className={classes.tableCell}
                    key={"name"}
                  >
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        label={tr("name")}
                        name={"name"}
                        InputLabelProps={{
                          className: classes.colorSecondary
                        }}
                        className={classes.textField}
                        value={tableRows[rowId].name}
                        onChange={(event) =>
                          handleChange("name", rowId, event.target.value)
                        }
                        margin="normal"
                        onBlur={() => handleRowUpdate(rowId)}
                      />
                    </Grid>
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.tableCell}
                    key={"type"}
                  >
                    <Grid item xs={12} md={12} lg={12}>
                      <FormControl className={classes.textFieldForm}>
                        <InputLabel
                          className={classes.colorSecondary}
                          htmlFor="type-controlled-open-select"
                        >
                          {tr("type")}
                        </InputLabel>
                        <Select
                          required
                          value={tableRows[rowId].type || ""}
                          onChange={handleSelectChange}
                          inputProps={{
                            name: "type",
                            id: "type-controlled-open-select"
                          }}
                        >
                          <MenuItem value="">
                            <em>{tr("_select_type")}</em>
                          </MenuItem>
                          {Object.keys(VALUE_TYPES).map((key) => {
                            return (
                              <MenuItem
                                value={VALUE_TYPES[key]}
                                key={key}
                                rowid={rowId}
                                elementkey={key}
                              >
                                {VALUE_TYPES[key]}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </TableCell>
                  <TableCell className={classes.dataCell}>
                    <Grid item xs={12} md={12} lg={12}>
                      {tableRows[rowId].data === null ? (
                        <Typography>{tr("_unknown")}</Typography>
                      ) : (
                        <LimitData
                          minValue={tableRows[rowId].minValue}
                          maxValue={tableRows[rowId].maxValue}
                          type={tableRows[rowId].type}
                          handleChange={handleLimitChange}
                          rowid={rowId}
                          limitType={tableRows[rowId].limitType}
                        />
                      )}
                    </Grid>
                  </TableCell>

                  <TableCell
                    align="left"
                    className={classes.tableCell}
                    key={"site"}
                  >
                    <Select
                      required
                      style={{
                        color: sitesWithColors[tableRows[rowId].site]
                          ? sitesWithColors[tableRows[rowId].site]
                          : "inherit"
                      }}
                      value={tableRows[rowId].site || ""}
                      onChange={handleSelectChange}
                      inputProps={{
                        name: "site",
                        id: "site-open-select"
                      }}
                    >
                      <MenuItem value="">
                        <em>{tr("_select_site")}</em>
                      </MenuItem>
                      {siteList.map((site) => {
                        return (
                          <MenuItem
                            value={site.name}
                            key={site._id}
                            rowid={rowId}
                            elementkey={site._id}
                          >
                            {site.name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </TableCell>

                  <TableCell
                    align="left"
                    className={classes.tableCell}
                    key={"devices"}
                  >
                    {/* dropdown input, ei voi editoida. vieressa nappi, joka avaa device selectorin */}
                    <Grid item xs={12} md={12} lg={12}>
                      <FormControl className={classes.textFieldForm}>
                        <InputLabel
                          className={classes.colorSecondary}
                          htmlFor="type-controlled-open-select"
                        >
                          {tr("_devices")}
                        </InputLabel>
                        <Select
                          required
                          value={
                            tableRows[rowId].devices.length > 0
                              ? tableRows[rowId].devices[0].name
                              : ""
                          }
                          //onChange={handleSelectChange}
                          placeholder="No devices"
                          inputProps={{
                            name: "type",
                            id: "device-select"
                          }}
                        >
                          <MenuItem value="">
                            <em>{tr("_selected_devices")}</em>
                          </MenuItem>
                          {tableRows[rowId].devices.map((type) => {
                            return (
                              <MenuItem value={type.name} key={type.name}>
                                {type.name}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      container
                      justify="flex-start"
                      item
                      xs={12}
                      className={classes.buttons}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => toggleValueitor(rowId)}
                        className={classes.button}
                      >
                        {tr("_add_devices")}
                      </Button>
                    </Grid>
                  </TableCell>
                  <TableCell align="right" className={classes.tableCell}>
                    <Grid justify="flex-end" container>
                      <Grid item xs={12} md={8} lg={8}>
                        <FormControl
                          className={classes.textFieldForm}
                          style={{ marginRight: 0 }}
                        >
                          <Button
                            variant="contained"
                            onClick={() => deleteLimit(rowId)}
                            className={(classes.removeButton)}
                          >
                            {tr("_delete")}
                          </Button>
                          <Button
                            disabled={!validRows.includes(rowId)}
                            variant="contained"
                            color="secondary"
                            onClick={() => saveLimit(rowId)}
                            className={classes.saveButton}
                          >
                            {tr("_save")}
                          </Button>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Grid>
      <Grid item xs={12} className={classes.pagination}>
        {sortedRows.length > pageSize && (
          <LimitsTablePagination
            page={page}
            maxPage={Math.ceil(sortedRows.length / pageSize) - 1}
            changePage={handlePageChange}
          />
        )}
      </Grid>
      {modalOpen && (
        <FilterModal
          anchorEl={anchorEl}
          open={modalOpen}
          handleClose={handleModalClose}
          filter={filter}
          handleChange={handleFilterChange}
          handleClear={handleFilterClear}
          options={modalOptions}
          header={modalHeader}
          sitesWithColors={sitesWithColors}
        />
      )}
      {device && (
        <DeviceDetail
          isOpen={deviceinfoOpen}
          anchorEl={anchorEl}
          details={device}
          onClose={infoClose}
          handleEdit={() => console.log("onClick not implemented")}
        />
      )}
    </Grid>
  )
}

const styles = (theme) => ({
  grid: {
    background: "transparent",
    margin: "auto",
    height: "100%",
    paddingBottom: "84px", // Navbar takes 64px rest for spacing
    flexGrow: 1,
    overflowX: "auto",
    maxWidth: "96%"
  },
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%"
  },
  textFieldForm: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%"
  },
  button: {
    margin: 10,
    color: theme.palette.secondary.contrasted,
    backgroundColor: theme.palette.secondary.themed
  },
  table: {
    borderSpacing: "0px 10px",
    borderCollapse: "separate"
  },
  tableHead: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.dark
  },
  tableHeadCell: {
    color: theme.palette.text.field,
    borderBottom: "none",
    fontSize: "16pt",
    padding: "5px"
  },
  tableRowBody: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.table.tableRowBackground,
    alignContent: "center",
    padding: theme.spacing(1)
  },
  tableSortLabel: {
    "&:active": {
      color: "#FFFFFF"
    }
  },
  tableCell: {
    borderBottom: "none",
    padding: "0px 15px 0px 15px"
  },
  dataCell: {
    maxWidth: "300px",
    minWidth: "250px",
    borderBottom: "none",
    padding: "0px 1px 0px 1px",
    margin: "0px"
  },
  statusOK: {
    color: "#4CBF9A",
    fontSize: "16px"
  },
  statusFAULT: {
    color: "#A60838",
    fontSize: "16px"
  },
  infoIcon: {
    "&:hover": {
      cursor: "pointer"
    }
  },
  activeSortIcon: {
    opacity: 1
  },
  inactiveSortIcon: {
    opacity: 0.5
  },
  clearFiltersCell: {
    borderBottom: "none",
    fontSize: "10pt",
    "&:hover": {
      cursor: "pointer"
    },
    paddingLeft: "0px"
  },
  controlLabel: {
    margin: "1px"
  },
  pagination: {
    marginBottom: "2%",
    marginTop: "auto"
  },
  saveButton: {
    margin: 10,
    backgroundColor: theme.palette.action.main,
    color: theme.palette.secondary.themed,
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
  colorSecondary:{
    color: theme.palette.action.main + " !important",   
  },
  removeButton: {
    margin: 10,
    color: theme.palette.unselected.main,
    backgroundColor: theme.palette.error.dark,
    "&:hover": {
      color: theme.palette.error.dark,
    },
  }
})

export default withStyles(styles, { withTheme: true })(LimitsTable)
