import * as React from "react"

import Grid from "@material-ui/core/Grid"
import withStyles from "@material-ui/core/styles/withStyles"

import Button from "@material-ui/core/Button"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"
import * as axios from "axios"
import { connect } from "react-redux"
import { tr } from "translations"
import { fetchCustomers } from "../../../../actions/customers"
import {
  AddSites,
  DeleteSites,
  fetchSites,
  ModifySites,
  SubscripeAlerts
} from "../../../../actions/sites"
import { fetchUsers } from "../../../../actions/users"
import { ManageHeader } from "../../../../components/manageMenu/manageHeader"

class SitesAddNew extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      customer: "",
      name: "",
      address: "",
      zip: "",
      city: "",
      country: "",
      lat: "",
      lng: "",
      gateways: [""],
      contactPerson: "",
      contactPhone: "",
      contactEmail: "",
      contactInformation: "",
      siteGroup: "",
      phoneSubscription: [],
      phoneSubscriptionChecked: false,
      users: "",
      error: null,
      formUpdated: false,
      //   isSending: false,
      submitSuccessful: false,
      expanded: null
    }

    this.geocodeAddress = this.geocodeAddress.bind(this)
    this.handleAddSites = this.handleAddSites.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleChangePanel = this.handleChangePanel.bind(this)
    this.handleChechbox = this.handleChechbox.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleModifySite = this.handleModifySite.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.resetError = this.resetError.bind(this)
  }

  componentDidMount() {
    this.props.fetchUsers()
    this.props.fetchSites()
    this.props.fetchCustomers()
  }

  handleAddSites() {
    this.props.onSelectView(0)
  }

  geocodeAddress() {
    return new Promise((resolve, reject) => {
      let key = "AIzaSyDqkPzWzLFtFaAHCmgiL4vAP1qO-LndYrk"
      let address =
        this.state.address +
        "+" +
        this.state.city +
        "+" +
        this.state.zip +
        "+" +
        this.state.country

      axios
        .get(
          "https://maps.googleapis.com/maps/api/geocode/json?address=" +
            address +
            "&key=" +
            key
        )
        .then(res => {
          const { lat, lng } = res.data.results[0].geometry.location
          resolve({ lat, lng })
        })
        .catch(err => {
          console.log("Geocode error: ", err)
          reject({
            error: "Geocode API error - use default location",
            lat: 61.446716,
            lng: 23.857413
          })
        })
    })
  }

  handleChange({ target }) {
    this.setState({ [target.name]: target.value, formUpdated: true })
  }

  handleChechbox(event, _id, name) {
    const target = event.target

    let subscription = { _id }
    switch (name) {
      case "phoneSubscriptionChecked":
        subscription.phone = target.checked
        break
      case "emailSubscriptionChecked":
        subscription.email = target.checked
        break
    }
    this.props.SubscripeAlerts(subscription).then(() => {
      this.props.fetchSites()
    })
  }

  async handleDelete(id) {
    this.props.DeleteSites(id).then(() => this.props.fetchSites())
  }

  handleModifySite(id, group) {
    let newGroup = Object.assign([], group)
    let index = newGroup.indexOf(this.state.users)
    if (index > -1) {
      newGroup.splice(index, 1)
    } else {
      newGroup.push(this.state.users)
    }
    this.props
      .ModifySites({
        _id: id,
        name: this.state.name,
        contactPerson: this.state.contactPerson,
        contactPhone: this.state.contactPhone,
        contactEmail: this.state.contactEmail,
        contactInformation: this.state.contactInformation,
        siteGroup: this.state.siteGroup,
        group: newGroup
      })
      .then(() => {
        this.props.fetchSites()
      })
  }

  handleChangePanel(panel, expanded, site) {
    this.setState({
      expanded: expanded ? panel : false,
      contactPerson: site.contactPerson || "",
      contactPhone: site.contactPhone || "",
      contactEmail: site.contactEmail || "",
      contactInformation: site.contactInformation || "",
      name: site.name || "",
      phoneSubscription: site.phoneSubscription,
      siteGroup: site.siteGroup || "",
      users: ""
    })
  }

  resetError() {
    this.setState({ error: null })
  }

  async handleSubmit(e) {
    e.preventDefault()
    const {
      address,
      city,
      country,
      customer,
      name,
      gateways,
      siteGroup,
      zip,
      contactPerson,
      contactPhone,
      contactEmail
    } = this.state
    const { lat, lng } = await this.geocodeAddress()
    const pos = { lat, lng }
    this.props
      .AddSites({
        address,
        city,
        country,
        customer,
        name,
        pos,
        gateways,
        siteGroup,
        zip,
        contactPerson,
        contactPhone,
        contactEmail
      })
      .then(() => {
        this.props.fetchSites()
        this.setState({
          formUpdated: false,
          submitSuccessful: true,
          error: null
        })
      })

    this.props.onSelectView(0)
  }

  render() {
    const { classes, customerList } = this.props
    const { customer, formUpdated } = this.state

    return (
      <div className={classes.root}>
        <ManageHeader
          addNew={this.handleAddSites}
          title={tr("_add_new_site")}
          addNewText={tr("_return_to_site_menu")}
        />

        <Grid container justify="center">
          <Grid item xs={11} lg={10}>
            <div>
              <Grid container spacing={0} alignItems="center">
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl className={classes.textFieldForm}>
                    <InputLabel
                      classes={{ root: classes.label }}
                      htmlFor="customer-controlled-open-select"
                    >
                      {tr("_customer")}
                    </InputLabel>
                    <Select
                      required
                      value={customer}
                      onChange={this.handleChange}
                      inputProps={{
                        name: "customer",
                        id: "customer-controlled-open-select"
                      }}
                    >
                      <MenuItem value="">
                        <em>{tr("_choose_customer")}</em>
                      </MenuItem>
                      {customerList
                        ? customerList.map(customer => {
                            return (
                              <MenuItem value={customer._id} key={customer._id}>
                                {customer.name}
                              </MenuItem>
                            )
                          })
                        : ""}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    required
                    id="outlined-name"
                    label={tr("_site_name")}
                    name="name"
                    className={classes.textField}
                    value={this.state.name}
                    onChange={this.handleChange}
                    margin="normal"
                    InputLabelProps={{
                      className: classes.label
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    id="outlined-siteGroup"
                    label={tr("_site_group")}
                    name="siteGroup"
                    className={classes.textField}
                    value={this.state.siteGroup}
                    onChange={this.handleChange}
                    margin="normal"
                    InputLabelProps={{
                      className: classes.label
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    required
                    id="outlined-address"
                    label={tr("_address")}
                    name="address"
                    className={classes.textField}
                    value={this.state.address}
                    onChange={this.handleChange}
                    margin="normal"
                    InputLabelProps={{
                      className: classes.label
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    //   required
                    id="outlined-zip"
                    label={tr("_postal_code")}
                    name="zip"
                    className={classes.textField}
                    value={this.state.zip}
                    onChange={this.handleChange}
                    type="number"
                    margin="normal"
                    InputLabelProps={{
                      className: classes.label
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    required
                    id="outlined-city"
                    label={tr("_city")}
                    name="city"
                    className={classes.textField}
                    value={this.state.city}
                    onChange={this.handleChange}
                    margin="normal"
                    InputLabelProps={{
                      className: classes.label
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    required
                    id="outlined-country"
                    label={tr("_country")}
                    name="country"
                    className={classes.textField}
                    value={this.state.country}
                    onChange={this.handleChange}
                    margin="normal"
                    InputLabelProps={{
                      className: classes.label
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    id="outlined-contactPerson"
                    label={tr("_contact_person")}
                    name="contactPerson"
                    className={classes.textField}
                    value={this.state.contactPerson}
                    onChange={this.handleChange}
                    margin="normal"
                    InputLabelProps={{
                      className: classes.label
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    id="outlined-contactEmail"
                    label={tr("_email")}
                    name="contactEmail"
                    className={classes.textField}
                    value={this.state.contactEmail}
                    onChange={this.handleChange}
                    margin="normal"
                    InputLabelProps={{
                      className: classes.label
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    id="outlined-contactPhone"
                    label={tr("_phone_number")}
                    name="contactPhone"
                    className={classes.textField}
                    value={this.state.contactPhone}
                    onChange={this.handleChange}
                    margin="normal"
                    InputLabelProps={{
                      className: classes.label
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <br />
                  <Button
                    disabled={!formUpdated}
                    variant="contained"
                    color="primary"
                    className={classes.saveButton}
                    onClick={this.handleSubmit}
                  >
                    {tr("_submit")}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  AddSites: obj => dispatch(AddSites(obj)),
  DeleteSites: string => dispatch(DeleteSites(string)),
  fetchSites: () => dispatch(fetchSites()),
  fetchCustomers: () => dispatch(fetchCustomers()),
  ModifySites: obj => dispatch(ModifySites(obj)),
  SubscripeAlerts: obj => dispatch(SubscripeAlerts(obj)),
  fetchUsers: () => dispatch(fetchUsers())
})

const mapStateToProps = state => {
  return {
    siteList: state.sites.siteList,
    customerList: state.customers.customerList,
    user: state.users.user,
    userList: state.users.userList
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    height: "100vh",
    backgroundImage: theme.backgroundImage,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "95%"
  },
  textFieldForm: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "95%"
  },
  saveButton: {
    backgroundColor: theme.palette.action.main,
    color: theme.palette.button.caption
  },
  label: {
    color: theme.palette.text.primary,
    "&.Mui-focused": {
      color: theme.palette.text.primary
    }
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(SitesAddNew))
