
export default (
  state = {
    error: null,
    deviceDataStore: {},
    isFetching: false,
    shouldRefresh: false,
    updatedDevices: [],
    initialDataDevice: undefined,
    lastUpdated: Date.now(),
    filterHours: 3,
    lastFlushTime: undefined,
    deviceFilterHours: {}
  },
  action
) => {
  switch (action.type) {
    case "DEVICEDATA_LIST_REQUESTED":
    case "DEVICEDATA_ADD_REQUESTED":
    case "DEVICEDATA_UPDATE_REQUESTED":
    case "DEVICEDATA_DELETE_REQUESTED":
    case "DEVICEDATA_UPGRADE_REQUESTED":
    case "DEVICEDATA_DATALIST_REQUESTED":
    case "DEVICEDATA_DEVICE_FILTER_HOURS_MODIFY":
      return {
        ...state,
        deviceFilterHours: action.payload
      }

    case "DEVICEDATA_DATA_REQUESTED":
      return {
        ...state,
        isFetching: true,
        shouldRefresh: false
      }

    case "DEVICEDATA_DATALIST_RECEIVED": {
      function groupBy(arr, property) {
        return arr.reduce(function(memo, x) {
          if (!memo[x[property]]) { memo[x[property]] = [] }
          memo[x[property]].push(x)
          return memo
        }, {})
      }
      // This case we initialize data from DB to state
      // this data item includes only one device data.
      // We filter too old data
      // we remove also same device data before adding new data
      const { deviceDataStore, filterHours, deviceFilterHours } = state
      const now = new Date().getTime()
      const ids = action.iotDeviceData.nodes
      let updatedDataArray = []
      // Filter this device data away only if data received had content
      if (action.iotDeviceData.data.length) {
        updatedDataArray = [...action.iotDeviceData.data]
      }
      const hours = filterHours ? filterHours : 3
      const filterTime = hours * 3600000
      // Filter older than set hour data
      if (updatedDataArray.length > 50) {
        updatedDataArray = updatedDataArray.filter(
          item => (deviceFilterHours && deviceFilterHours[item.nodeId])
            ? (now - (typeof item.ts === Number ? item.ts : new Date(item.ts).getTime()) < deviceFilterHours[item.nodeId] * 3600000)
            : (now - (typeof item.ts === Number ? item.ts : new Date(item.ts).getTime()) < filterTime)
        )
      }
      if (updatedDataArray.length === 0) {
        updatedDataArray = updatedDataArray.filter(
          item => now - (typeof item.ts === Number ? item.ts : new Date(item.ts).getTime()) < 24 * 3600000
        )
      }
      const updatedDeviceDataStore = { ...deviceDataStore }
      const updatedDataSeries = [] //updatedDeviceDataStore[nodeId] || []
      updatedDataArray.forEach(entry => {
        if (entry !== null) updatedDataSeries.push(entry)
      })

      let o = groupBy(updatedDataSeries, "nodeId")
      for (let x=0; x<ids.length; x++) {
        let id = ids[x]
        let value = o[id]
        updatedDeviceDataStore[id] = value
      }
      //updatedDeviceDataStore[nodeId] = updatedDataSeries
      return {
        ...state,
        isFetching: false,
        shouldRefresh: false,
        lastUpdated: Date.now(),
        deviceDataStore: updatedDeviceDataStore,
        updatedDevices: Object.keys(updatedDeviceDataStore)
      }
    }
    case "DEVICEDATA_DATA_RECEIVED": {
      // This case we initialize data from DB to state
      // this data item includes only one device data.
      // We filter too old data
      // we remove also same device data before adding new data
      const { deviceDataStore, filterHours, deviceFilterHours } = state
      const now = new Date().getTime()
      const nodeId = action.iotDeviceData.nodeId
      let updatedDataArray = []
      // Filter this device data away only if data received had content
      if (action.iotDeviceData.data.length) {
        updatedDataArray = [...action.iotDeviceData.data]
      }
      const hours = filterHours ? filterHours : 3
      const filterTime = hours * 3600000
      // Filter older than set hour data
      if (updatedDataArray.length > 50) {
        updatedDataArray = updatedDataArray.filter(
          item => (deviceFilterHours && deviceFilterHours[item.nodeId])
            ? (now - (typeof item.ts === Number ? item.ts : new Date(item.ts).getTime()) < deviceFilterHours[item.nodeId] * 3600000)
            : (now - (typeof item.ts === Number ? item.ts : new Date(item.ts).getTime()) < filterTime)
        )
      }
      if (updatedDataArray.length === 0) {
        updatedDataArray = [...action.iotDeviceData.data].filter(
          item => now - item.ts < 24 * 3600000
        )
      }
      const updatedDeviceDataStore = { ...deviceDataStore }
      const updatedDataSeries = [] //updatedDeviceDataStore[nodeId] || []
      updatedDataArray.forEach(entry => {
        if (entry !== null) updatedDataSeries.push(entry)
      })
      updatedDeviceDataStore[nodeId] = updatedDataSeries

      return {
        ...state,
        isFetching: false,
        shouldRefresh: false,
        lastUpdated: Date.now(),
        lastUpdatedDeviceId: nodeId, // for groundplan
        deviceDataStore: updatedDeviceDataStore,
        updatedDevices: Object.keys(updatedDeviceDataStore)
      }
    }
    case "DEVICEDATA_ADD_FROM_BUFFER_RECEIVED": {
      const { deviceDataStore, filterHours, deviceFilterHours /*, iotDeviceFiltered*/ } = state
      const now = new Date().getTime()
      const incomingDataBufferArray = action.dataBufferArray || []
      // if(!action.bufferArray) {
      //   throw new Error(`Expected an array, received ${action.bufferArray}`)
      // }
      const updatedDataStore = { ...deviceDataStore }
      let updatedDeviceIds = []
      incomingDataBufferArray.forEach(dataRow => {
        const thisDeviceId = dataRow.deviceId
        const thisDeviceData = dataRow.device
        if (
          thisDeviceId !== null &&
          thisDeviceId !== undefined &&
          thisDeviceData
        ) {
          const dataSeries = updatedDataStore[thisDeviceId]
          if (dataSeries) {
            //console.log('pushing', thisDeviceData)
            updatedDataStore[dataRow.deviceId].push(thisDeviceData)
          } else {
            updatedDataStore[dataRow.deviceId] = [thisDeviceData]
          }
          updatedDeviceIds.push(thisDeviceId)
        }
      })
      updatedDeviceIds.forEach(id => {
        const hours = (deviceFilterHours && deviceFilterHours[id]) ? deviceFilterHours[id] : filterHours ? filterHours : 3
        const filterTime = hours * 3600000
        updatedDataStore[id] = updatedDataStore[id].filter(
          item => now - (typeof item.ts === Number ? item.ts : new Date(item.ts).getTime()) < filterTime
        )
      })
      return {
        ...state,
        isFetching: false,
        deviceDataStore: updatedDataStore,
        error: null,
        lastUpdatedDeviceId: updatedDeviceIds.length > 0 ? updatedDeviceIds[0] : "", // for groundplan
        updatedDevices: updatedDeviceIds,
        lastFlushTime: Date.now()
      }
    }
    case "DEVICEDATA_LIST_RECEIVED":
      return {
        ...state,
        isFetching: false,
        shouldRefresh: false,
        deviceDataList: action.deviceDataList,
        lastUpdated: Date.now()
      }

    case "DEVICEDATA_SET_FILTER": {
      return {
        ...state,
        filterHours: action.hours
      }
    }

    case "DEVICEDATA_RESET": {
      return {
        ...state,
        updatedDevices: [],
        deviceDataStore: {}
      }
    }

    default:
      return state
  }
}
