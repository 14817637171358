import { reducer as notifications } from "react-notification-system-redux"
import storage from "redux-persist/lib/storage"
import { combineReducers } from "redux"
import auth from "./auth"
import iotDevices from "./iotDevices"
import customers from "./customers"
import reg from "./registration"
import sites from "./sites"
import users from "./users"
import ui from "./ui"
import areas from "./areas"
import types from "./types"
import faults from "./faults"
import presets from "./presets"
import commands from "./commands"
import dashboard from "./dashboard"
import websocket from "./websocket"
import dataUpdate from "./dataUpdate"
import limits from "./limits"
import trials from "./trials"
import favouriteWidgets from "./favouriteWidgets"

const appReducer = combineReducers({
  areas,
  auth,
  commands,
  customers,
  dashboard,
  faults,
  iotDevices,
  notifications,
  presets,
  reg,
  sites,
  types,
  ui,
  users,
  websocket,
  dataUpdate,
  limits,
  favouriteWidgets,
  trials
})

export const rootReducer = (state, action) => {
  if (action.type === "LOG_OUT") {
    //console.log("state", state)
    //storage.removeItem("persist:platform")
    state = undefined
  }

  return appReducer(state, action)
}
