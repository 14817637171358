import React from "react"
import PropTypes from "prop-types"
import { tr } from "translations"
import { platformDarkBlueSelected } from "../services/colors"

import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"

class TextLine extends React.Component {
  constructor() {
    super()
    this.state = {
      image: undefined,
      backgroundColor: undefined,
      title: ""
    }
  }

  render() {
    const { classes, mode, text, onClick } = this.props
    let backgroundColor = undefined
    let textColor = undefined
    // let image = undefined
    let title = undefined
    if (mode === 0) {
      // image = require("../images/no-way-icon.png")
      backgroundColor = "#F8DDE7"
      textColor = platformDarkBlueSelected
      title = tr("_error")
    } else if (mode === 1) {
      // image = require("../images/ok-icon.png")
      backgroundColor = "#96FF96"
      textColor = "#008000"
      title = tr("_info")
    }

    return (
      <Grid container className={classes.container}>
        <Grid
          style={{
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: backgroundColor
          }}
        >
          <Grid style={{ flex: 1, marginLeft: 10, marginRight: 10 }}>
            {/* <Image source={image} style={{width: 20, height: 20 }}/>*/}
          </Grid>
          <Grid style={{ flex: 6, marginRight: 10 }}>
            <Typography
              numberoflines={3}
              style={{ fontSize: 16, color: textColor, alignSelf: "center" }}
            >
              {title} {text}
            </Typography>
          </Grid>
          <Button style={{ flex: 1 }} onClick={() => onClick()}>
            <Typography
              style={{
                fontSize: 18,
                marginRight: 10,
                color: textColor,
                fontWeight: "bold",
                alignSelf: "center"
              }}
            >
              X
            </Typography>
          </Button>
        </Grid>
      </Grid>
    )
  }
}

TextLine.propTypes = {
  text: PropTypes.string,
  onPress: PropTypes.func,
  mode: PropTypes.number
}

const styles = () => ({
  container: {
    height: 60,
    marginTop: 0,
    marginBottom: 10,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%"
  }
})

export default withStyles(styles)(TextLine)
