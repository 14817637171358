import { PersistGate } from "redux-persist/integration/react"
import { Provider, connect } from "react-redux"
import App from "./App"
import React from "react"
import ReactDOM from "react-dom"
import { persistor, store } from "./storeConfigure"
import Loading from "./containers/Loading"

const ConnectedApp = connect(state => state.auth)(App)

const AppProvider = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <ConnectedApp />
      </PersistGate>
    </Provider>
  )
}

ReactDOM.render(<AppProvider />, document.querySelector("div#app"))
