import React from "react"
import Grid from "@material-ui/core/Grid"
import CircularProgress from "@material-ui/core/CircularProgress"
import Typography from "@material-ui/core/Typography"

import { tr } from "translations"

const WidgetLoading = () => {
  return (
    <Grid container justify="center" alignItems="center">
      <Typography variant="h4" style={{ marginRight: 15 }}>
        {tr("_loading")}
      </Typography>
      <CircularProgress color="secondary" thickness={4} size={64} />
    </Grid>
  )
}

export default WidgetLoading
