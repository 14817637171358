import Hidden from "@material-ui/core/Hidden"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import IconButton from "@material-ui/core/IconButton"
import GridList from "@material-ui/core/GridList"
import GridListTile from "@material-ui/core/GridListTile"


import Grid from "@material-ui/core/Grid"
import withStyles from "@material-ui/core/styles/withStyles"

import Button from "@material-ui/core/Button"
import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import NotificationsIcon from "@material-ui/icons/Notifications"
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff"
import VisibilityIcon from "@material-ui/icons/Visibility"
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { tr } from "translations"
import { fetchCustomers } from "../../../../actions/customers"
import { DeleteSites, fetchSites, ModifySites } from "../../../../actions/sites"
import { fetchUsers } from "../../../../actions/users"
import { fetchUserSelf } from "../../../../actions/users"
import AddAreaModal from "../../../../components/modals/AddArea"
import { ExpansionPanelSubheading } from "../../../../components/parts/ExpansionPanelSubheading"
import SiteTile from "./../../../../components/elements/SiteTile"
import ConfirmationDialog from "../../../../components/modals/ConfirmationDialog"
import { ManageHeader } from "../../../../components/manageMenu/manageHeader"

const Sites = (props) => {
  const { classes, theme } = props

  const [name, setName] = useState("")
  const [address, setAddress] = useState("")
  const [zip, setZip] = useState("")
  const [city, setCity] = useState("")
  const [country, setCountry] = useState("")
  const [lat, setLat] = useState("")
  const [lng, setLng] = useState("")
  const [contactPerson, setContactPerson] = useState("")
  const [contactPhone, setContactPhone] = useState("")
  const [contactEmail, setContactEmail] = useState("")
  const [contactInformation, setContactInformation] = useState("")
  const [siteGroup, setSiteGroup] = useState("")
  const [expanded, setExpanded] = useState(null)
  const [addAreaOpen, setAddAreaOpen] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [deletableSite, setDeletetableSite] = useState(null)
  const [numberValid, setNumberValid] = useState(true)

  const siteList = useSelector((state) => state.sites.siteList)
  const user = useSelector((state) => state.users.user)
  const customerList = useSelector((state) => state.customers.customerList)
  const userList = useSelector((state) => state.users.userList)
  const devicelist = useSelector((state) => state.iotDevices.iotDeviceList)
  const customer = customerList.length === 1 ? customerList[0] : null

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchUserSelf())
    dispatch(fetchUsers())
    dispatch(fetchSites())
    dispatch(fetchCustomers())
  }, [dispatch])

  const handleSiteVisibilityToUser = (siteId, group, userId) => {
    let newGroup = Object.assign([], group)
    const index = newGroup.indexOf(userId)
    if (index > -1) {
      newGroup.splice(index, 1)
    } else {
      newGroup.push(userId)
    }
    dispatch(
      ModifySites({
        _id: siteId,
        group: newGroup,
      })
    ).then(() => {
      dispatch(fetchSites())
    })
  }

  // const handleChechbox = (event, _id, name) => {
  //   const target = event.target

  //   let subscription = { _id }
  //   switch (name) {
  //     case "phoneSubscriptionChecked":
  //       subscription.phone = target.checked
  //       break
  //     case "emailSubscriptionChecked":
  //       subscription.email = target.checked
  //       break
  //   }
  //   dispatch(SubscripeAlerts(subscription)).then(() => {
  //     dispatch(fetchSites())
  //   })
  // }

  const handleDelete = async (id) => {
    dispatch(DeleteSites(id)).then(() => {
      dispatch(fetchSites())
      setDeletetableSite(null)
    })
  }

  const handleModifySite = (id, group) => {
    dispatch(
      ModifySites({
        _id: id,
        name,
        contactPerson,
        contactPhone,
        contactEmail,
        contactInformation,
        siteGroup,
        address,
        zip,
        city,
        country,
        pos: {
          lat,
          lng,
        },
        group,
      })
    ).then(() => {
      dispatch(fetchSites())
    })
    //   .catch(err => console.log(err))
  }

  const handleChangePanel = (panel, expanded, site) => {
    setExpanded(expanded ? panel : false)
    setContactPerson(site.contactPerson || "")
    setContactPhone(site.contactPhone || "")
    setContactEmail(site.contactEmail || "")
    setContactInformation(site.contactInformation || "")
    setName(site.name || "")
    setSiteGroup(site.siteGroup || "")
    setAddress(site.address || "")
    setZip(site.zip || "")
    setCity(site.city || "")
    setCountry(site.country || "")
    setLat(site.pos && site.pos.lat ? site.pos.lat : "")
    setLng(site.pos && site.pos.lng ? site.pos.lng : "")
  }

  const handleAddArea = () => {
    setAddAreaOpen(!addAreaOpen)
  }

  const handleAddNewSite = () => {
    props.onSelectView(2) // Change view to SitesAddNew
  }

  const onClickSiteTile = (siteId) => {
    props.onSelectSite(siteId) // Change to site details view
  }

  const validateNumber = (number) => {
    if (number != "" && number != undefined) {
      let rgp = /^\+[ ]?([0-9]{1,3})[- ]?([0-9]{1,3})([- ]?([0-9]{1,10}))*$/
      if (number.match(rgp)) setNumberValid(true)
      else setNumberValid(false)
    } else setNumberValid(true)
  }

  // Testdata for UI work

  // Use real data
  let sites = siteList
  /* eslint-disable indent */
  return (
    <div className={classes.root}>
      <ManageHeader
        addNew={handleAddNewSite}
        addNewText={tr("_add_new_site")}
        title={tr("_sites")}
      />
      <Grid container spacing={1} alignContent="flex-start">
        <Grid item xs={1} />
        {/* Header and cancel/save buttons */}
        <Grid item xs={10}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={1}
          >
            {/* Site tile grid */}
            <Grid item xs={12}>
              <GridList spacing={10} cellHeight="auto">
                {sites &&
                  sites.map((site, index) => {
                    return (
                      <GridListTile
                        key={index}
                        style={{ width: 320, height: 135, cursor: "pointer" }}
                      >
                        <SiteTile
                          siteId={site._id}
                          logo={customer?.logo}
                          onClick={onClickSiteTile}
                          line1={site.name}
                          line2={
                            "" +
                            (devicelist.filter(
                              (device) => device.site === site._id
                            ).length === 0
                              ? 0
                              : devicelist.filter(
                                  (device) => device.site === site._id
                                ).length) +
                            " " +
                            (devicelist.filter(
                              (device) => device.site === site._id
                            ).length == 1
                              ? tr("_one_device")
                              : tr("_n_devices"))
                          }
                          line3={site.address}
                          line4={site.country}
                          theme={theme}
                        />
                      </GridListTile>
                    )
                  })}
              </GridList>
            </Grid>
          </Grid>
        </Grid>

        {/* Old UI code below */}

        <Grid container justify="center">
          <Grid item xs={11} lg={10}>
            <AccordionSummary>
              <Hidden mdDown>
                <Typography className={classes.headingPart1}>
                  {tr("_group")}
                </Typography>
                <Typography className={classes.headingPart2}>
                  {tr("_site")}
                </Typography>
                <Typography className={classes.headingPart3}>
                  {tr("_address")}
                </Typography>
                <Typography className={classes.headingPart4}>
                  {tr("_city")}
                </Typography>
              </Hidden>

              <Hidden lgUp>
                <Typography className={classes.headingPart1Small}>
                  {tr("_group")}
                </Typography>
                <Typography className={classes.headingPart2Small}>
                  {tr("_site")}
                </Typography>
              </Hidden>
            </AccordionSummary>
            {siteList
              ? siteList.map((site) => {
                  return (
                    <Accordion
                      key={site._id}
                      expanded={expanded === site._id}
                      onChange={(event, expanded) =>
                        handleChangePanel(site._id, expanded, site)
                      }
                      className={classes.expansionpanel}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Hidden mdDown>
                          <Typography className={classes.headingPart1}>
                            {site.siteGroup}
                          </Typography>
                          <Typography className={classes.headingPart2}>
                            {site.name}
                          </Typography>
                          <Typography className={classes.headingPart3}>
                            {site.address}
                          </Typography>
                          <Typography className={classes.headingPart4}>
                            {site.city}
                          </Typography>
                        </Hidden>
                        <Hidden lgUp>
                          <Typography className={classes.headingPart1Small}>
                            {site.siteGroup}
                          </Typography>
                          <Typography className={classes.headingPart2Small}>
                            {site.name}
                          </Typography>
                          <Typography className={classes.headingPart3Small}>
                            {site.phoneSubscription &&
                            user &&
                            site.phoneSubscription.indexOf(user._id) > -1 ? (
                              <NotificationsIcon />
                            ) : (
                              <NotificationsOffIcon />
                            )}
                          </Typography>
                        </Hidden>
                      </AccordionSummary>

                      <AccordionDetails>
                        <Grid container spacing={0}>
                          <ExpansionPanelSubheading
                            title={
                              user && user.role === "user"
                                ? tr("_site_info")
                                : tr("_modify_site_information")
                            }
                          />
                          <Grid item xs={12} md={6} lg={4}>
                            <TextField
                              id={"outlined-name-" + site._id}
                              label={tr("_site_name")}
                              name="name"
                              className={classes.textField}
                              InputLabelProps={{
                                classes: {
                                  root: classes.inputRoot,
                                  focused: classes.inputFocus,
                                },
                              }}
                              InputProps={{
                                classes: {
                                  underline: classes.underline,
                                },
                              }}
                              value={name}
                              onChange={
                                (user && user.role) === "user"
                                  ? undefined
                                  : (event) => setName(event.target.value)
                              }
                              margin="normal"
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            <TextField
                              id={"outlined-site-group-" + site._id}
                              label={tr("_site_group")}
                              name="siteGroup"
                              className={classes.textField}
                              InputLabelProps={{
                                classes: {
                                  root: classes.inputRoot,
                                  focused: classes.inputFocus,
                                },
                              }}
                              InputProps={{
                                classes: {
                                  underline: classes.underline,
                                },
                              }}
                              value={siteGroup}
                              onChange={
                                (user && user.role) === "user"
                                  ? undefined
                                  : (event) => setSiteGroup(event.target.value)
                              }
                              margin="normal"
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            <TextField
                              id={"outlined-contact-person-" + site._id}
                              label={tr("_contact_person")}
                              name="contactPerson"
                              className={classes.textField}
                              InputLabelProps={{
                                classes: {
                                  root: classes.inputRoot,
                                  focused: classes.inputFocus,
                                },
                              }}
                              InputProps={{
                                classes: {
                                  underline: classes.underline,
                                },
                              }}
                              value={contactPerson}
                              onChange={
                                (user && user.role) === "user"
                                  ? undefined
                                  : (event) =>
                                      setContactPerson(event.target.value)
                              }
                              margin="normal"
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            <TextField
                              error={!numberValid}
                              helperText={
                                !numberValid
                                  ? "International format (eg. +11 222 333)"
                                  : null
                              }
                              id={"outlined-contact-phone-" + site._id}
                              label={tr("_phone")}
                              name="contactPhone"
                              className={classes.textField}
                              InputLabelProps={{
                                classes: {
                                  root: classes.inputRoot,
                                  focused: classes.inputFocus,
                                },
                              }}
                              InputProps={{
                                classes: {
                                  underline: classes.underline,
                                },
                              }}
                              value={contactPhone}
                              onChange={
                                (user && user.role) === "user"
                                  ? undefined
                                  : (event) => {
                                      setContactPhone(event.target.value)
                                      validateNumber(event.target.value)
                                    }
                              }
                              margin="normal"
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            <TextField
                              id={"outlined-contact-email-" + site._id}
                              label={tr("_email")}
                              name="contactEmail"
                              className={classes.textField}
                              InputLabelProps={{
                                classes: {
                                  root: classes.inputRoot,
                                  focused: classes.inputFocus,
                                },
                              }}
                              InputProps={{
                                classes: {
                                  underline: classes.underline,
                                },
                              }}
                              value={contactEmail}
                              onChange={
                                (user && user.role) === "user"
                                  ? undefined
                                  : (event) =>
                                      setContactEmail(event.target.value)
                              }
                              margin="normal"
                            />
                          </Grid>

                          <Grid item xs={12} md={6} lg={4}>
                            <TextField
                              id={"outlined-contact-address-" + site._id}
                              label={tr("_street_address")}
                              name="address"
                              className={classes.textField}
                              InputLabelProps={{
                                classes: {
                                  root: classes.inputRoot,
                                  focused: classes.inputFocus,
                                },
                              }}
                              InputProps={{
                                classes: {
                                  underline: classes.underline,
                                },
                              }}
                              value={address}
                              onChange={
                                (user && user.role) === "user"
                                  ? undefined
                                  : (event) => setAddress(event.target.value)
                              }
                              margin="normal"
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            <TextField
                              id={"outlined-contact-zip-" + site._id}
                              label={tr("_zipcode")}
                              name="zip"
                              className={classes.textField}
                              InputLabelProps={{
                                classes: {
                                  root: classes.inputRoot,
                                  focused: classes.inputFocus,
                                },
                              }}
                              InputProps={{
                                classes: {
                                  underline: classes.underline,
                                },
                              }}
                              value={zip}
                              onChange={
                                (user && user.role) === "user"
                                  ? undefined
                                  : (event) => setZip(event.target.value)
                              }
                              margin="normal"
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            <TextField
                              id={"outlined-contact-city-" + site._id}
                              label={tr("_city")}
                              name="city"
                              className={classes.textField}
                              InputLabelProps={{
                                classes: {
                                  root: classes.inputRoot,
                                  focused: classes.inputFocus,
                                },
                              }}
                              InputProps={{
                                classes: {
                                  underline: classes.underline,
                                },
                              }}
                              value={city}
                              onChange={
                                (user && user.role) === "user"
                                  ? undefined
                                  : (event) => setCity(event.target.value)
                              }
                              margin="normal"
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            <TextField
                              id={"outlined-contact-country-" + site._id}
                              label={tr("_country")}
                              name="country"
                              className={classes.textField}
                              InputLabelProps={{
                                classes: {
                                  root: classes.inputRoot,
                                  focused: classes.inputFocus,
                                },
                              }}
                              InputProps={{
                                classes: {
                                  underline: classes.underline,
                                },
                              }}
                              value={country}
                              onChange={
                                (user && user.role) === "user"
                                  ? undefined
                                  : (event) => setCountry(event.target.value)
                              }
                              margin="normal"
                            />
                          </Grid>

                          <Grid item xs={12} md={6} lg={4}>
                            <TextField
                              id={"outlined-site-lat-" + site._id}
                              label={tr("Latitude")}
                              name="lat"
                              className={classes.textField}
                              InputLabelProps={{
                                classes: {
                                  root: classes.inputRoot,
                                  focused: classes.inputFocus,
                                },
                              }}
                              InputProps={{
                                classes: {
                                  underline: classes.underline,
                                },
                              }}
                              value={lat}
                              onChange={
                                (user && user.role) === "user"
                                  ? undefined
                                  : (event) => setLat(event.target.value)
                              }
                              margin="normal"
                              type="number"
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            <TextField
                              id={"outlined-site-lng-" + site._id}
                              label={tr("Longitude")}
                              name="lng"
                              className={classes.textField}
                              InputLabelProps={{
                                classes: {
                                  root: classes.inputRoot,
                                  focused: classes.inputFocus,
                                },
                              }}
                              InputProps={{
                                classes: {
                                  underline: classes.underline,
                                },
                              }}
                              value={lng}
                              onChange={
                                (user && user.role) === "user"
                                  ? undefined
                                  : (event) => setLng(event.target.value)
                              }
                              margin="normal"
                              type="number"
                            />
                          </Grid>

                          {user && user.role !== "user" && (
                            <Grid container spacing={0} justify="flex-end">
                              <Grid item>
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    setDeletetableSite(site._id)
                                    setConfirmOpen(true)
                                  }}
                                  className={classes.removeButton}
                                >
                                  {tr("_remove_site")}
                                </Button>
                              </Grid>
                              <Grid item>
                                <Button
                                  variant="contained"
                                  className={classes.saveButton}
                                  disabled={!numberValid}
                                  onClick={() =>
                                    handleModifySite(site._id, site.group)
                                  }
                                >
                                  {tr("_save")}
                                </Button>
                              </Grid>
                            </Grid>
                          )}
                          {user && user.role !== "user" && (
                            <Grid container item xs={12}>
                              <ExpansionPanelSubheading
                                title={tr("_add_area")}
                              />
                              <Grid item xs={6}>
                                <Button
                                  variant="contained"
                                  className={classes.saveButton}
                                  onClick={() => handleAddArea()}
                                >
                                  {tr("_addNew")}
                                </Button>
                              </Grid>
                            </Grid>
                          )}

                          {user && user.role !== "user" && (
                            <Grid container spacing={0}>
                              <ExpansionPanelSubheading
                                title={tr("Site_users")}
                              />
                              <Grid item xs={12}>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>{tr("_firstname")}</TableCell>
                                      <TableCell>{tr("_lastname")}</TableCell>
                                      <TableCell>{tr("_role")}</TableCell>
                                      <TableCell>
                                        {tr("_site_visibility_to_user")}
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {userList
                                      ? userList
                                          .filter(
                                            (userToList) =>
                                              userToList.customer ===
                                              site.customer
                                          )
                                          .map((userToList) => {
                                            return (
                                              <TableRow key={userToList._id}>
                                                <TableCell>
                                                  {userToList.firstname}
                                                </TableCell>
                                                <TableCell>
                                                  {userToList.lastname}
                                                </TableCell>
                                                <TableCell>
                                                  {userToList.role}
                                                </TableCell>
                                                <TableCell>
                                                  <IconButton
                                                    disabled={
                                                      userToList.role ===
                                                        "admin" ||
                                                      (user &&
                                                        user.role === "user")
                                                        ? true
                                                        : false
                                                    }
                                                    onClick={() =>
                                                      handleSiteVisibilityToUser(
                                                        site._id,
                                                        site.group,
                                                        userToList._id
                                                      )
                                                    }
                                                  >
                                                    {site.group.indexOf(
                                                      userToList._id
                                                    ) > -1 ||
                                                    userToList.role ===
                                                      "admin" ? (
                                                      <VisibilityIcon />
                                                    ) : (
                                                      <VisibilityOffIcon />
                                                    )}
                                                  </IconButton>
                                                </TableCell>
                                              </TableRow>
                                            )
                                          })
                                      : null}
                                  </TableBody>
                                </Table>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  )
                })
              : ""}
            {addAreaOpen && (
              <AddAreaModal
                onClose={() => handleAddArea()}
                isOpen={addAreaOpen}
                site={expanded}
              />
            )}
            {confirmOpen && (
              <ConfirmationDialog
                onClose={() => {
                  setConfirmOpen(false)
                  setDeletetableSite(null)
                }}
                onAccept={() => {
                  handleDelete(deletableSite)
                  setConfirmOpen(false)
                }}
                header={tr("_remove_site")}
                text={`${tr("_you_sure_delete_site")} ${
                  siteList.find((site) => site._id === deletableSite).name
                }`}
                fullScreen={false}
                isOpen={confirmOpen}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
/* eslint-enable indent */

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "calc(100vh - 122px)",
    backgroundImage: theme.backgroundImage,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    overflow: "auto",
  },
  headingPart1: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "22.50%",
    flexShrink: 0,
    color: theme.palette.text.field,
  },
  headingPart2: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "22.50%",
    color: theme.palette.text.field,
  },
  headingPart3: {
    color: theme.palette.text.field,
    flexBasis: "30%",
    fontSize: theme.typography.pxToRem(15),
  },
  headingPart4: {
    color: theme.palette.text.field,
    flexBasis: "15%",
    fontSize: theme.typography.pxToRem(15),
  },
  headingPart5: {
    color: theme.palette.text.field,
    flexBasis: "10%",
    fontSize: theme.typography.pxToRem(15),
  },
  headingPart1Small: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "35%",
    flexShrink: 0,
    color: theme.palette.text.field,
  },
  headingPart2Small: {
    color: theme.palette.text.field,
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "45%",
  },
  headingPart3Small: {
    color: theme.palette.text.field,
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "20%",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "95%",
    color: theme.palette.text.field,
  },
  checkboxField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "95%",
  },
  searchField: {
    marginTop: "5px",
    width: "180px",
    textColor: theme.palette.text.field,
  },
  inputRoot: {
    color: theme.palette.text.field + " !important",
  },
  inputFocus: {
    color: theme.palette.text.warning + " !important",
  },
  underline: {
    "&::before": {
      color: theme.palette.text.primary,
    },
    "&::hover": {
      borderBottom: "2px solid " + theme.palette.text.primary,
    },
    "&::after": {
      borderBottom: "2px solid " + theme.palette.text.warning,
    },
  },
  topRowGridItem: {
    height: "100%",
  },
  saveButton: {
    marginRight: theme.spacing(4),
    backgroundColor: theme.palette.action.main,
    color: theme.palette.secondary.themed,
    "&:hover": {
      color: theme.palette.action.main,
    },
  },
  removeButton: {
    marginRight: theme.spacing(4),
    color: theme.palette.unselected.main,
    backgroundColor: theme.palette.error.dark,
    "&:hover": {
      color: theme.palette.error.dark,
    },
  },
})

export default withStyles(styles, { withTheme: true })(Sites)
