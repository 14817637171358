
import AppBar from "@material-ui/core/AppBar"
import Grid from "@material-ui/core/Grid"
import Toolbar from "@material-ui/core/Toolbar"
import Tooltip from "@material-ui/core/Tooltip"
import createStyles from "@material-ui/core/styles/createStyles"
import withStyles from "@material-ui/core/styles/withStyles"
import { connect } from "react-redux"


import React from "react"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import IoTStation_logo from "../../images/iot_station_logo.png"
import { tr } from "../../translations"

// Components
import MainAppBar from "./MainAppBar"
import GroundPlanAppBar from "./GroundPlanAppBar"

class AppBarWithLogo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      site: "landing"
    }
  }

  /** Update site state based on history.location change */

  static getDerivedStateFromProps(props, state) {
    if (state.site !== props.history.location.pathname.split("/")[2]) {
      // Update
      return {
        site: props.history.location.pathname.split("/")[2] || "landing"
      }
    }

    return null
  }

  render() {
    const { classes, isDark, history, customerList } = this.props
    const { site } = this.state
    let topBarColor = ""
    let banner = IoTStation_logo
    if(customerList && customerList.length === 1){
      const customer = customerList[0]
      if(customer.topBarColor){
        topBarColor = customerList[0].topBarColor
      }
      if(customer.banner && customer.banner.length){
        banner = customer.banner
      }
      
    }
    const active = history.location.pathname.split("/")[1] === "groundplan"
    return (
      <AppBar className={classes.appBar} style={{backgroundColor: topBarColor}} color="secondary">
        <Toolbar>
          {(!active || window.innerWidth > 760) && (
            <Link
              replace={history.location.pathname === "/dashboard"}
              to="/dashboard"
            >
              {" "}
              <Tooltip title={tr("_dashboard")}>
                <Grid container item justify="flex-start" spacing={0} xs={3}>
                  <img
                    src={banner}
                    alt="IoT Station"
                    height="35px"
                    style={{ margin: "5px 0px" }}
                  />
                </Grid>
              </Tooltip>
            </Link>
          )}

          <Grid container item xs={12}>
            {!active && <MainAppBar history={history} />}

            {active && (
              <GroundPlanAppBar
                history={history}
                isDark={isDark}
                propsSite={site}
              />
            )}
          </Grid>
        </Toolbar>
      </AppBar>
    )
  }
}

const styles = theme =>
  createStyles({
    appBar: {
      margin: 0,
      padding: 0,
      position: "relative", // Changed to relative so that attached profile page nav bar shows correctly.
      [theme.breakpoints.up("md")]: {
        width: "100%"
      }
    }
  })
  
const mapStateToProps = state => {
  return {
    customerList: state.customers.customerList,
  }
}

const mapDispatchToProps = () => ({})


export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles, { withTheme: true })(AppBarWithLogo))
)
