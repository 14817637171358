import axios from "axios"
import { getApiUrl } from "../../constants/config"
import { parseErrorData } from "../../services/errors"
import { authHeader } from "../../actions/common"
import { stateType } from "../../constants/actions"


const deviceLightLevelsRequestFailed = error => ({
  //   type: stateType.IOTDEVICE_ADD_FAILED,
  type: stateType.SET_ERROR,
  error
})

const displayInfo = info => ({
  type: stateType.SET_INFO,
  info
})


// device specific actions here, eg. send command to turn lights on

export const deviceAction = id => {
  console.log("Action for device:", id)
}


export const deviceSetLightLevels = deviceCommand => async (dispatch, getState) => {
  try {

    if(deviceCommand.deviceId == undefined) {
      throw "No device"
    }

    let deviceUrl = getApiUrl() + "/api/devicetype/GenericLED/setLevels"

    const state = getState()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }
    const { data } = await axios.post(
      deviceUrl,
      deviceCommand,
      authHeader(useToke)
    )
    //let device = null

    if (!data || !data.success ) {
      throw (data && data.error) || "Failed to set levels"
    }

    //device = data.device
    let info = { description: "LEVELS_CHANGED" }
    dispatch(displayInfo(info))

    return Promise.resolve()//dispatch(iotDeviceAddReceived(device)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(deviceLightLevelsRequestFailed(errorReply)))
  }
}
