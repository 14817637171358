import React from "react"

import Fade from "@material-ui/core/Fade"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"

import useTheme from "@material-ui/core/styles/useTheme"
import FastRewindIcon from "@material-ui/icons/FastRewind"
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord"
import { DeviceMarker } from "../SvgLib"

const DeviceListItem = props => {
  const { classes, device, type } = props

  const theme = useTheme()

  return (
    <Fade in={true} timeout={800}>
      <Paper className={classes.defaultPaper}>
        <Grid container>
          <Grid container item xs={1} alignItems="center">
            {type === "unknown" ? (
              <FastRewindIcon
                className={classes.colorunknown}
                style={{ fontSize: 32 }}
              />
            ) : (
              <DeviceMarker
                style={{ height: 32, width: 32 }}
                iconName={
                  device && device.deviceType ? device.deviceType : "default"
                }
                innerColor={theme.palette.primary.main}
                color={theme.palette.text.primary}
              />
            )}
          </Grid>
          <Grid container item xs={9}>
            <Grid item xs={12}>
              <Typography className={classes.deviceName} color="textPrimary">
                {device && device.deviceId ? device.name ?? device.deviceId : ""}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.deviceId} color="textPrimary">
                {device && device.deviceType ? device.deviceType : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={2} justify="flex-end" alignItems="center">
            <FiberManualRecordIcon
              className={
                type === "alert"
                  ? classes.colorError
                  : type === "unknown"
                    ? classes.colorunknown
                    : classes.colorConnected
              }
              fontSize="small"
            />
          </Grid>
        </Grid>
      </Paper>
    </Fade>
  )
}

const styles = theme => ({
  defaultPaper: {
    padding: theme.spacing(1),
    marginBottom: 2
  },
  icons: {
    color: theme.palette.secondary.light
  },
  deviceName: {
    fontSize: 16
  },
  deviceId: {
    fontSize: 12
  },
  colorError: {
    color: theme.palette.error.main
  },
  colorConnected: {
    color: theme.palette.action.main
  },
  colorunknown: {
    color: theme.palette.error.light
  }
})

export default withStyles(styles)(DeviceListItem)
