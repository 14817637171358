import React, { useState } from "react"

import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Dialog from "@material-ui/core/Dialog"
import Fade from "@material-ui/core/Fade"
import ListItemText from "@material-ui/core/ListItemText"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import Typography from "@material-ui/core/Typography"
import makeStyles from "@material-ui/core/styles/makeStyles"
import useTheme from "@material-ui/core/styles/useTheme"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { tr } from "translations"
import { widgetTypes } from "./DashboardWidgets/widgetTypes"
import SettingsButtons from "./DashboardWidgets/settingModals/parts/settingsButtons"

const DefineModal = props => {
  const { isOpen, closeMenu, onSave } = props

  const [type, setType] = useState("")

  const classes = useStyles()
  const theme = useTheme()

  const handleSave = () => {
    // create object to save to dashboard db
    onSave(type)
    closeMenu()
  }

  const handleType = event => {
    event.preventDefault()
    setType(event.target.value)
  }

  return (
    <Dialog
      open={isOpen}
      onClose={closeMenu}
      aria-labelledby="simple-dialog-title"
    >
      <Fade in={isOpen} timeout={700}>
        <Paper className={classes.menuPaper}>
          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.title} variant="h6">
                {tr("_select_widget")}
              </Typography>
            </Grid>
            <Grid container className={classes.innerContent}>
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.subTitle}>
                  {tr("_box_type")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Select
                  value={type}
                  onChange={handleType}
                  inputProps={{
                    name: "type",
                    id: "type-id"
                  }}
                  classes={{ root: classes.root, icon: classes.icon }}
                  IconComponent={ExpandMoreIcon}
                  disableUnderline
                  displayEmpty
                  MenuProps={{
                    PaperProps: {
                      style: {
                        backgroundColor: theme.palette.popup.background
                      }
                    }
                  }}
                >
                  <MenuItem value="">
                    <Typography className={classes.text}>
                      <em>{tr("_selectType")}</em>
                    </Typography>
                  </MenuItem>
                  {widgetTypes
                    .filter(item => item !== "DefaultWidget")
                    .map(type => {
                      return (
                        <MenuItem key={type} value={type}>
                          <ListItemText
                            classes={{
                              primary: classes.text
                            }}
                            primary={tr(`_${type}`)}
                          />
                        </MenuItem>
                      )
                    })}
                </Select>
              </Grid>
              <SettingsButtons
                handleSave={handleSave}
                closeMenu={closeMenu}
                disabled={!type}
                selectText={tr("_select")}
              />
            </Grid>
          </Grid>
        </Paper>
      </Fade>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  menuPaper: {
    backgroundColor: theme.palette.popup.background,
    width: 600,
    minHeight: 250,
    padding: theme.spacing(2)
  },
  root: {
    minWidth: 500,
    minHeight: 30,
    marginTop: 10
  },
  icon: {
    color: theme.palette.popup.text
  },
  subTitle: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.fixed
  },
  innerContent: {
    margin: theme.spacing(1)
  },
  title: {
    color: theme.palette.text.fixed,
    fontWeight: "bold"
  },
  text: {
    color: theme.palette.text.fixed
  }
}))

export default DefineModal
