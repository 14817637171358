import React from "react"

import Grid from "@material-ui/core/Grid"
import withStyles from "@material-ui/core/styles/withStyles"
import withTheme from "@material-ui/core/styles/withTheme"

import { connect } from "react-redux"
import * as iotDevices from "../../actions/iotDevices"

// Subcontainers
import CompanyProfile from "./subcontainers/CompanyProfile"
import CustomerManage from "./subcontainers/CustomerManage"
import MyProfile from "./subcontainers/MyProfile"
import UserManage from "./subcontainers/UserManage"
import RegisterInvite from "./subcontainers/RegisterInvite"
import CustomerManageAddNew from "./subcontainers/CustomerManageAddNew"
import ProfilePageAppBar from "../../components/AppBarWithLogo/ProfilePageAppBar"
import SitesManage from "./subcontainers/Sites"
import LimitsList from "./subcontainers/LimitsList"
import GroupsList from "./subcontainers/GroupsList"
import TrialTokenManage from "./subcontainers/TrialTokenManage"
import TrialTokenManageAdd from "./subcontainers/TrialTokenManageAdd"
import ReportsManage from "./subcontainers/ReportsManage"


class ManageView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      addNewCustomerView: false,
      userInviteView: false,
      addTrialTokens: false
    }

    this.handleChangeUserView = this.handleChangeUserView.bind(this)
    this.handleChangeCustomerView = this.handleChangeCustomerView.bind(this)
    this.handleChangeTrialView = this.handleChangeTrialView.bind(this)
  }

  subcontainer() {
    // Get the subcontainer from props
    const {
      match: {
        params: { sub }
      },
      role,
      token
    } = this.props
    // Routing
    switch (sub) {
      case "company":
        return <CompanyProfile />
      case "groups":
        return <GroupsList />
      case "areas":
        return <SitesManage />
      case "user":
        return this.state.userInviteView ? (
          <RegisterInvite handleChangeUserView={this.handleChangeUserView} />
        ) : (
          <UserManage handleChangeUserView={this.handleChangeUserView} />
        )
      case "customer":
        return role === "superAdmin" &&
          token &&
          this.state.addNewCustomerView ? (
              <CustomerManageAddNew
                handleChangeCustomerView={this.handleChangeCustomerView}
              />
            ) : (
              <CustomerManage
                handleChangeCustomerView={this.handleChangeCustomerView}
              />
            )
      case "trial":
        return role === "superAdmin" && token ? (
          this.state.addTrialTokens ? (
            <TrialTokenManageAdd
              handleChangeTrialView={this.handleChangeTrialView}
            />
          ) : (
            <TrialTokenManage
              handleChangeTrialView={this.handleChangeTrialView}
            />
          )
        ) : (
          <MyProfile />
        )
      case "reports":
        return role === "superAdmin" && token ? (          
          <ReportsManage />          
        ) : (
          <MyProfile />
        )
      case "limits":
        return <LimitsList />
      default:
        return <MyProfile />
    }
  }

  handleChangeUserView() {
    this.setState({ userInviteView: !this.state.userInviteView })
  }

  handleChangeCustomerView() {
    this.setState({ addNewCustomerView: !this.state.addNewCustomerView })
  }

  handleChangeTrialView() {
    this.setState({ addTrialTokens: !this.state.addTrialTokens })
  }

  subViewToggler() {}

  render() {
    const {
      classes,
      match: {
        params: { sub }
      }
    } = this.props

    return (
      <Grid className={classes.root}>
        <ProfilePageAppBar sub={sub} role={this.props.role} />
        <Grid item style={{ overflowY: "auto" }}>
          {this.subcontainer()}
        </Grid>
      </Grid>
    )
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: "100vh",
    width: "100vw"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  themedbutton: {
    color: theme
  },
  navItemStyle: {
    padding: theme.spacing(1),
    margin: theme.spacing(1)
  }
})

const mapDispatchToProps = {
  ...iotDevices
}

const mapStateToProps = state => {
  return {
    iotDevices: state.iotDevices,
    role: state.auth.role,
    token: state.auth.token
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTheme(ManageView)))
