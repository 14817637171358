import React from "react"
import Grid from "@material-ui/core/Grid"
import ListItemText from "@material-ui/core/ListItemText"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import makeStyles from "@material-ui/core/styles/makeStyles"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { tr } from "translations"

const WidgetSiteSelector = props => {
  const { onSelect, selected, sites } = props

  const classes = useStyles()
  return (
    <Grid item xs={12}>
      <Select
        value={selected}
        onChange={onSelect}
        inputProps={{
          name: "type",
          id: "type-id"
        }}
        style={{ float: "right" }}
        classes={{
          root: classes.root,
          icon: classes.icon,
          selectMenu: classes.menu
        }}
        IconComponent={ExpandMoreIcon}
        disableUnderline
        displayEmpty
      >
        <MenuItem value="">
          <ListItemText>
            <em>{tr("_show_all")}</em>
          </ListItemText>
        </MenuItem>
        {sites.map((site, i) => {
          return (
            <MenuItem key={i} value={site._id}>
              <ListItemText primary={site.name} />
            </MenuItem>
          )
        })}
      </Select>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 100,
    minHeight: 30
  },
  icon: {
    color: theme.palette.text.primary
  },
  menu: {
    padding: 0
  }
}))

export default WidgetSiteSelector
