import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import Hidden from "@material-ui/core/Hidden"
import Button from "@material-ui/core/Button"

import Grid from "@material-ui/core/Grid"

import withStyles from "@material-ui/core/styles/withStyles"

import EditOutlinedIcon from "@material-ui/icons/EditOutlined"
import {
  /*checkIoTDevices,*/
  fetchIoTDevices,
  FilterDevices,
  ModifyIoTDevices
} from "../actions/iotDevices"
import { fetchAreas, FilterArea, UpdateAreaCoordinates } from "../actions/areas"
import { fetchSites } from "../actions/sites"
import { fetchActiveFaults, fetchFaults } from "../actions/faults"
import { fetchModels } from "../actions/types"
import DeviceDetail from "../components/GroundPlan/DeviceDetail"
import EditAreaModal from "../components/modals/EditArea"
import EditDevice from "../components/GroundPlan/EditDevice"
import FloatingMarker from "../components/GroundPlan/FloatingMarker"
import GroundplanMasterWrap from "../components/GroundPlan/GroundplanMasterWrap"
import AlertMenu from "../components/GroundPlan/AlertMenu"
// import { useInterval } from "../services/customHooks"
import AddAreaModal from "../components/modals/AddArea"
import AddDeviceModal from "../components/modals/AddDevice"

const GroundPlanScreen = props => {
  const { classes, match } = props

  const [deviceMoving, setDeviceMoving] = useState(null)
  const [dragStartCoords, setDragStartCoords] = useState(null)
  const [deviceArea, setDeviceArea] = useState(null)
  const [selected, setSelected] = useState(null)
  const [editAreaOpen, setEditAreaOpen] = useState(false)
  const [editDevice, setEditDevice] = useState(null)
  const [deviceOpen, setDeviceOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [warningOpen, setWarningOpen] = useState(false)
  const [faultHistoryOpen, setFaultHistoryOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [filter, setFilter] = useState(false)
  const [dashboardOpen, setDashBoardOpen] = useState(false)
  const [addAreaOpen, setAddAreaOpen] = useState(false)
  const [addDeviceOpen, setAddDeviceOpen] = useState(false)

  const dispatch = useDispatch()

  // redux states
  const areas = useSelector(state => state.areas)
  const iotDevices = useSelector(state => state.iotDevices)
  const showAlertHistory = useSelector(state => state.ui.showAlertHistory)
  // custom hook to check updates
  // UPDATE: data is not fetched anymore, use websocket data
  /* useInterval(() => {
    dispatch(checkIoTDevices(handleUpdate))
  }, 10000) */

  useEffect(() => {
    Promise.all([
      dispatch(fetchAreas()),
      dispatch(fetchIoTDevices()),
      dispatch(fetchFaults()),
      dispatch(fetchActiveFaults()),
      dispatch(fetchSites()),
      dispatch(fetchModels())
    ]).then(setFilter(true))
  }, [dispatch])

  useEffect(() => {
    // timer to check if menu opened else item moves
    let timer = null
    if (selected) {
      timer = setTimeout(() => {
        setDragStartCoords({x: selected.pos.x, y: selected.pos.y})
        setDeviceArea(areas.areaList.find((area) => area._id === selected.area))
        setDeviceMoving(selected)
        setSelected(null)
        timer = null
      }, 200)
    }
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [selected])

  useEffect(() => {
    if (filter) {
      dispatch(
        FilterArea(
          decodeURI(match.params.id),
          areas && areas.areaOpen ? areas.areaOpen.floor : 1
        )
      ).then(() =>
        dispatch(
          FilterDevices(
            decodeURI(match.params.id),
            areas.areaOpen,
            iotDevices ? iotDevices.deviceFilter : []
          )
        )
      )
      setFilter(false)
    }
  }, [filter, areas, dispatch, iotDevices, match.params.id])

  /* const handleUpdate = () => {
    // if device status was changed during interval, this function is called
    Promise.all([
      dispatch(fetchFaults()),
      dispatch(fetchActiveFaults())
    ]).then(() => setFilter(true))
  } */

  //new iotdevice drag feature functions
  const onDeviceGrip = device => event => {
    event.preventDefault()
    setDeviceMoving(device)
  }

  // current iotdevice drage feature functions
  const onDeviceGripDelayed = device => event => {
    event.preventDefault()
    setSelected(device)
  }

  // Functions for device edits
  const editingOpen = device => event => {
    event.preventDefault()
    if (deviceMoving === null) {
      setEditDevice(device)
      setDeviceOpen(true)
      setAnchorEl(event.currentTarget)
      setSelected(null)
    }
  }

  const editingClose = () => {
    setEditDevice(null)
    setDeviceOpen(false)
    setAnchorEl(null)
    setSelected(null)
  }

  const chooseEditing = device => event => {
    event.preventDefault()
    const anchorElement = document.getElementById(device.deviceId)

    setEditDevice(device)
    setDeviceOpen(true)
    setAnchorEl(anchorElement)
  }

  const onDeviceCancel = () => {
    console.log("Device Cancelling")
    setDeviceMoving(null)
  }

  const onAreaRelease = (area, x, y) => {
    dispatch(
      UpdateAreaCoordinates(area, x, y)
    )
  }

  const onDeviceRelease = (x, y) => {
    if (deviceMoving.status !== "NEW") {
      let endX = x
      let endY = y
      if(!areas.areaOpen){
        let areaOffset = deviceArea?.pos
        let movedX = x - (areaOffset?.x || 0) - dragStartCoords.x
        let movedY = y - dragStartCoords.y
        endX = dragStartCoords.x  + movedX
        endY = dragStartCoords.y + movedY - (areaOffset?.y || 0)
      }
      dispatch(
        ModifyIoTDevices(
          {
            ...deviceMoving,
            area: deviceArea || areas.areaOpen._id,
            site: decodeURI(match.params.id)
          },
          { x: endX, y: endY }
        )
      )
        .then(setDeviceMoving(null))
        .then(() => setFilter(true))

        .catch(err => console.log(err))
    } else {
      setEditDevice({
        ...deviceMoving,
        area: areas.areaOpen._id,
        site: decodeURI(match.params.id),
        pos: { x: x, y: y }
      })
      setDeviceOpen(true)
      setDeviceMoving(null)
      setSelected(null)
    }
  }

  const handleAlertMenu = menuType => event => {
    event.preventDefault()
    switch (menuType) {
      case "alert":
        setWarningOpen(false)
        setFaultHistoryOpen(false)
        setAlertOpen(!alertOpen)
        break;
      case "faultLog":
        setAlertOpen(false)
        setWarningOpen(false)
        setFaultHistoryOpen(!faultHistoryOpen)
        break;
      case "warning":
        setAlertOpen(false)
        setFaultHistoryOpen(false)
        setWarningOpen(!warningOpen)
        break;

      default:
        break;
    }
  }

  const handleEdit = () => {
    setDeviceOpen(false)
    setEditOpen(!editOpen)
  }
  const handleInfo = () => {
    setDeviceOpen(false)
    setDashBoardOpen(!dashboardOpen)
  }

  const handleClose = () => {
    setEditOpen(false)
    setDashBoardOpen(false)
    setEditDevice(null)
  }

  const handleRemove = () => {
    dispatch(
      ModifyIoTDevices({ ...editDevice, area: null }, { x: null, y: null })
    ).catch(err => console.log(err))
    handleClose()
  }

  const handleEditArea = () => {
    setEditAreaOpen(!editAreaOpen)
  }

  const handleAddArea = () => {
    setAddAreaOpen(!addAreaOpen)
  }

  const handleAddDevice = () => {
    setAddDeviceOpen(!addDeviceOpen)
  }

  const filteredIoTDevices = areas.areaOpen ? iotDevices.iotDeviceFiltered : []

  return (
    <FloatingMarker
      deviceMoving={deviceMoving}
      onDeviceRelease={onDeviceRelease}
      onDeviceCancel={onDeviceCancel}
    >
      {editDevice && (
        <DeviceDetail
          isOpen={deviceOpen}
          anchorEl={anchorEl}
          details={editDevice}
          onClose={editingClose}
          handleEdit={handleEdit}
          handleInfo={handleInfo}
        />
      )}

      {editAreaOpen && (
        <EditAreaModal
          isOpen={editAreaOpen}
          onClose={handleEditArea}
          handleAddArea={() => handleAddArea()}
          handleAddDevice={() => handleAddDevice()}
        />
      )}
      {editOpen && (
        <EditDevice
          isOpen={editOpen}
          device={editDevice}
          onClose={handleClose}
          removeDevice={handleRemove}
        />
      )}
      {addAreaOpen && (
        <AddAreaModal
          onClose={() => handleAddArea()}
          isOpen={addAreaOpen}
          site={areas.areaOpen.site}
        />
      )}
      {addDeviceOpen && (
        <AddDeviceModal
          onClose={() => handleAddDevice()}
          isOpen={addDeviceOpen}
          areas={areas}
          site={areas.areaOpen.site}
        />
      )}
      <Hidden>
        <GroundplanMasterWrap
          onDeviceGrip={onDeviceGrip}
          onDeviceGripDelayed={onDeviceGripDelayed}
          onDeviceCancel={onDeviceCancel}
          onDeviceRelease={onDeviceRelease}
          onAreaRelease={onAreaRelease}
          editingOpen={editingOpen}
          deviceMoving={deviceMoving}
          selected={editDevice}
        />
      </Hidden>

      <div
        style={{
          position: "absolute",
          right: 0,
          maxWidth: 220,
          marginTop: 25,
          marginRight: 20
        }}
      >{areas.areaOpen && (<Grid container item xs={12} spacing={1}>
          <Grid container className={classes.root} spacing={2}>
            <Grid container item xs={12}>
              <AlertMenu
                menuType={"alert"}
                iotDevices={filteredIoTDevices}
                openDevice={chooseEditing}
                open={alertOpen}
                handleOpen={handleAlertMenu}
              />
            </Grid>
            {showAlertHistory &&
              <Grid container item xs={12}>
                <AlertMenu
                  menuType={"faultLog"}
                  iotDevices={filteredIoTDevices}
                  openDevice={chooseEditing}
                  open={faultHistoryOpen}
                  handleOpen={handleAlertMenu}
                />
              </Grid>
            }
            <Grid container item xs={12}>
              <Hidden xsDown>
                <AlertMenu
                  menuType={"warning"}
                  iotDevices={filteredIoTDevices}
                  onDrag={onDeviceGrip}
                  open={warningOpen}
                  handleOpen={handleAlertMenu}
                />
              </Hidden>
            </Grid>
          </Grid>
        </Grid>)}
        
      </div>
      {!areas.floorOpen && (<div
        style={{
          position: "absolute",
          right: 0,
          bottom: 0,
          marginBottom: 25,
          marginRight: 20
        }}
      >
        <Grid>
          <Button onClick={handleEditArea}>
            <EditOutlinedIcon fontSize="large" />
          </Button>
        </Grid>
      </div>)}
      
    </FloatingMarker>
  )
}

const styles = theme => ({
  root: {
    maxHeight: "calc(100% - 90px)", // full height minus top navbar and margins
    paddingTop: theme.spacing(0)
  }
})

export default withStyles(styles)(GroundPlanScreen)
