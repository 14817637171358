export default (
  state = {
    error: null,
    forms: {},
    info: null,
    language: "EN",
    currentTheme: "dark",
    showAlertHistory: false,
    navigation: defaultNavigationState(),
    users: defaultUsersState(),
    aboutOpen: false
  },
  action
) => {  
  switch (action.type) {
    case "SET_ERROR":
      return { ...state, error: action.error }
    case "SET_INFO":
      return { ...state, info: action.info }
    case "RESET_INFO":
      return { ...state, info: null }
    case "RESET_ERROR":
      return { ...state, error: null }

    case "CHANGE_LANGUAGE":
      return { ...state, language: action.language }
    case "CHANGE_THEME":
      return { ...state, currentTheme: action.theme }
    case "UI_RESET_VIEWS":
      return { ...state, users: defaultUsersState() }
    case "CHANGE_ALERTHISTORY_VISIBILITY":
      return { ...state, showAlertHistory: action.showAlertHistory }

    case "UI_USERS_OPEN_ADDING":
      return { ...state, users: { ...state.users, mode: "ADDING", user: null } }
    case "UI_USERS_OPEN_LIST":
      return { ...state, users: { ...state.users, mode: "LIST", user: null } }
    case "UI_USERS_OPEN_EDITING":
      return {
        ...state,
        users: { ...state.users, mode: "EDITING", user: action.user }
      }
    case "UI_USERS_OPEN_PW_EDITING":
      return {
        ...state,
        users: { ...state.users, mode: "PW_EDITING", user: action.user }
      }

    case "UI_START_FETCHING":
      switch (action.target) {
        case "UI_ORDERING_RULES":
          return {
            ...state,
            orderingRules: { ...state.orderingRules, isFetching: true }
          }
      }
      break

    case "UI_DATA_RECEIVED":
      switch (action.target) {
        case "UI_ORDERING_RULES":
          return {
            ...state,
            orderingRules: {
              ...state.orderingRules,
              isFetching: false,
              shouldRefresh: false,
              stockWithRules: action.data
            }
          }
      }
      break

    case "UI_UPDATE_STATE":
      switch (action.target) {
        case "UI_NAVIGATION":
          return {
            ...state,
            navigation: { ...state.navigation, ...action.newValue }
          }
      }
      break
    case "ABOUT_OPEN":
      return { ...state, aboutOpen: action.open }
    default:
      return state
  }
}

const defaultUsersState = () => {
  return { mode: "LIST", user: null }
}

const defaultNavigationState = () => {
  return { stockCategory: null, stockCategory2: null }
}
