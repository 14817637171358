import React from "react"
import { tr } from "translations"

class ErrorView extends React.Component {
  render() {
    return (
      <div>
        <h1 style={{ color: "red" }}>{tr("_fatal_error")}</h1>
        <h2>{this.props.error.message}</h2>
      </div>
    )
  }
}

export default ErrorView
