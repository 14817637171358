import React, { useState } from "react"

import Sites from "./Sites"
import SitesAddNew from "./SitesAddNew"
//import Tests from "./Tests"
import SiteDetails from "./SiteDetails"

const SitesManage = () => {
  const [sub, setSub] = useState(0)
  const [selected, setSelected] = useState(null)

  const onSelectSite = site => {
    setSub(3)
    setSelected(site)
  }

  const handleSelectView = sub => {
    setSub(sub)
  }

  if (sub === 0) {
    return <Sites onSelectView={handleSelectView} onSelectSite={onSelectSite} />
  } /*else if (sub === 1) {
    return <Tests selected={selected} />
  } */ else if (
    sub === 2
  ) {
    return <SitesAddNew onSelectView={handleSelectView} />
  } else if (sub === 3) {
    return (
      <SiteDetails onSelectView={handleSelectView} selectedSiteId={selected} />
    )
  }
}

export default SitesManage
