import React, { useEffect, useState } from "react"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"

import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import MoreHorizIcon from "@material-ui/icons/MoreHoriz"
import { tr } from "translations"

const LimitsTablePagination = props => {
  const { classes, maxPage, changePage, page } = props

  const [shownPages, setShownPages] = useState([])

  useEffect(() => {
    let tempShownPages = []
    if (maxPage < 7) {
      tempShownPages = new Array(maxPage - 1)
        .fill(1)
        .map((v, index) => index + 1)
    } else if (page >= 0 && page <= 3) {
      tempShownPages = [1, 2, 3, 4]
    } else if (page <= maxPage && page >= maxPage - 3) {
      tempShownPages = [maxPage - 4, maxPage - 3, maxPage - 2, maxPage - 1]
    } else {
      tempShownPages = [page - 1, page, page + 1]
    }

    setShownPages(tempShownPages)
  }, [page, maxPage])

  return (
    <div className={classes.div}>
      <Button
        className={classes.navigate}
        startIcon={<NavigateBeforeIcon />}
        onClick={() => changePage(page - 1)}
        disabled={page === 0}
      >
        {tr("_previous")}
      </Button>
      <Typography
        className={page === 0 ? classes.selected : classes.pageNumber}
        onClick={() => changePage(0)}
      >
        1
      </Typography>

      {!shownPages.includes(2) && maxPage >= 7 && (
        <IconButton className={classes.iconButton}>
          <MoreHorizIcon />{" "}
        </IconButton>
      )}
      {shownPages.map(value => (
        <Typography
          key={value}
          className={page === value ? classes.selected : classes.pageNumber}
          onClick={() => changePage(value)}
        >
          {value + 1}
        </Typography>
      ))}
      {!shownPages.includes(maxPage - 1) && maxPage >= 7 && (
        <IconButton className={classes.iconButton}>
          <MoreHorizIcon />{" "}
        </IconButton>
      )}
      <Typography
        className={page === maxPage ? classes.selected : classes.pageNumber}
        onClick={() => changePage(maxPage)}
      >
        {maxPage + 1}
      </Typography>
      <Button
        className={classes.navigate}
        endIcon={<NavigateNextIcon />}
        onClick={() => changePage(page + 1)}
        disabled={page === maxPage}
      >
        {tr("_next")}
      </Button>
    </div>
  )
}

const styles = theme => ({
  div: {
    float: "right",
    color: theme.palette.primary.light,
    display: "inline-block"
  },
  navigate: {
    marginRight: "1px",
    color: theme.palette.primary.light,
    fontWeight: "bold",
    fontSize: "14pt",
    textTransform: "none",
    marginBottom: "5px"
  },
  pageNumber: {
    fontSize: "16pt",
    fontWeight: "bold",
    display: "inline-block",
    margin: "0px 15px 0px 15px",
    "&:hover": {
      cursor: "pointer"
    }
  },
  selected: {
    color: theme.palette.secondary.light,
    fontSize: "16pt",
    fontWeight: "bold",
    display: "inline-block",
    margin: "0px 15px 0px 15px"
  },
  iconButton: {
    borderRadius: "5px",
    height: "35px",
    color: theme.palette.primary.light
  }
})

export default withStyles(styles, { withTheme: true })(LimitsTablePagination)
