import { stateType } from "../constants/actions"
import { Mutex } from "async-mutex"
import DeviceDataBuffer from "./../services/deviceDataBuffer"
const mutex = new Mutex()

const deviceDataTimeFilterSet = hours => ({
  type: stateType.DEVICEDATA_SET_FILTER,
  hours: hours
})

// bufferArray : [{ device: /* device data */ */, deviceId : /* device id*/ */ }, /* ... */]
export const deviceDataAddFromBufferReceived = dataBufferArray => ({
  type: stateType.DEVICEDATA_ADD_FROM_BUFFER_RECEIVED,
  dataBufferArray
})
export function conditionalFlushDataBuffer(dispatch, lastFlushTime) {
  const currentBuffer = DeviceDataBuffer.getWebsocketBuffer()
  if (
    currentBuffer.length > 0 &&
    (!lastFlushTime || Date.now() - lastFlushTime > 1000)
  ) {
    dispatch(
      deviceDataAddFromBufferReceived(DeviceDataBuffer.getWebsocketBuffer())
    )
    DeviceDataBuffer.clear()
  }
}
export const wsDataUpdate = iotdata => async (dispatch, getState) => {
  const release = await mutex.acquire()
  try {
    const state = getState()
    const lastFlushTime = state.dataUpdate.lastFlushTime
    const now = new Date().getTime()
    if (!iotdata.ts) iotdata["ts"] = now // jos data route ei ole lisannyt stampia, tehdaan se tassa
    DeviceDataBuffer.add(iotdata.data, iotdata.deviceId)
    conditionalFlushDataBuffer(dispatch, lastFlushTime)
  } finally {
    release()
  }
}

export const setDeviceDateTimeFilter = hours => async dispatch => {
  dispatch(deviceDataTimeFilterSet(hours))
}
