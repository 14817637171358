import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"

import Grid from "@material-ui/core/Grid"
import GridListTile from "@material-ui/core/GridListTile"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"
import CancelIcon from "@material-ui/icons/Cancel"
import InfoIcon from '@material-ui/icons/Info';
import { tr } from "translations"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { IconButton } from "@material-ui/core"


const FaultHistoryItem = props => {
  const { classes, item, openDevice } = props
  const [fault, setFault] = useState([])
  const [deviceOpen, setDeviceOpen] = useState(false)
  const faults = useSelector(state => state.faults)
  var yesterday = new Date().getTime() - (24 * 60 * 60 * 1000);

  useEffect(() => {
    var faultsFormat = []
    faults.faultList.filter(f => {
      return f.device === item.deviceId && (new Date(f.startTime).getTime() > yesterday)
    }).map(f => {
      var startDateString = new Date(f.startTime).toLocaleDateString();
      if (faultsFormat && faultsFormat.findIndex(e => e.startDateString === startDateString) == -1) {
        faultsFormat.push({ startDateString: startDateString, data: [{ ...f }] })
      }
      else {
        faultsFormat[faultsFormat.findIndex(e => e.startDateString === startDateString)].data.push({ ...f })
      }
    }
    )
    setFault(faultsFormat)
  }, [faults, item.deviceId])

  const handleOpen = event => {
    event.preventDefault()
    setDeviceOpen(!deviceOpen)
  }


  if (fault.length > 0) {
    return (
      <GridListTile key={item._id.toString()}>
        <Paper
          className={classes.faultHistoryItemPaper}
          square={true}
        >
          <Grid container alignItems="center">
            <Grid container
              justify="space-around"
              alignItems="center"
              item xs={12} >
              {/* onClick={handleOpen}> */}
              <IconButton
                onClick={openDevice(item)}>
                <InfoIcon
                  className={classes.iconInfo}
                  style={{ fontSize: 24 }} />
              </IconButton>
              <Typography align="center" variant="subtitle1" color="textPrimary">{item.name ?? item.deviceId}</Typography>
              <IconButton
                onClick={handleOpen}>
                {deviceOpen ? (
                  <ExpandLessIcon color="action" fontSize="large" />
                ) : (
                  <ExpandMoreIcon color="action" fontSize="large" />
                )}
              </IconButton>

            </Grid>
          </Grid>

          {deviceOpen && fault.map(f =>
            <Grid container alignItems="center" key={f.startDateString}>
              <Grid item xs={12} >
                <Typography align="center" className={classes.itemText} variant="subtitle2">
                  {f && f.startDateString
                    ? f.startDateString
                    : tr("_unknownFault")} - {f.data.length} alerts
                </Typography>
              </Grid>
              {f.data.map(e =>

                <Grid container alignItems="center" key={e._id.toString()}>
                  <Grid item xs={3}>
                    <Typography className={classes.itemText} variant="body2">
                      {new Date(e.startTime).toLocaleTimeString('fi-FI', { hour: '2-digit', minute: '2-digit' })}
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography className={classes.itemText} variant="body2">
                      {e && e.description
                        ? e.description
                        : tr("_unknownFault")}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}

        </Paper>

      </GridListTile>
    )
  } else { return null; }
}

const styles = theme => ({
  faultHistoryItemPaper: {
    width: 220,
    padding: 5,
    borderColor: theme.palette.error.dark,
    borderStyle: "solid",
    borderWidth: 1
  },
  iconAlert: {
    color: theme.palette.error.dark
  },
  iconInfo: {
    color: theme.palette.secondary.main
  },
  itemText: {
    color: theme.palette.text.dark
  },
})

export default withStyles(styles, { withTheme: true })(FaultHistoryItem)
