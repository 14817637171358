export default (
  state = {
    trialTokens: [],
    isFetching: false,
    error: null,
    shouldRefresh: false,
    showTrial: null
  },
  action
) => {
  switch (action.type) {
    case "TRIALTOKEN_REQUESTED":
      return {
        ...state,
        isFetching: true,
        shouldRefresh: false
      }
    case "TRIALTOKENLIST_RECEIVED":
      return {
        ...state,
        isFetching: false,
        shouldRefresh: false,
        trialTokens: action.trialTokens
      }
    case "TRIALTOKEN_RESET":
      return {
        ...state,
        trialTokens: []
      }
    case "TRIALTOKEN_SUCCESS":
      return { ...state, isFetching: false }
    case "TRIALTOKEN_ERROR":
      return {
        ...state,
        isFetching: false,
        shouldRefresh: true,
        error: action.error
      }
    case "TRIALTOKEN_RESET_ERROR":
      return { ...state, error: null }
    case "TRIALTOKEN_SHOWTRIAL":
      return { ...state, showTrial: action.showTrial }
    case "TRIALTOKEN_CLOSETRIAL":
      return { ...state, showTrial: null }
    default:
      return state
  }
}
