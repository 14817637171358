import React, { useState } from "react"
import { useSelector } from "react-redux"

import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import Grid from "@material-ui/core/Grid"
import Fade from "@material-ui/core/Fade"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import useTheme from "@material-ui/core/styles/useTheme"
import withStyles from "@material-ui/core/styles/withStyles"

import { tr } from "translations"
import RadioButton from "./RadioButton"

const CopyModal = props => {
  const { classes, isOpen, closeMenu, onSave, target } = props

  const [selection, setSelection] = useState("")
  const isDark = useSelector(state => state.ui.currentTheme === "dark")

  const theme = useTheme()

  const handleSave = () => {
    onSave(selection)
    closeMenu()
  }

  const handleChange = event => {
    if (!event.target.value) {
      setSelection(event.target.id)
    } else {
      setSelection(event.target.value)
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={closeMenu}
      aria-labelledby="simple-dialog-title"
    >
      <Fade in={isOpen} timeout={700}>
        <Paper className={classes.menuPaper}>
          <Grid container>
            <Grid
              item
              xs={12}
              container
              alignItems="center"
              className={classes.title}
            >
              <Typography
                variant="h6"
                color={isDark ? "primary" : "textPrimary"}
              >
                {tr("_select_dashboard_widget")}
              </Typography>
            </Grid>

            <RadioButton
              value={"widgetOne"}
              target={target}
              selection={selection}
              handleChange={handleChange}
            />
            <RadioButton
              value={"widgetTwo"}
              target={target}
              selection={selection}
              handleChange={handleChange}
            />
            <RadioButton
              value={"widgetThree"}
              target={target}
              selection={selection}
              handleChange={handleChange}
            />
            <RadioButton
              value={"widgetFour"}
              target={target}
              selection={selection}
              handleChange={handleChange}
            />
            <RadioButton
              value={"widgetFive"}
              target={target}
              selection={selection}
              handleChange={handleChange}
            />
            <RadioButton
              value={"widgetSix"}
              target={target}
              selection={selection}
              handleChange={handleChange}
            />

            <Grid
              item
              xs={12}
              container
              className={classes.buttons}
              justify="flex-end"
            >
              <Button
                variant="outlined"
                className={classes.button}
                onClick={closeMenu}
                style={{
                  backgroundColor: "gray"
                }}
              >
                {tr("_cancel")}
              </Button>
              <Button
                variant="contained"
                className={classes.button}
                style={{
                  backgroundColor: theme.palette.action.main
                }}
                onClick={handleSave}
              >
                {tr("_save")}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Fade>
    </Dialog>
  )
}

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
    width: 125,
    height: 40
  },
  buttons: {
    marginTop: 40
  },
  menuPaper: {
    backgroundColor: theme.palette.popup.background,
    width: 600,
    minHeight: 400,
    padding: theme.spacing(4)
  },
  title: {
    marginBottom: theme.spacing(2)
  }
})

export default withStyles(styles)(CopyModal)
