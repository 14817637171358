import { getWsUrl } from "../constants/config"
import { wsDeviceUpdate } from "./iotDevices"
import { wsDataUpdate } from "./dataUpdate"
import { wsFaultUpdate } from "./faults"
import {
  handleWatchlistAdd,
  handleWatchlistReduce
} from "../services/dataConverter"
// const wsConnect = () => ({ type: 'WS_CONNECT' })
const wsConnecting = () => ({ type: "WS_CONNECTING" })
const wsConnected = ws => ({ type: "WS_CONNECTED", ws })
const wsDisconnect = () => ({ type: "WS_DISCONNECT" })
const wsDisconnected = () => ({ type: "WS_DISCONNECTED" })
const updateWatchlist = (dashboardDevices, groundplanDevices) => ({
  type: "UPDATE_WATCHLIST",
  dashboardDevices,
  groundplanDevices
})
const cleanWatchlist = () => ({ type: "CLEAN_WATCHLIST" })

let socket = null
let reConnect
export const webSocketConnect = () => async dispatch => {
  try {
    console.log("Trying to open websocket")
    socket = new WebSocket(await getWsUrl())

    dispatch(wsConnecting())
    // websocket handlers
    socket.onmessage = evt => {
      //console.log("ws", evt)
      dispatch(onMessage(evt))
    }
    socket.onclose = evt => {
      dispatch(onClose(evt))
    }
    socket.onopen = () => {
      console.log("socket open")
      dispatch(onOpen(socket))
    }
  } catch (err) {
    console.log("socket connect failed", err)
  }
}

const onMessage = evt => async dispatch => {
  const msg = JSON.parse(evt.data)
  switch (msg.type) {
    case "login-ack":
      dispatch(watchlistRefresh())
      break
    case "logout-ack":
      socket.close()
      break
    case "iotupdate":
      dispatch(wsDeviceUpdate(msg))
      break
    case "ping":
      // console.log("Websocket keepalive!")
      break
    case "measureupdate":
      dispatch(wsDataUpdate(msg))
      break
    case "faultupdate":
      dispatch(wsFaultUpdate(msg))
      if (msg.data) {
        dispatch(
          wsDeviceUpdate({
            deviceId: msg.deviceId,
            data: {
              status: msg.data.active ? "FAULT" : "OK"
            }
          })
        )
      }
      break
    case "widget-ok":
      // widget were ok, ignore message
      break
    case "dataupdate":
      //console.log("update", data)
      dispatch(wsDataUpdate(msg))
      break
    default:
      console.log("unhandled message with type: ", msg)
  }
}

// websocket tries to reopen connection if closed, typically for network issues
const onClose = () => async dispatch => {
  dispatch(wsDisconnect())
  if (!reConnect) {
    reConnect = setInterval(() => dispatch(webSocketConnect()), 30000)
  }
  dispatch(wsDisconnected())
}

const onOpen = ws => async (dispatch, getState) => {
  const state = getState()
  if (state && state.auth && state.auth.token) {
    dispatch(webSocketAuth())
  }
  if (reConnect) {
    clearInterval(reConnect)
  }
  dispatch(wsConnected(ws))
}

export const webSocketDisconnect = () => async dispatch => {
  socket.CLOSE()
  dispatch(wsDisconnected())
}

export const webSocketAuth = () => async (dispatch, getState) => {
  try {
    const state = getState()
    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }
    if (socket && socket.readyState === 1) {
      socket.send(JSON.stringify({ type: "login", token: useToke }))
    }
  } catch (err) {
    console.log("socket auth failed, reconnect ", err)
    dispatch(onClose())
  }
}

export const webSocketDrop = () => async (dispatch, getState) => {
  const state = getState()
  let useToke = ""
  if (state && state.auth && state.auth.token) {
    useToke = state.auth.token
  }
  if (socket && socket.readyState === 1) {
    socket.send(JSON.stringify({ type: "logout", token: useToke }))
  }
}

export const webSocketWatch = devices => async (dispatch, getState) => {
  // console.log("webSocketWidgetOpen", devices)
  try {
    const state = getState()
    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }
    if (socket && socket.readyState === 1) {
      socket.send(
        JSON.stringify({
          type: "watchDevice",
          token: useToke,
          devices: devices
        })
      )
    }
  } catch (err) {
    console.log("socket failed reconnect ", err)
    dispatch(onClose())
  }
}

export const webSocketUnwatch = devices => async (dispatch, getState) => {
  try {
    const state = getState()
    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }
    if (socket && socket.readyState === 1) {
      socket.send(
        JSON.stringify({
          type: "unwatchDevice",
          token: useToke,
          devices: devices
        })
      )
    }
  } catch (err) {
    console.log("socket failed reconnect ", err)
    dispatch(onClose())
  }
}

export const watchlistAdd = devices => async (dispatch, getState) => {
  const state = getState()
  const dash = state.websocket.dashboardDevices
  const ground = state.websocket.groundplanDevices
  const allDevs = [...dash, ...ground]
  const send = handleWatchlistAdd(devices, allDevs)
  if (send.length > 0) {
    dispatch(webSocketWatch(send))
  }

  dispatch(updateWatchlist([...dash, ...devices], ground))
}
export const watchlistRemove = devices => async (dispatch, getState) => {
  const state = getState()
  const dash = [...state.websocket.dashboardDevices]
  const ground = state.websocket.groundplanDevices
  const allDevs = [...dash, ...ground]
  const send = handleWatchlistReduce(devices, allDevs)

  if (send.length > 0) {
    dispatch(webSocketUnwatch(send))
  }

  devices.forEach(id => {
    let found = false
    const idx = dash.indexOf(id)
    if (idx != -1 && !found) {
      found = true
      dash.splice(idx, 1)
    }
  })

  dispatch(updateWatchlist(dash, ground))
}

export const watchlistRefresh = () => async (dispatch, getState) => {
  const state = getState()
  const ids = [
    ...state.websocket.dashboardDevices,
    ...state.websocket.groundplanDevices
  ]
  const send = []
  ids.forEach(id => {
    const idx = send.indexOf(id)
    if (idx == -1) {
      send.push(id)
    }
  })
  if (send.length > 0) {
    dispatch(webSocketWatch(send))
  }
}

export const watchlistGroundplan = devices => async (dispatch, getState) => {
  const state = getState()
  const dash = state.websocket.dashboardDevices
  const ground = state.websocket.groundplanDevices
  // console.log("watchlistGroundplan", ground.length)
  if (ground.length > 0) {
    dispatch(webSocketUnwatch(ground))
  }

  const send = handleWatchlistAdd(devices, dash)
  if (send.length > 0) {
    dispatch(webSocketWatch(send))
  }
  dispatch(updateWatchlist(dash, devices))
}

export const watchlistWipe = () => async (dispatch, getState) => {
  const state = getState()
  const dash = state.websocket.dashboardDevices
  const ground = state.websocket.groundplanDevices
  const allDevs = [...dash, ...ground]
  dispatch(cleanWatchlist())
  dispatch(webSocketUnwatch(allDevs))
}
