import * as React from "react"

import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import IconButton from "@material-ui/core/IconButton"


import Typography from "@material-ui/core/Typography"
import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import Grid from "@material-ui/core/Grid"
import withStyles from "@material-ui/core/styles/withStyles"

import Button from "@material-ui/core/Button"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { connect } from "react-redux"
import { tr } from "translations"
import {
  AddCustomers,
  DeleteCustomers,
  fetchCustomers
} from "../../../actions/customers"
import { fetchSites, ModifySites } from "../../../actions/sites"
import { fetchUsers } from "../../../actions/users"
import { ManageHeader } from "../../../components/manageMenu/manageHeader"
import VisibilityIcon from "@material-ui/icons/Visibility"
import CustomerSelection from "../../../components/modals/CustomerSelection"
import { ExpansionPanelSubheading } from "../../../components/parts/ExpansionPanelSubheading"

class CustomerManage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      customers: [],
      name: "",
      selectedFile: null,
      error: null,
      isSending: false,
      submitSuccessful: false,
      expanded: null,
      showCustomerSelectionModal: false,
      siteToTransfer: null
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.resetError = this.resetError.bind(this)
    this.handleselectedFile = this.handleselectedFile.bind(this)
    this.handleChangePanel = this.handleChangePanel.bind(this)
    this.handleChangeCustomerView = this.handleChangeCustomerView.bind(this)
    this.handleSiteTransfer = this.handleSiteTransfer.bind(this)
    this.handleToggle = this.handleToggle.bind(this)
    this.onSubmitCustomer = this.onSubmitCustomer.bind(this)
  }

  componentDidMount() {
    this.props.fetchCustomers()
    this.props.fetchSites()
    this.props.fetchUsers()
  }

  /** Handle button press to initiate site transfer */
  handleSiteTransfer(site) {
    this.setState({
      showCustomerSelectionModal: true,
      siteToTransfer: site ? site : null
    })
  }

  handleChangeCustomerView() {
    this.props.handleChangeCustomerView()
  }

  handleChangePanel(expanded, customer) {
    this.setState({
      expanded: expanded ? customer._id : false,
      _id: customer._id || ""
    })
  }

  /** Toggle modal */
  handleToggle(target) {
    this.setState({
      [target]: !this.state[target]
    })
  }

  /** Transfer site to new customer. Note that this clears site's current users */
  onSubmitCustomer(customer) {
    let transferSite = {}
    transferSite._id = this.state.siteToTransfer._id
    transferSite.customer = customer
    transferSite.group = []

    this.props
      .ModifySites(transferSite)
      .then(() => {
        this.props.fetchSites()
      })
      .catch(err => console.log(err))
  }

  handleChange(event) {
    const target = event.target

    switch (target.name) {
      case "name":
        return this.setState({ name: target.value })
    }
  }

  async handleDelete(id) {
    this.props.DeleteCustomers(id).then(() => this.props.fetchCustomers())
  }

  resetError() {
    this.setState({ error: null })
  }

  handleselectedFile(event) {
    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0
    })
  }

  async handleSubmit(e) {
    e.preventDefault()
    const { name, selectedFile } = this.state

    this.props
      .AddCustomers({ name }, selectedFile)
      .then(() => this.props.fetchCustomers())
      .catch(err => console.log("Save failed", err))
  }

  render() {
    const { customerList, classes } = this.props
    const { expanded, showCustomerSelectionModal /* error,*/ } = this.state

    return (
      <div className={classes.root}>
        <ManageHeader
          addNew={this.handleChangeCustomerView}
          addNewText={tr("Add_new_customer")}
          title={tr("_customerManage")}
        />

        <Grid container justify="center">
          <Grid item xs={11} lg={10}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.headingPart1}>
                {tr("Company")}
              </Typography>
              <Typography className={classes.headingPart2}>
                {tr("Sites")}
              </Typography>
              <Typography className={classes.headingPart3}>
                {tr("Users")}
              </Typography>
            </AccordionSummary>

            {customerList
              ? customerList.map((customer /* , idx */) => {
                  const customerSites = this.props.siteList.filter(
                    c => c.customer === customer._id
                  )
                  const customerUsers = this.props.userList.filter(
                    c => c.customer === customer._id
                  )

                  return (
                    <Accordion
                      key={customer._id}
                      expanded={expanded === customer._id}
                      onChange={(event, expanded) =>
                        this.handleChangePanel(expanded, customer)
                      }
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.headingPart1}>
                          {customer.name}
                        </Typography>
                        <Typography className={classes.headingPart2}>
                          {customerSites.length}
                        </Typography>
                        <Typography className={classes.headingPart3}>
                          {customerUsers.length}
                        </Typography>
                      </AccordionSummary>

                      <AccordionDetails>
                        <Grid container spacing={0}>
                          <ExpansionPanelSubheading
                            title={tr("Customers_sites")}
                          />
                          <Grid item xs={12}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>{tr("_site_name")}</TableCell>
                                  <TableCell>{tr("_address")}</TableCell>
                                  <TableCell>{tr("_city")}</TableCell>
                                  <TableCell>{tr("_Transfer_site")}</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {customerSites
                                  ? customerSites.map(site => {
                                      return (
                                        <TableRow key={site._id}>
                                          <TableCell>{site.name}</TableCell>
                                          <TableCell>{site.address}</TableCell>
                                          <TableCell>{site.city}</TableCell>
                                          <TableCell>
                                            <IconButton
                                              onClick={() =>
                                                this.handleSiteTransfer(site)
                                              }
                                            >
                                              <VisibilityIcon />
                                            </IconButton>
                                          </TableCell>
                                        </TableRow>
                                      )
                                    })
                                  : null}
                              </TableBody>
                            </Table>
                          </Grid>

                          <ExpansionPanelSubheading
                            title={tr("Customers_users")}
                          />

                          <Grid item xs={12}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>{tr("_firstname")}</TableCell>
                                  <TableCell>{tr("_lastname")}</TableCell>
                                  <TableCell>{tr("_role")}</TableCell>
                                  {/* <TableCell>
                                    {tr("_remove_site_from_user")}
                                  </TableCell> */}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {customerUsers
                                  ? customerUsers.map(user => {
                                      return (
                                        <TableRow key={user._id}>
                                          <TableCell>
                                            {user.firstname}
                                          </TableCell>
                                          <TableCell>{user.lastname}</TableCell>
                                          <TableCell>{user.role}</TableCell>
                                          {/* <TableCell>
                                            <IconButton>
                                              <Visibility />
                                            </IconButton>
                                          </TableCell> */}
                                        </TableRow>
                                      )
                                    })
                                  : null}
                              </TableBody>
                            </Table>
                          </Grid>

                          <ExpansionPanelSubheading
                            title={tr("Delete_customer")}
                          />

                          <Grid item xs={12}>
                            <Button
                              color="secondary"
                              variant="contained"
                              onClick={this.handleDelete.bind(
                                this,
                                customer._id
                              )}
                            >
                              {tr("Delete_customer")}
                            </Button>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  )
                })
              : ""}
          </Grid>
        </Grid>

        <CustomerSelection
          customerList={customerList}
          onClose={() => this.handleToggle("showCustomerSelectionModal")}
          isOpen={showCustomerSelectionModal}
          onSubmitCustomer={this.onSubmitCustomer}
        />
      </div>
    )
  }
}

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  headingPart1: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    color: theme.palette.text.field
  },
  headingPart2: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    color: theme.palette.text.field
  },
  headingPart3: {
    color: theme.palette.text.field,
    flexBasis: "10%",
    fontSize: theme.typography.pxToRem(15)
  },
  subHeading: {
    padding: "2rem 0 1rem"
  },
  pageTitle: {
    display: "inline-block",
    paddingBottom: "1rem",
    paddingTop: "2rem"
  },
  root: {
    flexGrow: 1,
    height: "calc(100vh - 130px)",
    backgroundImage: theme.backgroundImage,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed"
  },
  dense: {
    marginTop: 16
  },
  menu: {
    width: 200
  }
})

const mapDispatchToProps = dispatch => ({
  AddCustomers: (obj, file) => dispatch(AddCustomers(obj, file)),
  DeleteCustomers: string => dispatch(DeleteCustomers(string)),
  fetchCustomers: () => dispatch(fetchCustomers()),
  fetchSites: () => dispatch(fetchSites()),
  ModifySites: obj => dispatch(ModifySites(obj)),
  fetchUsers: () => dispatch(fetchUsers())
})

const mapStateToProps = state => {
  return {
    customerList: state.customers.customerList,
    siteList: state.sites.siteList,
    userList: state.users.userList
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(CustomerManage))
