import React, { useEffect, useState } from "react"
import loadable from "@loadable/component"
import { widgetTypes } from "./DashboardWidgets/widgetTypes"

const SelectedWidget = loadable(
  props => import(`./DashboardWidgets/settingModals/${props.page}`),
  {
    cacheKey: props => props.page
  }
)

const DefineSettingsModal = props => {
  const { widget } = props

  const [page, setPage] = useState(null)

  useEffect(() => {
    if (widget && widgetTypes.includes(widget)) {
      // first clear earlier selection
      setPage(null)
      // after small timeout set new page
      setTimeout(() => {
        setPage(`setting${widget}`)
      }, 200)
    }
  }, [widget])

  return <>{page && <SelectedWidget page={page} {...props} />}</>
}

export default DefineSettingsModal
