import * as React from "react"

import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import Checkbox from "@material-ui/core/Checkbox"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import withStyles from "@material-ui/core/styles/withStyles"

import Button from "@material-ui/core/Button"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"
import { connect } from "react-redux"
import { tr } from "translations"
import { fetchCustomers } from "../../../actions/customers"
import { inviteUser } from "../../../actions/users"
import { ManageHeader } from "../../../components/manageMenu/manageHeader"

class RegisterInvite extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      role: "user",
      customer: "",
      siteList: this.props.siteList || [],
      formUpdated: false,
      error: null,
      isSending: false,
      submitSuccessful: false,
      selected: []
    }

    this.resetError = this.resetError.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.isSelected = this.isSelected.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChangeUserView = this.handleChangeUserView.bind(this) // Controls which view is in user manage page
  }

  componentDidMount() {
    this.props.fetchCustomers()
  }

  handleChangeUserView() {
    this.props.handleChangeUserView()
  }

  handleChange({ target }) {
    const newSiteList = Object.assign([], this.props.siteList)
    const newList = newSiteList.filter((site) => site.customer === target.value)
    let newState = {
      [target.name]: target.value,
      formUpdated: true
    }
    switch (target.name) {
      case "customer":
        newState.siteList = newList
        break
    }
    this.setState(newState)
  }

  /** Handle Site table selections */
  handleClick(event, id) {
    const { selected } = this.state
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    this.setState({ selected: newSelected })
  }

  /** Helper function to Site table selections */
  isSelected(id) {
    this.state.selected.indexOf(id) !== -1
  }

  resetError() {
    this.setState({ error: null })
  }

  handleSubmit() {
    const { email, role, selected } = this.state
    /**
     * if role === admin, there is only one possible customer,
     * so UI selection is not shown --> set customer manually here.
     * Otherwise take it from state
     */
    const customer =
      this.props.role === "admin"
        ? this.props.customerList[0]._id
        : this.state.customer

    this.props
      .inviteUser({
        customerId: customer,
        email,
        role,
        sites: selected
      })
      .then(() => {
        this.setState({
          email: "",
          role: "user",
          customer: "",
          error: null,
          selected: [],
          formUpdated: false,
          isSending: false,
          submitSuccessful: true
        })
        this.props.handleChangeUserView()
      })
      .catch((err) => console.log("Error", err))
  }

  render() {
    const { classes, customerList } = this.props
    const {
      customer,
      email,
      formUpdated,
      role,
      siteList,
      isSending,
      submitSuccessful
    } = this.state

    return (
      <div className={classes.root}>
        <ManageHeader
          addNew={this.handleChangeUserView}
          addNewText={tr("Return_to_userManage")}
          title={tr("Add_new_user")}
        />

        <Grid container={true} justify="center">
          <Grid item xs={11} lg={10}>
            <Grid container={true}>
              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  required
                  id="outlined-email"
                  label={tr("_email")}
                  type="email"
                  name="email"
                  className={classes.textField}
                  InputLabelProps={{
                    classes: {
                      root: classes.inputRoot,
                      focused: classes.inputFocus
                    }
                  }}
                  InputProps={{
                    classes: {
                      underline: classes.underline
                    }
                  }}
                  value={email}
                  onChange={this.handleChange}
                  margin="normal"
                  // variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel
                    htmlFor="role-controlled-open-select"
                    classes={{
                      root: classes.inputRoot,
                      focused: classes.inputFocus
                    }}
                  >
                    {tr("_role")}
                  </InputLabel>
                  <Select
                    required
                    value={role}
                    onChange={this.handleChange}
                    inputProps={{
                      name: "role",
                      id: "role-controlled-open-select"
                    }}
                  >
                    <MenuItem value="">
                      <em>{tr("_select_role")}</em>
                    </MenuItem>
                    <MenuItem value={"user"}>user</MenuItem>
                    <MenuItem value={"admin"}>admin</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {this.props.role === "superAdmin" ? (
                <React.Fragment>
                  <Grid item xs={12} md={6} lg={4}>
                    <FormControl className={classes.formControl}>
                      <InputLabel
                        htmlFor="customer-controlled-open-select"
                        classes={{
                          root: classes.inputRoot,
                          focused: classes.inputFocus
                        }}
                      >
                        {tr("Select_customer")}
                      </InputLabel>
                      <Select
                        required
                        value={customer}
                        onChange={this.handleChange}
                        inputProps={{
                          name: "customer",
                          id: "customer-controlled-open-select",
                          classes: {
                            underline: classes.underline
                          }
                        }}
                      >
                        <MenuItem value="">
                          <em>{tr("Select_customer")}</em>
                        </MenuItem>
                        {customerList
                          ? customerList.map((customer) => {
                              return (
                                <MenuItem
                                  value={customer._id}
                                  key={customer._id}
                                >
                                  {customer.name}
                                </MenuItem>
                              )
                            })
                          : ""}
                      </Select>
                    </FormControl>
                  </Grid>
                </React.Fragment>
              ) : null}
            </Grid>
            {/* <Grid container={true}> */}
            <Grid item xs={12}>
              <Button
                className={classes.sendButton}
                color="primary"
                disabled={
                  !formUpdated ||
                  isSending ||
                  submitSuccessful ||
                  role === "" ||
                  email === "" ||
                  (this.props.role === "superAdmin" && customer === "")
                }
                variant="contained"
                onClick={this.handleSubmit}
              >
                {tr("_send")}
              </Button>
            </Grid>
            {/* </Grid> */}
          </Grid>
        </Grid>

        <ManageHeader title={tr("Users_sites")} />

        <Grid container={true} justify="center">
          <Grid item xs={11} lg={10}>
            <Paper className={classes.addForm} elevation={2}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>{tr("_site")}</TableCell>
                    <TableCell align="right">{tr("_address")}</TableCell>
                    <TableCell align="right">{tr("_city")}</TableCell>
                    <TableCell align="right">
                      {tr("_choose_user_sites")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {siteList
                    ? siteList.map((site) => {
                        const isSelected = this.isSelected(site.id)
                        return (
                          <TableRow
                            hover
                            onClick={(event) =>
                              this.handleClick(event, site._id)
                            }
                            role="checkbox"
                            aria-checked={isSelected}
                            tabIndex={-1}
                            key={site._id}
                            selected={isSelected}
                          >
                            <TableCell component="th" scope="row">
                              {site.name}
                            </TableCell>
                            <TableCell align="right">{site.address}</TableCell>
                            <TableCell align="right">{site.city}</TableCell>
                            <TableCell align="right">
                              <Checkbox checked={isSelected} />
                            </TableCell>
                          </TableRow>
                        )
                      })
                    : null}
                </TableBody>
              </Table>
            </Paper>

            {submitSuccessful && (
              <div className="alert alert-success">
                <strong>{tr("_user_invitation_success")}</strong>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    )
  }
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: "calc(100vh - 130px)",
    width: "100%",
    backgroundImage: theme.backgroundImage,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    overflow: "auto"
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: 150,
    width: "95%"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "95%"
  },
  sendButton: {
    backgroundColor: theme.palette.action.main,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    },
    color: theme.palette.secondary.themed,
    "&:hover": {
      color: theme.palette.text.primary
    }
  },
  dense: {
    marginTop: 16
  },
  menu: {
    width: 200
  },
  addForm: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  },
  inputRoot: {
    color: theme.palette.text.field + " !important"
  },
  inputFocus: {
    color: theme.palette.text.warning + " !important"
  },
  underline: {
    "&::before": {
      color: theme.palette.text.primary
    },
    "&::hover": {
      borderBottom: "2px solid " + theme.palette.text.primary
    },
    "&::after": {
      borderBottom: "2px solid " + theme.palette.text.warning
    }
  }
})

const mapDispatchToProps = (dispatch) => ({
  inviteUser: (userdata) => dispatch(inviteUser(userdata)),
  fetchCustomers: () => dispatch(fetchCustomers())
})

const mapStateToProps = (state) => {
  return {
    customerList: state.customers.customerList,
    role: state.auth.role,
    siteList: state.sites.siteList
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(RegisterInvite))
