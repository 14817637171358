import React from "react"

import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import withStyles from "@material-ui/core/styles/withStyles"
import withTheme from "@material-ui/core/styles/withTheme"

import LanguageIcon from "@material-ui/icons/Language"
import MapIcon from "@material-ui/icons/Map"
import AccountCircleIcon from "@material-ui/icons/AccountCircle"
import TrackChangesIcon from "@material-ui/icons/TrackChanges"
import ListAltIcon from "@material-ui/icons/ListAlt"
import { Link } from "react-router-dom"
import { tr } from "translations"
import { Logout } from "../SvgLib"
import { platformBlue } from "../../services/colors"

const NavigationMenu = props => {
  const { classes, anchorElAdd, mainMenuOpen, closeMenu, history } = props

  let iconSize = 25

  return (
    <Menu
      anchorEl={anchorElAdd}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      disableAutoFocusItem={true}
      open={mainMenuOpen}
      onClose={closeMenu}
      classes={{ paper: classes.menuPaper }}
    >
      <Link to="/dashboard" className={classes.menutext}>
        <MenuItem
          name="dashboard"
          onClick={closeMenu}
          selected={history.location.pathname.includes("/dashboard")}
        >
          <ListItemIcon>
            <TrackChangesIcon
              className={
                history.location.pathname === "/dashboard"
                  ? classes.iconTopBarSelected
                  : classes.iconTopBar
              }
              style={{ fontSize: iconSize }}
            />
          </ListItemIcon>
          <ListItemText primary={tr("_dashboard")} />
        </MenuItem>
      </Link>

      <Link to="/map" className={classes.menutext}>
        <MenuItem
          name="map"
          onClick={closeMenu}
          selected={history.location.pathname.includes("/map")}
        >
          <ListItemIcon>
            <LanguageIcon
              className={
                history.location.pathname === "/map"
                  ? classes.iconTopBarSelected
                  : classes.iconTopBar
              }
              style={{ fontSize: iconSize }}
            />
          </ListItemIcon>
          <ListItemText primary={tr("_map")} />
        </MenuItem>
      </Link>
      <Link to="/groundplan" className={classes.menutext}>
        <MenuItem
          name="groundplan"
          onClick={closeMenu}
          selected={history.location.pathname.includes("/groundplan")}
        >
          <ListItemIcon>
            <MapIcon
              className={
                history.location.pathname.includes("/groundplan")
                  ? classes.iconTopBarSelected
                  : classes.iconTopBar
              }
              style={{ fontSize: iconSize }}
            />
          </ListItemIcon>
          <ListItemText primary={tr("_groundplan")} />
        </MenuItem>
      </Link>
      <Link to="/devicelist" className={classes.menutext}>
        <MenuItem
          name="devicelist"
          onClick={closeMenu}
          selected={history.location.pathname.includes("/devicelist")}
        >
          <ListItemIcon>
            <ListAltIcon
              className={
                history.location.pathname.includes("/devicelist")
                  ? classes.iconTopBarSelected
                  : classes.iconTopBar
              }
              style={{ fontSize: iconSize }}
            />
          </ListItemIcon>
          <ListItemText primary={tr("_deviceList")} />
        </MenuItem>
      </Link>

      <Link to="/manage/profile" className={classes.menutext}>
        <MenuItem
          name="profile"
          onClick={closeMenu}
          selected={history.location.pathname.includes("/manage/profile")}
        >
          <ListItemIcon>
            <AccountCircleIcon
              className={
                history.location.pathname === "/manage/profile"
                  ? classes.iconTopBarSelected
                  : classes.iconTopBar
              }
              style={{ fontSize: iconSize }}
            />
          </ListItemIcon>
          <ListItemText primary={tr("_myProfile")} />
        </MenuItem>
      </Link>

      <Link to="/logout" className={classes.menutext}>
        <MenuItem name="settings" onClick={closeMenu}>
          <ListItemIcon>
            <Logout
              color={platformBlue}
              style={{ height: iconSize, width: iconSize }}
            />
          </ListItemIcon>
          <ListItemText primary={tr("_logout")} />
        </MenuItem>
      </Link>
    </Menu>
  )
}

const styles = theme => ({
  menutext: {
    color: theme.palette.popup.text,
    textDecoration: "none"
  },
  iconTopBar: {
    color: platformBlue //theme.palette.popup.text,
  },
  iconTopBarSelected: {
    color: theme.palette.text.activeNav
  },
  menuPaper: {
    backgroundColor: theme.palette.popup.background
  }
})

export default withStyles(styles, { withTheme: true })(
  withTheme(NavigationMenu)
)
