import * as React from "react"

import Grid from "@material-ui/core/Grid"
import withStyles from "@material-ui/core/styles/withStyles"

import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import { connect } from "react-redux"
import { tr } from "translations"
import {
  AddCustomers,
  DeleteCustomers,
  fetchCustomers
} from "../../../actions/customers"
import { ManageHeader } from "../../../components/manageMenu/manageHeader"

class CustomerManageAddNew extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      customers: [],
      name: "",
      error: null,
      isSending: false,
      expanded: null
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.resetError = this.resetError.bind(this)
    this.handleChangePanel = this.handleChangePanel.bind(this)
    this.handleChangeCustomerView = this.handleChangeCustomerView.bind(this)
  }

  componentDidMount() {
    this.props.fetchCustomers()
  }

  handleChangeCustomerView() {
    this.props.handleChangeCustomerView()
  }

  handleChangePanel(expanded, customer) {
    this.setState({
      expanded: expanded ? customer._id : false,
      _id: customer._id || ""
    })
  }

  handleChange(event) {
    const target = event.target

    switch (target.name) {
      case "name":
        return this.setState({ name: target.value })
    }
  }

  async handleDelete(id) {
    this.props.DeleteCustomers(id).then(() => this.props.fetchCustomers())
  }

  resetError() {
    this.setState({ error: null })
  }

  async handleSave() {
    this.setState({ isSending: true })
    const { name } = this.state
    try {
      await this.props.AddCustomers({ name })
      await this.props.fetchCustomers()
      this.setState({ isSending: false })
      this.props.handleChangeCustomerView()
    } catch (err) {
      this.setState({ isSending: false })
    }
  }

  render() {
    const { classes } = this.props
    const { isSending } = this.state

    return (
      <div className={classes.root}>
        <ManageHeader
          addNew={this.handleChangeCustomerView}
          addNewText={tr("Add_new_customer_return")}
          title={tr("Add_new_customer_title")}
        />

        <Grid container spacing={0} justify="center">
          <Grid item xs={11} lg={10}>
            <Grid container spacing={0}>
              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  required
                  label={tr("Customers_name")}
                  name="name"
                  className={classes.textField}
                  InputLabelProps={{
                    classes: {
                      root: classes.inputRoot,
                      focused: classes.inputFocus
                    }
                  }}
                  InputProps={{
                    classes: {
                      underline: classes.underline
                    }
                  }}
                  value={this.state.name}
                  onChange={this.handleChange}
                  margin="normal"
                />
              </Grid>

              <Grid item xs={12} className={classes.formAreaButton}>
                <Button
                  disabled={isSending}
                  color="primary"
                  className={classes.saveButton}
                  variant="contained"
                  onClick={this.handleSave}
                >
                  {tr("_save")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const styles = theme => ({
  formAreaButton: {
    paddingTop: "4rem"
  },
  root: {
    flexGrow: 1,
    height: "calc(100vh - 130px)",
    width: "100%",
    backgroundImage: theme.backgroundImage,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed"
  },
  inputRoot: {
    color: theme.palette.text.field + " !important"
  },
  inputFocus: {
    color: theme.palette.text.warning + " !important"
  },
  underline: {
    "&::before": {
      color: theme.palette.text.primary
    },
    "&::hover": {
      borderBottom: "2px solid " + theme.palette.text.primary
    },
    "&::after": {
      borderBottom: "2px solid " + theme.palette.text.warning
    }
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "95%"
  },
  saveButton: {
    backgroundColor: theme.palette.action.main,
    color: theme.palette.secondary.themed,
    "&:hover": {
      color: theme.palette.text.primary
    }
  }
})

const mapDispatchToProps = dispatch => ({
  AddCustomers: (obj, file) => dispatch(AddCustomers(obj, file)),
  DeleteCustomers: string => dispatch(DeleteCustomers(string)),
  fetchCustomers: () => dispatch(fetchCustomers())
})

const mapStateToProps = state => {
  return {
    customerList: state.customers.customerList
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(CustomerManageAddNew))
