import React, { useEffect, useState } from "react"

import Grid from "@material-ui/core/Grid"
import withStyles from "@material-ui/core/styles/withStyles"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { platformBlue, platformWhite } from "../../../services/colors"
import { ManageHeader } from "../../../components/manageMenu/manageHeader"
import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import Typography from "@material-ui/core/Typography"
import { useSelector } from "react-redux"
import { tr } from "translations"
import Button from "@material-ui/core/Button"

const GroupsList = props => {
  const { classes } = props

  const siteList = useSelector(state => state.sites.siteList)
  const devices = useSelector(state => state.iotDevices.iotDeviceList)

  const [groups, setGroups] = useState([])
  const [visibleGroups, setVisibleGroups] = useState([])

  useEffect(
    () => {
      let tempGroups = []
      for(const device of devices){
        if(device.group && !tempGroups.includes(device.group)){
          tempGroups.push(device.group)
        }
      }
      setGroups(tempGroups)
      setVisibleGroups(tempGroups)
      // eslint-disable-next-line
    }, [])

  const toggleVisibleGroup = (group) => {
    let tempGroups = [...visibleGroups]
    if(visibleGroups.includes(group)){
      tempGroups = tempGroups.filter(listGroup => listGroup !== group)
    }
    else{
      tempGroups.push(group)
    }
    setVisibleGroups(tempGroups)
  }
 
  return (
    <div className={classes.div}>
      <Grid container spacing={1} className={classes.grid}>
        <Grid item xs={12}>
          <ManageHeader title={tr("_groups")} />
        </Grid>
        <Grid container direction="row">
          <Grid item xs={6} style={{marginLeft: 20}}>
            {siteList.map(site => {
              let siteGroupsDevices = {} /* {"groupName": [deviceData]} */
              for(const device of devices){
                if(device.site !== site._id){
                  continue
                }
                if(!device.group){
                  continue
                }
                if(Object.keys(siteGroupsDevices).includes(device.group)){
                  let newDeviceList = siteGroupsDevices[device.group]
                  newDeviceList.push(device)
                  siteGroupsDevices[device.group] = newDeviceList
                }
                else{
                  siteGroupsDevices[device.group] = [device]
                }
              }
              return (
                <Accordion key={site._id}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.headingPart1}>
                      {site.name}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionLevel2}>
                    <Grid item xs={12}>
                      {Object.keys(siteGroupsDevices).map(group => {
                        return ( !visibleGroups.includes(group) ? <div key={group}/> :
                            <Accordion key={group}>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.headingPart2}>
                                  {group}
                                </Typography>
                              </AccordionSummary>
                              {siteGroupsDevices[group].map(device => {
                                return (
                                  <AccordionDetails key={device._id} className={classes.accordionLevel3}>
                                    {device.name}
                                  </AccordionDetails>
                                )
                              })}
                            </Accordion>
                        )
                      })}
                    
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )
            })}
          </Grid>

          <Grid item xs={5}  style={{marginLeft: 20}}>
            <Grid container direction="row">
              {groups.map(group => {
                return(
                  <Button
                    variant="outlined"
                    className={classes.button}
                    key={group}
                    onClick={() => {toggleVisibleGroup(group)}}
                    style={{
                      backgroundColor:
                        visibleGroups.includes(group) ? platformBlue : "gray",
                      color: platformWhite,
                      fontSize: "12px"
                    }}
                    classes={{ label: classes.label }}
                  >
                    {group}
                  </Button>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

const styles = theme => ({
  div: {
    width: "100%",
    height: "100vh",
    backgroundImage: theme.backgroundImage,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    flexGrow: 1,
    overflow: "auto"
  },
  expansionPanel: {
    backgroundColor: "#031321",
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.unselected.main,
    borderTop: "1px solid",
    borderTopColor: theme.palette.unselected.main
  },
  expansionPanelTitle: {
    display: "inline-block",
    paddingLeft: "3rem"
  },
  accordionLevel2:{
    marginLeft: 10
  },
  accordionLevel3:{
    marginLeft: 20
  },
  button: {
    margin: theme.spacing(1),
    width: 125,
    height: 40
  },
})

export default withStyles(styles, { withTheme: true })(GroupsList)
