import React, { useEffect, useState } from "react"

import TextField from "@material-ui/core/TextField"
import ButtonBase from "@material-ui/core/ButtonBase"
import Dialog from "@material-ui/core/Dialog"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import useTheme from "@material-ui/core/styles/useTheme"
import makeStyles from "@material-ui/core/styles/makeStyles"
import CloseIcon from "@material-ui/icons/Close"
import { useDispatch, useSelector } from "react-redux"
import { tr } from "translations"
import { AddArea } from "../../actions/areas"
import { fetchSites } from "../../actions/sites"
import AddFile from "./parts/AddFile"
import SettingsButtons from "../Dashboard/DashboardWidgets/settingModals/parts/settingsButtons"

export default function AddAreaModal(props) {
  const { fullScreen, isOpen, site, onClose } = props

  const [floor, setFloor] = useState(1)
  const [info, setInfo] = useState("")
  const [name, setName] = useState("")
  const [selectedFile, setSelectedFile] = useState(null)

  const classes = useStyles()
  const dispatch = useDispatch()
  const theme = useTheme()

  const siteList = useSelector(state => state.sites.siteList)

  useEffect(
    () => {
      dispatch(fetchSites)
    },
    // eslint-disable-next-line
    []
  )

  /** Remove uploaded image from state */
  const handleDeleteFile = () => {
    setSelectedFile(null)
  }

  /** Save uploaded file to state */
  const handleselectedFile = event => {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0])
    }
  }

  /**
   * Save or clear form button handlers
   */
  const handleSave = () => {
    const siteObject = siteList
      ? siteList.find(target => target.name === site)
      : null

    dispatch(
      AddArea(
        siteObject ? siteObject._id : site,
        name,
        info,
        floor,
        selectedFile
      )
    )
      .then(() => {
        dispatch(fetchSites())
        handleCancel()
      })
      .catch(err => console.log("Adding area failed", err))
  }

  const handleCancel = () => {
    setInfo("")
    setName("")
    setFloor(1)
    onClose()
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      modal="true"
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
      className={classes.dialogRoot}
      fullWidth={true}
      maxWidth={"lg"}
    >
      <Grid container className={classes.container}>
        <Grid
          item
          xs={12}
          container
          justify="space-between"
          alignItems="center"
          className={classes.topicTextField}
        >
          <Typography variant="h6">{tr("Add_layer_to_site")}</Typography>
          <ButtonBase size="large" onClick={onClose}>
            <CloseIcon fontSize="large" className={classes.icon} />
          </ButtonBase>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="standard-name"
            label={tr("Floor_name")}
            name="name"
            className={classes.textField}
            value={name}
            onChange={event => setName(event.target.value)}
            margin="normal"
            InputLabelProps={{
              style: { color: theme.palette.text.fixed, fontWeight: 500 }
            }}
            inputProps={{
              style: { color: theme.palette.text.fixed }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="standard-floor"
            label={tr("Floor_number")}
            name="floor"
            type="number"
            className={classes.textField}
            value={floor}
            onChange={event => setFloor(event.target.value)}
            margin="normal"
            InputLabelProps={{
              style: { color: theme.palette.text.fixed, fontWeight: 500 }
            }}
            inputProps={{
              style: { color: theme.palette.text.fixed }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="standard-info"
            label={tr("Info")}
            name="info"
            className={classes.textField}
            value={info}
            onChange={event => setInfo(event.target.value)}
            margin="normal"
            InputLabelProps={{
              style: { color: theme.palette.text.fixed, fontWeight: 500 }
            }}
            inputProps={{
              style: { color: theme.palette.text.fixed }
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.fileUploadArea}>
          <AddFile
            title={tr("Upload_ground_plan")}
            id="firstId"
            selectedFile={selectedFile ? selectedFile.name : null}
            handleDeleteFile={handleDeleteFile}
            handleselectedFile={handleselectedFile}
          />
        </Grid>

        <SettingsButtons
          handleSave={handleSave}
          closeMenu={handleCancel}
          disabled={!name}
          selectText={tr("Add_layer")}
        />
      </Grid>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.popup.background,
    padding: theme.spacing(2)
  },
  dialogRoot: {
    minWidth: 500,
    maxWidth: 600,
    margin: "auto"
  },
  icon: {},
  fileUploadArea: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2)
  },
  textField: {
    color: theme.palette.text.fixed
  },
  topicTextField: {
    color: theme.palette.text.fixed
  }
}))
