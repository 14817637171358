import React, { useState } from "react"
import { useSelector } from "react-redux"

import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import useTheme from "@material-ui/core/styles/useTheme"
import makeStyles from "@material-ui/core/styles/makeStyles"
import withStyles from "@material-ui/core/styles/withStyles"
import { tr } from "translations"
import SettingsButtons from "./settingsButtons"

const DefineDeviceDialog = props => {
  const { cancelValues, saveValues, deviceValues, deviceTypes } = props
  const classes = useStyles()
  const theme = useTheme()

  const siteInfoNames = useSelector(state => {
    let list = []
    state.sites.siteList.forEach(site => {
      list.push({ id: site._id, name: site.name })
    })
    return list
  })

  let list = []
  siteInfoNames.forEach(site => {
    list.push(site.name)
  })
  const siteNames = list

  const sites = useSelector(state => state.sites)
  const areas = useSelector(state => state.areas)
  const iotDevices = useSelector(state => state.iotDevices)
  const [Values, setValues] = useState(deviceValues) // valitut laitteet, alussa kaikki mahdolliset laittteet ja siita filteroidaan..
  const [types, setTypes] = useState([])
  const [groups, setGroups] = useState([])
  const [selectedSites, setselectedSites] = useState(siteNames)
  const [selectAlls, setSelectAlls] = useState([])
  const [expandedSite, setexpandedSite] = useState([])
  const [expandedArea, setexpandedArea] = useState([])
  const [expandedDevice, setexpandedDevice] = useState([])

  let deviceGroups = []
  for(const iotDevice of iotDevices.iotDeviceList){
    if(iotDevice.group && !deviceGroups.includes(iotDevice.group)){
      deviceGroups.push(iotDevice.group)
    }
  }
  

  const checkIfSelected = obj => {
    for(const valuerow of Values){
      if (valuerow._id === obj._id) {
        return true
      }
    }
    return false

  }

  const handleExtend = (site, target) => () => {
    switch (target) {
      case "site": {
        let newArray = expandedSite.slice()
        if (expandedSite.indexOf(site) > -1) {
          newArray.splice(newArray.indexOf(site), 1)
        } else {
          newArray.push(site)
        }
        setexpandedSite(newArray)
        break
      }
      case "area": {
        let newArray = expandedArea.slice()
        if (expandedArea.indexOf(site) > -1) {
          newArray.splice(newArray.indexOf(site), 1)
        } else {
          newArray.push(site)
        }
        setexpandedArea(newArray)
        break
      }
      case "device": {
        let newArray = expandedDevice.slice()
        if (expandedDevice.indexOf(site) > -1) {
          newArray.splice(newArray.indexOf(site), 1)
        } else {
          newArray.push(site)
        }
        setexpandedDevice(newArray)
        break
      }
      default:
        null
    }
  }

  const handleSelect = value  => {
    let newArray = Values.slice()
    if (checkIfSelected(value)) {
      newArray = newArray.filter(row => {
        return row._id !== value._id || row.deviceId !== value.deviceId
      })
      if (selectAlls.includes(value.deviceType)) {
        let temp = selectAlls.slice()
        temp.splice(temp.indexOf(value.deviceType), 1)
        setSelectAlls(temp)
      }
      if (value.group && groups.includes(value.group)) {
        let temp = groups.slice()
        temp.splice(temp.indexOf(value.group), 1)
        setGroups(temp)
      }
    } else {
      newArray.push(value)
    }
    setValues(newArray)
  }

  const handleAccept = () => {

    saveValues(Values)
  }

  const handleSelectGroup = (value) => {
    let tempGroups = [...groups]
    let tempDevices = [...Values]

    if(tempGroups.includes(value)){
      tempGroups = tempGroups.filter(val => val !== value)
    }
    else {
      tempGroups.push(value)
      for(const device of iotDevices.iotDeviceList){
        if(device.group === value && !checkIfSelected(device)){
          tempDevices.push(device)
        }
      }
    }
    setGroups(tempGroups)
    setValues(tempDevices)
  }

  const handleSelectAll = value => () => {
    let siteIds = []
    siteInfoNames.map(site => {
      if (selectedSites.includes(site.name)) siteIds.push(site.id)
    })

    // if value is on select-all list, remove it
    let temp = selectAlls.slice()
    if (temp.includes(value)) {
      temp.splice(temp.indexOf(value), 1)
    } else {
      temp.push(value)
      // valitse kaikki taman tyyppiset laiteet - aktiivisille siteille
      let newArray = Values.slice()
      iotDevices.iotDeviceList.map(device => {
        if (device.deviceType === value && siteIds.includes(device.site)) {
          if (
            Values.findIndex(item => {
              return item._id === device._id
            }) === -1
          ) {
            newArray.push(device)
          }
        }
      })
      setValues(newArray)
    }

    setSelectAlls(temp)
  }

  const handleType = value => () => {
    let temp = types.slice()
    if (temp.includes(value)) {
      temp.splice(temp.indexOf(value), 1)
      if (selectAlls.includes(value)) {
        let temp2 = selectAlls.slice()
        temp2.splice(temp2.indexOf(value), 1)
        setSelectAlls(temp2)
      }

      // poista valittujen listalta kaiki taman tyyppiset
      let newArray = Values.slice()
      newArray = newArray.filter(row => {
        return row.type !== value
      })
      setValues(newArray)
    } else {
      temp.push(value)
    }

    setTypes(temp)
  }

  const handleSetSite = value => () => {
    const siteInfo = siteInfoNames.find(site => {
      return site.name === value
    })

    let temp = selectedSites.slice()
    let temp2 = []
    if (temp.includes(value)) {
      temp.splice(temp.indexOf(value), 1)

      // poistetaan valituista kaikki jotka on talla sitella
      let newArray = Values.slice()
      newArray = newArray.filter(row => {
        if (row.site === siteInfo.id) {
          if (selectAlls.includes(row.type) && !temp2.includes(row.type)) {
            temp2.push(row.type)
          }
        }
        return row.site !== siteInfo.id
      })
      setValues(newArray)
    } else {
      temp.push(value)
    }

    // trim selectAlls list
    if (temp2.length > 0) {
      let temp3 = selectAlls.slice()
      temp2.forEach(type => {
        temp3.splice(temp3.indexOf(type), 1)
      })
      setSelectAlls(temp3)
    }

    setselectedSites(temp)
  }

  return (
    <Paper className={classes.menuPaper}>
      <Grid
        item
        xs={12}
        container
        className={classes.buttons}
        justify="flex-end"
      >
        <Grid
          item
          xs={3}
          container
          className={classes.buttons}
          justify="flex-start"
        >
          <Typography
            style={{ float: "right" }}
            variant="h6"
            className={classes.text}
          >
            {tr("_filter_sites")}
          </Typography>
        </Grid>

        <Grid
          item
          xs={9}
          container
          className={classes.buttons}
          justify="flex-end"
        >
          {siteNames.map((site, index) => {
            return (
              <Button
                variant="outlined"
                className={classes.button}
                key={index}
                id={site}
                onClick={handleSetSite(site)}
                style={{
                  backgroundColor: selectedSites.includes(site)
                    ? theme.palette.action.main
                    : "gray",
                  fontSize: "12px"
                }}
              >
                {site}
              </Button>
            )
          })}
        </Grid>
      </Grid>
      {deviceTypes.length > 0 && (
        <Typography
          style={{ float: "left" }}
          variant="h6"
          className={classes.text}
        >
          {tr("_filter_device_types")}
        </Typography>
      )}
      <Grid
        item
        xs={12}
        container
        className={classes.buttons}
        justify="flex-start"
      >
        {deviceTypes.map((type, index) => {
          return (
            <Button
              variant="outlined"
              className={classes.button}
              key={index}
              id={type}
              onClick={handleType(type)}
              style={{
                backgroundColor: types.includes(type)
                  ? theme.palette.action.main
                  : "gray",
                fontSize: "12px"
              }}
            >
              {type}
            </Button>
          )
        })}
      </Grid>
      <Grid container className={classes.innerContent}>
        {types.map((type, index) => {
          return (
            <Grid item xs={3} key={index}>
              <Typography variant="caption" className={classes.subTitle}>
                {type}
              </Typography>

              <Button
                variant="contained"
                className={classes.button}
                style={{
                  float: "right",
                  //top: -40,
                  right: 15,
                  backgroundColor: selectAlls.includes(type)
                    ? theme.palette.action.main
                    : "gray"
                }}
                onClick={handleSelectAll(type)}
              >
                {tr("_selectAll")}
              </Button>
            </Grid>
          )
        })}
      </Grid>

      {deviceGroups.length > 0 && (
        <Typography
          style={{ float: "left" }}
          variant="h6"
          className={classes.text}
        >
          {tr("_filter_device_groups")}
        </Typography>
      )}
      <Grid container className={classes.innerContent}>
        {deviceGroups.map((group, index) => {
          return (
            <Grid item xs={3} key={index}>
              <Typography variant="caption" className={classes.subTitle}>
                {group}
              </Typography>

              <Button
                variant="contained"
                className={classes.button}
                style={{
                  float: "right",
                  //top: -40,
                  right: 15,
                  backgroundColor: groups.includes(group)
                    ? theme.palette.action.main
                    : "gray"
                }}
                onClick={()=> {handleSelectGroup(group)}}
              >
                {tr("_selectAll")}
              </Button>
            </Grid>
          )
        })}
      </Grid>
      <Paper className={classes.selectPaper}>
        {sites.siteList.map(site => {
          return (
            selectedSites.includes(site.name) && (
              <Accordion
                key={site._id}
                square
                expanded={expandedSite.indexOf(site._id) > -1}
                onChange={handleExtend(site._id, "site")}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography>{site.name}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.topSummary}>
                  <div style={{ width: "100%" }}>
                    {areas.areaList.map(area => {
                      return (
                        area.site === site._id && (
                          <Accordion2
                            key={area._id}
                            square
                            expanded={expandedArea.indexOf(area._id) > -1}
                            onChange={handleExtend(area._id, "area")}
                          >
                            <AccordionSummary2
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                            >
                              <Typography>{area.name}</Typography>
                            </AccordionSummary2>
                            <AccordionDetails2>
                              <div style={{ width: "100%" }}>
                                {iotDevices.iotDeviceList.map(device => {
                                  return (
                                    device.area === area._id &&
                                    (((deviceTypes.length == 0 ||
                                      deviceTypes.includes(
                                        device.deviceType
                                      )) &&
                                      types.length == 0) ||
                                      types.includes(device.deviceType)) && (
                                      <Grid key={device._id} item xs={12}>
                                        <Button
                                          variant="outlined"
                                          className={classes.smallbutton}
                                          onClick={()=> {handleSelect(device)}}
                                          style={{
                                            backgroundColor: checkIfSelected(device) 
                                              ? "green" : "grey"
                                          }}
                                        >
                                          <Grid container justify="flex-start">
                                            {device.name}, [{device.deviceId}]
                                          </Grid>
                                        </Button>
                                      </Grid>
                                    )
                                  )
                                })}
                              </div>
                            </AccordionDetails2>
                          </Accordion2>
                        )
                      )
                    })}
                  </div>
                </AccordionDetails>
              </Accordion>
            )
          )
        })}
      </Paper>
      <SettingsButtons
        handleSave={handleAccept}
        closeMenu={cancelValues}
        disabled={Values.length < 1}
        selectText={tr("_accept")}
      />
    </Paper>
  )
}

const Accordion2 = withStyles({
  root: {
    border: 0,
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "auto"
    },
    borderLeft: 2,
    borderColor: "#fff"
  },
  expanded: {}
})(Accordion)

const AccordionSummary2 = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56
    }
  },
  content: {
    "&$expanded": {
      margin: "12px 0"
    }
  },
  expanded: {}
})(AccordionSummary)

const AccordionDetails2 = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: 0
  }
}))(AccordionDetails)

const useStyles = makeStyles(theme => ({
  menuPaper: {
    backgroundColor: theme.palette.popup.background,
    //color: theme.palette.popup.text,
    width: 600,
    minHeight: 400,
    padding: theme.spacing(2)
  },
  selectPaper: {
    backgroundColor: theme.palette.popup.background,
    //color: theme.palette.popup.text,
    overflow: "scroll",
    width: "100%",
    maxHeight: 600
    //padding: theme.spacing(2)
  },
  buttons: {
    marginTop: 3
  },
  button: {
    margin: theme.spacing(1),
    marginTop: 0,
    width: 120,
    minHeight: 30
  },
  smallbutton: {
    margin: 0,
    width: "100%",
    height: 30
  },
  topSummary: {
    padding: 0,
    paddingLeft: 10
  },
  subTitle: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.fixed
  },
  innerContent: {
    margin: theme.spacing(1)
  },
  text: {
    color: theme.palette.text.fixed
  }
}))

export default DefineDeviceDialog
