// import { authError } from "Actions/auth"

export const ajaxSuccess = target => ({ type: "AJAX_SUCCESS", target })
export const ajaxError = (target, error) => ({
  type: "AJAX_ERROR",
  error,
  target
})

export const errorHandler = (
  target,
  err,
  response = "Internal server error"
) => {
  // if ((err && err.status === 401) || /401/.test(err)) return authError()

  const error =
    (err && err.message) || (err.toString && err.toString()) || response
  return ajaxError(target, error)
}

export const requestDelete = (target, id) => ({
  type: "DELETE_REQUESTED",
  id,
  target
})
export const deleteCompleted = (target, object) => ({
  type: "DELETE_COMPLETED",
  object,
  target
})

export const requestList = target => ({ type: "LIST_REQUESTED", target })

export const listReceived = (target, list) => ({
  type: "LIST_RECEIVED",
  list,
  lastUpdated: Date.now(),
  target
})

export const dataReceived = (target, address, data) => ({
  type: "DATA_RECEIVED",
  data,
  address,
  lastUpdated: Date.now(),
  target
})

export const requestSuccessful = () => ({
  type: "REQUEST_SUCCESSFUL"
})

export const requestUpdate = (target, id) => ({
  type: "UPDATE_REQUESTED",
  id,
  target
})

export const authHeader = token => {
  return { headers: { Authorization: `${token}` } }
}

export const loginAuthHeader = () => {
  return {
    headers: {
      Authorization: `UvyYrKnay3Hip79rv9J2ZFwPYYl2vwgbYRnqUFMHwGrVZH61dR`
    }
  }
}
