import React from "react"
import loadable from "@loadable/component"
import { typeOptions } from "../../devicetypes/typeOptions"

const SelectedDevice = loadable(
  props => import(`../../devicetypes/${props.page}/widgetUI`),
  {
    cacheKey: props => props.page
  }
)

const DeviceWidget = props => {
  // for now load Default, change when all device types are fixed
  const page = props.device.deviceType
    ? typeOptions.includes(props.device.deviceType)
      ? props.device.deviceType
      : "Default"
    : "Default"

  return <SelectedDevice {...props} page={page} />
}

export default DeviceWidget
