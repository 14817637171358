import * as React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"

const ExpansionPanelSubheadingComponent = ({ classes, title }) => (
  <Grid item xs={12} className={classes.subHeading}>
    <Typography variant="h6" className={classes.title}>
      {title}
    </Typography>
  </Grid>
)

const styles = () => ({
  subHeading: {
    padding: "2rem 0 1rem"
  }
})

export const ExpansionPanelSubheading = withStyles(styles, { withTheme: true })(
  ExpansionPanelSubheadingComponent
)
