let gLanguage = "EN"
let languageReady = false

const translations = {
  _accelerationX: {FI: "Kiihtyvyys X", EN: "Acceleration X"},
  _accelerationY: {FI: "Kiihtyvyys Y", EN: "Acceleration Y"},
  _accelerationZ: {FI: "Kiihtyvyys Z", EN: "Acceleration Z"},
  _select_language: { FI: "Valitse kieli", EN: "Select language" },
  _select_theme: { FI: "Valitse teema", EN: "UI color" },
  _select_temperature_decimal_place: { FI: "Lämpötilan desimaalit", EN: "Temperature decimals" },
  _www_page: { FI: "Nettisivu", EN: "Web page" },
  _site: { FI: "Kohde", EN: "Site" },
  _site_name: { FI: "Kohteen nimi", EN: "Site name" },
  _site_info: { FI: "Kohteen tiedot", EN: "Site info" },
  _company_name: { FI: "Yrityksen nimi", EN: "Company name" },
  _site_group: { FI: "Kohteen ryhmä", EN: "Site group" },
  _contact_person: { FI: "Yhteyshenkilö", EN: "Contact person" },
  _contact_phone: { FI: "Yhteys puhelinnumero", EN: "Contact phone number" },
  _contact_email: { FI: "Yhteys sähköposti", EN: "Contact email" },

  _remove_site: { FI: "Poista kohde", EN: "Delete site" },
  _you_sure_delete_site: {
    FI: "Haluatko varmasti poistaa kohteen",
    EN: "Are you sure you want to delete site"
  },
  Delete_floor: { FI: "Poista kerros", EN: "Delete floor" },
  Site_users: { FI: "Käyttäjät", EN: "Users" },
  _remove_site_from_user: {
    FI: "Poista kohde käyttäjältä",
    EN: "Remove site from the user"
  },
  _site_visibility_to_user: {
    FI: "Kohteen näkyvyys käyttjälle",
    EN: "Site visibility to user"
  },
  _choose_user: { FI: "Valitse käyttäjä", EN: "Choose user" },
  _choose_user_sites: {
    FI: "Valitse käyttäjän kohteet",
    EN: "Choose user sites"
  },
  _added_types: { FI: "Lisätyt laitetyypit", EN: "Added device types" },
  _site_creation_success: {
    FI: "Kohteen luonti onnistui",
    EN: "Site creation was successful"
  },
  _user_invitation_success: {
    FI: "Kutsun lähetys onnistui!",
    EN: "User invitation sent successfully"
  },
  _remove_user: { FI: "Poista käyttäjä", EN: "Delete user" },
  _modify_site_information: {
    FI: "Muokkaa kohteen tietoja",
    EN: "Edit site info"
  },
  _device_types_management: {
    FI: "Laitetyyppien hallinointi",
    EN: "Device types"
  },
  _id: { FI: "Tunnus", EN: "Id" },
  _device_type: { FI: "Laitetyyppi", EN: "Device type" },
  _device: { FI: "Laite", EN: "Device" },
  _time: { FI: "Aika", EN: "Time" },
  _ts: { FI: "Päivitetty", EN: "Updated" },
  _date: { FI: "Päivämäärä", EN: "Date" },
  _hours: { FI: "tuntia", EN: "hours" },
  _hour: { FI: "tunti", EN: "hour" },
  _types: { FI: "Laitetyypit", EN: "Device list" },
  _log_In: { FI: "Kirjaudu", EN: "Log in" },
  _failed_to_log_in: {
    FI: "Kirjautuminen epäonnistui",
    EN: "Failed to log in"
  },
  _postal_code: { FI: "Postinumero", EN: "Postal code" },
  _address: { FI: "Osoite", EN: "Address" },
  _street_address: { FI: "Osoite", EN: "Street address" },

  _submit: { FI: "Tallenna", EN: "Submit" },
  _clear_filter: { FI: "Tyhjennä suodin", EN: "Clear filter" },

  _browse: { FI: "Selaa", EN: "Browse" },

  //Register Forms
  _sign_up: { EN: "Sign up", FI: "Luo tunnus" },
  _registerheadlinestep1: { EN: "Create account", FI: "Luo käyttäjätunnus" },
  _send: { EN: "Send", FI: "Lähetä" },
  _resend: { EN: "Resend", FI: "Lähetä uudestaan" },

  _password_lost: { EN: "Password missing?", FI: "Salasana hukassa?" },
  _email: { EN: "Email", FI: "Sähköposti" },
  _recover_text: {
    EN:
      "No worries. Write your email, which you used to register to IoT Station-services",
    FI:
      "Ei hätää. Kirjoita alle sähköpostiosoiteesi, jota käytit rekisteröityessäsi palveluun"
  },
  _check_your_email: { EN: "Check your email", FI: "Kurkkaa sähköpostiisi" },
  _create_new_password: { EN: "Create new password", FI: "Luo uusi salasana" },
  _zipcode: { EN: "Postal code", FI: "Postinumero" },
  _phone: { EN: "Phone number", FI: "Puhelinnumero" },
  _register_success_detail: {
    EN: "Now you can sign in to the IoT Station-service with your device.",
    FI: "Nyt voit kirjautua laitteellasi IoT Station-palveluun."
  },
  _register_success: {
    EN: "Your registration was succesful!",
    FI: "Rekisteröityminen onnistui!"
  },
  _registrationSuccessful: {
    FI: "Rekisteröinti onnistui",
    EN: "Registration successful"
  },
  // Users
  _username: { FI: "Käyttäjätunnus", EN: "Username" }, // log-in name, kaytetaan e-mail -osoitetta
  Users: { FI: "Käyttäjiä", EN: "Users" },
  Status: { FI: "Tila", EN: "Status" },
  Reset_password: { FI: "Salasanan uusiminen", EN: "Reset password" },
  _link_to_change_email: {
    FI: "Linkki salasanan uusimiseksi lähetetty.",
    EN: "A link to reset password has been sent."
  },

  Add_filter: { FI: "näytä/piilota suodatin", EN: " show/hide filter" },
  _filter: { FI: "Suodatin", EN: "Filter" },

  // Navigation
  _myProfile: { FI: "Oma profiili", EN: "My profile" },
  _my_account: { FI: "Oma tili", EN: "My account" },
  _account: { FI: "Tili", EN: "Account" },
  _companyInfo: { FI: "Yrityksen tiedot", EN: "Company info" },
  Company: { FI: "Yritys", EN: "Company" },
  _areas: { FI: "Kohteet", EN: "Sites" },
  _clients: { FI: "Asiakkaat", EN: "Clients" },
  UserManage: { FI: "Käyttäjien hallinnointi", EN: "User management" },
  Users_sites: { FI: "Käyttäjän kohteet", EN: "User's sites" },
  Return_to_userManage: {
    FI: "Palaa käyttäjien hallinnointiin",
    EN: "Return to user management"
  },
  _customerManage: {
    FI: "Asiakkaiden hallinnointi",
    EN: "Customer management"
  },
  _addNew: { FI: "Lisää", EN: "Add" },
  _dashboard: { FI: "Etupaneeli", EN: "Dashboard" },
  _map: { FI: "Kartta", EN: "Map" },
  _groundplan: { FI: "Pohjapiirokset", EN: "Groundplans" },
  _selectSite: { FI: "Valitse kohde", EN: "Choose site" },
  _selectArea: { FI: "Valitse kerros", EN: "Choose floor" },
  _deviceList: { FI: "Laitelista", EN: "Device list" },
  _previous: { FI: "Edellinen", EN: "Previous" },
  _next: { FI: "Seuraava", EN: "Next" },
  _back: { FI: "Takaisin", EN: "Back" },
  _accept: { FI: "Hyväksy", EN: "Accept" },

  // Platform modify
  _unknownFault: { FI: "Tuntematon vika", EN: "Unknown fault" },
  _deviceid: { FI: "Tunniste", EN: "Device ID" },

  // common
  _cancel: { FI: "Peruuta", EN: "Cancel" },
  _continue: { FI: "Jatka", EN: "Continue" },
  _login: { EN: "Login", FI: "Kirjaudu sisään" },
  _logout: { FI: "Kirjaudu ulos", EN: "Sign out" },
  _password: { FI: "Salasana", EN: "Password" },
  _short_password: {
    FI: "Salasana on liian lyhyt",
    EN: "Password is too short"
  },
  _password_form_requirements: {
    FI:
      "Salasana on oltava vähintään kahdeksan merkkiä pitkä ja sisällettävä pieniä ja isoja kirjaimia sekä numeroita",
    EN:
      "Password has to be at least eight characters long and contain both small and big characters and digits"
  },

  _selectAll: { FI: "Valitse kaikki", EN: "Select all" },

  //device detail and edit modal
  _model: { FI: "Malli", EN: "Model" },
  _type: { FI: "Tyyppi", EN: "Type" },
  _location: { FI: "Sijainti", EN: "Location" },
  _close: { FI: "Sulje", EN: "Close" },

  Select_customer: {
    FI: "Valitse asiakas",
    EN: "Select customer"
  },
  _select_company: {
    FI: "Valitse yritys",
    EN: "Select company"
  },
  _logout_text: { FI: "Olet kirjautunut ulos!", EN: "You have logged out!" },

  _open: { FI: "Avaa", EN: "Open" },

  _add_new_type: { FI: "Lisää uusi tyyppi", EN: "Add new type" },
  _add_new_site: { FI: "Lisää uusi kohde", EN: "Add new site" },

  _assigned_sites: { FI: "Kohteet", EN: "Assigned sites" },
  _return_to_site_menu: { FI: "Paluu kohteet menuun", EN: "Return to sites" },
  Add_new_customer: { FI: "Lisää uusi asiakas", EN: "Add new customer" },
  Add_new_customer_title: {
    FI: "Uuden asiakkaan lisäys",
    EN: "Add new customer"
  },
  Customers_sites: {
    FI: "Asiakkaan kohteet",
    EN: "Customer's sites"
  },
  Customers_users: {
    FI: "Asiakkaan käyttäjät",
    EN: "Customer's users"
  },
  Customers_name: {
    FI: "Asiakkaan nimi",
    EN: "Customer's name"
  },
  Delete_customer: {
    FI: "Poista asiakas",
    EN: "Delete customer"
  },
  Add_new_customer_return: {
    FI: "Palaa asiakkaiden hallinnointiin",
    EN: "Return to customer management page"
  },
  _firstname: { FI: "Etunimi", EN: "First name" },
  _lastname: { FI: "Sukunimi", EN: "Last name" },
  _role: { FI: "Rooli", EN: "Role" },
  _select_role: { FI: "Valitse rooli", EN: "Select role" },
  _Transfer_site: { FI: "Siirrä kohde", EN: "Move site" },
  _select_new_customer: {
    FI: "Valitse uusi asiakas",
    EN: "Select new customer"
  },
  _transfer_site_to_new_customer: {
    FI: "Siirrä valittu kohde uudelle asiakkaalle",
    EN: "Transfer selected site to new customer"
  },
  _transfer: { FI: "Siirrä", EN: "Transfer" },

  _comments: { FI: "Huomautuksia", EN: "Comments" },
  Sites: { FI: "Kohteet", EN: "Sites" },

  No_upload: { FI: "Ei ladattua tiedostoa", EN: "No uploaded drawing" },
  Add_new_user: { FI: "Lisää uusi käyttäjä", EN: "Add new user" },
  Add_layer: { FI: "Lisää uusi kerros", EN: "Add new floor" },
  Add_layer_to_site: {
    FI: "Lisää kerros kohteeseen",
    EN: "Add floor to the site"
  },
  Add_devices: { FI: "Lisää laitteita", EN: "Add devices" },
  Add_days: { FI: "Lisää päiviä", EN: "Add days" },

  _power_consumption: { FI: "Ottoteho", EN: "Wattage" },
  _power: { FI: "Teho", EN: "Power" },

  Edit_area: { FI: "Muokkaa kerroksen tietoja", EN: "Edit floor info" },
  _devices: { FI: "Laitteet", EN: "Devices" },
  _delete_floor: { FI: "Poista kerros", EN: "Delete floor" },

  _country: { FI: "Maa", EN: "Country" },
  _customer: { FI: "Asiakas", EN: "Customer" },
  _choose_customer: { FI: "Valitse asiakas", EN: "Choose customer" },
  Floor_name: { FI: "Kerroksen nimi", EN: "Name of the floor" },
  Floor_number: { FI: "Kerroksen valinta", EN: "Number of the floor" },
  Info: { FI: "Info", EN: "Info" },
  Upload_ground_plan: {
    FI: "Pohjapiirroksen lataus",
    EN: "Upload ground plan"
  },
  Latitude: { FI: "Leveyspiiri", EN: "Latitude" },
  Longitude: { FI: "Pituuspiiri", EN: "Longitude" },

  // ERRORS
  GENERAL_ERROR: { FI: "General error", EN: "General error" },
  TRIAL_EXPIRED: { FI: "Koeaika on päättynyt", EN: "Trial time expired" },
  INVALID_DATA: { FI: "Virheelistä tietoa", EN: "Invalid data" },
  NO_PERMISSION: { FI: "Ei lupaa", EN: "No permission" },
  ADD_SITE_COORDS_FIRST: { FI: "Lisää kohteen koordinaatit ensin", EN: "Add site coordinates first" },
  USERNAME_OR_PASSWORD_ERROR: {
    FI: "Virheellinen käyttäjätunnus tai salasana",
    EN: "Wrong username or password"
  },
  NEW_CUSTOMER_ADDED: { FI: "Uusi asiakas lisätty", EN: "New customer added" },
  CUSTOMER_DELETED: { FI: "Asiakas poistettu", EN: "Customer removed" },
  CUSTOMER_MODIFIED: { FI: "Tallennettu", EN: "Saved" },
  NEW_AREA_ADDED: { FI: "Uusi kerros lisätty", EN: "New layer added" },
  AREA_MODIFICATION_DONE: {
    FI: "Kerroksen tiedot tallennettu",
    EN: "Area info saved"
  },
  AREA_DELETED: {
    FI: "Kerroksen poistettu",
    EN: "Area deleted"
  },
  DEVICE_ADDED: { FI: "Laite lisätty", EN: "Device added" },
  DEVICE_MODIFIED: { FI: "Tallennettu", EN: "Saved" },
  DEVICE_DELETED: { FI: "Laite poistettu", EN: "Device deleted" },
  TYPE_ADDED: { FI: "Malli lisätty", EN: "Model added" },
  TYPE_MODIFIED: { FI: "Tallennettu", EN: "Saved" },
  TYPE_DELETED: { FI: "Malli poistettu", EN: "Model deleted" },
  IMAGE_DELETE_FAILED: {
    FI: "Kuvan poisto epäonnistui",
    EN: "Image delete failed"
  },

  // IoT Station specific
  _search: { EN: "Search", FI: "Hae" },
  _hide: { EN: "Hide", FI: "Piilota" },
  _view_plan: { EN: "View Plan", FI: "Näytä" },
  _list: { EN: "List", FI: "Lista" },
  _active: { EN: "Active", FI: "Aktiiviset" },
  _all: { EN: "All", FI: "Kaikki" },
  _alerts: { EN: "Alerts", FI: "Vikailmoitusta" },
  _limitAlerts: { EN: "Alerts", FI: "Hälytykset" },
  _warnings: { EN: "Warnings", FI: "Varoitusta" },
  _faultlogs: { EN: "24h Alert Log", FI: "24h Vikahistoria" },
  _info: { EN: "INFO", FI: "LISÄTIETOJA" },
  _edit: { EN: "EDIT", FI: "MUOKKAUS" },
  _edit2: { EN: "Edit", FI: "Muokkaa" },
  _connected: { EN: "Connected", FI: "Yhdistetty" },
  _delete: { EN: "Delete", FI: "Poista" },
  _copy: { EN: "Copy", FI: "Kopioi" },
  _relocate: { EN: "Relocate", FI: "Uudelleen sijoita" },
  _define: { EN: "Define", FI: "Määritä" },
  _new_widget: { EN: "New Widget", FI: "Uusi Näkymä"},
  _device_list: { EN: "Device List", FI: "Laite lista" },
  _save: { EN: "Save", FI: "Tallenna" },
  _edit_device_info: { EN: "Edit Device Info", FI: "Muokkaa laitteen tietoja" },
  _missing_device_type: {
    EN: "Missing Device Type",
    FI: "Laitteen tyyppi puuttuu"
  },
  _widget_title_mandatory: {
    EN: "Widget title is mandatory",
    FI: "Widgetin otsikko on pakollinen"
  },
  _device_info: { EN: "Device Info", FI: "Laitteen tietoja" },
  _status: { EN: "Status", FI: "Tila" },
  _name: { EN: "Name", FI: "Nimi" },
  _number: { EN: "Number", FI: "Numero" },
  _installation_date: { EN: "Installation Date", FI: "Käyttöönotto pvm" },
  _selectType: { EN: "Select Type", FI: "Valitse malli" },
  _selectPageSize: { EN: "Select PageSize", FI: "Valitse sivukoko" },
  _title: { EN: "Title", FI: "Otsikko" },
  _box_type: { EN: "Dashboard Item", FI: "Näkymän tyyppi" },
  _box_title: { EN: "Widget Title", FI: "Widgetin nimi" },
  _dashboard_widget: { EN: "Dashboard Widget", FI: "Näkymä" },
  _select_dashboard_widget: {
    EN: "Select Dashboard Widget to Copy Here",
    FI: "Valitse näkymä jonka haluat tähän"
  },
  _create_dashboard: {
    EN: "Create Dashboard",
    FI: "Luo näkymä"
  },
  _select: { EN: "Select", FI: "Valitse" },
  _selectLineGraph: { EN: "Select graph", FI: "Valitse graaffi" },
  _basic_multiline_graph: {
    EN: "Basic graph (multi device)",
    FI: "Tyypilinen graaffi (monilaitteinen)"
  },
  _scaleable_multiline_graph: {
    EN: "Scaleable graph (multi device)",
    FI: "Skaalattava graaffi (monilaitteinen)"
  },
  _avenrage_min_max_graph: {
    EN: "Minmax graph (single device)",
    FI: "Minmax graaffi (yksi laite)"
  },
  _unknown_device: { EN: "Unknown Device", FI: "Tuntematon laite" },
  _select_default_site: {
    EN: "Choose Default Site",
    FI: "Valitse oletuskohde"
  },
  _add_area: {
    EN: "Add Area / Floor To The Site",
    FI: "Lisää kerros kohteeseen"
  },
  _add_device: { EN: "Add new device", FI: "Luo uusi laite" },
  _add_devices: { EN: "Add devices", FI: "Luo laitteita" },
  _theme_dark: { EN: "Dark", FI: "Tumma" },
  _theme_light: { EN: "Light", FI: "Vaalea" },
  _temperature: { EN: "Temperature", FI: "Lämpötila" },
  _humidity: { EN: "Humidity", FI: "Ilman kosteus" },
  _pressure: { EN: "Air Pressure", FI: "Ilmanpaine" },
  _airpressure: { EN: "Air Pressure", FI: "Ilmanpaine" },
  _lux: { EN: "Lux", FI: "Valo" },
  _ambientlight: { EN: "Ambient Light", FI: "Valaistusvoimakkuus" },
  _voc: { EN: "VOC", FI: "VOC" },
  _unknown: { EN: "Unknown", FI: "Tuntematon" },
  _room_temperature: { EN: "Room Temperature", FI: "Huoneiden Lämpötilat" },
  _degree_average: { EN: "Degree Average", FI: "Keskiarvo" },
  _change: { EN: "Change", FI: "Muutos" },
  _both: { EN: "Both", FI: "Molemmat" },
  _limits: { EN: "Limits", FI: "Raja-arvot" },
  _alert_communication: {
    EN: "Alert communication",
    FI: "Hälytysten viestintä"
  },
  _enable_alerts: { EN: "Enable alerts", FI: "Raja-arvot käytössä" },
  _disable_alerts: { EN: "Disable alerts", FI: "Raja-arvot pois" },
  _show_alertlogs: {EN: "Show 24h Alert History", FI: "Näytä 24h hälytyshistoria"},

  _realizedTemperatures: {
    EN: "Realized temperatures",
    FI: "Aiemmat lämpötilat"
  },
  _report: {
    EN: "report",
    FI: "raportti"
  },
  _reports: {
    EN: "Reports",
    FI: "raportit"
  },
  _selectPrecision: { EN: "Choose precision", FI: "Valitse tarkkuus" },
  _daily: { EN: "Daily", FI: "Päivittäinen" },
  _hourly: { EN: "Hourly", FI: "Tunneittain" },
  _from: { EN: "From", FI: "Mistä" },
  _to: { EN: "To", FI: "Mihin" },
  _getData: { EN: "Get Data", FI: "Hae data" },
  _backToDevicelist: { EN: "Back to Device list", FI: "Takaisin laitelistaan" },
  _average: { EN: "Average", FI: "Keskiarvo" },
  _downloadCSV: { EN: "Download CSV", FI: "Lataa CSV" },
  _print: { EN: "Print", FI: "Tulosta" },
  _sort: { EN: "Sort", FI: "Järjestä" },
  _noMatches: {
    EN: "No matching records found",
    FI: "Hakuehdoilla ei löytynyt tuloksia"
  },
  _choose_columns: {
    EN: "Choose colums",
    FI: "Valitse sarakkeet"
  },
  _tempAvg: {
    EN: "Average",
    FI: "Keskiarvo"
  },
  _tempMin: {
    EN: "Minimum",
    FI: "Minimi"
  },
  _tempMax: {
    EN: "Maximum",
    FI: "Maksimi"
  },
  _group: {
    EN: "Group",
    FI: "Ryhmä"
  },

  _line_graph_type: { EN: "Linegraph type", FI: "Viivakaavion tyyppi" },
  _time_setting: { EN: "Time setting", FI: "Aika asetus" },
  _setTimeSetting: { EN: "set setting", FI: "Aseta asetus" },
  _from_now: { EN: "From now", FI: "Tästä hetkestä" },
  _last: { EN: "Last", FI: "Viime" },
  _currently_ongoing: { EN: "Currently ongoing", FI: "Tällä hetkellä kuluva" },
  _oneday: { EN: "One day", FI: "Yhden päivän" },
  _custom_time: { EN: "Custom time", FI: "Mukautettu päivä" },
  _min_value: { EN: "Minimum value", FI: "Minimi arvo" },
  _mid_value: { EN: "Line point (%)", FI: "Viiva piste (%)" },
  _max_value: { EN: "Maximum value", FI: "Maksimi arvo" },
  _allowScale: { EN: "Allow scale", FI: "Salli skaalaus" },
  _unknownSite: { EN: "Unknown site", FI: "Tuntematon kohde" },
  _scaleWithContent: {
    EN: "Scale with content",
    FI: "Skaalaa sisällön mukaan"
  },
  _defineDevices: { EN: "Select devices", FI: "Valitse laitteet" },
  _defineLimits: { EN: "Select limits", FI: "Valitse raja-arvot" },
  _setFill: { EN: "Set fill", FI: "Aseta täyttö" },
  _colorcode: { EN: "Color code", FI: "Väri koodi" },
  _show_all: { EN: "Show all", FI: "Näytä kaikki" },
  _define_widget: { EN: "Define dashboard widget", FI: "Määritä näkymä" },
  _piechart_desc: {
    EN: "Device status pie chart",
    FI: "Laitteiden tila piirakkakaavio"
  },
  _avenrage_desc: {
    EN: "Value average widget",
    FI: "Arvojen keskiarvo näkymä"
  },
  _limit_desc: {
    EN: "Limit display and simple control widget",
    FI: "Limitin näyttö ja yksinkertainen hallinta näkymä"
  },
  _limitToDisplay: { EN: "Display limits", FI: "Näytä raja-arvot" },
  _limitType: { EN: "Limit Type", FI: "Raja-arvon tyyppi" },
  _limitInputError: { EN: "Check limit values!", FI: "Tarkista raja-arvot!" },
  _selectLimit: { EN: "Select limit", FI: "Valitse raja-arvo" },
  _hollow_mid: { EN: "Hollowed middle", FI: "Ontto keskialue" },
  _customSelection: { EN: "custom selection", FI: "Vapaa valinnainen" },
  _DeviceStatus: { EN: "Device status", FI: "Laitetilat" },
  _select_site_or_custom: {
    EN: "select site or custom device selection",
    FI: "Valitse kohde tai vapaa valintainen laitelista"
  },
  _select_values_for_display: {
    EN: "Select values for display",
    FI: "Valitse arvot näytettäväksi"
  },
  _Alerts: { EN: "Alerts", FI: "Hälytykset" },
  _AlarmLimits: { EN: "Alarm limits", FI: "Hälytysrajat" },
  _min: { EN: "minimum", FI: "minimi" },
  _max: { EN: "maximum", FI: "maksimi" },
  _temp: { EN: "TEMP", FI: "TEMP" },
  _hum: { EN: "HUM", FI: "HUM" },
  _pres: { EN: "PRES", FI: "PRES" },
  _city: { EN: "City", FI: "Kaupunki" },
  _sms: { EN: "Change", FI: "Muutos" },
  _latitude: { EN: "Latitude", FI: "Leveysaste" },
  _longitude: { EN: "Longitude", FI: "Pituusaste" },
  _upload_logo: { EN: "Logo upload", FI: "Logon valinta" },
  _upload_banner: { EN: "Banner upload", FI: "Bannerin valinta" },
  _users_of_the_site: { EN: "Users of the site", FI: "Kohteen käyttäjät" },
  _add_new_user: { EN: "Add new user to the site", FI: "Lisää käyttäjä" },
  _sites: { EN: "Sites", FI: "Kohteet" },
  _arrange_by: { EN: "Arrange by", FI: "Lajittele" },
  _value: { EN: "Value", FI: "Arvo" },
  _main_value: { EN: "Main Value", FI: "Pääarvo" },
  _last_update: { EN: "Last updated", FI: "Viimeisin päivitys" },
  _alert_period: { EN: "Alert Delay", FI: "Hälytysviive" },
  _alert_period_tip: {
    EN: "Time before device is sending a delayed alert message.",
    FI: "Aika, jonka jälkeen haluat laitteen raportoivan hälytyksestä."
  },
  _no_update_time: { EN: "No Updates Delay", FI: "Ei päivityksiä viive" },
  _no_update_time_tip: {
    EN:
      "Time after device is set to fault state if it is not receiving messages from cloud.",
    FI:
      "Aika, jonka jälkeen laite menee vikatilaan, mikäli se ei saa päivityksiä pilvipalvelimelta."
  },
  _first_alert_tooltip: {
    EN:
      "Set message types you want to receive from device alerts. These messages are sent immediatelly after the alert is registered.",
    FI:
      "Aseta viestintätavat, jolla haluat saada hälytyksen laitteiden ilmoittamista vioista. Nämä viestit lähetetään välittömästi rekisteröityämme vian."
  },
  _resend_alert_tooltip: {
    EN:
      "Set message types you want to receive from device alerts. These messages are sent with some delay after the alert is registered. Delay is 60min by default or you can set it from device settings individually for each device.",
    FI:
      "Aseta viestintätavat, jolla haluat saada uudestaan hälytyksen laitteiden ilmoittamista vioista. Nämä hälytysviestit lähetetään viiveellä. Viive on laitekohtainen ja sen voi asettaa laitteen asetuksista. Mikäli välitöntä hälytystä ei ole asetettu, ensimmäinen hälytysviesti tulee vasta asetetun viiveen jälkeen."
  },
  _define_devices: {
    EN: "Define up to 10 devices",
    FI: "Määritä korkeintaan 10 laitetta"
  },
  _pillar_graph: { EN: "Pillar graph", FI: "Pylväsdiagrammi" },
  _loading: { EN: "Loading", FI: "Ladataan" },

  // Widget names
  _DefaultWidget: {
    FI: "Oletusnäkymä",
    EN: "Default Widget"
  },
  _AlertsWidget: {
    FI: "Hälytykset",
    EN: "Alerts"
  },
  _WarningsWidget: {
    FI: "Varoitukset",
    EN: "Warnings"
  },
  _StatusWidget: {
    FI: "Tila",
    EN: "Status"
  },
  _ValueAverageWidget: {
    FI: "Keskiarvot",
    EN: "Averages"
  },
  _LineWidget: {
    FI: "Viivakaavio",
    EN: "Line Graph"
  },
  _LimitWidget: {
    FI: "Raja-arvot",
    EN: "Limits"
  },
  _PillarChartWidget: {
    FI: "Pylväsdiagrammi",
    EN: "Pillar Graph"
  },
  _LEDWidget: {
    FI: "LED laite",
    EN: "LED device"
  },

  // Profile pages - Sites
  _profile: { EN: "Profile", FI: "Profiili" },
  _n_devices: { EN: "devices", FI: "laitetta" }, // "12 devices" etc.
  _one_device: { EN: "device", FI: "laite" },

  // Sites - Site details
  _phone_number: { EN: "Phone number", FI: "Puhelinnumero" },

  _add_new: { EN: "Add New", FI: "Lisää uusi" },
  _limitsList: { EN: "Limits", FI: "Raja-arvoja" },
  _alert_terms: { EN: "Alert Terms", FI: "Ilmoituksen rajat" },
  name: { EN: "name", FI: "nimi" },
  type: { EN: "type", FI: "tyyppi" },
  _alert_if: { EN: "Make an alert if", FI: "Tee hälytys jos" },
  _value_below: { EN: "value is BELOW", FI: "arvo on ALLE" },
  _value_above: { EN: "value is ABOVE", FI: "arvo on YLI" },
  _no_sites_note: {
    EN: "User has no Sites defined",
    FI: "Käyttäjällä ei vielä Kohdetta asetettuna"
  },
  _noArea: { EN: "No area selected", FI: "Aluetta ei valittuna" },
  _selected_devices: { EN: "Selected devices", FI: "Valitut laitteet" },
  _select_site: { EN: "Select site", FI: "Valitse kohde" },
  _select_type: { EN: "Select type", FI: "Valitse tyyppi" },

  _registertrialcode: {
    EN: "Give trial registration code",
    FI: "Anna kokeilu rekisteröinti koodi"
  },
  _trialToken: { EN: "Trial code", FI: "Kokeilu koodi" },
  _checkToken: { EN: "Check code", FI: "Varmista koodi" },
  _trialregistrationInProgress: {
    EN: "Trial Code is valid",
    FI: "Kokeilu koodi on käytettävissä"
  },
  _trialregistrationInProgress2: {
    EN: "Trial account and company registered succesfully!",
    FI: "Koeaika käyttäjä ja yritys rekisteröity onnistuneesti!"
  },
  _trialregisterheadlinestep1: {
    EN: "Create admin account",
    FI: "Luo järjestelmävalvoja tunnus"
  },
  _trialregisterheadlinestep2: {
    EN: "Create trial company",
    FI: "Luo koeaika yritys"
  },
  _sendSuccess: {
    EN: "send succeeded, check your email.",
    FI: "Lähetys onnistui, tarkista sähköpostisi"
  },
  _trialregisterheadlinestep3: { EN: "Create site", FI: "Luo kohde" },
  _trialregistrationUserDesc: {
    EN: "Account is still operational, after trial time expires",
    FI: "Tili jää käytettäväksi vaikka, koeaika vanhenisikin."
  },
  _trialregistrationOrgDesc: {
    EN: "Trial company will disable when trial time expire.",
    FI: "Koeajan vanhetessa, kokeily yritys tulee epäkäytettäväksi."
  },
  _checkUser: { EN: "Check user", FI: "Varmista käyttäjä" },
  _activateTrial: { EN: "Activate trial time", FI: "Aktivoi koeaika" },
  _code: { EN: "Code", FI: "Koodi" },
  _make_premium: { EN: "Make premium", FI: "Tee varsinaiskäyttäjäksi" },
  _expire: { EN: "Expire", FI: "Vanhenna" },
  _trial_token_manage: { EN: "Trial token management", FI: "Koeaika hallinta" },
  _expiration: { EN: "Expiration", FI: "Kestoaika" },
  _claimed: { EN: "Claimed", FI: "Varattu" },
  _receiver: { EN: "Receiver", FI: "Vastaanottaja" },
  _received: { EN: "Received", FI: "Vastaanotettu" },
  _expiresIn: { EN: "Expires", FI: "Vanhenee" },
  _trialTokens: { EN: "Trials", FI: "Koeajat" },
  _add_new_trial_token: { EN: "Add new trial token", FI: "Lisää uusi koeaika" },
  _days: { EN: "days", FI: "päivää" },
  _remove_trial: { EN: "Remove trial", FI: "Poista koeaika" },
  _bulk_create: { EN: "Bulk create", FI: "massa lisää" },
  _save_bulk: { EN: "save", FI: "tallenna" },
  _quantity: { EN: "quantity", FI: "määrä" },
  _custom_trial_expiration: {
    EN: "custom trial expiration (days)",
    FI: "mukautettu vanhentumisaika (päiviä)"
  },
  _custom_trial_code: {
    EN: "custom trial code",
    FI: "mukautettu varmennekoodi"
  },
  _trialCode: { EN: "trial code", FI: "koeaika koodi" },
  _expires: { EN: "expires", FI: "vanhenee" },
  _return_to_trial_list: {
    EN: "return to trial list",
    FI: "palaa takasin koeaika listaan"
  },
  _trialDescription: {
    EN: "This trial company, details below:",
    FI: "Tämä yritys on koeajalla, yksityiskohdat alla:"
  },
  _trialCompany: {
    EN: "Trial company",
    FI: "koeaika yritys"
  },
  _timeLeft: { EN: "Trial time expires in ", FI: "Koeaika vanhenee " },
  _timeLeft2: { EN: "", FI: " kuluttua" },
  _organizationExpired: {
    EN: "Company trial time has expired",
    FI: "Yhtiön koeaika on päättynyt"
  },
  _error: { EN: "Error", FI: "Virhe" },

  _open_widget: { EN: "Open Widget", FI: "Avaa widgetti" },
  _saved_widgets: { EN: "Saved Widgets", FI: "Talletetut widgetit" },
  _favourite_saved: { EN: "Favourite saved", FI: "Suosikki talletettu" },
  _favourite_deleted: { EN: "Favourite deleted", FI: "Suosikki poistettu" },
  _first_alert: { EN: "First alert", FI: "Ensimmäinen hälytys" },
  _resend_alerts: { EN: "Resend alerts", FI: "Uudelleenlähetetyt hälytykset" },
  _password_change_success: {
    EN: "Password change succeeded!",
    FI: "Salasanan vaihto onnistui!"
  },

  _alert_status: { EN: "Alert Status", FI: "Hälytyksen tila" },
  _acknowledge: { EN: "Acknowledge", FI: "Kuittaa" },

  // status widget possible state names (from device update)
  FAULT: { EN: "FAULT", FI: "VIKA" },
  DISABLED: { EN: "DISABLED", FI: "EI KÄYTÖSSÄ" },

  _less_than: { EN: "Less than", FI: "Alle" },
  _more_than: { EN: "More than", FI: "Yli" },
  _values: { EN: "Values", FI: "Arvot" },

  _filter_sites: { EN: "Filter sites", FI: "Rajaa kohteet" },
  _filter_device_types: { EN: "Filter device types", FI: "Rajaa laitetyypit" },
  _filter_device_groups: { EN: "Filter device groups", FI: "Rajaa laiteryhmät" },
  _show_device_types: { EN: "Show device types", FI: "Näytä laitetyypit" },

  _alert_new: { EN: "New", FI: "Uusi" },
  _alert_read: { EN: "Read", FI: "Luettu" },
  _alert_ack: { EN: "Acknowledged", FI: "Kuitattu" },

  OK: { EN: "OK", FI: "OK" },

  _site_deleted: { EN: "Site deleted", FI: "Kohde poistettu" },
  _no_devices: {
    EN: "Site has no devices yet.",
    FI: "Sijainnilla ei ole vielä laitteita."
  },
  _add_some_devices: {
    EN: "Go to Device list on main menu to add some.",
    FI:
      "Voit käydä lisäämässä uusia laitteita päävalikon Laitelistaus-kohdasta."
  },
  _load: { EN: "Load", FI: "Lataa" },
  _empty_widget: {
    EN: "I feel empty. But not for long!",
    FI: "Olen tyhjä, mutta en kauaa!"
  },
  _redefine_widget: {
    EN: "Redefine me by clicking the three dots up there on the right corner.",
    FI: "Määrittele minulle sisältö oikean yläkulman nappulasta."
  },
  _select_widget: {
    EN: "Select Widget Type",
    FI: "Valitse widgetin tyyppi"
  },
  _choose_color: {
    EN: "Choose Color",
    FI: "Valitse väri"
  },
  _gateway: { EN: "Gateway", FI: "Yhdyskäytävä" },
  _choose_slider: { EN: "Widget with sliders", FI: "Käytä liukusäätimiä" },
  _supported_file_formats: {
    EN: "Supported file formats: jpeg, png and bmp",
    FI: "Tuetut tiedostomuodot: jpeg, png ja bmp"
  },

  // REPORTS view
  _reportsManage: {
    FI: "Asiakkaiden viikkoraporttien hallinnointi",
    EN: "Customer weekly reports management"
  },
  _receive_reports: {
    FI: "Vastaanottaa raportteja",
    EN: "Receives reports"
  },
  _weekly_reports: {
    FI: "Viikkoraportit",
    EN: "Weekly reports"
  },
  _monthly_reports: {
    FI: "Kuukausiraportit",
    EN: "Monthly reports"
  },

  _disabled: {
    FI: "Pois käytöstä",
    EN: "Disabled"
  },
  _enabled: {
    FI: "Käytössä",
    EN: "Enabled"
  },
  _topBarColor: {
    FI: "Yläpalkin väri",
    EN: "Top bar color"
  },
  _reset: {
    FI: "Nollaa",
    EN: "Reset"
  },
  _floor: {
    FI: "Kerroksen",
    EN: "Floor"
  },
  _overview: {
    FI: "Yleiskatsaus",
    EN: "overview"
  },
  _floor_groundplan_overview: {
    FI: "Kerroksen yleiskatsauksen pohjakuva",
    EN: "Floor groundplan overview"
  },
  _show_devices_on_map: {
    FI: "Näytä laitteet kartalla",
    EN: "Show devices on map"
  },
  _show: {
    FI: "Näytä",
    EN: "Show"
  },
  _limitStartTime: {
    FI: "Rajoita aikajanan alkua",
    EN: "Limit start time"
  },
  _limitEndTime: {
    FI: "Rajoita aikajanan loppua",
    EN: "Limit end time"
  },
  _daysAgo: {
    FI: "Päivää sitten",
    EN: "Days ago"
  },
  _hoursAgo: {
    FI: "Tuntia sitten",
    EN: "Hours ago"
  },
  _show_graph:{
    FI: "Näytä kuvaaja",
    EN: "Show graph"
  },
  _hide_graph:{
    FI: "Piilota kuvaaja",
    EN: "Hide graph"
  },
  _rotate_views:{
    FI: "Esittele näkymiä",
    EN: "Rotate views"
  },
  _rotation_views:{
    FI: "Esiteltävät näkymät",
    EN: "Rotation views"
  },
  _rotation_interval:{
    FI: "Näkymien aikaväli (s)",
    EN: "Rotation interval (s)"
  },
  _groups:{
    FI: "Ryhmät",
    EN: "Groups"
  },
  _click_to_edit_device:{
    FI: "Klikkaa muokataksesi laitetta",
    EN: "Click to edit device"
  },
  _click_to_edit_geo_borders:{
    FI: "Klikkaa muokataksesi georajoja",
    EN: "Click to edit geo borders"
  },
  _no:{
    FI: "Ei",
    EN: "No"
  },
  _yes:{
    FI: "Kyllä",
    EN: "Yes"
  },
  _show_geo_borders:{
    FI: "Näytä georajaus",
    EN: "Show geo borders"
  },
  _alerts_on_geo_borders:{
    FI: "Geohälytykset käytössä",
    EN: "Alerts on geo borders"
  }
}

export const setLanguage = language => {
  gLanguage = language
  languageReady = true
}

export const getLanguage = () => {
  return languageReady ? gLanguage : null
}

export const tr = (sentence, additional) => {
  if (Object.prototype.hasOwnProperty.call(translations, sentence)) {
    if (additional) {
      let translatedSentence = translations[sentence][gLanguage]
      if (additional.length > 0) {
        translatedSentence = translatedSentence.replace("?1", additional[0])
      }

      if (additional.length > 1) {
        translatedSentence = translatedSentence.replace("?2", additional[1])
      }

      return translatedSentence
    } else {
      return translations[sentence][gLanguage]
    }
  } else {
    // ## are added to recognice missing translations
    return "##" + sentence + "##"
  }
}
