import React from "react"

import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import withMobileDialog from "@material-ui/core/withMobileDialog"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"

import Select from "@material-ui/core/Select"
import { connect } from "react-redux"
import { tr } from "translations"
import { Close } from "../SvgLib"

/**
 * Modal to select new customer for site
 */
class CustomerSelectionModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      customer: "",
      formUpdated: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange({ target }) {
    this.setState({ [target.name]: target.value, formUpdated: true })
  }

  /**
   * Save or clear form button handlers
   */
  handleSubmit(button) {
    const { customer } = this.state

    switch (button) {
      case "save":
        this.props.onSubmitCustomer(customer)
        this.setState({
          customer: "",
          formUpdated: false
        })
        this.props.onClose()
        break
      case "reject":
        this.setState({
          customer: "",
          formUpdated: false
        })
        this.props.onClose()
        break
    }
  }

  render() {
    const { classes, customerList, fullScreen, isOpen } = this.props
    const { formUpdated, customer } = this.state

    return (
      <Dialog
        fullScreen={fullScreen}
        modal="true"
        onClose={this.props.onClose}
        aria-labelledby="simple-dialog-title"
        open={isOpen}
        className={classes.dialogRoot}
        fullWidth={true}
        maxWidth={"lg"}
      >
        <Paper className={classes.modalPaper} elevation={2}>
          <Toolbar className={classes.root}>
            <IconButton
              className={classes.buttonGroupRight}
              aria-label="Print"
              onClick={() => this.handleSubmit("reject")}
            >
              <Close style={{ height: "30px" }} color="#898989" />
            </IconButton>
          </Toolbar>
          <Grid container justify="center" className={classes.container}>
            <Grid item xs={10}>
              <Typography
                align="center"
                variant="h6"
                noWrap={true}
                className={classes.infoTitle}
              >
                {tr("_select_new_customer")}
              </Typography>

              <Typography
                align="center"
                variant="subtitle1"
                className={classes.infoText}
              >
                {tr("_transfer_site_to_new_customer")}
              </Typography>

              <Grid item xs={12} md={6} lg={4}>
                <FormControl className={classes.textField}>
                  <InputLabel htmlFor="customer-controlled-open-select">
                    {tr("_customer")}
                  </InputLabel>
                  <Select
                    required
                    value={customer}
                    onChange={this.handleChange}
                    inputProps={{
                      name: "customer",
                      id: "customer-controlled-open-select"
                    }}
                  >
                    <MenuItem value="">
                      <em>{tr("_choose_customer")}</em>
                    </MenuItem>
                    {customerList
                      ? customerList.map(customer => {
                          return (
                            <MenuItem value={customer._id} key={customer._id}>
                              {customer.name}
                            </MenuItem>
                          )
                        })
                      : ""}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.footer}>
              <Grid container spacing={0} justify="center">
                <Grid item className={classes.buttonSend}>
                  <Button
                    disabled={!formUpdated}
                    variant="contained"
                    color="primary"
                    onClick={() => this.handleSubmit("save")}
                  >
                    {tr("_transfer")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Dialog>
    )
  }
}

const styles = theme => ({
  dialogRoot: {
    minWidth: 500,
    maxWidth: 600,
    margin: "auto"
  },
  modalPaper: {
    // backgroundColor: "white"
  },
  textField: {
    color: theme.palette.gray,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: 400
  },
  themeIcon: {
    color: theme.palette.grey[400]
  },
  root: {
    margin: 0
  },
  infoTitle: {
    color: theme.palette.grey[400]
  },
  infoText: {
    paddingTop: theme.spacing(2),
    color: theme.palette.grey[500]
  },
  buttonGroupRight: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  buttonSend: {
    color: theme.palette.getContrastText(theme.palette.primary.dark),
    margin: theme.spacing(2)
  },
  inputColor: {
    color: theme.palette.grey.main
  },
  labelColor: {
    color: theme.palette.grey.main
  },
  underline: {
    borderBottom: "1px solid #898989"
  }
})

export default connect()(
  withStyles(styles)(withMobileDialog()(CustomerSelectionModal))
)
