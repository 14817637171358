import * as React from "react"
import useTheme from "@material-ui/core/styles/useTheme"
import { MapMarker } from "../SvgLib"

const MapMarkerPoint = props => {
  const {
    alerts,
    site,
    unplaced,
    selectMarker,
    $hover,
    selected,
    iconSize,
    logo
  } = props

  const theme = useTheme()

  const handleClick = () => {
    selectMarker(site._id, site.pos)
  }
  const size = iconSize - 10
  let scale = 1.3
  let width = size
  let height = size
  let left = -size / 2
  let top = -size

  if ($hover || selected) {
    width = width * scale
    height = height * scale
    left = left * scale
    top = top * scale
  }

  return (
    <div>
      <MapMarker
        color={
          alerts
            ? theme.palette.error.threatening
            : unplaced
              ? theme.palette.error.light
              : theme.palette.action.main
        }
        style={{
          zIndex: $hover ? 120 : selected ? 100 : 20,
          position: "absolute",
          height: height,
          width: width,
          left: left,
          top: top,
          overflow: "visible"
        }}
        iconName={site.name.includes("AJR") ? "ajrlogo" : "partner"}
        logo={logo}
        selected={selected}
        innerColor={theme.palette.secondary.main}
        onClick={handleClick}
      />
    </div>
  )
}

export default MapMarkerPoint
