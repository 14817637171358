// keep this array updated with device model deviceType enum

export const typeOptions = [
  "Default",
  "TreonNode",
  "TreonAssetNode",
  "Danfoss",
  "Caplan",
  "Generic_LED",
  "Oyster3"
]
