import React from "react"
import FormLabel from "@material-ui/core/FormLabel"
import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"
import withStyles from "@material-ui/core/styles/withStyles"

// Text field component according to UI sketches
const ProfileTextField = props => {
  const {
    classes,
    fieldName,
    id,
    onChange,
    value,
    fieldLabel,
    uneditable,
    type,
    error,
    helperText
  } = props
  return (
    <Grid>
      <FormLabel
        component="legend"
        style={{ fontWeight: "bold" }}
        classes={{
          root: classes.inputRoot,
          focused: classes.inputFocus
        }}
      >
        {fieldLabel}
      </FormLabel>
      <TextField
        error={error}
        helperText={helperText}
        id={id}
        name={fieldName}
        className={classes.textField}
        value={value}
        type={type}
        onChange={uneditable ? undefined : onChange}
        margin="normal"
        InputProps={{
          classes: {
            input: classes.inputColor,
            underline: classes.underline
          }
        }}
        InputLabelProps={{
          classes: {
            root: classes.inputRoot,
            focused: classes.inputFocus,
            error: classes.labelColor
          }
        }}
      />
    </Grid>
  )
}

const styles = theme => ({
  inputColor: {
    color: theme.palette.text.primary
  },
  textField: {
    marginTop: "5px",
    marginBottom: "1rem",
    width: "100%",
    textColor: theme.palette.text.field
  },
  inputRoot: {
    color: theme.palette.text.field + " !important"
  },
  inputFocus: {
    color: theme.palette.text.warning + " !important"
  },
  underline: {
    "&::before": {
      color: theme.palette.text.primary
    },
    "&::hover": {
      borderBottom: "2px solid " + theme.palette.text.primary
    },
    "&::after": {
      borderBottom: "2px solid " + theme.palette.text.warning
    }
  }
})

export default withStyles(styles, { withTheme: true })(ProfileTextField)
