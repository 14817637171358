import React from "react"
import Grid from "@material-ui/core/Grid"
import withStyles from "@material-ui/core/styles/withStyles"
import withTheme from "@material-ui/core/styles/withTheme"
import { connect } from "react-redux"
import * as jwt from "jsonwebtoken"

// Subcontainers
import UserSub from "./subcontainers/UserSub"
import OrganizationSub from "./subcontainers/OrganizationSub"
import SiteSub from "./subcontainers/SiteSub"

class TrialActivationView extends React.Component {
  constructor(props) {
    super(props)

    let userId
    let trialToken
    if (this.props.match.params.token) {
      const decoded = jwt.decode(this.props.match.params.token)
      if (decoded && decoded._id) {
        userId = decoded._id
        trialToken = decoded.token
      } else {
        this.props.history.push("/login")
      }
    }

    this.state = {
      sub: ["OrganizationSub", "SiteSub", "UserSub"],
      step: 0,
      org: {},
      site: {},
      userId,
      trialToken
    }
    this.nextStep = this.nextStep.bind(this)
  }

  subcontainer() {
    const { sub, step, org, site } = this.state
    switch (sub[step]) {
      case "UserSub":
        return <UserSub org={org} site={site} />
      case "OrganizationSub":
        return <OrganizationSub nextStep={this.nextStep} />
      case "SiteSub":
        return <SiteSub nextStep={this.nextStep} org={org} />
    }
  }

  nextStep(target, value) {
    this.setState({ step: this.state.step + 1, [target]: value })
  }

  render() {
    const { classes } = this.props

    return (
      <Grid className={classes.root}>
        <Grid item style={{ overflowY: "auto" }}>
          {this.subcontainer()}
        </Grid>
      </Grid>
    )
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: "100vh",
    width: "100vw"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  themedbutton: {
    color: theme
  },
  navItemStyle: {
    padding: theme.spacing(1),
    margin: theme.spacing(1)
  }
})

const mapDispatchToProps = () => ({})

const mapStateToProps = state => {
  return {
    iotDevices: state.iotDevices,
    role: state.auth.role,
    token: state.auth.token
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTheme(TrialActivationView)))
