/*import axios from "axios"
import { getApiUrl } from "../../constants/config"
import { authHeader } from "../../actions/common" */
import { parseErrorData } from "../../services/errors"
import { stateType } from "../../constants/actions"

const iotDeviceApiFailed = error => ({
  type: stateType.SET_ERROR,
  error
})

const dataUpdatesRequested = () => ({
  type: stateType.DEVICEDATA_DATA_REQUESTED
})

const dataUpdatesReceived = iotDeviceData => ({
  type: stateType.DEVICEDATA_DATA_RECEIVED,
  iotDeviceData
})

export const deviceAction = id => {
  console.log("Action for device:", id)
}
export const getDataUpdates = (id /*, days = 1*/) => async (
  dispatch
  /*getState*/
) => {
  try {
    dispatch(dataUpdatesRequested())
    /*let deviceUrl =
      getApiUrl() + "/api/devicetype/GenericDali/" + id + "?days=" + days

    const state = getState()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }
    const { data } = await axios.get(deviceUrl, authHeader(useToke)) */
    const dataItem = { data: [] /*data.data*/, nodeId: id }
    return Promise.resolve(dispatch(dataUpdatesReceived(dataItem)))
  } catch (err) {
    let errorReply = parseErrorData(err)
    if (!errorReply) {
      errorReply = err
    }
    return Promise.reject(dispatch(iotDeviceApiFailed(errorReply)))
  }
}
