export const stateType = {
  AUTH_RESET_ERROR: "AUTH_RESET_ERROR",
  RESET_ERROR: "RESET_ERROR",
  SET_ERROR: "SET_ERROR",
  SET_INFO: "SET_INFO",
  RESET_INFO: "RESET_INFO",
  CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
  CHANGE_THEME: "CHANGE_THEME",
  CHANGE_ALERTHISTORY_VISIBILITY: "CHANGE_ALERTHISTORY_VISIBILITY",
  CHANGE_DEVICETABLE_COLUMNS: "CHANGE_DEVICETABLE_COLUMNS",
  CHANGE_DEVICELIST_FILTER: "CHANGE_DEVICELIST_FILTER",
  CHANGE_DEVICELIST_SORT: "CHANGE_DEVICELIST_SORT",

  TOKEN_SUCCEEDED: "TOKEN_SUCCEEDED",
  TOKEN_FAILED: "TOKEN_FAILED",
  TOKEN_REQUESTED: "TOKEN_REQUESTED",
  TOKEN_RESET: "TOKEN_RESET",

  VALIDATION_REQUESTED: "VALIDATION_REQUESTED",
  VALIDATION_SUCCEEDED: "VALIDATION_SUCCEEDED",
  TRIAL_VALIDATION_SUCCEEDED: "TRIAL_VALIDATION_SUCCEEDED",
  VALIDATION_FAILED: "VALIDATION_FAILED",
  SET_PAGESIZE: "SET_PAGESIZE",

  IOTDEVICE_RESET: "IOTDEVICE_RESET",
  IOTDEVICE_LIST_REQUESTED: "IOTDEVICE_LIST_REQUESTED",
  IOTDEVICE_LIST_RECEIVED: "IOTDEVICE_LIST_RECEIVED",
  IOTDEVICE_LIST_FAILED: "IOTDEVICE_LIST_FAILED",
  IOTDEVICE_RESET_ERROR: "IOTDEVICE_RESET_ERROR",
  FAULT_DEVICE_FILTER: "FAULT_DEVICE_FILTER",

  IOTDEVICE_ADD_FAILED: "IOTDEVICE_ADD_FAILED",
  IOTDEVICE_ADD_REQUESTED: "IOTDEVICE_ADD_REQUESTED",
  IOTDEVICE_ADD_RECEIVED: "IOTDEVICE_ADD_RECEIVED",

  IOTDEVICE_MODIFY_FAILED: "IOTDEVICE_MODIFY_FAILED",
  IOTDEVICE_MODIFY_REQUESTED: "IOTDEVICE_MODIFY_REQUESTED",
  IOTDEVICE_PLACEMENT_REQUESTED: "IOTDEVICE_PLACEMENT_REQUESTED",
  IOTDEVICE_MODIFY_RECEIVED: "IOTDEVICE_MODIFY_RECEIVED",

  IOTDEVICE_DELETE_FAILED: "IOTDEVICE_DELETE_FAILED",
  IOTDEVICE_DELETE_REQUESTED: "IOTDEVICE_DELETE_REQUESTED",
  IOTDEVICE_DELETE_RECEIVED: "IOTDEVICE_DELETE_RECEIVED",

  IOTDEVICE_DATA_REQUESTED: "IOTDEVICE_DATA_REQUESTED",
  IOTDEVICE_DATA_RECEIVED: "IOTDEVICE_DATA_RECEIVED",

  IOTDEVICE_FILTER: "IOTDEVICE_FILTER",
  DEVICE_FILTER: "DEVICE_FILTER",

  USER_SELF_REQUESTED: "USER_SELF_REQUESTED",
  USER_SELF_RECEIVED: "USER_SELF_RECEIVED",
  USER_FETCH_ERROR: "USER_FETCH_ERROR",
  USER_RESET_ERROR: "USER_RESET_ERROR",

  USER_RESET: "USER_RESET",
  USER_REGISTER_REQUESTED: "USER_REGISTER_REQUESTED",
  USER_REGISTER_SUCCESS: "USER_REGISTER_SUCCESS",
  USER_REGISTER_ERROR: "USER_REGISTER_ERROR",

  USER_RESETPWD_REQUESTED: "USER_RESETPWD_REQUESTED",
  USER_RESETPWD_SUCCESS: "USER_RESETPWD_SUCCESS",
  USER_RESETPWD_ERROR: "USER_RESETPWD_ERROR",

  CUSTOMER_RESET: "CUSTOMER_RESET",
  CUSTOMER_LIST_REQUESTED: "CUSTOMER_LIST_REQUESTED",
  CUSTOMER_LIST_RECEIVED: "CUSTOMER_LIST_RECEIVED",
  CUSTOMER_LIST_FAILED: "CUSTOMER_LIST_FAILED",
  CUSTOMER_RESET_ERROR: "CUSTOMER_RESET_ERROR",

  CUSTOMER_ADD_FAILED: "CUSTOMER_ADD_FAILED",
  CUSTOMER_ADD_REQUESTED: "CUSTOMER_ADD_REQUESTED",
  CUSTOMER_ADD_RECEIVED: "CUSTOMER_ADD_RECEIVED",

  CUSTOMER_MODIFY_FAILED: "CUSTOMER_MODIFY_FAILED",
  CUSTOMER_MODIFY_REQUESTED: "CUSTOMER_MODIFY_REQUESTED",
  CUSTOMER_MODIFY_RECEIVED: "CUSTOMER_MODIFY_RECEIVED",

  CUSTOMER_DELETE_FAILED: "CUSTOMER_DELETE_FAILED",
  CUSTOMER_DELETE_REQUESTED: "CUSTOMER_DELETE_REQUESTED",
  CUSTOMER_DELETE_RECEIVED: "CUSTOMER_DELETE_RECEIVED",

  SITE_RESET: "SITE_RESET",
  SITE_LIST_REQUESTED: "SITE_LIST_REQUESTED",
  SITE_LIST_RECEIVED: "SITE_LIST_RECEIVED",
  SITE_LIST_FAILED: "SITE_LIST_FAILED",
  SITE_RESET_ERROR: "SITE_RESET_ERROR",

  SITE_ADD_FAILED: "SITE_ADD_FAILED",
  SITE_ADD_REQUESTED: "SITE_ADD_REQUESTED",
  SITE_ADD_RECEIVED: "SITE_ADD_RECEIVED",

  SITE_MODIFY_FAILED: "SITE_MODIFY_FAILED",
  SITE_MODIFY_REQUESTED: "SITE_MODIFY_REQUESTED",
  SITE_MODIFY_RECEIVED: "SITE_MODIFY_RECEIVED",

  SITE_DELETE_FAILED: "SITE_DELETE_FAILED",
  SITE_DELETE_REQUESTED: "SITE_DELETE_REQUESTED",
  SITE_DELETE_RECEIVED: "SITE_DELETE_RECEIVED",

  SITE_SELECTED: "SITE_SELECTED",

  AREA_RESET: "AREA_RESET",
  AREA_LIST_REQUESTED: "AREA_LIST_REQUESTED",
  AREA_LIST_RECEIVED: "AREA_LIST_RECEIVED",
  AREA_LIST_FAILED: "AREA_LIST_FAILED",
  AREA_RESET_ERROR: "AREA_RESET_ERROR",

  AREA_ADD_FAILED: "AREA_ADD_FAILED",
  AREA_ADD_REQUESTED: "AREA_ADD_REQUESTED",
  AREA_ADD_RECEIVED: "AREA_ADD_RECEIVED",

  AREA_MODIFY_FAILED: "AREA_MODIFY_FAILED",
  AREA_MODIFY_REQUESTED: "AREA_MODIFY_REQUESTED",
  AREA_MODIFY_RECEIVED: "AREA_MODIFY_RECEIVED",

  AREA_DELETE_FAILED: "AREA_DELETE_FAILED",
  AREA_DELETE_REQUESTED: "AREA_DELETE_REQUESTED",
  AREA_DELETE_RECEIVED: "AREA_DELETE_RECEIVED",

  AREA_SELECT: "AREA_SELECT",
  FLOOR_SELECT: "FLOOR_SELECT",
  AREA_FLOORS: "AREA_FLOORS",

  MODEL_LIST_REQUESTED: "MODEL_LIST_REQUESTED",
  MODEL_LIST_RECEIVED: "MODEL_LIST_RECEIVED",
  MODEL_LIST_FAILED: "MODEL_LIST_FAILED",

  MODEL_ADD_FAILED: "MODEL_ADD_FAILED",
  MODEL_ADD_REQUESTED: "MODEL_ADD_REQUESTED",
  MODEL_ADD_RECEIVED: "MODEL_ADD_RECEIVED",

  MODEL_MODIFY_FAILED: "MODEL_MODIFY_FAILED",
  MODEL_MODIFY_REQUESTED: "MODEL_MODIFY_REQUESTED",
  MODEL_MODIFY_RECEIVED: "MODEL_MODIFY_RECEIVED",

  MODEL_DELETE_FAILED: "MODEL_DELETE_FAILED",
  MODEL_DELETE_REQUESTED: "MODEL_DELETE_REQUESTED",
  MODEL_DELETE_RECEIVED: "MODEL_DELETE_RECEIVED",

  FAULT_LIST_FAILED: "FAULT_LIST_FAILED",
  FAULT_LIST_REQUESTED: "FAULT_LIST_REQUESTED",
  FAULT_LIST_RECEIVED: "FAULT_LIST_RECEIVED",
  ACTIVE_LIST_RECEIVED: "ACTIVE_LIST_RECEIVED",

  FAULT_ADD_FAILED: "FAULT_ADD_FAILED",
  FAULT_ADD_REQUESTED: "FAULT_ADD_REQUESTED",
  FAULT_ADD_RECEIVED: "FAULT_ADD_RECEIVED",
  FAULT_RESET: "FAULT_RESET",

  PRESETS_REQUESTED: "PRESETS_REQUESTED",
  PRESETS_RECEIVED: "PRESETS_RECEIVED",
  PRESETS_FAILED: "PRESETS_FAILED",

  PRESET_ADD_FAILED: "PRESET_ADD_FAILED",
  PRESET_ADD_REQUESTED: "PRESET_ADD_REQUESTED",
  PRESET_ADD_RECEIVED: "PRESET_ADD_RECEIVED",

  PRESETS_LIGHTS_RECEIVED: "PRESETS_LIGHTS_RECEIVED",

  PRESET_MODIFY_FAILED: "PRESET_MODIFY_FAILED",
  PRESET_MODIFY_REQUESTED: "PRESET_MODIFY_REQUESTED",
  PRESET_MODIFY_RECEIVED: "PRESET_MODIFY_RECEIVED",

  PRESET_DELETE_REQUESTED: "PRESET_DELETE_REQUESTED",
  PRESET_DELETE_SUCCESS: "PRESET_DELETE_SUCCESS",
  PRESET_DELETE_FAILURE: "PRESET_DELETE_FAILURE",

  PRESET_REMOVE_DEVICE: "PRESET_REMOVE_DEVICE",

  COMMAND_REQUESTED: "COMMAND_REQUESTED",

  COMMAND_SUCCESS: "COMMAND_SUCCESS",
  COMMAND_FAILED: "COMMAND_FAILED",

  COMMAND_RESET: "COMMAND_RESET",

  DASHBOARD_RESET: "DASHBOARD_RESET",
  DASHBOARD_LIST_REQUESTED: "DASHBOARD_LIST_REQUESTED",
  DASHBOARD_LIST_RECEIVED: "DASHBOARD_LIST_RECEIVED",
  DASHBOARD_LIST_FAILED: "DASHBOARD_LIST_FAILED",
  DASHBOARD_RESET_ERROR: "DASHBOARD_RESET_ERROR",

  DASHBOARD_ADD_FAILED: "DASHBOARD_ADD_FAILED",
  DASHBOARD_ADD_REQUESTED: "DASHBOARD_ADD_REQUESTED",
  DASHBOARD_ADD_RECEIVED: "DASHBOARD_ADD_RECEIVED",

  DASHBOARD_MODIFY_FAILED: "DASHBOARD_MODIFY_FAILED",
  DASHBOARD_MODIFY_REQUESTED: "DASHBOARD_MODIFY_REQUESTED",
  DASHBOARD_MODIFY_RECEIVED: "DASHBOARD_MODIFY_RECEIVED",

  DASHBOARD_DELETE_FAILED: "DASHBOARD_DELETE_FAILED",
  DASHBOARD_DELETE_REQUESTED: "DASHBOARD_DELETE_REQUESTED",
  DASHBOARD_DELETE_RECEIVED: "DASHBOARD_DELETE_RECEIVED",

  DASHBOARD_DEVICE_MODIFY_RECEIVED: "DASHBOARD_DEVICE_MODIFY_RECEIVED",
  DASHBOARD_DEVICE_ADD_RECEIVED: "DASHBOARD_DEVICE_ADD_RECEIVED",
  DASHBOARD_DEVICE_LIST_RECEIVED: "DASHBOARD_DEVICE_LIST_RECEIVED",
  DASHBOARD_DEVICE_DELETE_RECEIVED: "DASHBOARD_DEVICE_DELETE_RECEIVED",

  DASHBOARD_SET_LINEWIDGET: "DASHBOARD_SET_LINEWIDGET",

  DEVICEDATA_DATA_REQUESTED: "DEVICEDATA_DATA_REQUESTED",
  DEVICEDATA_DATA_RECEIVED: "DEVICEDATA_DATA_RECEIVED",
  DEVICEDATA_DATALIST_REQUESTED: "DEVICEDATA_DATALIST_REQUESTED",
  DEVICEDATA_DATALIST_RECEIVED: "DEVICEDATA_DATALIST_RECEIVED",
  DEVICEDATA_MODIFY_RECEIVED: "DEVICEDATA_MODIFY_RECEIVED",
  DEVICEDATA_ADD_RECEIVED: "DEVICEDATA_ADD_RECEIVED",
  DEVICEDATA_LIST_REQUESTED: "DEVICEDATA_LIST_REQUESTED",
  DEVICEDATA_LIST_RECEIVED: "DEVICEDATA_LIST_RECEIVED",
  DEVICEDATA_SET_FILTER: "DEVICEDATA_SET_FILTER",
  DEVICEDATA_ADD_FROM_BUFFER_RECEIVED: "DEVICEDATA_ADD_FROM_BUFFER_RECEIVED",
  DEVICEDATA_RESET: "DEVICEDATA_RESET",
  DEVICEDATA_DEVICE_FILTER_HOURS_MODIFY: "DEVICEDATA_DEVICE_FILTER_HOURS_MODIFY",

  LIMIT_LIST_REQUESTED: "LIMIT_LIST_REQUESTED",
  LIMIT_ADD_RECEIVED: "LIMIT_ADD_RECEIVED",
  LIMIT_LIST_RECEIVED: "LIMIT_LIST_RECEIVED",
  LIMIT_LIST_MODIFYED: "LIMIT_LIST_MODIFYED",
  LIMIT_DELETE: "LIMIT_DELETE",

  FAVOURITEWIDGET_LIST_REQUESTED: "FAVOURITEWIDGET_LIST_REQUESTED",
  FAVOURITEWIDGET_ADD_RECEIVED: "FAVOURITEWIDGET_ADD_RECEIVED",
  FAVOURITEWIDGET_LIST_RECEIVED: "FAVOURITEWIDGET_LIST_RECEIVED",
  FAVOURITEWIDGET_DELETE: "FAVOURITEWIDGET_DELETE",

  TRIALTOKEN_SHOWTRIAL: "TRIALTOKEN_SHOWTRIAL",
  TRIALTOKEN_REQUESTED: "TRIALTOKEN_REQUESTED",
  TRIALTOKEN_CLOSETRIAL: "TRIALTOKEN_CLOSETRIAL",
  TRIALTOKENLIST_RECEIVED: "TRIALTOKENLIST_RECEIVED",
  TRIALTOKEN_RESET_ERROR: "TRIALTOKEN_RESET_ERROR",
  TRIALTOKEN_SUCCESS: "TRIALTOKEN_SUCCESS",
  TRIALTOKEN_RESET: "TRIALTOKEN_RESET",
  TRIALTOKEN_ERROR: "TRIALTOKEN_ERROR"
}
