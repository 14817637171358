export default (
  state = {
    areaList: [],
    isFetching: false,
    error: null,
    shouldRefresh: false,
    areaOpen: null,
    floorOpen: null,
    floorData: []
  },
  action
) => {
  switch (action.type) {
    case "AREA_LIST_REQUESTED":
    case "AREA_ADD_REQUESTED":
    case "AREA_UPDATE_REQUESTED":
    case "AREA_DELETE_REQUESTED":
    case "AREA_UPGRADE_REQUESTED":
      return {
        ...state,
        isFetching: true,
        shouldRefresh: false
      }
    case "AREA_MODIFY_RECEIVED": {
      let { areaList } = state
      const newList = areaList.map(area => {
        if (area._id === action.area._id) {
          return action.area
        } else {
          return area
        }
      })

      return {
        ...state,
        isFetching: false,
        area: action.area,
        areaList: newList,
        error: null
      }
    }
    case "AREA_ADD_RECEIVED": {
      const { areaList } = state
      const newList = [...areaList, action.area]
      return {
        ...state,
        isFetching: false,
        area: action.area,
        areaList: newList,
        error: null
      }
    }
    case "AREA_LIST_RECEIVED":
      return {
        ...state,
        isFetching: false,
        shouldRefresh: false,
        areaList: action.areaList,
        lastUpdated: Date.now()
      }
    case "AREA_DELETE_RECEIVED": {
      let { areaList } = state

      let newList = areaList.filter(function(item) {
        if (item._id == action.areaId) {
          return false
        } else {
          return true
        }
      })
      return {
        ...state,
        isFetching: false,
        site: action.site,
        areaList: newList,
        areaOpen: null,
        error: null
      }
    }
    case "AREA_AJAX_SUCCESS":
      return { ...state, isFetching: false }

    case "AREA_AJAX_ERROR":
      return {
        ...state,
        isFetching: false,
        shouldRefresh: true,
        error: action.error
      }

    case "AREA_RESET_ERROR":
      return { ...state, error: null }

    case "AREA_SELECT":
      return { ...state, areaOpen: action.areaOpen }

    case "FLOOR_SELECT":
      return { ...state, floorOpen: action.floorOpen }

    case "AREA_FLOORS":
      return { ...state, floorData: action.floorData }
    default:
      return state
  }
}
