import axios from "axios"
import { getApiUrl } from "../constants/config"
import { parseErrorData } from "../services/errors"
import { authHeader } from "./common"
import { stateType } from "../constants/actions"
/*
LIMIT_LIST_REQUESTED
LIMIT_MODIFY_REQUESTED
LIMIT_ADD_REQUESTED
LIMIT_ADD_RECEIVED
LIMIT_LIST_RECEIVED
LIMIT_LIST_MODIFYED
*/
const limitRequestFailed = (error) => ({
  type: stateType.SET_ERROR,
  error
})

const limitListRequested = () => ({
  type: stateType.LIMIT_LIST_REQUESTED
})

const limitListReceived = (limitList) => ({
  type: stateType.LIMIT_LIST_RECEIVED,
  limitList
})

const limitAddReceived = (limit) => ({
  type: stateType.LIMIT_ADD_RECEIVED,
  limit
})

export const fetchLimits = (limitId) => async (dispatch, getState) => {
  try {
    dispatch(limitListRequested())
    let limitUrl = getApiUrl() + "/api/limits"

    if (limitId) {
      limitUrl = limitUrl + "/" + limitId
    }

    const state = getState()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }

    const { data } = await axios.get(limitUrl, authHeader(useToke))

    let limitList = null
    if (!data || !data.success || !data.limits) {
      throw (data && data.error) || "Failed to fetch faults"
    }

    limitList = data.limits

    return Promise.resolve(dispatch(limitListReceived(limitList)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(limitRequestFailed(errorReply)))
  }
}

export const addLimits = (newLimit) => async (dispatch, getState) => {
  try {
    dispatch(limitListRequested())
    let limitUrl = getApiUrl() + "/api/limits"

    const state = getState()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }
    const { data } = await axios.post(limitUrl, newLimit, authHeader(useToke))
    let limit = null

    if (!data || !data.success || !data.limit) {
      throw (data && data.error) || "Failed to fetch"
    }

    limit = data.limit

    return Promise.resolve(dispatch(limitAddReceived(limit)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(limitRequestFailed(errorReply)))
  }
}

export const modifyLimit = (modlimit) => async (dispatch, getState) => {
  try {
    dispatch(limitListRequested())
    let limitUrl = getApiUrl() + "/api/limits/"

    const state = getState()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }
    const { data } = await axios.put(
      limitUrl + modlimit._id,
      modlimit,
      authHeader(useToke)
    )
    let limit = null

    if (!data || !data.success || !data.limit) {
      throw (data && data.error) || "Failed to fetch"
    }

    limit = data.limit

    return Promise.resolve(dispatch(limitAddReceived(limit)))
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(limitRequestFailed(errorReply)))
  }
}

export const deleteLimit = (dellimit) => async (dispatch, getState) => {
  try {
    dispatch(limitListRequested())
    let limitUrl = getApiUrl() + "/api/limits/"

    const state = getState()

    let useToke = ""
    if (state && state.auth && state.auth.token) {
      useToke = state.auth.token
    }
    const { data } = await axios.delete(
      limitUrl + dellimit._id,
      dellimit,
      authHeader(useToke)
    )
    // let limit = null

    if (!data || !data.success) {
      throw (data && data.error) || "Failed to delete"
    }

    return Promise.resolve()
  } catch (err) {
    const errorReply = parseErrorData(err)
    return Promise.reject(dispatch(limitRequestFailed(errorReply)))
  }
}
