import * as React from "react"
import useTheme from "@material-ui/core/styles/useTheme"
import { MapMarker } from "../SvgLib"

const MapDeviceMarker = props => {
  const {
    alerts,
    unplaced,
    clicked,
    $hover,
    selected,
    iconSize,
    name,
    latestData,
    onMouseEnter,
    onMouseLeave,
    tooltip
  } = props

  const theme = useTheme()

  const handleClick = (e) => {
    clicked(e)
  }
  const size = iconSize - 10
  let scale = 1.3
  let width = size
  let height = size
  let left = -size / 2
  let top = -size

  if (selected) {
    width = width * scale
    height = height * scale
    left = left * scale
    top = top * scale
  }

  return (
    <div>
      <div
        style={{
          zIndex: $hover ? 120 : selected ? 100 : 20,
          position: "absolute",
          height: "fit-content",
          width: "max-content",
          padding: "3px",
          border: "1px solid black",
          borderRadius: "2px",
          backgroundColor: "white",
          color: "black",
          left: -19 - name.length * 2.5,
          top:  + top - 25,
          overflow: "visible"
        }}
      >
        { name + (latestData.temperature ? ( " " + latestData.temperature?.toFixed(2) + " °C" ) : "" ) }
      </div>

      <MapMarker
        color={
          alerts
            ? theme.palette.error.threatening
            : unplaced
              ? theme.palette.error.light
              : theme.palette.action.main
        }
        style={{
          zIndex: $hover ? 120 : selected ? 100 : 20,
          position: "absolute",
          height: height,
          width: width,
          left: left,
          top: top,
          overflow: "visible"
        }}
        iconName={"commonlight"}

        selected={selected}
        innerColor={theme.palette.secondary.main}
        onClick={handleClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      {tooltip && <div
        style={{
          zIndex: $hover ? 120 : selected ? 100 : 20,
          position: "absolute",
          height: "fit-content",
          width: "max-content",
          padding: "3px",
          border: "1px solid black",
          borderRadius: "2px",
          backgroundColor: "white",
          color: "black",
          left: -19 - name.length * 2.5,
          bottom:  -30,
          overflow: "visible"
        }}
      >
        {tooltip}

      </div>}
    </div>
  )
}

export default MapDeviceMarker
