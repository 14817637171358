import React from "react"

import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"
import { ChromePicker } from "react-color"
import Colorize from "@material-ui/icons/Colorize"
import FormLabel from "@material-ui/core/FormLabel"
import Checkbox from "@material-ui/core/Checkbox"

import { connect } from "react-redux"
import { tr } from "translations"
import { fetchCustomers, ModifyCustomers } from "../../../actions/customers"
import ProfileTextField from "./../../../components/elements/ProfileTextField"
import ImageInput from "./../../../components/elements/ImageInput"
import { ManageHeader } from "../../../components/manageMenu/manageHeader"
import { typeOptions} from "../../../devicetypes/typeOptions"
import moment from "moment"

class CompanyProfile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      customer: "",
      _id: "",
      name: "",
      www: "",
      address: "",
      zip: "",
      city: "",
      logo: "", // Logo currently used
      logoFile: null, // Logo to be uploaded
      banner: "",
      bannerFile: null,
      country: "",
      latitude: "",
      longitude: "",
      topBarColor: "",
      showFullFloor: false,
      showDevicesOnMap: false,
      error: null,
      formUpdated: false,
      isSending: false,
      topBarColorOpen: false,
      deviceTypes: []
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleLogoFileChanged = this.handleLogoFileChanged.bind(this)
    this.handleBannerFileChanged = this.handleBannerFileChanged.bind(this)
    this.resetBanner = this.resetBanner.bind(this)
    this.resetLogo = this.resetLogo.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleResetColor = this.handleResetColor.bind(this)
    this.updateAfterFetch = this.updateAfterFetch.bind(this)
    this.handleType = this.handleType.bind(this)
  }

  componentDidMount() {
    this.props.fetchCustomers().then(res => this.updateAfterFetch(res))
  }

  updateAfterFetch(res){
    const selectedCustomer = res.customerList[0]
    this.setState({
      selectedCustomer,
      customer: selectedCustomer._id || "",
      _id: selectedCustomer._id || "",
      name: selectedCustomer.name || "",
      www: selectedCustomer.www || "",
      address: selectedCustomer.address || "",
      zip: selectedCustomer.zip || "",
      city: selectedCustomer.city || "",
      logo: selectedCustomer.logo || "",
      banner: selectedCustomer.banner || "",
      showFullFloor: selectedCustomer.showFullFloor || false,
      showDevicesOnMap: selectedCustomer.showDevicesOnMap || false,
      country: selectedCustomer.country || "",
      latitude: selectedCustomer.latitude || "",
      longitude: selectedCustomer.longitude || "",
      topBarColor: selectedCustomer.topBarColor || "",
      trialToken: selectedCustomer.trialToken || undefined,
      deviceTypes: selectedCustomer.deviceTypes || []
    })
  }


  handleLogoFileChanged(logoFile) {
    this.setState({ logoFile, formUpdated: true })
  }
  handleBannerFileChanged(bannerFile) {
    this.setState({ bannerFile, formUpdated: true })
  }
  
  resetBanner(){
    this.setState({banner: "", bannerFile: null, formUpdated: true})
  }

  resetLogo(){
    this.setState({logo: "", logoFile: null, formUpdated: true})
  }

  handleType(type){
    let deviceTypes = [...this.state.deviceTypes]
    if(deviceTypes.includes(type)){
      deviceTypes = deviceTypes.filter((filterType) => filterType !== type)
    }
    else{
      deviceTypes.push(type)
    }
    this.setState({deviceTypes, formUpdated: true})
  }

  handleChange({ target }) {
    const newCustomerList = Object.assign([], this.props.customerList)
    const selectedCustomer = newCustomerList.filter(
      customer => customer._id === target.value
    )

    let newState = {
      [target.name]: target.value,
      formUpdated: true
    }

    switch (target.name) {
      case "customer":
        newState._id =
          selectedCustomer[0] && selectedCustomer[0]._id
            ? selectedCustomer[0]._id
            : ""
        newState.name =
          selectedCustomer[0] && selectedCustomer[0].name
            ? selectedCustomer[0].name
            : ""
        newState.www =
          selectedCustomer[0] && selectedCustomer[0].www
            ? selectedCustomer[0].www
            : ""
        newState.address =
          selectedCustomer[0] && selectedCustomer[0].address
            ? selectedCustomer[0].address
            : ""
        newState.zip =
          selectedCustomer[0] && selectedCustomer[0].zip
            ? selectedCustomer[0].zip
            : ""
        newState.city =
          selectedCustomer[0] && selectedCustomer[0].city
            ? selectedCustomer[0].city
            : ""
        newState.latitude =
          selectedCustomer[0] && selectedCustomer[0].latitude
            ? selectedCustomer[0].latitude
            : ""
        newState.longitude =
          selectedCustomer[0] && selectedCustomer[0].longitude
            ? selectedCustomer[0].longitude
            : ""
        newState.topBarColor =
        selectedCustomer[0] && selectedCustomer[0].topBarColor
          ? selectedCustomer[0].topBarColor
          : ""
        newState.deviceTypes =
        selectedCustomer[0] && selectedCustomer[0].deviceTypes
          ? selectedCustomer[0].deviceTypes
          : ""
        newState.logo =
        selectedCustomer[0] && selectedCustomer[0].logo
          ? selectedCustomer[0].logo
          : ""
        newState.banner =
        selectedCustomer[0] && selectedCustomer[0].banner
          ? selectedCustomer[0].banner
          : ""
        newState.showFullFloor =
        selectedCustomer[0] && selectedCustomer[0].showFullFloor
          ? selectedCustomer[0].showFullFloor
          : false
        newState.showDevicesOnMap =
        selectedCustomer[0] && selectedCustomer[0].showDevicesOnMap
          ? selectedCustomer[0].showDevicesOnMap
          : false
          
        break
    }

    this.setState(newState)
  }
  handleResetColor(){
    const formUpdated = this.state.topBarColor !== ""
    this.setState({ topBarColor : "", formUpdated })
  }

  handleSubmit() {
    const {
      _id,
      name,
      www,
      address,
      zip,
      city,
      logo,
      logoFile,
      banner,
      bannerFile,
      country,
      latitude,
      longitude,
      topBarColor,
      showFullFloor,
      showDevicesOnMap,
      deviceTypes
    } = this.state
    //console.log('handleSubmit', _id, name, www,address, zip, city, country, latitude, longitude)
    this.setState({ isSending: true }, () => {
      this.props
        .ModifyCustomers({
          _id,
          name,
          www,
          address,
          zip,
          city,
          logo,
          logoFile,
          banner,
          bannerFile,
          country,
          latitude,
          longitude,
          topBarColor,
          showFullFloor,
          showDevicesOnMap,
          deviceTypes
        })
        .then(() => {
          this.props.fetchCustomers().then(res => this.updateAfterFetch(res))

          this.setState({
            error: null,
            formUpdated: false,
            isSending: false
          })
        })
    })
  }

  handleCancel() {
    const selectedCustomer = this.props.customerList[0]
    this.setState({
      selectedCustomer,
      customer: selectedCustomer._id || "",
      _id: selectedCustomer._id || "",
      name: selectedCustomer.name || "",
      www: selectedCustomer.www || "",
      address: selectedCustomer.address || "",
      zip: selectedCustomer.zip || "",
      city: selectedCustomer.city || "",
      logo: selectedCustomer.logo || "",
      banner: selectedCustomer.banner || "",
      country: selectedCustomer.country || "",
      latitude: selectedCustomer.latitude || "",
      longitude: selectedCustomer.longitude || "",
      topBarColor: selectedCustomer.topBarColor || "",
      deviceTypes: selectedCustomer.deviceTypes || [],
      showFullFloor: selectedCustomer.showFullFloor || false,
      showDevicesOnMap: selectedCustomer.showDevicesOnMap || false,
      formUpdated: false
    })
  }

  render() {
    const { classes } = this.props
    const {
      formUpdated,
      name,
      www,
      address,
      city,
      logo,
      banner,
      zip,
      country,
      latitude,
      longitude,
      trialToken,
      topBarColor,
      topBarColorOpen,
      showFullFloor,
      showDevicesOnMap,
      deviceTypes
    } = this.state

    return (
      <div className={classes.root}>
        <ManageHeader title={tr("_companyInfo")} />
        <Grid
          container
          spacing={0}
          className={classes.formArea}
          justify="center"
        >
          {/* Header and cancel/save buttons */}
          <Grid item xs={10}>
            <Grid container direction="row" justify="flex-end">
              {/* <Grid item>
                <Typography variant={"h5"} className={classes.pageTitle}>
                  {tr("_companyInfo")}
                </Typography>
              </Grid> */}

              <Grid item>
                <Grid
                  container
                  style={{ height: "100%" }}
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      className={classes.button}
                      onClick={this.handleCancel}
                    >
                      {tr("_cancel")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      className={classes.saveButton}
                      disabled={!formUpdated}
                      onClick={this.handleSubmit}
                    >
                      {tr("_save")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Company selection dropdown not part of UI sketches. */}
          {this.props.role === "superAdmin" ? (
            <Grid container spacing={0} className={classes.formArea}>
              <Grid item xs={1} />
              <Grid item xs={10}>
                <Grid container spacing={3}>
                  <React.Fragment>
                    <Grid item xs={12} md={6} lg={4}>
                      <FormControl className={classes.textField}>
                        <InputLabel
                          classes={{
                            root: classes.inputRoot,
                            focused: classes.inputFocus
                          }}
                          htmlFor="company-controlled-open-select"
                        >
                          {tr("_select_company")}
                        </InputLabel>
                        <Select
                          required
                          value={this.state.customer}
                          onChange={this.handleChange}
                          inputProps={{
                            name: "customer",
                            id: "company-controlled-open-select"
                          }}
                        >
                          {this.props.customerList
                            ? this.props.customerList.map(customer => {
                                return (
                                  <MenuItem
                                    value={customer._id}
                                    key={customer._id}
                                  >
                                    {customer.name}
                                  </MenuItem>
                                )
                              })
                            : ""}
                        </Select>
                      </FormControl>
                    </Grid>
                  </React.Fragment>
                </Grid>
              </Grid>
            </Grid>
          ) : null}

          <Grid container spacing={0} className={classes.formArea}>
            <Grid item xs={1} />
            <Grid item xs={10}>
              <Grid container spacing={3}>
                {/* Row 1: Company name and web page */}
                <Grid item xs={12} md={3}>
                  <ProfileTextField
                    id="outlined-name"
                    fieldName="name"
                    fieldLabel={tr("_company_name")}
                    value={name || ""}
                    onChange={this.handleChange}
                    // {...this.props}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <ProfileTextField
                    id="outlined-www"
                    fieldName="www"
                    fieldLabel={tr("_www_page")}
                    value={www || ""}
                    onChange={this.handleChange}
                    // {...this.props}
                  />
                </Grid>
              </Grid>

              {/* Row 2: Address, postal code, city, country */}
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                  <ProfileTextField
                    id="outlined-address"
                    fieldName="address"
                    fieldLabel={tr("_address")}
                    value={address || ""}
                    onChange={this.handleChange}
                    // {...this.props}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <ProfileTextField
                    id="outlined-zip"
                    fieldName="zip"
                    fieldLabel={tr("_postal_code")}
                    value={zip || ""}
                    onChange={this.handleChange}
                    // {...this.props}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <ProfileTextField
                    id="outlined-city"
                    fieldName="city"
                    fieldLabel={tr("_city")}
                    value={city || ""}
                    onChange={this.handleChange}
                    // {...this.props}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <ProfileTextField
                    id="outlined-country"
                    fieldName="country"
                    fieldLabel={tr("_country")}
                    value={country || ""}
                    onChange={this.handleChange}
                    // {...this.props}
                  />
                </Grid>
              </Grid>

              {/* Latitude/longitude */}
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                  <ProfileTextField
                    id="oulined-latitude"
                    fieldName="latitude"
                    fieldLabel={tr("_latitude")}
                    value={latitude || ""}
                    onChange={this.handleChange}
                    // {...this.props}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <ProfileTextField
                    id="outlined-longitude"
                    fieldName="longitude"
                    fieldLabel={tr("_longitude")}
                    value={longitude || ""}
                    onChange={this.handleChange}
                    // {...this.props}
                  />
                </Grid>
              </Grid>

              {/* Show full floor plan */}
              <Grid container spacing={3}>
                <Grid item >

                  <FormLabel
                    component="legend"
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                    classes={{
                      root: classes.inputRoot,
                      focused: classes.inputFocus
                    }}
                  >
                    {tr("_floor_groundplan_overview")}
                  </FormLabel>

                  <Checkbox
                    color="default"
                    className={classes.checkbox}
                    id="floorOverview"
                    checked={showFullFloor}
                    onChange={() => this.handleChange({ target: { name: "showFullFloor", value: !showFullFloor } })}
                  />
                  <label htmlFor="floorOverview" className={classes.checkBoxLabel}>
                    {tr("_show")}
                  </label>
                </Grid>
                <Grid item >

                  <FormLabel
                    component="legend"
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                    classes={{
                      root: classes.inputRoot,
                      focused: classes.inputFocus
                    }}
                  >
                    {tr("_show_devices_on_map")}
                  </FormLabel>

                  <Checkbox
                    color="default"
                    className={classes.checkbox}
                    id="devicesOnMap"
                    checked={showDevicesOnMap}
                    onChange={() => this.handleChange({ target: { name: "showDevicesOnMap", value: !showDevicesOnMap } })}
                  />
                  <label htmlFor="devicesOnMap" className={classes.checkBoxLabel}>
                    {tr("_show")}
                  </label>
                </Grid>
                
              </Grid>

              {/* Upload logo & banner */}
              <Grid container spacing={3} className={classes.imageInput}>

                <Grid item xs={12} md={6} lg={5}>
                  <FormLabel
                    component="legend"
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                    classes={{
                      root: classes.inputRoot,
                      focused: classes.inputFocus
                    }}
                  >
                    {tr("_upload_logo")}
                  </FormLabel>
                  <ImageInput defaultImage={logo} onReset={this.resetLogo} onFileChanged={this.handleLogoFileChanged}/>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <FormLabel
                    component="legend"
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                    classes={{
                      root: classes.inputRoot,
                      focused: classes.inputFocus
                    }}
                  >
                    {tr("_upload_banner")}
                  </FormLabel>
                  <ImageInput defaultImage={banner} onReset={this.resetBanner} previewImgStyle={{width: "120px"}} onFileChanged={this.handleBannerFileChanged}/>
                </Grid>

              </Grid>

              {/* Top Bar Color */}
              <Grid container spacing={3}>
                <Grid item>
                  <Grid >
                    <FormLabel
                      component="legend"
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                      classes={{
                        root: classes.inputRoot,
                        focused: classes.inputFocus
                      }}
                    >
                      {tr("_topBarColor")}
                    </FormLabel>
                  </Grid>
                  <Grid container direction="row" spacing={2}>
                    <Grid item >
                      <Button
                        variant="outlined"
                        className={classes.colorButton}
                        onClick={() => this.setState({ topBarColorOpen: !topBarColorOpen })}
                        style={{
                          backgroundColor: topBarColor
                        }}
                      >
                        <Colorize className={classes.icon} />
                      </Button>
                    </Grid>
                    {topBarColor && 
                    <Grid item >
                      <Button
                        variant="contained"
                        className={classes.button}
                        onClick={this.handleResetColor}
                      >
                        {tr("_reset")}
                      </Button>
                    </Grid>}
                  </Grid>
                </Grid>
              </Grid>
              
              { topBarColorOpen && 
              <ChromePicker 
                color={topBarColor}
                disableAlpha={true}
                onChangeComplete={(val) => this.handleChange({ target: { name: "topBarColor", value: val.hex } })}
                style={{ minWidth: 100, maxWidth: 130 }}
              /> 
              }

              {/* Device type limitations */}
              {this.props.role === "superAdmin" && (
                <Grid container spacing={3} className={classes.formArea} style={{marginTop: "15px"}}>
                  <Grid item xs={12} md={6}>
                    <FormLabel
                      component="legend"
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                      classes={{
                        root: classes.inputRoot,
                        focused: classes.inputFocus
                      }}
                    >
                      {tr("_show_device_types")}
                    </FormLabel>
                    <Grid
                      item
                      xs={12}
                      container
                      className={classes.buttons}
                      justify="flex-start"
                    >
                      {typeOptions.map((type, index) => {
                        return (
                          <Button
                            variant="outlined"
                            className={deviceTypes.includes(type) ? classes.selectedButton : classes.unSelectedButton}
                            key={index}
                            id={type}
                            onClick={() => this.handleType(type)}
                            style={{
                              margin: "5px",
                              marginRight: "10px",
                              marginLeft: 0,
                              fontSize: "12px"
                            }}
                          >
                            {type}
                          </Button>
                        )
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
              
              
            </Grid>
          </Grid>
          {/* End form area */}
        </Grid>
        {trialToken && <ManageHeader title={tr("_trialCompany")} />}
        {trialToken && (
          <Grid
            container
            spacing={0}
            className={classes.formArea}
            justify="center"
          >
            <Grid container spacing={0} className={classes.formArea}>
              <Grid item xs={1} />
              <Grid container item xs={10}>
                <Grid item xs={12} md={12}>
                  <Typography variant="body2" color="textPrimary">
                    {tr("_trialDescription")}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.makeroomTop} md={3}>
                  <Typography variant="body2" color="textPrimary">
                    {tr("_trialCode") + ": " + trialToken.trialToken}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.makeroomTop} md={3}>
                  <Typography variant="body2" color="textPrimary">
                    {tr("_expires") +
                      ": " +
                      moment(trialToken.willExpireIn).format("DD/MM/YYYY")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    )
  }
}

const styles = theme => ({
  formAreaButton: {
    paddingTop: "4rem"
  },
  pageTitle: {
    display: "inline-block",
    paddingBottom: "3rem",
    paddingTop: "2rem",
    fontWeight: "bold"
  },
  makeroomTop: {
    marginTop: 12
  },
  root: {
    flexGrow: 1,
    height: "100vh",
    backgroundImage: theme.backgroundImage,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed"
  },
  saveButton: {
    float: "right",
    backgroundColor: theme.palette.action.main,
    color: theme.palette.button.caption
  },
  button: {
    backgroundColor: theme.palette.button.default,
    color: theme.palette.button.caption
  },
  colorButton: {
    //height: 25
  },
  divider: {
    height: "4rem"
  },
  // "Logo upload"
  subheading: {
    fontWeight: "bold",
    fontSize: "16px",
    marginBottom: theme.spacing(1),
    color: theme.palette.text.primary
  },
  // For text fields
  textField: {
    marginTop: "5px",
    marginBottom: "1rem",
    width: "100%",
    textColor: theme.palette.text.field
  },
  inputColor: {
    color: theme.palette.text.primary
  },
  checkBoxLabel: {
    fontSize: "16px",
    verticalAlign: "middle"
  },
  checkbox: {
    color: theme.palette.action.main
  },
  labelColor: {
    color: theme.palette.text.field,
    fontWeight: "bold"
  },
  inputRoot: {
    color: theme.palette.text.field + " !important"
  },
  inputFocus: {
    color: theme.palette.text.warning + " !important"
  },
  imageInput:{
    marginBottom: theme.spacing(2),
  },
  underline: {
    "&::before": {
      color: theme.palette.text.primary
    },
    "&::hover": {
      borderBottom: "2px solid " + theme.palette.text.primary
    },
    "&::after": {
      borderBottom: "2px solid " + theme.palette.text.warning
    }
  },
  selectedButton: {
    backgroundColor: theme.palette.action.main
  },
  unSelectedButton: {
    backgroundColor: "gray"
  }
})

const mapDispatchToProps = dispatch => ({
  fetchCustomers: () => dispatch(fetchCustomers()),
  ModifyCustomers: customer => dispatch(ModifyCustomers(customer))
})

const mapStateToProps = state => {
  return {
    customerList: state.customers.customerList,
    role: state.auth.role,
    siteList: state.sites.siteList
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(CompanyProfile))
