class DeviceDataBuffer {
  constructor() {
    this.getWebsocketBuffer = this.getWebsocketBuffer.bind(this)
    this.clear = this.clear.bind(this)
    this.add = this.add.bind(this)
  }
  getWebsocketBuffer() {
    if (!this.bufferArray) {
      this.bufferArray = []
    }
    return this.bufferArray
  }
  clear() {
    this.bufferArray = []
  }
  add(data, deviceId) {
    if (data && deviceId !== null && deviceId !== undefined) {
      const buffer = this.getWebsocketBuffer()
      buffer.push({ device: data, deviceId })
    }
  }
}
export default new DeviceDataBuffer()
