import { getLanguage, tr } from "../translations"

export function convertDate(dt) {
  const date = new Date(dt)
  const days = date.getDate() > 9 ? "" + date.getDate() : "0" + date.getDate()
  const months =
    date.getMonth() + 1 > 9
      ? "" + (date.getMonth() + 1)
      : "0" + (date.getMonth() + 1)
  return days + "." + months + "." + date.getFullYear()
}

export function convertTime(dt) {
  const date = new Date(dt)
  const hours =
    date.getHours() > 9 ? "" + date.getHours() : "0" + date.getHours()
  const minutes =
    date.getMinutes() > 9 ? "" + date.getMinutes() : "0" + date.getMinutes()
  return hours + ":" + minutes
}

export function convertCurrentTimeToObject() {
  const date = new Date()
  const hours =
    date.getHours() > 9 ? "" + date.getHours() : "0" + date.getHours()
  const minutes =
    date.getMinutes() > 9 ? "" + date.getMinutes() : "0" + date.getMinutes()
  return { h: hours, m: minutes }
}

export function convertTimeToObject(date) {
  if (!date) {
    return { h: "--", m: "--" }
  }
  const hours =
    date.getHours() > 9 ? "" + date.getHours() : "0" + date.getHours()
  const minutes =
    date.getMinutes() > 9 ? "" + date.getMinutes() : "0" + date.getMinutes()
  //console.log('convertTimeToObject: ', date, hours, minutes)
  return { h: hours, m: minutes }
}

export function convertMinutesToObject(totalMinutes) {
  const fullHours = Math.floor(totalMinutes / 60)
  const hours = fullHours > 9 ? "" + fullHours : "0" + fullHours
  const remainingMinutes = totalMinutes - hours * 60
  const minutes =
    remainingMinutes > 9 ? "" + remainingMinutes : "0" + remainingMinutes
  return { h: hours, m: minutes }
}

export function convertDateAndTime(dt) {
  const date = new Date(dt)
  const days = date.getDate() > 9 ? "" + date.getDate() : "0" + date.getDate()
  const months =
    date.getMonth() + 1 > 9
      ? "" + (date.getMonth() + 1)
      : "0" + (date.getMonth() + 1)
  const hours =
    date.getHours() > 9 ? "" + date.getHours() : "0" + date.getHours()
  const minutes =
    date.getMinutes() > 9 ? "" + date.getMinutes() : "0" + date.getMinutes()
  return (
    days +
    "." +
    months +
    "." +
    date.getFullYear() +
    " - " +
    hours +
    ":" +
    minutes
  )
}

export function isToday(d) {
  const today = new Date()
  const date = new Date(d)
  if (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() &&
    today.getFullYear()
  )
    return true
  else return false
}

export function getWeekNumbers(month) {
  const now = new Date()
  let dateNumber = 1
  let weekNumbers = []
  let sameMonth = true
  while (sameMonth) {
    const dayOfMonth = new Date(now.getFullYear(), month, dateNumber)
    if (dayOfMonth.getMonth() === month) {
      const firstOfJan = new Date(now.getFullYear(), 0, 1)
      const week = Math.ceil(
        ((dayOfMonth - firstOfJan) / 86400000 + firstOfJan.getDay() + 1) / 7
      )
      weekNumbers.push(week)
      dateNumber = dateNumber + 7
    } else sameMonth = false
  }
  return weekNumbers
}

export function getWeekNumber(day, month) {
  const now = new Date()
  const date = new Date(now.getFullYear(), month, day)
  let firstOfJan = new Date(now.getFullYear(), 0, 1)
  return Math.ceil(
    ((date - firstOfJan) / 86400000 + firstOfJan.getDay() + 1) / 7
  )
}

export function getDiffInMinutes(startDate, endDate) {
  return Math.round((endDate.valueOf() - startDate.valueOf()) / 60000)
}

// Month here is 1-indexed (January is 1, February is 2, etc). This is because we're using 0
// as the day so that it returns the last day of the last month, so you have to add 1
// to the month number so it returns the correct amount of days
export function getDaysInMonth(month, year) {
  return new Date(year, month, 0).getDate()
}

// Mainly for Timer.js
export function calculateMinutesFromAngle(angle) {
  return Math.round(angle / ((2 * Math.PI) / (24 * 12))) * 5
}

export function calculateTimeFromAngle(angle) {
  const roundedAngle = roundAngleToFives(angle)
  let totalMinutes = calculateMinutesFromAngle(roundedAngle)
  const fullHours = Math.floor(totalMinutes / 60)
  const hours = fullHours > 9 ? "" + fullHours : "0" + fullHours

  const remainingMinutes = totalMinutes - hours * 60
  const minutes =
    remainingMinutes > 9 ? "" + remainingMinutes : "0" + remainingMinutes

  return { h: hours, m: minutes }
}

export function roundAngleToFives(angle) {
  let fiveMinuteAngle = (2 * Math.PI) / 288
  return Math.round(angle / fiveMinuteAngle) * fiveMinuteAngle
}

export function calculateAngleFromCurrentDate() {
  const date = new Date()
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const totalMinutes = hours * 60 + minutes
  const minutesInADay = 24 * 60
  return roundAngleToFives(2 * Math.PI * (totalMinutes / minutesInADay))
}

export function calculateAngleFromDate(date) {
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const totalMinutes = hours * 60 + minutes
  const minutesInADay = 24 * 60
  return roundAngleToFives(2 * Math.PI * (totalMinutes / minutesInADay))
}

export function calculateAngleFromMinutes(minutes) {
  const minutesInADay = 24 * 60
  return roundAngleToFives(2 * Math.PI * (minutes / minutesInADay))
}

export function calculateAngleFromHour(hours) {
  const totalMinutes = hours * 60
  const minutesInADay = 24 * 60
  return roundAngleToFives(2 * Math.PI * (totalMinutes / minutesInADay))
}

function degreesToRadians(degrees) {
  return (degrees * Math.PI) / 180
}

export function distanceInKmBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
  const earthRadiusKm = 6371

  const dLat = degreesToRadians(lat2 - lat1)
  const dLon = degreesToRadians(lon2 - lon1)

  lat1 = degreesToRadians(lat1)
  lat2 = degreesToRadians(lat2)

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return earthRadiusKm * c
}

export function calculateMinutesBetween(start, end) {
  let hours = end.h - start.h
  const minutes = end.m - start.m

  if (start.h > end.h) {
    hours = 24 - start.h + end.h
  }

  const totalMinutes = 60 * hours + minutes
  //console.log("hours " + hours + " + minutes " + minutes + " == " + totalMinutes)

  return totalMinutes
}

export const GEOLOCATION_OPTIONS = {
  enableHighAccuracy: true,
  timeout: 20000,
  maximumAge: 1000
}

export const OCD = "b7f0027f76ad487984dd55c2433ee51b"

export function localeTime(date) {
  const language = getLanguage()
  const time = new Date(date)
  switch (language) {
    case "FI":
      return time.toLocaleString("fi-FI")
    case "EN":
      return time.toLocaleString("en-GB")
    default:
      return time.toLocaleString()
  }
}

export function checkPassword(password) {
  if (password.length > 7) {
    //return tr("_short_password")
    if (/[a-z]/.test(password)) {
      if (/[A-Z]/.test(password)) {
        if (/[0-9]/.test(password)) {
          return "ok"
        }
      }
    }
  }
  return tr("_password_form_requirements")
}
