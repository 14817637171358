import React from "react"

import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Dialog from "@material-ui/core/Dialog"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"

import useTheme from "@material-ui/core/styles/useTheme"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { tr } from "translations"
import { Close } from "../SvgLib"

/**
 * Modal to add attachment
 */
export default function ForgotPasswordSentModal(props) {
  const { isOpen, onClose } = props
  const theme = useTheme()
  const classes = useStyles()

  return (
    <Dialog
      modal="true"
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
      className={classes.dialogRoot}
      fullWidth={true}
      maxWidth={"lg"}
    >
      <Paper className={classes.modalPaper} elevation={2}>
        <Toolbar className={classes.root}>
          <IconButton
            className={classes.buttonGroupRight}
            aria-label="Print"
            onClick={onClose}
          >
            <Close
              style={{ height: "30px" }}
              color={theme.palette.text.primary}
            />
          </IconButton>
        </Toolbar>
        <Grid container justify="center" className={classes.container}>
          <Grid item xs={10}>
            <Typography
              align="center"
              variant="h6"
              noWrap
              className={classes.infoTitle}
            >
              {tr("_check_your_email")}
            </Typography>

            <Typography
              align="center"
              variant="subtitle1"
              className={classes.infoText}
            >
              {tr("_link_to_change_email")}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    minWidth: 300,
    maxWidth: 600,
    margin: "auto"
  },
  modalPaper: {},
  root: {
    margin: 0
  },
  infoTitle: {
    color: theme.palette.text.primary
  },
  infoText: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(8),
    color: theme.palette.text.primary
  },
  buttonGroupRight: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.text.primary
  }
}))
