import React from "react"

import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import useTheme from "@material-ui/core/styles/useTheme"

import { tr } from "translations"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

const HideButton = props => {
  const { hidden, handleHidden, width } = props
  const theme = useTheme()

  return (
    <Button
      variant="contained"
      style={{
        color: theme.palette.button.hide,
        borderRadius: 25,
        backgroundColor: theme.palette.primary.dark,
        width: width - 150,
        marginLeft: 75
      }}
      onClick={handleHidden}
      endIcon={hidden ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      size="large"
    >
      <Typography>{hidden ? tr("_open") : tr("_hide")}</Typography>
    </Button>
  )
}

export default HideButton
