const checkIfOnlyTemp = allData => {
  let onlyTemp = false

  allData.forEach(deviceData => {
    deviceData.forEach(row => {
      if (!row.humidity || !row.pressure) {
        onlyTemp = true
      }
    })
  })
  return onlyTemp
}

const deviceList = (iotDevices, selected) => {
  let list = []
  if (("SITE", selected.type)) {
    list = iotDevices.iotDeviceList.filter(
      // TODO: instead of filtering TreonNode, get metadata and check where to get these values
      device =>
        device.site === selected.selected[0] &&
        (device.deviceType === "TreonNode" ||
          device.deviceType === "TreonAssetNode" ||
          device.deviceType === "Ruuvi")
    )
    if (!list || list.length == 0) {
      list = iotDevices.iotDeviceList.filter(device =>
        selected.selected.includes(device.deviceId)
      )
    }
  }
  return list
}

const formAllData = (deviceDataStore, devices) => {
  let allData = []
  devices.forEach(device => {
    let dataSeries = deviceDataStore[device.deviceId] || []
    allData = allData.sort((a, b) => b.ts - a.ts)
    if (dataSeries && dataSeries.length > 0) {
      allData = [...allData, dataSeries]
    }
  })
  return allData
}

export const dataConvertAverage = (values, state) => {
  const input = [...values]
  const devices = deviceList(state.iotDevices, input)
  const allData = formAllData(state.dataUpdate.deviceDataStore, devices)
  const onlyTemp = checkIfOnlyTemp(allData)
  const output = {
    values: {
      type: input.splice(0, 1)[0],
      selected: [...input]
    },
    valueTypes: onlyTemp
      ? ["temperature"]
      : ["temperature", "humidity", "airpressure"]
  }
  return output
}
